import { useEffect, useRef, useState, useContext } from 'react';
import { MicFill, MicMuteFill, Telephone } from "react-bootstrap-icons";
import { Avatar } from '@mui/material';
import { useDispatch } from "react-redux";
import sendHttpRequest, { search } from '../../components/hooks/sendHttpRequest';
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Modal } from 'react-bootstrap';

import { useStopwatch } from "react-timer-hook";
import CloseIcon from "@mui/icons-material/Close";
import { useToggle } from '../../components/hooks/useToggle';
import "./PatientPortalVideoCall.css";
import { VideoCallContext } from '../../components/UI/AgoraHooks/VideoCallContext';
import { validateNullUndefined, videoUnloadHandler } from '../../utils/helpers/HelperFunctions';
import CustomizedSnackbar from '../../components/UI/General/CustomizedSnackbars';
import { setConsulationInProgress, setVideoOpen } from '../../redux/actions/providerActions';
import { dateTimeHelper } from '../../utils/dateTimeHelper';


const EndConsultationModal = ({
    show,
    handleEndConsulationModal,
    hours,
    minutes,
    seconds,
    setIsConsultaitonEnded,

}) => {

    const dispatch = useDispatch();
    const videoCallContext = useContext(VideoCallContext);
    const provider = videoCallContext?.appointmentData?.data?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0];
    const [isSessionEnding, setIsSessionEnding] = useState(false);

    const sessionBreakRequest = async (appointmentId, hours, minutes, seconds) => {
        setIsSessionEnding(true);

        const breakType = "LEAVE"
        let timeInSeconds = 0;
        try {
            timeInSeconds = (Number(hours) * 60 * 60) + Number(minutes) * 60 + Number(seconds);
        }
        catch (e) {
            console.error("CANNOT CONVERT TIME INTO SECONDS: ", e);
        }

        const httpResponse = await sendHttpRequest({
            method: "POST",
            url: `/video/SessionBreakRequest`,
            data: {
                appiontmentId: appointmentId,
                videoJoinRequestType: breakType,
                callDuration: timeInSeconds || 10000,
            }
        })

        if (!httpResponse.error) {

            videoCallContext.endSession().then(() => {
                videoCallContext.clearVideoCallContextData();
                dispatch(setConsulationInProgress(false));
                dispatch(setVideoOpen(false));
                localStorage.removeItem('context');
            });

        }
        setIsSessionEnding(false);
    }


    return (
        <>
            <Modal
                show={show}
                size="md"
                dialogClassName="end-consultation-modal-dialog"
                backdropClassName="modal-backdrop"
                contentClassName="modal-border modal-shadow"
                centered
            >
                <Modal.Header style={{ border: "none" }}>
                    <div className="row ">
                        <div className="col-12 ">
                            <span
                                style={{
                                    lineHeight: "12px",
                                    width: "18px",
                                    fontSize: "8pt",
                                    color: "#336383",
                                    position: "absolute",
                                    top: 15,
                                    right: 15,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    handleEndConsulationModal();
                                }}
                            >
                                {<CloseIcon />}
                            </span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row py-3">
                        <div className="col-sm-12">
                            <h2 className="font-18 fw-sb text-center">End Consultation</h2>
                        </div>
                    </div>

                    <div className="row justify-content-center py-3">
                        <div className="col-sm-9">
                            <span className="font-14 text--secondary d-block text-center">
                                Would you like to end the consultation with provider{" "}
                                <span className="fw-sb text--terciary">

                                    {provider?.prefix !== undefined && provider?.prefix !== null ? provider?.prefix + " " : 'Dr. '}
                                    {provider?.firstName !== undefined && provider?.firstName !== null && provider?.firstName + " "}
                                    {provider?.lastName !== undefined && provider?.lastName !== null && provider?.lastName}
                                </span>{" "}
                                ?
                            </span>
                        </div>
                    </div>

                    <div className="row justify-content-center py-3">
                        <div className="col-sm-5">
                            <button
                                disabled={isSessionEnding}
                                onClick={handleEndConsulationModal}
                                className="btn w-140 br-10 bg--d9e8ec shadow-none text--blue font-14 fw-sb"
                            >
                                Cancel
                            </button>
                        </div>

                        <div className="col-sm-5">
                            <button
                                disabled={isSessionEnding}
                                onClick={async () => {
                                    // dispatch(setConsulationInProgress(false));
                                    await sessionBreakRequest(videoCallContext.appointmentData?.data?.id);
                                    setIsConsultaitonEnded(true);
                                    handleEndConsulationModal(false);
                                    window.location.href = "/eportal/patient-portal/dashboard";
                                    // refresh();
                                    // window.location.origin + "/patient-portal/dashboard";
                                    // navigate("/patient-portal/dashboard", { replace: true });
                                }}
                                className="btn w-140 br-10 bg--red shadow-none text-white font-14 fw-sb"
                            >
                                End
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

function stringAvatar(name) {
    if (name !== null) {
        // console.log(name)
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
    }
}

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}





const PatientPortalVideoCall = () => {
    const data = JSON.parse(localStorage?.getItem("data"));
    const videoCallContext = useContext(VideoCallContext);
    const location = useLocation();


    const [hasProviderLeft, setHasProviderLeft] = useState(false);
    const [mute, setMute] = useState(() => (location?.state?.muteStatus));
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const interval = useRef(null);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const {
        seconds,
        minutes,
        hours,

    } = useStopwatch({ autoStart: true });


    const [volumeOff, setVolumeOff] = useToggle(false);
    const [endConsultation, setEndConsultation] = useToggle(false);
    const [isConsultationEnded, setIsConsultaitonEnded] = useToggle(false);
    const [isSessionEnding, setIsSessionEnding] = useState(false);

    const handleEndConsulationModal = () => {
        setEndConsultation(!endConsultation);
    };

    const handlePublisherAudio = () => {
        if (mute === true) {
            videoCallContext.unmuteAudio();
            setMute(false);
        } else {
            videoCallContext.muteAudio();
            setMute(true);
        }

    };


    let providerName = videoCallContext.appointmentData?.data?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.firstName + " " + videoCallContext.appointmentData?.data?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.lastName;


    const sendTokenRequest = async (appointmentId) => {
        const httpResponse = await sendHttpRequest({
            method: "GET",
            url: `/video/tokenrequest/${appointmentId}`,
        });

        if (!httpResponse.error) {
            console.log(httpResponse);


            // //Check for 200 ok - token received now navigate to the new page
            if (httpResponse.status === 200) {
                //Provider admitted the patient to the video call
                localStorage.setItem("data", JSON.stringify(httpResponse.data));
                clearInterval(interval.current);
                clearInterval(interval);
                interval.current = null;
                videoCallContext.client.join(videoCallContext.apiKey, httpResponse.data.sessionId, httpResponse.data.token)
                    .then((uid) => {
                        try {

                            videoCallContext.client?.publish([videoCallContext.getAudioTrack(), videoCallContext.getVideoTrack()]);
                        } catch (error) {
                            console.error(error);
                        }
                    });
                return { navigate: true, data: httpResponse.data };
            }

            // //403 means you are not allowed to join this video consultaion stop polling
            // if (httpResponse.status === 202) {
            //     return {
            //         message: strings.en.rejectedFromWaitingRoom,
            //         isLoading: false,
            //         navigate: false,
            //     };
            // }
        }
    };



    const checkStatusOfVideoCall = async (videoTrack, audioTrack) => {
        let appId = videoCallContext?.appointmentData?.data?.id;
        const httpResponse = await search({
            method: 'GET',
            url: `/video/JoinRequestStatus/${appId}`
        });
        if (!httpResponse.error) {
            console.log(httpResponse);
            if (httpResponse.data === "LEAVE" || httpResponse.data === "NEW") {
                //Patient is in waiting room
                //Start polling
                setHasProviderLeft(true);
                const fullScreenPublisher = document.getElementById('patient-portal-publisher');
                fullScreenPublisher.style.width = '100%';
                fullScreenPublisher.style.height = '100%';
                fullScreenPublisher.style.left = '0';
                fullScreenPublisher.style.bottom = '0';
                fullScreenPublisher.style.transition = 'all 0.3s ease-in-out';
                interval.current = setInterval(() => sendTokenRequest(appId), 10000);
            }
            else if (httpResponse.data === "END") {
                setHasProviderLeft(true);
                window.location.href = "/eportal/patient-portal/dashboard";
            }

        }
    }








    useEffect(() => {


        videoCallContext.client.on("user-unpublished", async (user, mediaType) => {
            console.log(user, mediaType);
            if (mediaType === "video") {
                console.log("Remote user unpublished video");
                const fullScreenPublisher = document.getElementById('patient-portal-publisher');
                fullScreenPublisher.style.width = '100%';
                fullScreenPublisher.style.height = '100%';
                fullScreenPublisher.style.left = '0';
                fullScreenPublisher.style.bottom = '0';
                fullScreenPublisher.style.transition = 'all 0.3s ease-in-out';
                setOpen(true);
                setMessage("Provider stopped the video");
            }
            if (mediaType === "audio") {
                setOpen(true);
                setMessage("Provider muted the mic");
            }
        })


        videoCallContext.client.on("user-published", async (remoteUser, mediaType) => {
            await videoCallContext.client.subscribe(remoteUser, mediaType);
            if (mediaType === "video") {
                //Logic for Conference video goes here
                remoteUser.videoTrack.play(document.getElementById("patient-portal-subscriber")); // in case of conference ?+
                let subscriberDiv = document.getElementById("patient-portal-subscriber");
                const cornerPublisher = document.getElementById('patient-portal-publisher');
                cornerPublisher.style.width = 'calc(100% - calc(100% - 25%))';
                cornerPublisher.style.height = 'calc(100% - calc(100% - 30%))';
                cornerPublisher.style.left = '1em';
                cornerPublisher.style.bottom = '1em';
                cornerPublisher.style.transition = 'all 0.3s ease-in-out';
                setHasProviderLeft(false);
                videoCallContext.setIsVideoCallActive(true);
                // subscriberDiv.children[2].style.borderRadius = "10px";
                // subscriberDiv.children[2].style.bottom = "1.6em";
            }
            if (mediaType === "audio") {
                remoteUser.audioTrack.play(); // audio does not need a DOM element
            }
        });


        videoCallContext.client.on("user-left", async (u, r) => {
            videoCallContext.client.leave();
            localStorage.removeItem("data");
            await checkStatusOfVideoCall();
        });

        const cleanupEffect = async () => {
            const httpResponse = await sendHttpRequest({
                method: "POST",
                url: `/video/SessionBreakRequest`,
                data: {
                    appiontmentId: videoCallContext.appointmentData?.data?.id,
                    videoJoinRequestType: "LEAVE",
                    callDuration: 10000,
                }
            });
            // Handle the response if necessary
            localStorage.removeItem('context');
        };

        return async () => {
            await cleanupEffect();
        }

    }, [])



    useEffect(() => {
        videoCallContext.createLocalTracks().then((res) => {

            videoCallContext.addVideoTrack(res[1]);
            videoCallContext.addAudioTrack(res[0]);
            res[1].play(document.getElementById("patient-portal-publisher"))
            try {
                if (videoCallContext.client.connectionState?.toLowerCase() !== "connected") {
                    videoCallContext.client
                        .join(videoCallContext.apiKey, data?.data?.sessionId, data?.data?.token)
                        .then((uid) => {
                            try {
                                console.log("mute status=", location?.state?.muteStatus)
                                if (location?.state?.muteStatus === true) {
                                    console.info("Trying muting mic");
                                    videoCallContext.getAudioTrack().setMuted(true);
                                    setMute(true);
                                }
                                videoCallContext.client?.publish([videoCallContext.getAudioTrack(), videoCallContext.getVideoTrack()]);
                            } catch (error) {
                                console.error(error);
                            }
                        });
                }
            }
            catch (e) {
                console.error(e)
            }
        })





        // window.removeEventListener('popstate', handleBack);
        return async () => {
            videoCallContext.endSession().then(() => {
                localStorage.removeItem("data");
                localStorage.removeItem('context');
            });
        };

    }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.setItem("context", JSON.stringify(videoCallContext?.appointmentData?.data));
        }
        const savedState = JSON.parse(localStorage.getItem('context'));
        if (savedState !== null && savedState !== undefined) {
            videoCallContext.updateAppointmentData(savedState);
        }


        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    }, []);

    return (
        <>
            {endConsultation ? (
                <EndConsultationModal
                    show={endConsultation}
                    handleEndConsulationModal={handleEndConsulationModal}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                    setIsConsultaitonEnded={setIsConsultaitonEnded}

                />
            ) : null}
            <div className='call-page-content'>

                <div className='container py-2'>
                    <div className="row justify-content-center">
                        <div className="col-md-8 text-center">
                            <span className="font-14  text--secondary d-inline-block">
                                {dateTimeHelper(videoCallContext?.appointmentData?.data?.appointmentSlot?.startDateTime, "LT")}
                                Video Consultation with:{" "}
                                <span className="fw-sb text--terciary">

                                    {videoCallContext?.appointmentData?.data?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.prefix?.concat(
                                        " ",
                                        videoCallContext?.appointmentData?.data?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.firstName,
                                        " ",
                                        videoCallContext?.appointmentData?.data?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.lastName
                                    )}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="container">

                    <div className='patient-call-wrapper'>
                        <div id="patient-portal-publisher" className='patient-call-publisher'>

                        </div>
                        <div id="patient-portal-subscriber">
                            <div id="patient-portal-subscriber-initials" style={{ display: !hasProviderLeft && validateNullUndefined(providerName) ? 'block' : 'none' }}>
                                {validateNullUndefined(providerName) ? <Avatar {...stringAvatar(providerName)} sx={{ height: 256, width: 256, fontSize: '5rem' }} /> : ''}
                            </div>
                        </div>


                    </div>


                </div>
                <div className="row justify-content-center mt-2  ">
                    <div className="col-8 text-center">
                        <div className="d-flex justify-content-center gap-3">
                            <button
                                onClick={() => {
                                    setVolumeOff();
                                    handlePublisherAudio();
                                }}
                                className={`btn shadow-none justify-content-center br-10 d-flex align-items-center  patient-control-btn`}
                            >
                                {mute === false ? (
                                    <MicFill size={24} />
                                ) : (
                                    <MicMuteFill size={24} />
                                )}
                            </button>
                            <button
                                onClick={() => {
                                    handleEndConsulationModal(true);
                                }}
                                className={`btn shadow-none justify-content-center br-10 d-flex align-items-center  patient-control-btn`}
                                style={{ backgroundColor: "var(--danger)" }}
                            >
                                <Telephone size={24} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedSnackbar
                severity="info"
                open={open}
                handleClose={handleClose}
                message={message}
            />
        </>
    )
}

export default PatientPortalVideoCall