import { useEffect, useRef, useState } from "react"
import classes from '../Header/PatientSearch.module.css';
import sendHttpRequest, { search } from "../../hooks/sendHttpRequest";
import SearchIcon from "@mui/icons-material/Search";
import ProviderSearchItem from "./ProviderSearchItem";
import { useAuth } from "../../hooks/useAuth";
import { searchAllProvidersUrl, searchProviderUrl } from "../../../res/endpointurls";
import _ from 'lodash'

const SearchProvider = (props) => {
  const selectedProviders = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [provider, setProvider] = useState('');
  const searchInputFocus = useRef();
  const { user } = useAuth();

  const searchProvider = async (providerName) => {
    setIsLoading(true);
    setIsError(false);
    let apiUrl = searchProviderUrl(user?.tenantId);
    let param = {
      query : providerName
    }
    if(props?.inMessage){
      apiUrl = searchAllProvidersUrl(user?.tenantId);
      param = {search:providerName}
    }
    let httpResponse = await search({
      url: apiUrl,
      method: "GET",
      params: param,
    });
    if(!httpResponse?.error){
      setSuggestions(httpResponse?.data);
      // console.log(httpResponse);
    }
    else{
      // setSuggestions(httpResponse?.data);
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleOnChange = (inputValue) => {

    if (inputValue !== "") {
      setProvider(inputValue)
      searchProvider(inputValue);
      setSearchInputValue(inputValue);
    } else {
      setProvider('')
      setSuggestions([]);
      setIsLoading(false);
      setIsError(false);
      setSearchInputValue('')
    }
  };

  const handleSearchIconBtn = (event) => {
    event.preventDefault();
    // console.log("event.target.value", searchInputValue);
    handleOnChange(searchInputValue);
  };
  const handleProviderSearch = (provider) => {
    // console.log("Patient ID: ", patient.id);
    props?.handleProvidersAdd(provider);
    setSearchInputValue("");
  };

  useEffect(()=>{
    if(props?.focus===true){
      searchInputFocus?.current?.focus();
    }
  },[])

  return (
    <>
    <div
        className="input-group  bg-white "
        style={{
          border: "1px solid #D9E8EC",
          borderRadius: "10px",
          opacity: "1px",
          width: `${props.width || '396px'}`,

        }}
      >
        <input
          type="text"
          disabled={props?.disable === true ? true : false}
          ref = {searchInputFocus}
          // required={props?.required === true ? true : false}
          className="form-control  shadow-none border-0 ms-1 font-14"
          placeholder={props?.PlaceHolder ? props?.PlaceHolder : 'Search provider' }
          // style={{backgroundColor:'white'}}
          style={props?.disable === true ? { background: '#f2f7f8' ,height:"40px"} : {background:"#fff",height:"40px"}}
          onChange={(event) => _.debounce(handleOnChange(event.target.value), 400)}
          // defaultValue = {searchInputValue}
          value={searchInputValue}
          onBlur={() => {
            setTimeout(() => {
              setSuggestions([]);
              setIsError(false);
              setIsLoading(false);
            }, 5);
          }}
        />
        <button
          className="btn shadow-none"
          type="submit"
          disabled={props?.disable === true ? true : false}
          value={searchInputValue}
          onClick={(e) => _.debounce(handleSearchIconBtn(e), 300)}
          onBlur={() => {
            setTimeout(() => {
              setSuggestions([]);
              setIsError(false);
              setIsLoading(false);
            }, 5);
          }}
        >
          <SearchIcon  style={{ color: '#336383' }} />
        </button>
      </div>
      <div className="d-flex flex-column" style={{zIndex: '99999999'}}>
        <div
          className={suggestions.length > 0 ? `overflowy-auto ${classes["scroll__bar"]}` : undefined}
          style={{ position: "absolute", height: "60vh", zIndex: '99999999', }}
        >
          <ul
            className="list-group w-100"
            style={{
              listStyleType: "none",
              cursor: "pointer",
              paddingLeft: "0",
              zIndex: '99999999',
              border:'1px solid #d9e8ec',
              borderTop:0,
              backgroundColor: '#FFF',
              boxShadow:  "0 2px 8px rgba(0, 0, 0, 0.15)",
             
            }}
          >
            {suggestions?.length === 0 && isLoading ? (
              <ProviderSearchItem
                ProviderName={"No result found"}
                inMessage={props?.inMessage}
                key={0}
              />
            ) : isLoading ? (
              <ProviderSearchItem
              ProviderName={"Loading..."}
              inMessage={props?.inMessage}
              key={0}
              />
            ) : (
              suggestions.length > 0 &&
              suggestions.map((suggestion) => {
                return (
                  <ProviderSearchItem
                    ProviderName={
                      suggestion?.name[0]?.firstName + " " + suggestion?.name[0]?.lastName
                    }
                    key={suggestion?.userId}
                    Provider={suggestion}
                    OnMouseDown={handleProviderSearch}
                    width={props.width}
                    inMessage={props?.inMessage}
                  />
                );
              })
            )}
          </ul>
        </div>
      </div>
    </>
  )
}

export default SearchProvider;