import React, { useEffect } from 'react'
import FamilyMedicalHistoryTable from '../FamilyMedicalHistoryTable'
import HospitalizationTable from '../HospitalizationTable'
import MedicationHistoryTable from '../MedicationHistoryTable'
import SocialHistoryTable from '../SocialHistoryTable'
import SurgeryTable from '../SurgeryTable'
import PatientSocialHistoryView from './PatientSocialHistoryView'
import DiagnosisHistoryTable from '../DiagnosisHistoryTable'
import { useSelector } from 'react-redux'
import { ENCOUNTERS } from '../../../utils/helpers/subscriptionsConstants'

const PatientHistoryView = (props) => {
    const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
    
    
    

  return (
    <div className='container-fluid '>
        <div className='row mt-4 justify-content-center'>
            <div className='col-sm-11' hidden={subscriptionPrivilegeGroups[ENCOUNTERS] === undefined}>
            <DiagnosisHistoryTable
                    patientId={props?.patientId}
                    viewOnly={true}
                  />
            </div>
        </div>
        <div className='row mt-4 justify-content-center'>
            <div className='col-sm-11'>
            <MedicationHistoryTable
                    patientId={props?.patientId}
                    viewOnly={true}
                  />
            </div>
        </div>

        <div className='row mt-5 justify-content-center'>
            <div className='col-sm-11'>
            <FamilyMedicalHistoryTable
                    patientId={props?.patientId}
                    viewOnly={true}
                  />
            </div>
        </div>

        <div className='row mt-5 justify-content-center'>
            <div className='col-sm-11'>
                <SurgeryTable patientId={props?.patientId} viewOnly={true} />
            </div>
        </div>

        <div className='row mt-5 justify-content-center'>
            <div className='col-sm-11'>
                <HospitalizationTable patientId={props?.patientId} viewOnly={true} />
            </div>
        </div>

        <div className='row mt-5 justify-content-center'>
            <div className='col-sm-11'>
                <PatientSocialHistoryView patientId={props?.patientId} />
            </div>
        </div>
    </div>
  )
}

export default PatientHistoryView