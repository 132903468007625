import { useCallback, useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classes from "./AppointmentScheduleModal.module.css";
import moment from "moment";
import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useSelector } from "react-redux";
import {
  calenderDateTimeHelper,
  convertUTCtoTimzone,
  dateTimeHelper,
} from "../../../../utils/dateTimeHelper";
import CustomDatePicker from "../../General/CustomDatePicker";
import { RolesTextConversion } from "../../../../utils/helpers/RolesMap";
import { useAuth } from "../../../hooks/useAuth";
import {
  deleteAvailableSlot,
  providerWithNpiUrl,
} from "../../../../res/endpointurls";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import EditButtonIcon from "../../../../assets/icons/EditIcon";
import DeleteConfirmationModal from "../PatientModals/DeleteConfirmationModal";
import ErrorModal from "../PatientModals/ErrorModal";
import CalenderDatePicker from "../../General/CalenderDatePicker";
import { APPOINTMENTS } from "../../../../utils/helpers/subscriptionsConstants";

const ScheduleSlotRow = (props) => {
  console.log("props?.day: ", props?.day);

  return (
    <>
      <div className="pt-2 pe-0 ps-0 mb-1">
        <input
          type="time"
          name="startTime"
          data-format="hh:mm"
          data-day={props?.day}
          onInvalid={(e) => {
            e.target.setCustomValidity("Please enter a valid time");
          }}
          onInput={(e) => {
            e.target.setCustomValidity("");
          }}
          id={props.id}
          defaultValue={props.slotTime !== undefined && props.slotTime}
          style={{
            color: "#004FD4",
            background: "#F2F7F8 0% 0% no-repeat padding-box",
            fontWeight: "600",
            borderRadius: "10px",
            fontFamily: "Montserrat",
            textTransform: "capitalize",
            border: "1px solid #D9E8EC",
            height: "40px",
            width: "76px",
          }}
          className="font-13 p-1"
          onChange={(e) => props.handleSlotTimeChange(e, props?.slotTimekey)}
        />
      </div>
      {/* {console.log("props.slotObject", props.slotObject)} */}
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "0px",
    // border: "1px solid #D9E8EC",
    fontFamily: "Montserrat",
    // border: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    // backgroundColor: "#FAFCFC",
    height: 40,
  },
  "&:nth-of-type(odd)": {
    // backgroundColor: "#FAFCFC",
    height: 40,
  },
  "&:last-child td, &:last-child th": {
    // borderBottom: 0,
    border: 0,
  },
}));

 
function FacilitySelect({ providerFacilities,updateSlot,setUpdateSlot }) {
  const [selectedFacility,setSelectedFacility] = useState(updateSlot.facilityId)
  useEffect(() => {
    console.log("useEffect triggered. Facility ID:", updateSlot.facilityId,selectedFacility);
    setUpdateSlot({
      ...updateSlot,
      facilityId: selectedFacility,
    })
  }, [selectedFacility]);

  return (
    <div>
      <select
        className="form-select font-12 border--default br-10 shadow-none input"
        aria-label="action select"
        style={{ height: "36px" }}
        onChange={(e) =>{
          e.preventDefault();
          setSelectedFacility(e.target.value) 
        }
        }
        value={selectedFacility}
      >
        {providerFacilities?.length > 0 ? (
          providerFacilities.map((facilityItem) => (
            <option
              key={facilityItem?.facilityId}
              value={facilityItem?.facilityId}
            >
              {facilityItem?.businessName}
            </option>
          ))
        ) : (
          <option key={"no-data"} value={""} disabled>
            No data available
          </option>
        )}
      </select>
    </div>
  );
}

const ViewScheduleModal = (props) => {
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } =
    useSelector((state) => state.subscriptionPrivilegeGroups);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [timexone, setTimexone] = useState("");
  const [selectedProviderId, setSelectedProviderId] = useState();
  // const [selectedProviderId, setSelectedProviderId] = useState();
  const [prescribersList, setPrescribersList] = useState([]);
  const [isProviderScheduleLoading, setIsProviderScheduleLoading] =
    useState(false);
  const [prescriberScheduleData, setPrescriberScheduleData] = useState([]);
  const { user } = useAuth();
  let joined = convertUTCtoTimzone(new Date(), "", "YYYY-MM-DD");
  const [startDate, setStartDate] = useState(joined);
  const joinedEndDate = convertUTCtoTimzone(
    moment(new Date()).add(6, "days"),
    "",
    "YYYY-MM-DD"
  );
  // console.log("user?.timezone: ",user?.timezone,joinedEndDate);
  const [nextWeekDate, setNextWeekDate] = useState(joinedEndDate);
  const [endDate, setEndDate] = useState(nextWeekDate);
  const [deleteMedicationHistoryId, setDeleteMedicationHistoryId] = useState();
  const [deleteMedicationHistoryModal, setDeleteMedicationHistoryModal] =
    useState(false);
  const [providerFacilities, setProviderFacilities] = useState([]);
  const [isProviderFacilitiesLoading, setIsProviderFacilitiesLoading] =
    useState(false);
  // const [selectedFacilityId,setSelectedFacilityId] = useState(user?.facility?.facilityId)
  const [selectedFacilityId, setSelectedFacilityId] = useState("");
  const [weekStartDay, setWeekStartDay] = useState(
    JSON.parse(localStorage.getItem("user")).tenant?.weekStartDay
  );
  const [updateSlot, setUpdateSlot] = useState({
    provider: {
      userId: "",
    },
    TimeZone: "",
    startFrom: "",
    endDate: "",
    schedule: {
      monday: [
        {
          slot: -1,
          startTime: "",
          endTime: "",
          isValid: true,
        },
      ],
    },
    tenantId: user?.tenantId,
    facilityId: "",
  });

  const [editRowIndex, setEditRowIndex] = useState(null);
  const [isScheduledPublished, setIsScheduledPublished] = useState(false);
  const [disableUpdate,setDisableUpdate] = useState(false);
  const [isAptsExist,setIsAptsExist] = useState(false);

  const [errorModalBodyText,setErrorModalBodyText] = useState("")
  const [errorModalHeaderText,setErrorModalHeaderText] = useState("")

  const date = new Date();
  let curentDate = dateTimeHelper(date, "YYYY-MM");

  const handleEditClick =
    (index, availableSlot) => {
      console.log("availableSlot: ", availableSlot);
      let day = moment(
        dateTimeHelper(availableSlot?.startDateTime, "YYYY-MM-DD"),
        "YYYY-MM-DD"
      ).format("dddd");
      console.log("Day: ", day);
  
      setEditRowIndex(index); // Set the row index to edit
      setUpdateSlot((prevUpdateSlot) => ({
        ...prevUpdateSlot,
        provider: {
          userId: selectedProviderId, // Ensure this dependency is correct
        },
        day: day,
        startFrom: dateTimeHelper(availableSlot?.startDateTime, "YYYY-MM-DD"),
        endDate: dateTimeHelper(availableSlot?.endDateTime, "YYYY-MM-DD"),
        id:availableSlot?.id,
        schedule: {
          [day]: [
            {
              slot: availableSlot?.id,
              startTime: dateTimeHelper(availableSlot?.startDateTime, "HH:mm"),
              endTime: dateTimeHelper(availableSlot?.endDateTime, "HH:mm"),
              isValid: true,
            },
          ],
        },
        startTime: dateTimeHelper(availableSlot?.startDateTime, "HH:mm"),
        endTime: dateTimeHelper(availableSlot?.endDateTime, "HH:mm"),
        facilityId: updateSlot?.facilityId?.length > 0 ? updateSlot?.facilityId: availableSlot?.providerTenantPracticeLocation?.facility?.facilityId,
        slotId: availableSlot?.id,
      }));
    }
  console.log("updateSlot+++++++++++++++: ", updateSlot);

  const handleSaveClick = () => {
    setEditRowIndex(null); // Reset edit state after saving
  };
  const fecthAllPrescribers = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    }
    setIsLoading(false);
  };

  const fetchProviderFacilities = async (userId, tenantId) => {
    setIsProviderFacilitiesLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${userId}/tenant/${tenantId}/facilities`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setProviderFacilities(httpResponse.data);
    }
    setIsProviderFacilitiesLoading(false);
  };

  const updateProviderSchedule = async (cancelAppointments) => {
    setIsScheduledPublished(true);
    // setIsLoading(true);
    console.log("updateSlot: --------------", updateSlot);

    // Given date and time
    const localStartDateTime = updateSlot?.startFrom.concat(
      "T",
      updateSlot?.startTime,
      ":00"
    );
    const localEndDateTime = updateSlot?.endDate.concat(
      "T",
      updateSlot?.endTime,
      ":00"
    );

    // Convert to UTC
    // const utcDateTime = moment(localDateTime).utc().format("YYYY-MM-DDTHH:mm:ss");

    const httpResponse = await sendHttpRequest({
      url: `/providers/${updateSlot?.provider?.userId}/availabilities/${updateSlot?.slotId}`,
      method: "PUT",
      data: {
        endDate: updateSlot?.endDate,
        facilityId: updateSlot?.facilityId,
        provider: {
          userId: updateSlot?.provider?.userId,
        },
        schedule: { ...updateSlot?.schedule },
        providerTenantPracticeLocation:{facility:{facilityId:updateSlot?.facilityId}},

        // startDateTime: moment.utc(dateTimeHelper(localStartDateTime,"YYYY-MM-DDTHH:mm:ss")).format("YYYY-MM-DDTHH:mm:ss"),
        // endDateTime: moment.utc(dateTimeHelper(localEndDateTime,"YYYY-MM-DDTHH:mm:ss")).format("YYYY-MM-DDTHH:mm:ss"),
        startDateTime: localStartDateTime,
        endDateTime: localEndDateTime,

        startFrom: updateSlot?.startFrom,
        tenantId: updateSlot?.tenantId,
        cancelAppointments:cancelAppointments
      },
    });
    if (!httpResponse.error) {
      props?.setIsScheduleUpdated(true);
      props?.setScheduleUpdateSeverity("success");
      props?.setScheduleUpdateMsg("Schedule Updated Successfully");
      if(props?.fecthAppointments !== undefined){
        props?.fecthAppointments(curentDate);
      }
      setEditRowIndex(null);
      setUpdateSlot(undefined);
      fecthProviderSchedule(
        selectedProviderId,
        startDate,
        endDate,
        ""
      );
      setSelectedFacilityId(undefined);
      if (user?.role !== "PracticeAdmin") {
        fetchProviderFacilities(updateSlot?.provider?.userId, user?.tenantId);
      }
      // props?.handleModal();
      // console.log(httpResponse.data);
    } else {
      props?.setIsScheduleUpdated(true);
      props?.setScheduleUpdateSeverity("error");
      props?.setScheduleUpdateMsg("Something went wrong");
    }
    // setIsLoading(false);
    setIsScheduledPublished(false);
  };

  const deleteAvailableSlotRequestHandler = async (slotId) => {
    // setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: deleteAvailableSlot(slotId),
      method: "DELETE",
    });
    if (!httpResponse.error) {
      handleDeleteCloseModal();
      fecthProviderSchedule(
        selectedProviderId,
        startDate,
        nextWeekDate,
        selectedFacilityId
      );
    }
    // setIsLoading(false);
  };

  const fecthProviderSchedule = async (
    prescriberId,
    startDate,
    endDate,
    facilityId
  ) => {
    // console.log("prescriberId: ",prescriberId);
    setIsProviderScheduleLoading(true);
    setPrescriberScheduleData([]);
    if (prescriberId !== undefined && prescriberId !== 0) {
      const httpResponse = await sendHttpRequest({
        url: "/availabilities",
        method: "GET",
        params: {
          prescriberId: prescriberId,
          from: startDate,
          to: endDate,
          tenantId: user?.tenantId,
          facilityId: facilityId,
        },
      });
      if (!httpResponse.error) {
        // console.log("prescriber schedule data-> ", httpResponse.data);
        if (httpResponse.data !== undefined) {
          if (
            httpResponse.data?.length > 0 &&
            httpResponse.data[0]?.startDateTime !== null
          ) {
            // Format the date
            let date = dateTimeHelper(
              httpResponse.data[0].startDateTime,
              "ddd, MMM D, yyyy"
            );

            // Check if the week starts on Monday and the date includes "Sun"
            if (weekStartDay === "Monday" && date.includes("Sun")) {
              // Move the first element to the end of the array
              const shiftedData = [...httpResponse.data];
              const firstRow = shiftedData.shift(); // Remove the first element
              shiftedData.push(firstRow); // Add it to the end

              httpResponse.data = shiftedData; // Update the data
            }

            setPrescriberScheduleData(httpResponse.data); // Set the updated data
          }
        }
      }
    }
    setIsProviderScheduleLoading(false);
  };

  const checkIfAppointmentExists = async (appointmentId,update) => {
    const httpResponse = await sendHttpRequest({
      url: `/availabilities/check/provider/${selectedProviderId}/tenant/${user?.tenantId}/availability/${appointmentId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      if (httpResponse.data) {
        if(update === false){
          
          setDeleteMedicationHistoryId(appointmentId);
          setDeleteMedicationHistoryModal(!deleteMedicationHistoryModal);
        }
        if(update === true){
          updateProviderSchedule(false);
          setIsAptsExist(false);
        }
      } else {
        if(update === true){
          setIsAptsExist(true);
          setErrorModalBodyText(p=>"There are appointments scheduled for this slot. Do you want to proceed to cancel those appointments?")
          setErrorModalHeaderText(p=>"Cancel Appointments")
          setIsError(true);
        }
        else{
          setErrorModalBodyText(p=>"There are appointments scheduled for this time slot. Please confirm to cancel all appointments")
          setErrorModalHeaderText(p=>"Unable to delete the selected time slots")
          setIsError(true);
        }
      }
    }
  };

  const handleDeleteModal = async (id) => {
    await checkIfAppointmentExists(id,false);
  };

  const handleDeleteCloseModal = () => {
    setDeleteMedicationHistoryModal(!deleteMedicationHistoryModal);
    setDeleteMedicationHistoryId(null);
  };

  const handleErrorCloseModal = () => {
    setIsAptsExist(false);
    setIsError(false);
  };

  const handleProviderId = (event) => {
    setIsAptsExist(false);
    setEditRowIndex(null);
    let pId = event.target.value;
    setSelectedFacilityId("");
    setSelectedProviderId(pId);
    fetchProviderFacilities(pId, user?.tenantId);
    fecthProviderSchedule(pId, startDate, nextWeekDate, selectedFacilityId);
    setUpdateSlot({ ...updateSlot, provider: { userId: pId } });
  };

  const handleScheduleStartDate = (dateValue) => {
    let nextWeek = moment(new Date(dateValue))
      .add(6, "days")
      .format("YYYY-MM-DD");
    setStartDate(moment(dateValue).format("YYYY-MM-DD"));
    setNextWeekDate(nextWeek);
    setEndDate(nextWeek);
    fecthProviderSchedule(
      selectedProviderId,
      dateValue,
      nextWeek,
      selectedFacilityId
    );
  };

  const handleScheduleEndDate = (dateValue) => {
    if (startDate > dateValue) {
      setNextWeekDate(
        moment(new Date(startDate)).add(6, "days").format("YYYY-MM-DD")
      );
    } else {
      setNextWeekDate(dateValue);
    }
    fecthProviderSchedule(
      selectedProviderId,
      startDate,
      dateValue,
      selectedFacilityId
    );
  };

  const getUserTimezone = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/auth/user/timezone",
      method: "GET",
    });
    if (!httpResponse.error) {
      setTimexone(httpResponse?.data?.timezone);
      setUpdateSlot({ ...updateSlot, TimeZone: httpResponse?.data?.timezone });
    } else {
      console.log(httpResponse.error);
    }
  };

  const handlefacilityId = (facilityId) => {
    setIsAptsExist(false);
    setSelectedFacilityId(facilityId);
    fecthProviderSchedule(selectedProviderId, startDate, endDate, facilityId);
    setUpdateSlot({ ...updateSlot, facilityId: facilityId });
  };

  const handleSlotsDayTimeChange = (event, timeKey) => {
    const e = event.target;
    const slotId = e.id; // Unique identifier for the slot
    const newValue = e.value; // The updated time value
    const day = e.getAttribute("data-day"); // The day of the week associated with the slot

    setUpdateSlot((prevState) => {
      // Ensure `schedule` is initialized properly
      let updatedSchedule = { ...prevState.schedule };
      let startTimeValue = updatedSchedule[updateSlot.day][0]["startTime"];
      let endTimeValue = updatedSchedule[updateSlot.day][0]["endTime"];
      let isTimeValid = true;
      if (timeKey === "startTime") {
        updatedSchedule[updateSlot.day][0]["startTime"] = newValue;
        prevState["startTime"] = newValue;
        isTimeValid =   checkTimeValidity(newValue,endTimeValue);
        showRemoveorderOnValidity(slotId,isTimeValid);
        // console.log("checkTimeValidity(startTimeValue,endTimeValue): ",checkTimeValidity(newValue,endTimeValue));
        
      } else {
        updatedSchedule[updateSlot.day][0]["endTime"] = newValue;
        prevState["endTime"] = newValue;
        isTimeValid = checkTimeValidity(startTimeValue,newValue);
        showRemoveorderOnValidity(slotId,isTimeValid);
        // console.log("checkTimeValidity(startTimeValue,endTimeValue): ",checkTimeValidity(startTimeValue,newValue));
      }
      setDisableUpdate(!isTimeValid);
      // console.log("updatedSchedule: ", updatedSchedule);
   
      return {
        ...prevState,
        slotId: slotId,
        schedule: updatedSchedule,
      };
    });
  };

  const checkTimeValidity = (startTime, endTime) => {
    
    // Ensure both startTime and endTime are provided
    if (!startTime || !endTime) {
      return false; // Invalid if either time is missing
    }
  
    // Parse times into hours and minutes
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
  
    // Convert times to total minutes for comparison
    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;
  
    // Check if endTime is after or equal to startTime
    return endTotalMinutes >= startTotalMinutes;
  };
   
  
  const showRemoveorderOnValidity  = (timeObjectId, isValid) => {
    // Get all elements matching the given ID
    const timeObjects = document.querySelectorAll(`[id='${timeObjectId}']`);
  
    timeObjects.forEach((timeObject) => {
     
      // Update the CSS styles based on the validity
      if (isValid) {
        timeObject.style.border = "1px solid #D9E8EC";
      } else {
        timeObject.style.border = "1px solid #FD0D1B";
      }
    });
  };
  
  useEffect(() => {
    getUserTimezone();
    if (prescribersList?.length === 0) {
      fecthAllPrescribers();
      fecthProviderSchedule(
        props?.selectedPrescriberId,
        startDate,
        endDate,
        selectedFacilityId
      );
      if (user?.role !== "PracticeAdmin") {
        fetchProviderFacilities(props?.selectedPrescriberId, user?.tenantId);
      }
    }

    setSelectedProviderId(props?.selectedPrescriberId);
  }, [props?.selectedPrescriberId]);


 

  return (
    <>
      <DeleteConfirmationModal
        show={deleteMedicationHistoryModal}
        handleModal={handleDeleteCloseModal}
        deleteMedicaitonHistoryId={deleteMedicationHistoryId}
        modalText={"Are you sure you want to remove this time slot?"}
        deleteMedicationHistoryRequestHandler={
          deleteAvailableSlotRequestHandler
        }
      />
      <ErrorModal
        show={isError}
        handleModal={handleErrorCloseModal}
        modalText={errorModalBodyText}
        modalHeaderText={errorModalHeaderText}
        isAptsExist = {isAptsExist}
        updateProviderSchedule={updateProviderSchedule}
      />
      <ErrorModal
        show={isError}
        handleModal={handleErrorCloseModal}
        modalText={errorModalBodyText}
        modalHeaderText={errorModalHeaderText}
        isAptsExist={isAptsExist}
        updateProviderSchedule={updateProviderSchedule}
      />
      <Modal
        show={props.show}
        onHide={() => {
          setPrescriberScheduleData([]);
          props.handleModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        // dialogClassName={classes.root}
        scrollable
        centered
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb">
            View Schedule
            <div className="text--terciary font-12" style={{ fontWeight: 400 }}>
              {/* {"All times in: " + Date().slice(Date().indexOf("(") + 1, Date().lastIndexOf(")"))} */}
              {"All times in: "} {timexone}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row p-2 mt-1 mb-5 ">
            <div className="col-sm-3 col-md-3 mb-3">
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={"70%"}
                  height={40}
                />
              ) : (
                <div>
                  <label className="text--terciary b2 fw-sb">Provider</label>

                  <select
                    className={`form-select font-12 border--default br-10 shadow-none ${classes["custom-select"]}`}
                    aria-label="Provider select"
                    style={{
                      borderRadius: "10px",
                    }}
                    onChange={(e) => handleProviderId(e)}
                  >
                    <option value="" disabled selected hidden>
                      Select provider
                    </option>
                    {prescribersList !== undefined &&
                      prescribersList?.length > 0 &&
                      prescribersList?.map((prescriberItem) => {
                        return (
                          <option
                            value={prescriberItem?.userId}
                            key={prescriberItem?.userId}
                            selected={
                              props?.selectedPrescriberId ==
                              prescriberItem?.userId
                            }
                          >
                            {prescriberItem?.name[0]?.prefix}{" "}
                            {prescriberItem?.name[0]?.firstName}{" "}
                            {prescriberItem?.name[0]?.lastName}{" "}
                            {RolesTextConversion(prescriberItem?.role) !==
                            undefined
                              ? "(" +
                                RolesTextConversion(prescriberItem?.role) +
                                ")"
                              : ""}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}
            </div>
            <div className="col-sm-3 col-md-3 mb-3">
              {isProviderFacilitiesLoading ? (
                <Skeleton />
              ) : (
                <>
                  <label className="text--terciary b2 fw-sb">
                    {"Facility"}
                  </label>
                  <select
                    className={`form-select font-12  border--default br-10 shadow-none input`}
                    aria-label="action select"
                    style={{
                      height: "36px",
                    }}
                    onChange={(e) => {e.preventDefault(); handlefacilityId(e.target.value)}}
                    value={selectedFacilityId}
                  >
                    {/* <option
                      className="text--terciary"
                      value={""}
                      key={1}
                      selected
                      disabled
                      hidden
                    >
                      {"Select Facility"}
                    </option> */}
                    {providerFacilities?.length > 1 && (
                      <option id={0} value={0} key={0}>
                        {"All"}
                      </option>
                    )}
                    {providerFacilities?.length > 0 ? (
                      providerFacilities.map((facilityItem) => {
                        return (
                          <option
                            key={facilityItem?.facilityId}
                            id={facilityItem?.facilityId}
                            value={facilityItem?.facilityId}
                          >
                            {facilityItem?.businessName}
                          </option>
                        );
                      })
                    ) : (
                      <option key={"no-data"} value={""} disabled>
                        {"No data available"}
                      </option>
                    )}
                  </select>
                </>
              )}
            </div>

            <div className="col-sm-2 col-md-3 mb-3">
              <label className="text--terciary font-12 b2 fw-sb">
                Start Date
              </label>
              <CalenderDatePicker
                f12={true}
                dobHandler={handleScheduleStartDate}
                dateFormat="MMM d, yyyy"
                date={startDate}
                viewSchedule={true}
                required
                key={"CalStartDate"}
              />
            </div>

            <div className="col-sm-3 col-md-3 mb-3">
              <label className="text--terciary b2 fw-sb">End Date</label>
              <CalenderDatePicker
                f12={true}
                dobHandler={handleScheduleEndDate}
                dateFormat="MMM d, yyyy"
                minDate={startDate}
                date={nextWeekDate}
                viewSchedule={true}
                key={"CalEndDate"}
                required
              />
            </div>

            <Divider />

            <div className="row mt-3" style={{ minHeight: "30vh" }}>
              <div className="col-sm-12">
                <TableContainer
                  sx={{
                    borderRadius: "10px",
                    border: "1px solid #D9E8EC",
                  }}
                >
                  <Table>
                    <TableHead
                      sx={{
                        borderTopColor: "#FAFCFC",
                        borderRightColor: "#FAFCFC",
                        borderLeftColor: "#FAFCFC",
                      }}
                    >
                      <StyledTableRow>
                        {/* <StyledTableCell sx={{p:'0.75rem', width:'5rem'}}>Day</StyledTableCell> */}
                        <StyledTableCell sx={{ p: "0.75rem", width: "7rem" }}>
                          Date
                        </StyledTableCell>
                        <StyledTableCell sx={{ p: "0.75rem", width: "5rem" }}>
                          From
                        </StyledTableCell>
                        <StyledTableCell sx={{ p: "0.75rem", width: "5rem" }}>
                          To
                        </StyledTableCell>
                        <StyledTableCell sx={{ p: "0.75rem", width: "5rem" }}>
                          Facility
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ p: "0.75rem", width: "5rem" }}
                        ></StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody sx={{ fontSize: "12px" }}>
                      {isProviderScheduleLoading ? (
                        <StyledTableRow>
                          <StyledTableCell colSpan={5}>
                            <Skeleton animation="wave" />
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : prescriberScheduleData?.length > 0 ? (
                        prescriberScheduleData.map((availableSlot, index) => (
                          <StyledTableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* Start Date */}
                            <StyledTableCell className="text-center">
                              {availableSlot?.startDateTime &&
                                dateTimeHelper(
                                  availableSlot?.startDateTime,
                                  "ddd, MMM D, yyyy"
                                )}
                            </StyledTableCell>

                            {/* Start Time */}
                            <StyledTableCell
                              className="text-center"
                              onClick={(e) =>{
                                e.preventDefault();
                                if(subscriptionPrivilegeGroups[APPOINTMENTS]?.usc?.active === true){
                                  handleEditClick(index, availableSlot)
                                }
                              }}
                            >
                              {editRowIndex === index ? (
                                <ScheduleSlotRow
                                  id={availableSlot.id}
                                  slotObject={updateSlot}
                                  slotTime={updateSlot?.startTime ?? ""}
                                  handleSlotTimeChange={
                                    handleSlotsDayTimeChange
                                  }
                                  day={updateSlot?.day ?? ""}
                                  slotTimekey="startTime"
                                />
                              ) : (
                                availableSlot?.startDateTime &&
                                dateTimeHelper(
                                  availableSlot?.startDateTime,
                                  "LT"
                                )
                              )}
                            </StyledTableCell>

                            {/* End Time */}
                            <StyledTableCell
                              className="text-center"
                              onClick={(e) =>{e.preventDefault();
                                if(subscriptionPrivilegeGroups[APPOINTMENTS]?.usc?.active === true){
                                  handleEditClick(index, availableSlot)
                                }
                              }}
                            >
                              {editRowIndex === index ? (
                                <ScheduleSlotRow
                                  id={availableSlot.id}
                                  slotObject={availableSlot}
                                  handleSlotTimeChange={
                                    handleSlotsDayTimeChange
                                  }
                                  slotTime={updateSlot?.endTime ?? ""}
                                  day={updateSlot?.day ?? ""}
                                  slotTimekey={"endTime"}
                                />
                              ) : (
                                availableSlot?.endDateTime &&
                                dateTimeHelper(availableSlot?.endDateTime, "LT")
                              )}
                            </StyledTableCell>

                            {/* Business Name */}
                            <StyledTableCell className="text-center" 
                              onClick={(e) => {
                                e.preventDefault();
                                if(subscriptionPrivilegeGroups[APPOINTMENTS]?.usc?.active === true){
                                  handleEditClick(index, availableSlot)
                                }
                              }}
                              >
                              {
                              editRowIndex === index ? 
                                <>
                                    <FacilitySelect providerFacilities={providerFacilities} updateSlot={updateSlot} setUpdateSlot={setUpdateSlot}/>
                                  {/* <select
                                    className={`form-select font-12  border--default br-10 shadow-none input`}
                                    aria-label="action select"
                                    style={{
                                      height: "36px",
                                    }}
                                    onChange={(e) =>
                                     setSelectedFacilityId(e.target.value)
                                    }
                                    value={updateSlot?.facilityId}
                                  >
                                    
                                    {providerFacilities?.length > 0 ? (
                                      providerFacilities.map((facilityItem) => {
                                        return (
                                          <option
                                            key={facilityItem?.facilityId}
                                            id={facilityItem?.facilityId}
                                            value={facilityItem?.facilityId}
                                          >
                                            {facilityItem?.businessName}
                                          </option>
                                        );
                                      })
                                    ) : (
                                      <option key={"no-data"} value={""} disabled>
                                        {"No data available"}
                                      </option>
                                    )}
                                  </select> */}
                                </>
                              :
                              availableSlot?.providerTenantPracticeLocation
                                ?.facility?.businessName ?? ""}
                            </StyledTableCell>

                            {/* Actions */}
                            <StyledTableCell className="text-center">
                              <div className="d-flex flex-row col-12 justify-content-center">
                                <div
                                  className={`col-6 ${
                                    editRowIndex !== null ? "disabled-link" : ""
                                  }`}
                                  hidden={
                                    Number(user?.userId) ===
                                    Number(selectedProviderId)
                                      ? subscriptionPrivilegeGroups[
                                          APPOINTMENTS
                                        ]?.dps?.active === false
                                      : subscriptionPrivilegeGroups[
                                          APPOINTMENTS
                                        ]?.dpp?.active === false
                                  }
                                >
                                  <TrashIcon
                                    style={{
                                      color: "#ec609b",
                                      height: "15px",
                                      cursor: "pointer",
                                    }}
                                    id={availableSlot?.id}
                                    onClick={(e) =>
                                      handleDeleteModal(availableSlot?.id)
                                    }
                                  />
                                </div>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <StyledTableRow>
                          <StyledTableCell colSpan={5} className="text-center">
                            No data available
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                    
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {editRowIndex === null ? (
            <button
              className="btn px-5 py-2 blue_task_button"
              onClick={(e) => {
                e.preventDefault();
                setPrescriberScheduleData([]);
                props.handleModal();
              }}
              style={{
                border: "#004FD4 0% 0% no-repeat padding-box",
                width: "160px",
                height: "44px",
              }}
            >
              Close
            </button>
          ) : (
            <>
              <button
                className="btn  d9e8ec--button"
                onClick={(e) => {
                  e.preventDefault();
                  setEditRowIndex(null);
                }}
                style={{
                  border: "#004FD4 0% 0% no-repeat padding-box",
                  width: "160px",
                  height: "44px",
                }}
              >
                Cancel
              </button>
              <button
                className="btn  blue_task_button"
                onClick={(e) => {
                  e.preventDefault();
                  // props.handleModal();
                  props?.setIsScheduleUpdated(p=>false);
                  checkIfAppointmentExists(updateSlot?.id,true);
                  // updateProviderSchedule();
                }}
                style={{
                  border: "#004FD4 0% 0% no-repeat padding-box",
                  width: "160px",
                  height: "44px",
                }}
                disabled = {disableUpdate}
              >
                Update
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {/* {console.log("monCount",monCount)} */}
      {/* {console.log("mondaySlotsList",mondaySlotsList)} */}
      {/* {console.log("user?.userId !== selectedProviderId",user?.userId , selectedProviderId)} */}
      {/* {console.log(selectedFacilityId,user?.facility?.facilityId)} */}
    </>

  );
};

export default ViewScheduleModal;
