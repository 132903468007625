import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SearchPatient from "../Header/SearchPatient";
import sendHttpRequest, { search } from "../../hooks/sendHttpRequest";
import { changeDateTimeFormat, convertUTCtoTimzone, dateTimeHelper } from "../../../utils/dateTimeHelper";
import classes from "./NewAppointmentModal.module.css";
import {
  setPatientID,
  setProviderList,
} from "../../../redux/actions/providerActions";
import AppoinmentCalender from "../General/AppointmentCalender";
import { useUnsavedWorkPrompt } from "../../hooks/useUnsavedWorkPrompt";
import { formatPhoneNumber, unformatPhoneNumber, unsavedWorkHandler } from "../../../utils/helpers/HelperFunctions";
import ProviderAvailabilitySection from "./ProviderAvailabilitySection";
import { newAppointmentModalStrings } from "../../../res/strings";
import { useAuth } from "../../hooks/useAuth";
import { aptServiceURL, fetchPastAptURL } from "../../../res/endpointurls";
import ConsentFormSearch from "../../consent-forms/ConsentFormSearch";
import useConsentFormSearch from "../../consent-forms/useConsentFormSearch";
import Badge from "../Reusable/Badge";
import AppointmentService from "../../Services/Appointment.service";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneNumberInput from "../Reusable/PhoneNumberInput";
import ReferringPhysicianModal from "./ReferringPhysicianModal";


export const PatientVisitType = ({
  setPatientVisitType,
  appointmentType,
  fecthAllPrescribers,
  setSelectedFacility
}) => {
  const [patientVisit, setPatientVisit] = useState("virtual");

  const handlePntVisitType = (event) => {
    const id = event.target.id;
    setPatientVisit(id);
    setPatientVisitType(id);
    if (id === "virtual") {
      fecthAllPrescribers(appointmentType?.key, 0);
      setSelectedFacility(0);
    }
  };

  return (
    <>
      <input
        type="radio"
        name="patientVisitType"
        id="virtual"
        value="virtual"
        className="me-1 cursor--pointer"
        checked={patientVisit === "virtual"}
        onChange={(e) => handlePntVisitType(e)}
      />
      <label htmlFor="virtual" className="me-5 font-14 text--terciary">
        {"Virtual"}
      </label>
      <input
        type="radio"
        name="patientVisitType"
        id="in-patient"
        value="in-patient"
        className="me-1 cursor--pointer"
        checked={patientVisit === "in-patient"}
        onChange={(e) => handlePntVisitType(e)}
      />
      <label htmlFor="in-patient" className="me-5 font-14 text--terciary">
        {"In-Person"}
      </label>

    </>
  );
};

export const ProviderFacility = (props) => {
  const __str = newAppointmentModalStrings.en;

  return (
    <>
      <label className="fw-sb font-14 text--terciary">
        {__str.facilities_str}
      </label>
      {props?.isFacilityLoading ? (
        <Skeleton />
      ) : (
        <select
          className={`form-select font-12 shadow-none ${classes["custom-select"]} border--default br-10`}
          aria-label="Select follow up appointment"
          style={{
            height: "38px",
          }}
          onChange={(e) => {
            props?.setSelectedFacility(e.target.value);
            props?.handlefacilityId(e.target.value);
            // onDirty();
          }}
          required={props?.isRequired === true ? true : false}
        >
          <option value="0" selected key={"facilityAll"}>
            {"All Facilities"}
          </option>
          {props?.facilitiesData.map((facilityItem) => {
            return (
              <option
                value={facilityItem?.facilityId}
                id={facilityItem?.facilityId}
                key={facilityItem?.facilityId}
              >
                {facilityItem?.businessName}
              </option>
            );
          })}
        </select>
      )}
    </>
  );
};

const NewAppointmentModal = (props) => {
  const patientId = useSelector((state) => state.searchedPatientId.patientId);
  const dispatch = useDispatch();
  const __str = newAppointmentModalStrings.en;
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingud, setIsLoadingud] = useState(true);
  const [isSlotsLoading, setIsSlotsLoading] = useState(false);
  const [prescribersList, setPrescribersList] = useState([]);
  const [slotsTimezone, setSlotsTimezone] = useState();
  const [slotsTimezoneOffset, setSlotsTimezoneOffset] = useState();
  const [appointmentType, setAppointmentType] = useState();
  const [providerID, setProviderID] = useState();
  // const [providerID, setProviderID] = useState();
  const [chiefComplaint, setChiefComplaint] = useState("");
  const [commentText, setCommentText] = useState("");
  const [freeSlots, setFreeSlots] = useState([]);
  const [slotId, setSlotId] = useState();
  const [slotStartTime, setSlotStartTime] = useState();
  const [unSelectFreeSlot, setUnSelectFreeSlot] = useState(false);
  const [isAppointmentScheduled, setIsAppointmentScheduled] = useState(false);
  const [isCustomAppointmentSelected, setIsCustomAppointmentSelected] =
    useState(false);
  const [customStartTime, setCustomStartTime] = useState("");
  const [customEndTime, setCustomEndTime] = useState("");
  const [customDuration, setCustomDuration] = useState(15);
  const [timexone, setTimexone] = useState("");
  const [handleFreeSlots, setHandleFreeSlots] = useState(true);

  const [appointmentSection, setAppointmentSection] = useState(
    new Map([["selected", "calenderSection"]])
  );

  const [scheduleDate, setScheduleDate] = useState(
    convertUTCtoTimzone(
      new Date(),
      slotsTimezoneOffset !== undefined ? slotsTimezoneOffset : user.timezone,
      "YYYY-MM-DD"
    )
  );
  const [appointmentDuration, setAppointmentDuration] = useState();
  const [isDurationLoading, setIsDurationLoading] = useState(false);

  const [slotDurationList, setSlotDurationList] = useState([]);
  const [currentDate, setCurrentDate] = useState(
    convertUTCtoTimzone(new Date(), user.timezone, "YYYY-MM-DD LT")
  );
  const [pastAppointmentData, setPastAppointmentData] = useState([]);
  const [isPastAptLoading, setIsPastAptLoading] = useState(false);
  const [selectedPastAptId, setSelectedPastAptId] = useState();

  //==========Referring Physician============//
  const [referringPhysiciansList, setReferringPhysiciansList] = useState([]);
  const [areReferringPhysiciansLoading, setAreReferringPhysiciansLoading] = useState(false);
  const [referringPhysician, setReferringPhysician] = useState({});
  const [showReferringPhysicianFields, setShowReferringPhysicianFields] = useState(false);
  const [regexErrors, setRegexErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    zipcode: false,
  });

  function handleReferringPhysicianData(event) {
    event.preventDefault();
    if (event.target.name === "referringPhysicianDropdown") {
      const referringPhysicianId = event.target.value;
      const referringPhysicianData = referringPhysiciansList?.find((physician) => physician?.referringPhysicianId == referringPhysicianId);
      // console.log(referringPhysicianData);
      setReferringPhysician(previous => (referringPhysicianData));
      // handlePhysicianPhone(unformatPhoneNumber(referringPhysicianData?.phone))
    }
    else {
      const name = event.target.name;
      const value = event.target.value;
      setReferringPhysician(previous => {
        const newData = { ...previous };
        newData[name] = value;
        return newData;
      })
    }

    // if (event.target.name === "firstName") {
    //   setReferringPhysician(previous => {
    //     const newData = { ...previous };
    //     newData["firstName"] = event.target.value;
    //     return newData;
    //   })
    // }

  }

  const handlePhysicianPhone = (phoneValue) => {
    // console.log("Handling Physcian Phone", phoneValue);
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length == 12
    ) {
      // console.log("Valed Part");
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      setReferringPhysician(previous => {
        const newData = { ...previous };
        newData["phone"] = mobileNumber;
        return newData;
      })
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
      setReferringPhysician(previous => {
        const newData = { ...previous };
        newData["phone"] = "";
        return newData;
      })
    } else if (phoneValue?.length === 2 || phoneValue === undefined) {
      // dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));

      setReferringPhysician(previous => {
        const newData = { ...previous };
        newData["phone"] = "";
        return newData;
      })
    }
  };

  async function loadReferringPhysicians(tenantId) {
    setAreReferringPhysiciansLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${tenantId}/referring-physicians`,
      method: "GET",
    });
    if (!httpResponse.error) {
      console.log("Referring Physicians-> ", httpResponse.data);
      setReferringPhysiciansList(httpResponse.data);
    }
    setAreReferringPhysiciansLoading(false);
  }


  //=====Consent Form Search=====//
  const {
    consentFormSuggestions,
    setConsentFormSuggestions,
    isConsentFormLoading,
    setIsConsentFormLoading,
    isConsentFormError,
    setIsConsentFormError,
    consentSearchInputValue,
    setConsentSearchInputValue,
    consentFormName,
    setConsentFormName,
    selectedConsentForm,
    setSelectedConsentForm,
    searchConsentForm,
    handleConsentFormSearchInput,
    handleAddConsentForm,
    handleRemoveConsentForm,
  } = useConsentFormSearch();
  const [consentFormList, setConsentFormList] = useState([]);
  const [areDefaultFormsLoading, setAreDefaultFormsLoading] = useState(false);

  //==========Appointment Service States=============================
  const [appointmentServices, setAppointmentServices] = useState([]);

  const addConsentFormToList = (addedConsentForm) => {
    handleAddConsentForm(addedConsentForm);
    setConsentFormList((prevList) => {
      // console.log(prevList);
      // Check if the addedConsentForm already exists in the prevList
      if (!prevList?.some((form) => form.id === addedConsentForm.id)) {
        return [...prevList, addedConsentForm]; // Add the addedConsentForm if it doesn't exist
      }
      return prevList; // Return the unchanged prevList if the addedConsentForm already exists
    });
  };

  const removeConsentFormFromList = (formId) => {
    const filteredList = consentFormList?.filter((form) => form.id !== formId);
    setConsentFormList(filteredList);
  };

  //=====Unsaved work code - BEGIN=====//
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();

  // =========Patient visit type=================
  const [patientVisitType, setPatientVisitType] = useState("virtual");

  // =========Facility===========================
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [facilitiesData, setFacilitiesData] = useState([]);

  const handleChiefComplaint = (chiefComplaintValue) => {
    setChiefComplaint(chiefComplaintValue.trim());
    onDirty();
  };
  const handleComments = (comment) => {
    setCommentText(comment.trim());
    onDirty();
  };

  const fecthAllPrescribers = async (aptType, facilityId) => {
    setIsLoading(true);
    setProviderID(-1);
    setProviderList([]);
    const httpResponse = await sendHttpRequest({
      url: "/provider/providers",
      method: "GET",
      params: {
        // appointmentType: aptType,
        patientId: patientId,
        facilityId: facilityId,
      },
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    }
    setIsLoading(false);
  };

  const fecthFreeSlots1 = async (

    providerId,
    appointmentType,
    scheduleSelectedDate,
    durationMinutes
  ) => {
    setIsSlotsLoading(true);
    setFreeSlots([]);
    let timsezoneOffset =
      slotsTimezoneOffset !== undefined ? slotsTimezoneOffset : user.timezone;
    const httpResponse = await search({
      url: "/availabilities/availableSlots",
      method: "GET",
      params: {
        providerId: providerId,
        fromDate:
          scheduleSelectedDate === undefined
            ? scheduleDate
            : scheduleSelectedDate,
        toDate:
          scheduleSelectedDate === undefined
            ? scheduleDate
            : scheduleSelectedDate,
        appointmentType: appointmentType,
        patientId: patientId,
        page: 0,
        duplicates: false,
        tenantId: user?.tenantId,
        facilityId: selectedFacility,
        duration: durationMinutes === undefined ? appointmentType?.durationMinutes : durationMinutes
      },
    });
    if (!httpResponse.error) {
      setFreeSlots(httpResponse?.data?.providersAvailabilityList);
      if (
        httpResponse?.data?.providersAvailabilityList?.length > 0 &&
        (appointmentType?.toLowerCase() === "new" ||
          appointmentType.toLowerCase() === "follow_up")
      ) {
        setSlotStartTime(
          httpResponse?.data?.providersAvailabilityList?.at(0)?.startDateTime
        );
      }
    }
    setIsSlotsLoading(false);
  };



  const createAppointment = async () => {
    // setIsLoading(true);
    setIsAppointmentScheduled(true);
    let duration = 0;
    let appoinmentStartTime = -1;
    // let timsezoneOffset = slotsTimezoneOffset !== undefined ? slotsTimezoneOffset : user.timezone
    // let scheduleDateValue  = convertUTCtoTimzone(scheduleDate,timsezoneOffset,"YYYY-MM-DD");
    // alert(appointmentType)
    appoinmentStartTime = slotStartTime;
    if (appointmentType?.key.toLowerCase() !== "custom") {
      duration = appointmentDuration;
    } else if (appointmentType.key?.toLowerCase() === "custom") {
      if (customStartTime !== undefined) {
        // duration = getTimeDifference(customStartTime, customEndTime);
        duration = customDuration;
        appoinmentStartTime = changeDateTimeFormat(
          scheduleDate + "T" + customStartTime + ":00",
          "YYYY-MM-DDTHH:mm"
        );
      }
    }
    // alert(duration)
    if (patientId !== undefined && patientId !== -1) {
      //Patient Consent Forms
      const patientConsentForms = consentFormList?.map((form) => {
        return {
          appointmentConsentForm: {
            id: form.id,
          },
        };
      });

      const httpResponse = await sendHttpRequest({
        url: "/appointment",
        method: "POST",
        data: {
          prescriptionReference: {
            patient: {
              userId: patientId,
              patientConsentForms: patientConsentForms,
            },
            provider: { userId: providerID },
          },

          ...(Object.keys(referringPhysician)?.length > 1 && { referringPhysician }),
          // referringPhysician: referringPhysician,
          chiefComplaint: chiefComplaint,
          comments: commentText,
          startTime: appoinmentStartTime,
          appointmentType: { tenantServiceId: appointmentType?.tenantServiceId },
          appointmentSlot: {
            patient: { userId: patientId },
            provider: { userId: parseInt(providerID) },
            startDateTime: appoinmentStartTime,
            duration: duration,
            startDate: scheduleDate,
            providerTenantPracticeLocation: {
              tenant: {
                tenantId: user?.tenantId
              },
              facility: {
                facilityId: selectedFacility
              }
            }
          },
          parent: {
            id: selectedPastAptId,
          },
          patientVisitType: { internalValue: patientVisitType },
        },
      });
      if (!httpResponse.error) {
        props.setIsSentAppointment(true);
        // console.log("appointment-> ", httpResponse.data);
        dispatch(setPatientID(-1));
        if (props?.handleCurrentMonth !== null) {
          props?.handleCurrentMonth();
        }

        if (props?.fetchAppointments !== null) {
          const appRes = await props.fecthAppointments(props.appointmentDate);
          const appointmentsForSlotDate = appRes?.filter(item => {
            return item?.date == props?.convertDateFormat(props?.selectedCellDate);
          })?.at(0)?.appointments;
          appointmentsForSlotDate?.sort((a, b) => {
            const dateA = new Date(a.appointmentSlot.startDateTime);
            const dateB = new Date(b.appointmentSlot.startDateTime);

            // Compare dates
            return dateA - dateB;
          });
          if (appointmentsForSlotDate.length > 0) {
            props?.setCurrentCellAppointments(p => appointmentsForSlotDate);
          }
        }
        setIsCustomAppointmentSelected(false);
        setAppointmentType("NEW");
        setFreeSlots([]);
        setSlotsTimezoneOffset("");
        setSlotsTimezone("");
        setProviderID(0);
        // removeSelectedUser();
        props.handleModal();
      } else {
        // console.log("httpResponse", httpResponse);
        props?.setIsAppointmentNotRescheduled(true);
        props?.setAppointmentNotScheduledError(
          httpResponse?.errorMessage?.message
        );
      }
    } else {
      props?.setIsAppointmentNotRescheduled(true);
      props?.setAppointmentNotScheduledError("Please select a valid patient");
    }
    setIsAppointmentScheduled(false);
  };
  const handleAppointmentType = (event) => {
    let tenantServiceId = event.target.value;
    let aptObject = appointmentServices?.find((item) => { return item.tenantServiceId === Number(tenantServiceId) });
    let aptType = aptObject.key;
    console.log("aptType", aptType);

    if (appointmentType?.key === "FOLLOW_UP" && selectedPastAptId !== undefined) {
      setChiefComplaint("");
      document.getElementById("chiefComplaint").value = "";
    }

    if (aptType === "NEW") {
      setSelectedPastAptId(undefined);
      // setPrescribersList([{ userId: -10 }, ...prescribersList]);
    }
    if (aptType === "CUSTOM") {
      setSelectedPastAptId(undefined);
      setIsCustomAppointmentSelected(true);
      setAppointmentType(aptObject);
      // fecthAllPrescribers(aptType);
      setAppointmentSection(new Map([["selected", "calenderSection"]]));
    } else {
      setIsCustomAppointmentSelected(false);
      setUnSelectFreeSlot(true);
      setSlotId("");
      setSlotStartTime("");
      if (appointmentType?.key === "CUSTOM") {
        // fecthAllPrescribers(aptType);
      }
      setAppointmentType(aptObject);
      if (providerID !== 0 && providerID !== -1 && patientId !== -1) {
        fecthFreeSlots1(providerID, aptType, undefined, aptObject?.durationMinutes);
      }
    }
    if (aptType === "CUSTOM") {
      fetchAppointmentDuration(providerID, aptType);
    } else {
      setAppointmentDuration(aptObject?.durationMinutes)
    }
  };

  const handleProviderId = (event) => {
    event.preventDefault();
    setHandleFreeSlots(!handleFreeSlots);
    setProviderID(event.target.value);
    if (appointmentType !== undefined) {
      fetchAppointmentDuration(event.target.value, appointmentType?.key);
      fecthFreeSlots1(event.target.value, appointmentType?.key, undefined, appointmentType?.durationMinutes);
    }
    if (event.target.value === "0") {
      setAppointmentSection(
        new Map([["selected", "providerAvailabilitySection"]])
      );
    } else {
      setAppointmentSection(
        new Map([["selected", appointmentSection.get("selected")]])
      );
    }
    // else{
    //   setAppointmentSection(new Map([['selected','calenderSection']]))
    // }
  };

  const handleFreeSlot = (event) => {
    event.preventDefault();
    setUnSelectFreeSlot(false);
    // let slotId = event.target[event.target.selectedIndex].getAttribute("data");
    let slotStartTime = event.target.value;
    // setSlotId(slotId);
    setSlotStartTime(slotStartTime);

    // alert(event.target.data)
  };

  const handleCustomStartTimeChange = (e) => {
    e.preventDefault();
    setCustomStartTime(e.target.value);
    // console.log("custom start time", e.target.value);
    onDirty();
  };

  const handleCustomDuration = (e) => {
    e.preventDefault();
    setCustomDuration(e.target.value);
    onDirty();
  };

  const getPatientTimezone = async (patientId) => {
    const httpResponse = await sendHttpRequest({
      url: "/patient/timezone",
      method: "GET",
      params: {
        patientId: props?.patientId ?? patientId,
      },
    });
    if (!httpResponse.error) {
      setSlotsTimezoneOffset(httpResponse?.data?.timezoneOffset);
      setSlotsTimezone(httpResponse?.data?.timezone);
      setScheduleDate(
        convertUTCtoTimzone(
          new Date(),
          httpResponse?.data?.timezoneOffset,
          "YYYY-MM-DD"
        )
      );
      // setSlotsTimezoneOffset(httpResponse?.data?.timezoneOffset);
      // setSlotsTimezone(httpResponse?.data?.timezone);
      // setCurrentDate(convertUTCtoTimzone(new Date(),httpResponse?.data?.timezone,"YYYY-MM-DD LT"));
      // setTimexone(httpResponse?.data?.timezone);
    }
  };

  const postSelectedUser = async (patientid) => {
    getPatientTimezone(patientid);
    setFreeSlots([]);
  };


  const fetchAppointmentDuration = async (providerId, appointmentType) => {
    setIsDurationLoading(true);

    const httpResponse = await sendHttpRequest({
      url: "/appointment/duration",
      method: "GET",
      params: {
        prescriberId: providerId !== undefined ? providerId : -1,
        appointmentType: appointmentType,
        tenantId: user?.tenantId,
      },
    });
    if (!httpResponse.error) {
      let duration = httpResponse.data;
      if (duration.length > 0) {
        if (appointmentType === "CUSTOM") {
          let durationList = [];
          duration.forEach((durationItem) => {
            durationList.push({ duration: durationItem, text: durationItem });
          });
          setSlotDurationList(durationList);
        } else {
          // setAppointmentDuration(duration[0]);
        }
      }
    }
    setIsDurationLoading(false);
  };

  const fetchPastAppointments = async () => {
    setIsPastAptLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPastAptURL(patientId, user.userId),
      method: "GET",
    });

    if (!httpResponse.error) {
      setPastAppointmentData(httpResponse.data);
      // if(httpResponse.data?.length > 0){
      //   handlePastAppointmentLink(undefined,httpResponse.data[0]);
      // }
    }
    setIsPastAptLoading(false);
  };

  // Appointment Service


  const fetchAppointmentService = async () => {
    const httpResponse = await sendHttpRequest({
      url: aptServiceURL(user.tenantId),
      method: "GET"
    })
    if (!httpResponse.error) {
      let data = httpResponse?.data
      let aptObject = data.find((item) => { return item.key === "NEW" })
      setAppointmentServices(data)
      setAppointmentType(aptObject)
      setAppointmentDuration(aptObject?.durationMinutes)
    } else {
      setAppointmentServices([])
    }
  }

  const getDefaultForms = async (id) => {
    setAreDefaultFormsLoading(true);
    const response = await AppointmentService.fetchPatientDefaultForms(
      patientId
    );
    setConsentFormList(response ?? []);
    setAreDefaultFormsLoading(false);
  };

  const handleSectionType = (event) => {
    event.preventDefault();
    if (providerID !== "0" && appointmentType !== "CUSTOM") {
      setAppointmentSection(new Map([["selected", event.target.id]]));
    }
  };

  const handlePastAppointmentLink = (e, aptObject) => {
    let aptId = 0;
    if (e !== undefined) {
      e.preventDefault();
      aptId = e.target.value;
    } else {
      aptId = aptObject?.id;
    }
    let result = pastAppointmentData?.find((item) => {
      return item.id === Number(aptId);
    });
    if (result === undefined && aptObject !== undefined) {
      result = aptObject;
    }
    if (result !== undefined) {
      let chiefComplaint =
        result?.prescriptionReference?.encounter?.chiefComplaint;
      let providerId =
        result?.prescriptionReference?.providerTenantPracticeLocation?.provider
          ?.userId;

      document.getElementById("chiefComplaint").value = chiefComplaint;
      setSelectedPastAptId(result?.id);
      setChiefComplaint(chiefComplaint);
      setProviderID(providerId);
      fecthFreeSlots1(providerId, appointmentType?.key, undefined, appointmentType?.durationMinutes);
    }
  };

  const fetchProviderFacilities = async () => {
    setIsFacilityLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${user.tenantId}/facilities?forAppointment=true`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setFacilitiesData(httpResponse.data);
    }
    setIsFacilityLoading(false);
  };

  const handlefacilityId = (facilityId) => {
    setSelectedFacility(facilityId);
    if (patientId !== -1) {
      fecthAllPrescribers(appointmentType?.key, facilityId);
    }
  };

  useEffect(() => {
    if (props?.appointmentFromPatient === true) {
      getPatientTimezone(patientId);
    }
    if (facilitiesData?.length === 0) {
      fetchProviderFacilities();
    }
    if (patientId != -1) {
      fetchPastAppointments();
      setPrescribersList([]);
      fecthAllPrescribers(appointmentType?.key, selectedFacility);
      fetchAppointmentDuration(providerID, appointmentType?.key ?? "NEW");
      getDefaultForms(patientId);
    }
  }, [patientId]);
  useEffect(() => {
    fetchAppointmentDuration(providerID, appointmentType?.key ?? "NEW");
    fetchAppointmentService();
    // fecthAllPrescribers(appointmentType);
    // console.log("props?.selectedPrescriberId", props?.selectedPrescriberId);
    // if (
    //   props?.selectedPrescriberId != 0 &&
    //   props?.selectedPrescriberId != undefined
    // ) {
    //   setProviderID(props?.selectedPrescriberId);
    // }
    return () => {
      setPrescribersList([]);
    };
  }, []);

  useEffect(() => {
    loadReferringPhysicians(user?.tenantId);
  }, [])

  return (
    <>
      {showReferringPhysicianFields ? <ReferringPhysicianModal
        show={showReferringPhysicianFields}
        handleModalClose={() => { setShowReferringPhysicianFields(false) }}
        referringPhysician={referringPhysician}
        regexErrors={regexErrors}
        setShowReferringPhysicianFields={setShowReferringPhysicianFields}
        tenantId={user?.tenantId}
        setReferringPhysician={setReferringPhysician}
        referringPhysiciansList={referringPhysiciansList}
        handleReferringPhysicianData={handleReferringPhysicianData}
        handlePhysicianPhone={handlePhysicianPhone}
      /> : null}
      <Modal
        show={props.show}
        onHide={() => {
          setAppointmentType("NEW");
          setFreeSlots([]);
          setProviderID(0);
          setSlotsTimezoneOffset("");
          setSlotsTimezone("");
          setIsCustomAppointmentSelected(false);
          // handleNewAppointmentModal();
          // removeSelectedUser();
          props.handleModal();
        }}
        // size = "xl"
        backdrop="static"
        backdropClassName="modal-backdrop"
        keyboard={false}
        centered
        contentClassName={`${classes["appointmentmodal__style"]} `}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes["custom-modal-style"]}`}
      >
        <Modal.Header
          className={`${classes["appointmentmodal__border--bottom"]}`}
        >
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes. Do you wish to continue?",
                isDirty,
                props.handleModal,
                onPristine,
                props.handleModal
              );
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            New Appointment
            <div className="" style={{ fontWeight: 400, fontSize: "12px" }}>
              {slotsTimezone === null ||
                slotsTimezone === "" ||
                slotsTimezone === undefined
                ? ""
                : "Patient Timezone: " + slotsTimezone}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id="newAppointmentForm"
            onSubmit={(e) => {
              e.preventDefault();
              onPristine();
              createAppointment();
              // props.handleModal();
            }}
          >
            <div className={`row ${classes["scroll__bar"]}`} style={{ maxHeight: 'calc(100vh - 190px)', overflowY: 'auto' }}>
              <div className="col-6 px-4">
                <div className="form-group">
                  <label className="fw-sb font-14 text--terciary">
                    Patient
                  </label>
                  <SearchPatient
                    width="auto"
                    required={true}
                    EnableDirtyCheck={true}
                    onDirty={onDirty}
                    disable={props?.searchDisable === true ? true : false}
                    postSelectedUser={postSelectedUser}
                    focus={true}
                    patientId={props?.patientId}
                    name={props?.name}
                    // getPatientTimezone={getPatientTimezone}
                    newAppointment={true}
                    setSlotsTimezoneOffset={setSlotsTimezoneOffset}
                    setSlotsTimezone={setSlotsTimezone}
                    setProviderList={setProviderList}
                  />
                </div>
                <div className="form-group mt-2">
                  <PatientVisitType
                    key={"patient-visit-type"}
                    setPatientVisitType={setPatientVisitType}
                    fecthAllPrescribers={fecthAllPrescribers}
                    appointmentType={appointmentType}
                    setSelectedFacility={setSelectedFacility}
                  />
                </div>

                {/* Facility */}
                <div className="form-group mt-2">
                  {patientVisitType === "in-patient" ? (
                    <ProviderFacility
                      facilitiesData={facilitiesData}
                      isFacilityLoading={isFacilityLoading}
                      setSelectedFacility={setSelectedFacility}
                      handlefacilityId={handlefacilityId}
                    />
                  ) : null}
                </div>

                {/* Provider */}
                <div className="form-group mt-2">
                  <label className="fw-sb font-14 text--terciary">
                    {__str.provider}
                  </label>
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <select
                      className={` border--default input br-10 form-select font-14 shadow-none ${classes["custom-select"]}`}
                      aria-label="Provider select"
                      style={{
                        height: "40px",
                      }}
                      required
                      onChange={(e) => {
                        handleProviderId(e);
                        onDirty();
                      }}
                      onInvalid={(e) => {
                        e.target.setCustomValidity(
                          "Please select provider from the list"
                        );
                      }}
                      onInput={(e) => {
                        e.target.setCustomValidity("");
                      }}
                    >
                      <option
                        value=""
                        disabled
                        selected
                        hidden
                        key={"unselected"}
                      >
                        {__str.selectProvider}
                      </option>
                      {patientId !== -1 &&
                        appointmentType !== "CUSTOM" &&
                        prescribersList?.length > 0 && (
                          <option value={0} key={0}>
                            {"Any"}
                          </option>
                        )}
                      {prescribersList !== undefined &&
                        prescribersList?.length > 0 ? (
                        prescribersList?.map((prescriberItem) => {
                          if (prescriberItem?.userId === -10) {
                            return (
                              <option
                                value=""
                                disabled
                                selected
                                hidden
                                key={"unselected"}
                              >
                                {__str.selectProvider}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                value={prescriberItem?.userId}
                                key={prescriberItem?.userId}
                                selected={
                                  appointmentType === "FOLLOW_UP"
                                    ? prescriberItem?.userId === providerID
                                      ? true
                                      : false
                                    : false
                                }
                              >
                                {prescriberItem?.name[0]?.prefix}{" "}
                                {prescriberItem?.name[0]?.firstName}{" "}
                                {prescriberItem?.name[0]?.lastName}{" "}
                                {prescriberItem?.speciality[0]?.speciality !==
                                  undefined
                                  ? "(" +
                                  prescriberItem?.speciality[0]?.speciality +
                                  ")"
                                  : ""}
                              </option>
                            );
                          }
                        })
                      ) : !isLoading && prescribersList?.length === 0 ? (
                        <option key={-1} value="" disabled>
                          {"No providers available"}
                        </option>
                      ) : (
                        <option key={-2} value="" disabled>
                          {"Loading provider(s)"}
                        </option>
                      )}
                    </select>
                  )}
                </div>
                {/* Appointment Type */}
                <div className="form-group mt-2">
                  <label className="fw-sb font-14 text--terciary">
                    {__str.type}
                  </label>
                  <select
                    className={`form-select font-14 shadow-none ${classes["custom-select"]}`}
                    aria-label="Appointment type select"
                    style={{
                      border: "1px solid #D9E8EC",
                      height: "40px",
                      borderRadius: "10px",
                    }}
                    required
                    onChange={(e) => {
                      handleAppointmentType(e);
                      onDirty();
                    }}
                  >
                    <option value="" disabled hidden>
                      {__str.select}
                    </option>
                    {/* <option value="NEW" selected>
                      {__str.new}
                    </option>
                    <option value="FOLLOW_UP">{__str.followUp}</option>
                    <option value="CUSTOM">{__str.custom}</option> */}
                    {
                      appointmentServices.map((serviceItem) => {
                        return (
                          <option value={serviceItem?.tenantServiceId} key={serviceItem?.tenantServiceId}>{serviceItem?.title}</option>
                        )
                      })
                    }
                  </select>
                </div>

                {/* Past Appointment section */}
                {appointmentType?.key?.toLowerCase() === "follow_up" ? (
                  isPastAptLoading ? (
                    <Skeleton />
                  ) : pastAppointmentData?.length > 0 ? (
                    <div className="form-group mt-2">
                      <label className="fw-sb font-14 text--terciary">
                        {__str.passAppointments}
                      </label>
                      <select
                        className={`form-select font-14 shadow-none ${classes["custom-select"]}`}
                        aria-label="Select follow up appointment"
                        style={{
                          border: "1px solid #D9E8EC",
                          height: "40px",
                          borderRadius: "10px",
                        }}
                        onChange={(e) => {
                          handlePastAppointmentLink(e);
                          onDirty();
                        }}
                      >
                        <option value="">{"Select past appointment"}</option>
                        {pastAppointmentData.map((aptItem) => {
                          let chiefComp =
                            aptItem?.prescriptionReference?.encounter
                              ?.chiefComplaint ?? "";
                          return (
                            <option
                              value={aptItem?.id}
                              id={aptItem?.id}
                              key={aptItem?.id}
                            >
                              {dateTimeHelper(
                                aptItem?.appointmentSlot?.startDateTime,
                                "MMM DD, YYYY LT"
                              ).concat(" - ", chiefComp)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    <div className="form-group mt-2">
                      <label className="fw-sb font-14 text--terciary">
                        {__str.passAppointments}
                      </label>
                      <p className="font-12 text--terciary">
                        {"No earlier appointments available."}
                      </p>
                    </div>
                  )
                ) : null}
                {/* Chief Compalint */}
                <div className="form-group mt-2">
                  <label className="fw-sb font-14 text--terciary">
                    {__str.chiefcomplaint}
                  </label>
                  {/* <h2 className="font-18 text--terciary">Chief Complaint</h2> */}
                  <div>
                    <textarea
                      className="form-control br-10 input font-14 border--default shadow-none"
                      type={"text"}
                      id={"chiefComplaint"}
                      defaultValue={chiefComplaint ?? ""}
                      onChange={(e) => {
                        e.preventDefault();
                        handleChiefComplaint(e.target.value);
                      }}
                      rows={appointmentType?.key === "follow_up" ? 2 : 4}
                    />
                  </div>
                </div>

                {Object.keys(referringPhysician)?.length > 2 && showReferringPhysicianFields === false ?
                  <div className="form-group mt-2">
                    <span className="font-12 fw-sb d-block">Referring Physician: <span className="fw-thin">Dr. {referringPhysician?.firstName} {referringPhysician?.lastName}, {referringPhysician?.phone}</span></span>
                  </div>
                  : <div className="form-group mt-2">
                    {/* <span className="text-decoration-underline fw-sb text--secondary font-14 d-flex align-items-center gap-1"><PersonAddAltIcon />{__str.addReferringPhysician}</span> */}
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        if (showReferringPhysicianFields === false) {
                          setShowReferringPhysicianFields(true);
                        }


                      }}
                      className="btn pn-button-icon-blue  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12"

                    >
                      <span className="pn-button-icon-blue pe-1">
                        <PersonAddAltIcon color="#336383" sx={{ fontSize: "20px" }} />
                      </span>
                      <span
                        className={`d-inline-block pn-link-button `}
                        style={{ verticalAlign: "sub" }}
                      >
                        {__str.addReferringPhysician}
                      </span>
                    </button>

                  </div>}



                <div className="form-group mt-2">
                  <label className="fw-sb font-14 text--terciary">
                    Consent
                  </label>
                  {/* <textarea
                    type="text"
                    rows={appointmentType === "FOLLOW_UP" ? 2 : 3}
                    // placeholder={"Write here..."}
                    className="form-control br-10 border--default font-14 input shadow-none"
                    onChange={(e) => { e.preventDefault(); handleComments(e.target.value) }}
                  /> */}
                  {areDefaultFormsLoading ? (
                    <Skeleton />
                  ) : (
                    <ConsentFormSearch
                      suggestions={consentFormSuggestions}
                      setSuggestions={setConsentFormSuggestions}
                      isLoading={isConsentFormLoading}
                      setIsLoading={setIsConsentFormLoading}
                      isError={isConsentFormError}
                      setIsError={setIsConsentFormError}
                      searchInputValue={consentSearchInputValue}
                      setSearchInputValue={setConsentSearchInputValue}
                      consentFormName={consentFormName}
                      selectedConsentForm={selectedConsentForm}
                      setSelectedConsentForm={setSelectedConsentForm}
                      searchConsentForm={searchConsentForm}
                      handleSearchInput={handleConsentFormSearchInput}
                      handleAddConsentForm={addConsentFormToList}
                    />
                  )}
                  <div className="d-flex gap-2 mt-2 flex-wrap">
                    {consentFormList !== null &&
                      consentFormList?.map((consentform, index) => (
                        <Badge
                          key={consentform.id}
                          data={`${consentform.name}`}
                          handleRemove={removeConsentFormFromList}
                          removeId={consentform.id}
                        />
                      ))}
                  </div>
                </div>
              </div>

              {/* Right side Section */}
              <div className=" col-6  border--left">
                <div className="text--terciary font-14 fw-sb">
                  {__str.viewAvailableAppointments}
                </div>
                <div className="d-flex flex-row p-2 px-2 justify-content-between br-10 bg--d9e8ec font-12 text--terciary ">
                  <div className="ms-2">
                    <button
                      className={`border-0 br-7 fw-sb px-2 font-14 
                        ${appointmentSection.get("selected") ===
                          "calenderSection"
                          ? "bg--white"
                          : "bg--d9e8ec"
                        }
                        ${providerID == 0 ? "disabled" : ""}
                        `}
                      id="calenderSection"
                      onClick={(e) => {
                        handleSectionType(e);
                      }}
                    >
                      {__str.dateTime}
                    </button>
                  </div>
                  <div className=" ">
                    <button
                      className={`border-0 br-7 fw-sb font-14 px-3 ${appointmentSection.get("selected") ===
                        "providerAvailabilitySection"
                        ? "bg--white"
                        : "bg--d9e8ec"
                        }`}
                      id="providerAvailabilitySection"
                      onClick={(e) => {
                        handleSectionType(e);
                      }}
                    >
                      {/* Provider&#39;s earliest availability */}
                      {__str.providerEarlistAvailability}
                    </button>
                  </div>
                </div>
                <div className="border--bottom w-100 mt-2"></div>

                {appointmentSection.get("selected") ===
                  "providerAvailabilitySection" ? (
                  //  New Section
                  <div className="mt-3">
                    <ProviderAvailabilitySection
                      providerId={providerID}
                      setProviderID={setProviderID}
                      appointmentType={appointmentType}
                      setScheduleDate={setScheduleDate}
                      setSlotStartTime={setSlotStartTime}
                      scheduleDate={scheduleDate}
                      appointmentDuration={appointmentDuration}
                      patientId={patientId}
                      handleFreeSlots={handleFreeSlots}
                    />
                  </div>
                ) : (
                  //  Old Section
                  <>
                    <div className="form-group font-14"></div>
                    <div className="form-group">
                      <AppoinmentCalender
                        setScheduleDate={setScheduleDate}
                        EnableDirtyCheck={true}
                        setScheduleDateToSend={null}
                        onDirty={onDirty}
                        providerID={providerID}
                        appointmentType={appointmentType}
                        fecthFreeSlots1={fecthFreeSlots1}
                        appointmentDate={
                          slotsTimezoneOffset !== undefined
                            ? convertUTCtoTimzone(
                              new Date(),
                              slotsTimezoneOffset,
                              "YYYY-MM-DD"
                            )
                            : convertUTCtoTimzone(
                              new Date(),
                              user.timezone,
                              "YYYY-MM-DD"
                            )
                        }
                        slotsTimezoneOffset={slotsTimezoneOffset}
                        scheduleDate={scheduleDate}
                        patientId={patientId}
                      />
                    </div>
                    <div className="form-group mt-2">
                      {isCustomAppointmentSelected === false ? (
                        <div>
                          <div className="row mt-2">
                            <div className="col-sm-4 ">
                              <label className="text--terciary font-14 fw-sb">
                                {__str.starttime}
                              </label>
                              <div className="form-group">
                                <select
                                  className={`border--default br-10 col-sm-12 form-select cursor--pointer font-14 shadow-none ${classes["custom-select"]}`}
                                  aria-label="Slot select"
                                  required
                                  onChange={(e) => {
                                    handleFreeSlot(e);
                                    onDirty();
                                  }}
                                >
                                  <option
                                    value=""
                                    className="col-sm-12 font-14"
                                    selected={unSelectFreeSlot ? true : false}
                                    // selected
                                    disabled
                                    hidden
                                  ></option>

                                  {freeSlots?.length > 0 ? (
                                    freeSlots?.map((slotObject, index) => {
                                      let aptStartTime = changeDateTimeFormat(
                                        slotObject?.startDateTime,
                                        "LT"
                                      );
                                      let aptEndTime = changeDateTimeFormat(
                                        slotObject?.EndTime,
                                        "LT"
                                      );

                                      return (
                                        <option
                                          key={
                                            slotObject?.startDateTime + index
                                          }
                                          className="col-sm-12 font-14 p-1 mt-1"
                                          // data={slotItem?.id}
                                          value={slotObject?.startDateTime}
                                          style={{ textAlign: "justify" }}
                                        >
                                          {aptStartTime}
                                        </option>
                                      );
                                    })
                                  ) : !isSlotsLoading &&
                                    freeSlots?.length <= 0 ? (
                                    <option
                                      key={0}
                                      className="col-sm-12 font-14 p-1 mt-1"
                                      // data={slotItem?.id}
                                      value=""
                                      style={{ textAlign: "justify" }}
                                      disabled
                                    >
                                      {__str.noSlotFound}
                                    </option>
                                  ) : (
                                    <option
                                      key={0}
                                      className="col-sm-12 font-14 p-1 mt-1"
                                      // data={slotItem?.id}
                                      value=""
                                      style={{ textAlign: "justify" }}
                                      disabled
                                    >
                                      Loading Slots
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div style={{ width: "10vw" }}>
                                <label className="font-14 fw-sb text--terciary">
                                  Duration{" "}
                                </label>
                              </div>
                              <div
                                className="font-14  text--terciary align-self-center br-10"
                                style={{ border: "1px solid #d9e8ec" }}
                              >
                                <div className="center pt-1 ps-2 py-2">
                                  {isDurationLoading ? (
                                    <Skeleton />
                                  ) : appointmentDuration !== undefined ? (
                                    appointmentDuration + " minutes"
                                  ) : (
                                    <>&nbsp;</>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-sm-4 " style={{ width: "10vw" }}>
                            <label className="text--terciary font-14 fw-sb">
                              Start Time
                            </label>
                            <input
                              type="time"
                              name="time"
                              className={`font-14 p-2   ${classes["start__time__input"]} `}
                              datsa-format="hh:mm"
                              required
                              onInvalid={(e) => {
                                e.target.setCustomValidity(
                                  "Please enter a valid time"
                                );
                                onDirty();
                              }}
                              onInput={(e) => {
                                e.target.setCustomValidity("");
                                onDirty();
                              }}
                              onChange={(e) => {
                                handleCustomStartTimeChange(e);
                              }}
                            />
                          </div>
                          <div className="col-sm-4">
                            <div style={{ width: "10vw" }}>
                              <label className="text--terciary font-14 fw-sb">
                                Duration
                                {/* <span className="text--terciary font-10">
                                    (Min)
                                  </span> */}
                              </label>
                              {isDurationLoading ? (
                                <Skeleton />
                              ) : (
                                <select
                                  className={`form-select  font-14 shadow-none ${classes["custom-select"]} ${classes["duration"]}`}
                                  aria-label="Appointment type select"
                                  required
                                  onChange={(e) => handleCustomDuration(e)}
                                >
                                  {slotDurationList.map(
                                    (durationItem, index) => {
                                      return (
                                        <option
                                          value={durationItem.duration}
                                          selected={index === 0 && true}
                                        >
                                          {durationItem.duration + " minutes"}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer
          //   style={{ border: "none" }}
          className="modal-footer"
        >
          <button
            type="submit"
            form="newAppointmentForm"
            className={`btn blue_task_button px-5`}
            disabled={isAppointmentScheduled}
          >
            {isAppointmentScheduled ? "Scheduling..." : "Schedule"}
          </button>
        </Modal.Footer>
        {/* {console.log("patientId in appointment", patientId)}
        {console.log("provider List: ",prescribersList)} */}

        {/* {console.log("appointment Type", appointmentType)} */}
        {/* {console.log("slotsList", freeSlots)} */}
        {/* {console.log("provider Id", providerID)} */}
        {/* {console.log("slotsTimezone",slotsTimezone)} */}
        {/* {console.log("Chief complaint: ",chiefComplaint)} */}
      </Modal>
    </>
  );
};

export default NewAppointmentModal;
