import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../components/hooks/useAuth';
import PageHeader from '../../../../components/UI/General/PageHeader';
import useClaimPlaceOfServiceCodes from '../../claim-hooks/useClaimPlaceOfServiceCodes';
import { calenderDateTimeHelper, dateTimeHelper } from '../../../../utils/dateTimeHelper';

const EncounterDetailsPage = (props) => {

    const location = useLocation();
    const encounter = location?.state?.encounter;
    const referringPhysician = location?.state?.encounter?.referringPhysician;

    const { user } = useAuth();
    const navigate = useNavigate();
    const [primaryInsurance, setPrimaryInsurance] = useState(encounter?.patient?.patientInsurance?.find(insurance => insurance?.type === "Primary") ?? null);


    const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } = useClaimPlaceOfServiceCodes();
    const placeOfServiceMap = new Map();

    for (const { code, name } of placeOfServiceCodes) {
        placeOfServiceMap.set(code, name);
    }

    return (
        <>
            <PageHeader title={"Encounter Details"} />
            <div className='container py-3'>

                <div className='row justify-content-center'>
                    <div className='col-sm-7'>
                        <div className='row px-2'>
                            <div className="col-sm-3">
                                <span className='d-block fw-sb font-12 text--secondary '>
                                    Provider
                                </span>
                                <span className='d-block  font-12 text--terciary'>
                                    {/* {getProviderConcatenatedNameFromNameObject(prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name?.at(0))} */}
                                    {`${encounter?.provider?.prefix} ${encounter?.provider?.firstName} ${encounter?.provider?.lastName}`}
                                </span>
                            </div>
                            <div className="col-sm-3">
                                <span className='d-block fw-sb font-12 text--secondary '>
                                    Service Location
                                </span>
                                <span className='d-block font-12 text--terciary'>
                                    {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                    {`${encounter?.encounterMetadata?.serviceLocation}`}
                                </span>
                            </div>
                            <div className="col-sm-4">
                                <span className='d-block fw-sb font-12 text--secondary '>
                                    Place of Service
                                </span>

                                <span className='d-block font-12 text--terciary'>

                                    {placeOfServiceMap.get(encounter?.encounterMetadata?.placeOfService)}</span>


                            </div>
                        </div>
                        {/* {console.log("encounter", encounter)} */}
                        <div className='row px-2 mt-2'>
                            <div className="col-sm-3">
                                <span className='d-block fw-sb font-12 text--secondary '>
                                    Service Date
                                </span>
                                <span className='d-block  font-12 text--terciary'>
                                    {/* {getProviderConcatenatedNameFromNameObject(prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name?.at(0))} */}
                                    {calenderDateTimeHelper(encounter?.encounterMetadata?.dateOfServiceTo, "MMM DD, YYYY")}
                                    {/* {`${encounter?.provider?.prefix} ${encounter?.provider?.firstName} ${encounter?.provider?.lastName}`} */}
                                </span>
                            </div>
                            <div className="col-sm-3">
                                <span className='d-block fw-sb font-12 text--secondary '>
                                    Last Seen Date
                                </span>
                                <span className='d-block font-12 text--terciary'>
                                    {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                    {calenderDateTimeHelper(encounter?.lastSeenDate, "MMM DD, YYYY")}
                                </span>
                            </div>
                            <div className="col-sm-3">
                                <span className='d-block fw-sb font-12 text--secondary '>
                                    Date of Current Illness
                                </span>
                                <span className='d-block font-12 text--terciary'>
                                    {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                    {calenderDateTimeHelper(encounter?.dateOfCurrentIllnessOrSymptom, "MMM DD, YYYY")}
                                </span>


                            </div>
                            {encounter?.patient?.gender?.toLowerCase() === "female" && encounter?.lastMenstrualPeriodDate !== null ? <div className="col-sm-3">
                                <span className='d-block fw-sb font-12 text--secondary '>
                                    Last Menstural Period
                                </span>
                                <span className='d-block font-12 text--terciary'>
                                    {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                    {calenderDateTimeHelper(encounter?.lastMenstrualPeriodDate, "MMM DD, YYYY")}
                                </span>


                            </div> : null}
                        </div>

                        {referringPhysician !== null && referringPhysician !== undefined && Object?.keys(referringPhysician)?.length > 2 ? <div className='row px-2 mt-2'>
                            <div className='col-sm-12'>
                                <span className='font-14 fw-sb text--terciary d-block'>Referring Physician</span>
                                <div className='row '>
                                    <div className='col-sm-3'>
                                        <span className="font-12 d-block fw-sb text--secondary">Name</span>
                                        <span className="font-12 fw-thin d-block">Dr. {referringPhysician?.firstName} {referringPhysician?.lastName}</span>

                                    </div>
                                    <div className='col-sm-2'>
                                        <span className="font-12 d-block fw-sb text--secondary">NPI </span>
                                        <span className="font-12 d-block fw-thin">{referringPhysician?.npi}</span>
                                    </div>
                                    <div className='col-sm-2'>
                                        <span className="font-12 d-block fw-sb text--secondary">Phone </span>
                                        <span className="font-12 d-block fw-thin">{referringPhysician?.phone}</span>
                                    </div>
                                    <div className='col-sm-2'>
                                        <span className="font-12 d-block fw-sb text--secondary">Referral Code </span>
                                        <span className="font-12 d-block fw-thin">{encounter?.referralCode ?? 'N/A'}</span>
                                    </div>
                                    <div className='col-sm-2'>
                                        <span className="font-12 d-block fw-sb text--secondary ">Referral Date </span>
                                        <span className="font-12 d-block fw-thin">{encounter?.referralDate !== null && encounter?.referralDate !== undefined ? dateTimeHelper(encounter?.referralDate, "MMM DD, YYYY") : "N/A"}</span>
                                    </div>
                                    {encounter?.referralNote !== null && encounter?.referralNote !== undefined && encounter?.referralNote?.length > 0 ? <div className='col-sm-12'>
                                        <span className="font-12 d-block fw-sb text--secondary">Referral Note </span>
                                        <span className="font-12 d-block fw-thin">{encounter?.referralNote}</span>
                                    </div> : null}
                                </div>
                            </div>
                        </div> : null}

                        <div className='row mt-2 px-2'>
                            <div className='col-sm-4'>
                                <span className='d-block fw-sb font-12 text--secondary '>
                                    Diagnoses
                                </span>
                                <ul className='list-group'>
                                    {encounter?.diagnosis?.map(d => {
                                        return <li key={d.diagnosisId} style={{ borderColor: '#d9e8ec' }} className='list-group-item font-12'>{`${d.code} - ${d.description}`}</li>
                                    })}
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className='col-sm-4 border--left'>


                        <div>
                            <div className='row py-1'>
                                <div className='col-12'>
                                    <span className='text--terciary d-block font-14 fw-sb'>Patient</span>
                                </div>
                            </div>
                            <div className='row pb-1'>
                                <div className='col-4 font-12'>Name</div>
                                <div className='col-7 font-12'>{`${encounter?.patient?.firstName} ${encounter?.patient?.lastName}`}</div>
                            </div>
                            <div className='row pb-1'>
                                <div className='col-4 font-12'>DOB</div>
                                <div className='col-7 font-12'>{calenderDateTimeHelper(encounter?.patient?.dob, "MMM DD, YYYY")}</div>
                            </div>
                            {/* <div className='row pb-1'>
                                    <div className='col-4 font-12'>PID</div>
                                    <div className='col-7 font-12'></div>
                                </div> */}
                            <div className='row pb-1'>
                                <div className='col-4 font-12'>Gender</div>
                                <div className='col-7 font-12'>{`${encounter?.patient?.gender}`}</div>
                            </div>
                            <div className='row pb-1'>
                                <div className='col-4 font-12'>Address</div>
                                <div className='col-7 font-12'>
                                    {`${encounter?.patient?.addressLine1}${encounter?.patient?.addressLine2 !== null ? `, ${encounter?.patient?.addressLine2}` : ''}, ${encounter?.patient?.city}, ${encounter?.patient?.state}, ${encounter?.patient?.zipCode}`}
                                </div>
                            </div>

                            <div className='row pb-1'>
                                <div className='col-4 font-12'>Phone</div>
                                <div className='col-7 font-12'>{`${encounter?.patient?.primaryPhone}`}</div>
                            </div>


                        </div>

                        {primaryInsurance !== null ? <div>
                            <div className='row py-1'>
                                <div className='col-12'>
                                    <span className='text--terciary d-block font-14 fw-sb'>Insurance</span>
                                </div>
                            </div>
                            {primaryInsurance?.payerName !== null ? <div className='row pb-1'>
                                <div className='col-4 font-12'>Payer Name</div>
                                <div className='col-7 font-12'>{primaryInsurance?.payerName}</div>
                            </div> : null}
                            <div className='row pb-1'>
                                <div className='col-4 font-12'>Type</div>
                                <div className='col-7 font-12'>{primaryInsurance?.type}</div>
                            </div>
                            <div className='row pb-1'>
                                <div className='col-4 font-12'>Member ID</div>
                                <div className='col-7 font-12'>{primaryInsurance?.memberId}</div>
                            </div>
                            <div className='row pb-1'>
                                <div className='col-4 font-12'>Group</div>
                                <div className='col-7 font-12'>{primaryInsurance?.groupNumber}</div>
                            </div>
                            <div className='row pb-1'>
                                <div className='col-4 font-12'>Effective Date</div>
                                <div className='col-7 font-12'>    {calenderDateTimeHelper(primaryInsurance?.effectiveDate, "MMM DD, YYYY")}
                                </div>
                            </div>


                        </div> : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EncounterDetailsPage