import { useEffect, useRef, useState } from "react";
import sendHttpRequest, { search } from "../../hooks/sendHttpRequest";
import DrugItem from "../../prescription/DrugItem";
import classes from "./SearchPharmacy.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import IconNext from "../../../assets/icons/Icon-Next.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PreferredPharmacyModal from "../Modals/PharmacyModal/PreferredPharmacyModal";
import {
  formatPhoneNumber,
  formatPostalCode,
  isValidZipCode,
  pharmacySpecialty,
} from "../../../utils/helpers/HelperFunctions";
import { setUpdateEncounterData } from "../../../redux/actions/providerActions";
import { useDispatch, useSelector } from "react-redux";
import { featureFlagMap } from "../../../utils/helpers/ConfigMap";
import { useAuth } from "../../hooks/useAuth";
import { pharmacySearchURL } from "../../../res/endpointurls";
import _, { result } from 'lodash'
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { PnToolTip } from "../Reusable/PnComponents";
import { searchPharmacyStrings } from "../../../res/strings";

const SearchPharmacy = (props) => {
  const dispatch = useDispatch();
  const pharmacyStrings = searchPharmacyStrings.en;
  const featureFlags = featureFlagMap();
  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter?.Encounter
  );
  const prescribedMedications = useSelector(
    (state) => state.PrescribedMedication.prescribedDrugList
  );
  const [pharmacyResults, setPharmacyResults] = useState([]);
  const [pharmacyName, setPharmacyName] = useState("");
  const searchRef = useRef();
  const proximityRef = useRef();
  const [searchFilter, setSearchFilter] = useState("all");
  const [proximity, setProximity] = useState("0");
  //   const [pageNumber, setPageNumber] = useState(1);
  const [isPharmacyDataLoading, setIsPharmacyDataLoading] = useState(false);
  const [isSlotsLoading, setIsSlotsLoading] = useState(false);
  let { providerId, encounterId, patientId } = useParams();
  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [openPreferredPharmacyModal, setOpenPreferredPharmacyModal] =
    useState(false);
  const [pharmacyItem, setPharmacyItem] = useState();
  const [searchPharmacyError, setSearchPharmacyError] = useState("");
  const [pharmacySelectionError, setPharmacySelectionError] = useState("");
  const { user } = useAuth();
  const [totalResults,setTotalResults] = useState(undefined)

  const searchPharmacy = async (searchInput, speciality, pageNum, proximity) => {
    setPharmacyResults([]);
    setCurrentItems([]);
    setPages([]);
    setIsPharmacyDataLoading(true);
    // setPharmacyName("");
   
    const httpResponse = await search({
      url: featureFlags.get("surescript") === "false" ? pharmacySearchURL(user?.tenantId) : 
      "/pharmacy",
      method: "GET",
      params:
      {
        search: searchInput.trim(),
        specialty: speciality,
        pageNum: pageNum - 1,
        distance: proximity,
        zipCode: props.zipCode
      },
    });
    if (!httpResponse.error) {
      //   setPharmacyResults(httpResponse.data.pharmacies);
      if (httpResponse.data?.pharmacies?.length === 0) {
        setSearchPharmacyError(pharmacyStrings.no_result_found);
      } else {
        let noOfItems = httpResponse?.data?.totalResults;
        setTotalResults(noOfItems);
        setSearchPharmacyError("");
        setCurrentItems(httpResponse.data?.pharmacies);
        checkForProximityFilter(httpResponse.data?.pharmacies);
        setMaxPageLimit(Math.ceil(noOfItems / 10));
        let pagesList = [];
        for (let i = 1; i <= Math.ceil(noOfItems / 10); i++) {
          pagesList.push(i);
        }
        // console.log("pagesList : ",pagesList);
        setPages(pagesList);
      }
    } else {
      setSearchPharmacyError(pharmacyStrings.no_result_found);
      setPharmacyResults([]);
      setTotalResults(undefined);
    }
    setIsPharmacyDataLoading(false);
  };
  const persistPreferredPharmacy = (pharmacyId) => {
    const httpResponse = sendHttpRequest({
      url: "/patient/pharmacy",
      method: "PUT",
      data: {
        userId: props?.patientId,
        preferredPharmacy: { organizationId: pharmacyId },
      },
    });
  };

  /*
    assuming that distance is not null for zip code searches
  */
  const checkForProximityFilter = (pharmacies) => {
    if (pharmacies.find((pharmacy)=>(pharmacy?.distance === null))) {
      proximityRef.current.value = "";
    }
  }

  const handleSearchIconBtn = () => {
    let inputValue = document.getElementById("searchPharmacy").value;
    setPharmacyName(inputValue);
    // isValidZipCode(inputValue);
    setCurrentPage(1);
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);
    if (inputValue.length > 1) {
      setSearchPharmacyError("");
      searchPharmacy(inputValue, searchFilter, 1, proximity);
    } else {
      populatePharmacyError();
    }
  };

  const populatePharmacyError = () => {
    setSearchPharmacyError(pharmacyStrings.minimum_input_length);
    setPharmacyResults([]);
    setCurrentItems([]);
  }

  const handleOnChange = (event) => {
    setSearchPharmacyError("");
    setPharmacyName(event.target.value);
    setCurrentPage(1);
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);
    if (event.key === "Enter" && event.target.value.length > 1) {
      searchPharmacy(event.target.value, searchFilter, 1, proximity);
    } else {
      if (!(event.target.value.length > 1)) populatePharmacyError();
    }
  };
  const handleFilter = (event) => {
    setCurrentPage(1);
    setSearchFilter(event.target.value);
    if(pharmacyName?.length > 1){
      searchPharmacy(pharmacyName, event.target.value, 1, proximity);
    }
  };
  const handleProximity = (event) =>{
    setCurrentPage(1);
    setProximity(event.target.value);
    if(pharmacyName?.length > 1){
      searchPharmacy(pharmacyName, searchFilter, 1, event.target.value);
    }
  }
  const handlePreferredPharmacy = (event, pharmacyItem) => {
    //Getting status before unchecking all
    let status = event.target.checked;
    if (event.target.checked === true) {
      let allCheckboxRef = document.getElementsByClassName("preferredPharmacy");
      for (let i = 0; i < allCheckboxRef.length; i++) {
        allCheckboxRef[i].checked = false;
      }
      if (status === false) {
        event.target.checked = false;
      } else {
        event.target.checked = true;
      }
      // setPharmacyToSession(pharmacyItem)
      // setPharmacyToSession(pharmacyItem);
      setPharmacyItem(pharmacyItem);
      setOpenPreferredPharmacyModal(true);
      // setCurrentItems([]);
      // setPharmacyResults([]);
      // props?.setPreferredPharmacy(pharmacyItem);
      // props?.setPreferredPharmacyFlag(true);
      // persistPreferredPharmacy(pharmacyItem?.organizationId);
    }
  };
  const handleSelectedPharmacy = (event, pharmacyItem) => {
    //Getting status before unchecking all
    let status = event.target.checked;
    let allCheckboxRef = document.getElementsByClassName("selectedPharmacy");
    for (let i = 0; i < allCheckboxRef.length; i++) {
      allCheckboxRef[i].checked = false;
    }
    if (status === false) {
      event.target.checked = false;
      props?.setPharmacyId(-1);
      props?.setOrderPharmacy("");
      setPharmacyItem(undefined);
    } else {
      event.target.checked = true;
      // props?.setPharmacyId(pharmacyItem?.organizationId);
      // props?.setOrderPharmacy(pharmacyItem);
      props?.setPreferredPharmacyFlag(false);
      setPharmacyItem(pharmacyItem);
    }
    setPharmacySelectionError("");
    // setPharmacyToSession(pharmacyItem);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    let inputValue = document.getElementById("searchPharmacy").value;
    searchPharmacy(inputValue, searchFilter, pNumber,proximity);
  };
  const handleNextButton = (event) => {
    event.preventDefault();
    let inputValue = document.getElementById("searchPharmacy").value;
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    searchPharmacy(inputValue, searchFilter, currentPage + 1,proximity);
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    let inputValue = document.getElementById("searchPharmacy").value;
    // setPageNumber(pageNumber - 1);
    if (currentPage - 1 >= 1) {
      setCurrentPage(currentPage - 1);
      searchPharmacy(inputValue, searchFilter, currentPage - 1,proximity);
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
    // searchPharmacy(inputValue, "Retail,MailOrder", currentPage - 1);
  };
  const handleFirstPage = (event)=>{
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);
    
    handleCurrentPage(1);
  }
  const handleLastPage = (event)=>{
    event.preventDefault();
    let lastPage = Math.ceil(totalResults/10)
    let maxPageNumberLimitValue = Math.floor(totalResults/10);
    if((maxPageNumberLimitValue % 10) > 0){
      maxPageNumberLimitValue = maxPageNumberLimitValue - (maxPageNumberLimitValue % 10) + 5 
    }
    // setCurrentPage(totalResults);
    setMaxPageNumberLimit(maxPageNumberLimitValue);
    setMinPageNumberLimit(maxPageNumberLimitValue-pageNumberLimit);
    
    handleCurrentPage(lastPage);
  }
  const filterPharmacies = async ()=>{
    let isMatched = false;
    let preferredId = await props.preferredPharmacy?.organizationId;
    // console.log("preferredId: ",preferredId);
    if(preferredId !== undefined && props?.reOrderPharmacy[0] !== undefined){
      // console.log("Called---------------------------------------------");
      let result = props?.reOrderPharmacy.filter((item)=>{return item.organizationId !== preferredId});
      return [...new Set(result)];
    }
    return props?.reOrderPharmacy;
  }

  useEffect(async ()=>{
    // console.log("props?.reOrderPharmacy,props?.reOrderPharmacyFlag",props?.reOrderPharmacy,props?.reOrderPharmacyFlag);
    let filterResuslt = [];
    async function callFilterPharmacies(){
      filterResuslt = await filterPharmacies();
    }
    await callFilterPharmacies();
    // console.log("filterResuslt: ============",filterResuslt);
    if(props?.reOrderPharmacy[0] !== undefined && 
      props?.reOrderPharmacyFlag === true && filterResuslt?.length > 0){
        
        // console.log("I'm in if condition");
        if(filterResuslt?.length > 0){
          props.setPreferredPharmacyFlag(false);
        }
      props?.setOrderPharmacy(filterResuslt[0])
      setCurrentItems(filterResuslt)
      setPharmacyItem(filterResuslt[0])
      
      setTimeout(() => {
        let checkbox = document.getElementById(`${filterResuslt[0]?.organizationId}`);
        if(checkbox !== null){
          checkbox.checked = true;
        }
      }, 5);
    }else if (props?.preferredPharmacy?.organizationId !== undefined && filterResuslt?.length === 0){
      setCurrentItems([])
      setPharmacyItem(undefined)
      props?.setPreferredPharmacyFlag(true);
    }
    // else{
    //   if(prescribedMedications?.length === 0){
    //     setCurrentItems([])
    //     setPharmacyItem(undefined)
    //     if(props.preferredPharmacy?.organizationId !== undefined){
    //       props?.setPreferredPharmacyFlag(true);
    //     }
    //   }
    // }
    // let searchInput = props?.reOrderPharmacy?.standardizedOrganizationName +" "+ props?.reOrderPharmacy?.postalCode
    // searchPharmacy(searchInput, "", 1, proximity);
  },[props?.reOrderPharmacy,props?.reOrderPharmacyFlag]);
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            // if(number > maxPageNumberLimit){
            //   console.log(number,maxPageNumberLimit + 1,minPageNumberLimit,number < maxPageNumberLimit + 1 ,
            //     number > minPageNumberLimit);
            // }
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}  
                  id={number}
                  className={`${classes['list__item']} ${
                    currentPage === number ? classes["active__list__item"] : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handlePreferredPharmacyModal = () => {
    setOpenPreferredPharmacyModal(false);
  };

  const handleAddPharmacy = () => {
    if (pharmacyItem !== undefined && props?.preferredPharmacyFlag === false) {
      // console.log("I'm in pharmacyItem");
      props?.setOrderPharmacy(pharmacyItem);
      // setPharmacyToSession(pharmacyItem);
      dispatch(
        setUpdateEncounterData({
          ...updateEncounterData,
          Pharmacy: pharmacyItem,
        })
      );

      props?.setPharmacyId(pharmacyItem?.organizationId);
      props?.handleContinueToSummary();
      props?.setAddPharmacyBtnText("Update Pharmacy");
    } else if (
      props?.preferredPharmacy !== undefined &&
      props?.preferredPharmacyFlag === true
    ) {
      // console.log("I'm in props?.preferredPharmacy");
      props?.setOrderPharmacy(props?.preferredPharmacy);
      // setPharmacyToSession(props?.preferredPharmacy);
      dispatch(
        setUpdateEncounterData({
          ...updateEncounterData,
          Pharmacy: pharmacyItem,
        })
      );
      props?.setPharmacyId(props?.preferredPharmacy?.organizationId);
      props?.handleContinueToSummary();
      props?.setAddPharmacyBtnText("Update Pharmacy");
    } else if (props?.orderPharmacy !== undefined) {
      // console.log("I'm in props?.orderPharmacy");
      props?.setOrderPharmacy(props?.orderPharmacy);
      // setPharmacyToSession(props?.orderPharmacy);
      dispatch(
        setUpdateEncounterData({
          ...updateEncounterData,
          Pharmacy: pharmacyItem,
        })
      );
      props?.setPharmacyId(props?.orderPharmacy?.organizationId);
      props?.handleContinueToSummary();
      props?.setAddPharmacyBtnText("Update Pharmacy");
    } else {
      // console.log("I'm in else part");
      if (props?.preferredPharmacyFlag === false) {
        setPharmacySelectionError("Please select a pharmacy.");
      } else {
        setPharmacySelectionError("Please select a pharmacy.");
      }
    }
  };

  useEffect(() => {
    if (props?.focus) {
      searchRef?.current?.focus();
    }
  }, []);
  return (
    <div className="row">
      {openPreferredPharmacyModal && (
        <PreferredPharmacyModal
          show={openPreferredPharmacyModal}
          handleModal={handlePreferredPharmacyModal}
          setPreferredPharmacy={props?.setPreferredPharmacy}
          pharmacyItem={pharmacyItem}
          persistPreferredPharmacy={persistPreferredPharmacy}
          setPharmacyId={props?.setPharmacyId}
          setPreferredPharmacyFlag={props?.setPreferredPharmacyFlag}
          currentItems={currentItems}
          setCurrentItems={setCurrentItems}
          setPharmacyItem={setPharmacyItem}
        />
      )}

        {
          props?.replaceRequest ? null :
          <>
            <div className=" col-sm-6 col-12">
              

              <div
                className={`input-group border--default  br-10 ${classes["search__container"]}`}
              >
                <input
                  type="text"
                  className={`form-control col-sm-6  font-14 border-0 shadow-none ${classes.input}`}
                  id="searchPharmacy"
                  name="searchPharmacy"
                  ref={searchRef}
                  autoComplete="off"
                  placeholder="Search for pharmacy"
                  value={pharmacyName}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                  onKeyPress={(e) => {
                    handleOnChange(e);
                  }}
                />
                <button
                  className={
                    props?.disable === true
                      ? `btn br-10 shadow-none bg--disabled`
                      : `btn br-10 shadow-none`
                  }
                  type="submit"
                  onClick={() => handleSearchIconBtn()}
                >
                  <SearchIcon className=" text--secondary" />
                </button>
              </div>
              <span className="text--danger font-14 ">
                {pharmacySelectionError !== undefined ? pharmacySelectionError : ""}
              </span>
            </div>
            <div className="col-sm-3 col-12">
              <select
                className={`form-select border--default br-10 font-14 shadow-none ${classes["custom-select"]}`}
                aria-label="Appointment type select"
                ref={proximityRef}
                onChange={(e) => {
                  handleProximity(e);
                }}
              >
                <option value={""}>Proximity</option>
                <option value={"5"}>5 miles</option>
                <option value={"10"}>10 miles</option>
                <option value={"25"}>25 miles</option>
                <option value={"50"}>50 miles</option>
                <option value={"100"}>100 miles</option>
              </select>
            </div>
            <div className="col-sm-3 col-12">
              <select
                className={`form-select border--default br-10 font-14 shadow-none ${classes["custom-select"]}`}
                aria-label="Appointment type select"
                onChange={(e) => {
                  handleFilter(e);
                }}
              >
                <option value={"all"}>All pharmacies</option>
                <option value={"Retail"}>Retail</option>
                <option value={"MailOrder"}>Mail Order</option>
              </select>
            </div>
          </>
        }
      {isPharmacyDataLoading ? (
        <Skeleton />
      ) : currentItems?.length > 0 ? 
        <>
        <div>
          {
            totalResults === 1 ?
            <span className="font-12 text--terciary ms-1">{totalResults+" pharmacy found"}</span>
            :
            <span className="font-12 text--terciary ms-1">{totalResults !== undefined && totalResults+" pharmacies found"}</span>
          }
        </div>
          {
            currentItems?.map((pharmacyItem, index) => {
              return (
                <div className="col-sm-12 col-12 mt-3 ms-3" key={index}>
                  <div className="d-flex font-12 text--terciary">
                    {/* <div>{pharmacyItem?.organizationName}</div> */}
                    <div>
                      <input
                        className={`selectedPharmacy cursor--pointer form-check-input shadow-none py-1 ${classes["checkBox"]}`}
                        type="checkbox"
                        name="selectedPharmacy"
                        id = {pharmacyItem?.organizationId}
                        onClick={(e) => {
                          handleSelectedPharmacy(e, pharmacyItem);
                        }}
                      />
                    </div>

                    <div className="col-sm-6 col-8 px-3">
                      <div className="fw-sb">
                        <span>{pharmacyItem?.standardizedOrganizationName}</span>
                      </div>
                      <div>
                        <span>
                          {pharmacyItem?.stand !== null
                            ? "".concat(
                                pharmacyItem?.standardizedAddressLine1,
                                ", ",
                                pharmacyItem?.standardizedAddressLine2?.length > 0 ? pharmacyItem?.standardizedAddressLine2+", " :  "",
                                pharmacyItem?.standardizedCity || "",
                                ", ",
                                pharmacyItem?.stateProvince || "",
                                " ",
                                formatPostalCode(pharmacyItem?.postalCode || pharmacyItem?.standardizedPostalCode) || ""
                              )
                            : " "}
                        </span>
                      </div>
                      <div>
                        <span>
                          {pharmacyItem?.primaryTelephone !== null
                            ? "Phone: " +
                              formatPhoneNumber(pharmacyItem?.primaryTelephone)
                            : " "}
                        </span>
                      </div>
                      <div>
                        <span>
                          {pharmacyItem?.fax !== undefined
                            ? "".concat(
                                "Fax: ",
                                formatPhoneNumber(pharmacyItem?.fax)
                              )
                            : ""}
                        </span>
                      </div>

                      <div>
                        <span>
                          {"Specialty:"}{" "}
                          {pharmacySpecialty(pharmacyItem?.speciality)}
                          {/* {"Specialty: ".concat(
                            pharmacyItem?.speciality
                              ?.replace("~Specialty", "")
                              ?.split("~")
                              ?.join(", ")
                          )} */}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-2 ">
                      {
                        pharmacyItem?.distance !== null && 
                        <span className="font-10 text--terciary">{"Distance: "+ Number(pharmacyItem?.distance).toFixed(1) + " miles"}</span>
                      }
                    </div>
                    
                    {featureFlags.get("surescript") === "true" && (
                      <div className="col-sm-4 border--left">
                        <div className="form-check ms-4">
                          <input
                            className={`preferredPharmacy cursor--pointer my-auto form-check-input shadow-none ${classes["checkBox"]} `}
                            type="checkbox"
                            name="preferredPharmacy"
                            onClick={(e) => {
                              handlePreferredPharmacy(e, pharmacyItem);
                            }}
                          />
                          <label className="ms-1 ps-1 form-check-label font-12 text--terciary">
                            Set as patient preferred 
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          }
        </>
      : (
        <div className="text--terciary font-12">
          {searchPharmacyError !== undefined ? searchPharmacyError : ""}
        </div>
      )}
      <div
        className={`ps-4 mt-4 align-self-center ${
          props?.newPrescriptionRequest === true
            ? "col-sm-7 col-7"
            : "col-sm-8 col-8"
        }`}
      >
        {isPharmacyDataLoading ? (
          <Skeleton />
        ) : (
          <div className={"ps-4 d-flex flex-row justify-content-between"}>
            {pages.length > 1 ? (
              <div className="ps-2 mt-2">
                <PnToolTip title = "First" placement = "top">
                  <button
                    disabled = {currentPage === 1}
                    onClick={(e) => {
                      handleFirstPage(e);
                    }}
                    className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                  >
                    {/* <img src={IconNext} alt="icon-previous"></img> */}
                    <FirstPageIcon style={{color:"#ec609b"}}/>
                  </button>
                </PnToolTip>
                <PnToolTip title = "Previous" placement = "top">
                  <button
                    disabled = {currentPage === 1}
                    onClick={(e) => {
                      handlePreButton(e);
                    }}
                    className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                  >
                    <NavigateBeforeIcon style={{color:"#ec609b"}}/>
                    {/* <img src={IconNext} alt="icon-previous"></img> */}
                  </button>
                </PnToolTip>
              </div>
            ) : (
              ""
            )}

            <RenderPages />
          
            {pages.length > 1 ? (
              <div className="mt-2">
                <PnToolTip title = "Next" placement = "top">
                  <button
                    disabled = {currentPage === pages.length}
                    onClick={(e) => {
                      handleNextButton(e);
                    }}
                    className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                  >
                    {/* <img src={IconNext} alt="icon-next"></img> */}
                    <NavigateNextIcon style={{color:"#ec609b"}}/>
                  </button>
                </PnToolTip>
                  <PnToolTip title = "Last" placement = "top">
                    <button
                      disabled = {currentPage === pages.length}
                      onClick={(e) => {
                        handleLastPage(e);
                      }}
                      className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                    >
                      {/* <img src={IconNext} alt="icon-next"></img> */}
                      <LastPageIcon style={{color:"#ec609b"}}/>
                    </button>
                  </PnToolTip>
               </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <div
        className={`text-end mt-3 align-self-center  ${
          props?.newPrescriptionRequest === true
            ? "col-sm-5 col-5"
            : "col-sm-4 col-4 "
        }`}
      >
        <button
          className="btn blue_task_button"
          onClick={() => {
            handleAddPharmacy();
          }}
          disabled={props?.preferredPharmacyFlag ? false: pharmacyItem === undefined ?  true : false}
        >
          {props?.newPrescriptionRequest === true ? "Continue" : props?.addPharmacyBtnText}
        </button>
      </div>
      {/* </div> */}

      {/* {console.log("Current Page", currentPage)}
      {console.log("Pages List: ",pages)}
      {console.log("Max Page # limit", maxPageNumberLimit)}
      {console.log("Min Page # limit", minPageNumberLimit)} */}
      {/* {console.log("featureFlags.get('surescript')",featureFlags.get("surescript"))} */}
      {/* {console.log("props?.reOrderPharmacy,props?.reOrderPharmacyFlag",props?.reOrderPharmacy,props?.reOrderPharmacyFlag)} */}
    </div>
  );
};

export default SearchPharmacy;
