import SvgIcon from "@mui/material/SvgIcon";
import { styled } from '@mui/system';
import { PnToolTip } from "../../components/UI/Reusable/PnComponents";

export function EditIconComponent(props) {
    return (
        <PnToolTip title={props?.title}>
            <SvgIcon
                {...props}
                style={{
                    width: "17px",  // Set dimensions explicitly
                    height: "17px",  // Set height explicitly
                    fill: props?.style?.color,  // Ensure the fill color is applied correctly
                    cursor: 'pointer',  // Ensure the icon is clickable and shows cursor change on hover
                }}
            >
                <g id="EditIcon" transform="translate(1 1)">
                    <path
                        data-name="Caminho 68"
                        d="M11.946,6H4.988A1.988,1.988,0,0,0,3,7.988V21.9a1.988,1.988,0,0,0,1.988,1.988H18.9A1.988,1.988,0,0,0,20.891,21.9V14.946"
                        transform="translate(-3 -3.891)"
                        fill="none"
                        stroke={props?.style?.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        data-name="Caminho 69"
                        d="M22.437,3.436a2.109,2.109,0,0,1,2.982,2.982L15.976,15.86,12,16.854l.994-3.976Z"
                        transform="translate(-6.036 -2.818)"
                        fill="none"
                        stroke={props?.style?.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </SvgIcon>
        </PnToolTip>
    );
}

