import { Skeleton } from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import PageHeader from "../../patientportal/generic/PageHeader";
import { providerWithNpiUrl } from "../../res/endpointurls";
import {
    calenderDateTimeHelper,
  convertUTCtoTimzone,
  dateTimeHelper,
  getAgeByDateOfBirth,
} from "../../utils/dateTimeHelper";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import sendHttpRequest, {
  sendHttpRequestWithCancellation,
} from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { StyledTableRow, StyledTableCell, PnToolTip } from "../UI/Reusable/PnComponents";
import { useEffect } from "react";
import {
  convertToCSV,
  generateCSVFile,
  getPatientConcatenatedNameFromNameObject,
  getProviderConcatenatedNameFromNameObject,
  getProviderNameWithQualification,
  truncate,
} from "../../utils/helpers/HelperFunctions";
import FileDownloadIcon from "@mui/icons-material/FileDownload";


const HuddlePage = () => {
  const { user } = useAuth();
  const date = new Date();
  const fromDate = convertUTCtoTimzone(date, "", "YYYY-MM-DD");
  const toDate = convertUTCtoTimzone(
    moment(date).add(6, "days"),
    "",
    "YYYY-MM-DD"
  );
  const [appointmentStartDate, setAppointmentStartDate] = useState(fromDate);
  const [appointmentEndDate, setAppointmentEndDate] = useState(toDate);
  const [appointmentStatus, setAppointmentStatus] = useState("all"); 

  const [prescribersList, setPrescribersList] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentTableRows, setAppointmentTableRows] = useState([]);
  const [CSVData, setCSVData] = useState([]);
  const [appointmentDataMsg,setAppointmentDataMsg] = useState("");

  function handleAppointmentStartDate(startDateValue) {
    setAppointmentStartDate(startDateValue);
  }

  function handleAppointmentEndDate(endDateValue) {
    setAppointmentEndDate(endDateValue);
  }

  async function fecthAllPrescribers() {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
  }

  function handleProviderId(event) {
    setSelectedProviderId(event.target.value);
  }

  function handleAppointmentStatus(event) {
    setAppointmentStatus(event.target.value);
  }

  async function filterAppointments() {
    console.log(
      `/appointment/tenant/${user?.tenantId}?fromDate=${appointmentStartDate}&toDate=${appointmentEndDate}&status=${appointmentStatus}`
    );
    let url = `/appointment/tenant/${user?.tenantId}?fromDate=${appointmentStartDate}&toDate=${appointmentEndDate}&status=${appointmentStatus}&providerId=${selectedProviderId}`;
    if (appointmentStatus === "all") {
      url = `/appointment/tenant/${user?.tenantId}?fromDate=${appointmentStartDate}&toDate=${appointmentEndDate}&providerId=${selectedProviderId}`;
    }
    const httpResponse = await sendHttpRequestWithCancellation({
      method: "GET",
      url: url,
    });
    if (!httpResponse.error) {
      const arr = httpResponse.data?.map((appointment) => {
        const insuranceGroup =
          appointment?.prescriptionReference?.patient?.patientInsurance?.at(0);
        return {
          startDateTime: appointment?.appointmentSlot?.startDateTime,
          patientName: getPatientConcatenatedNameFromNameObject(
            appointment?.prescriptionReference?.patient?.name?.at(0)
          ),
          dateOfBirth: appointment?.prescriptionReference?.patient?.dateOfBirth,
          age: getAgeByDateOfBirth(
            appointment?.prescriptionReference?.patient?.dateOfBirth
          ),
          gender: appointment?.prescriptionReference?.patient?.gender,
          chiefComplaint: appointment?.chiefComplaint,
          providerName: getProviderNameWithQualification(
            appointment?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name?.at(
              0
            ),
            appointment?.prescriptionReference?.providerTenantPracticeLocation
              ?.provider?.qualification
          ),
          insuranceName:
            insuranceGroup !== null && insuranceGroup?.insuranceName?.length > 0
              ? insuranceGroup?.insuranceName
              : "Not available",
        };
      });
      const csvArr = httpResponse.data?.map((appointment) => {
        const insuranceGroup =
          appointment?.prescriptionReference?.patient?.patientInsurance?.at(0);
        return {
          "Start Date Time": dateTimeHelper(appointment?.appointmentSlot?.startDateTime,"YYYY-MM-DD hh:mm"),
          "Patient Name": getPatientConcatenatedNameFromNameObject(
            appointment?.prescriptionReference?.patient?.name?.at(0)
          ),
          "Date of Birth": calenderDateTimeHelper(appointment?.prescriptionReference?.patient?.dateOfBirth,"YYYY-MM-DD"),
          "Age": getAgeByDateOfBirth(
            appointment?.prescriptionReference?.patient?.dateOfBirth
          ),
          "Gender": appointment?.prescriptionReference?.patient?.gender,
          "Chief Complaint": appointment?.chiefComplaint,
          "Provider Name": "".concat(
            appointment?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.firstName," ",
            appointment?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.lastName," - ",
            appointment?.prescriptionReference?.providerTenantPracticeLocation
              ?.provider?.qualification
          ),
          "Insurance Name":
            insuranceGroup !== null && insuranceGroup?.insuranceName?.length > 0
              ? insuranceGroup?.insuranceName
              : "Not available",
          "Status": appointment?.status
        };
      });
      setAppointmentTableRows(arr);
      setCSVData(csvArr);
      if(arr?.length === 0){
        setAppointmentDataMsg("No data available")
      }else{
        setAppointmentDataMsg("")
      }
    }
  }

  function exportAsCsv() {
    const csvData = convertToCSV(CSVData);
    generateCSVFile(csvData,"Huddle_".concat(calenderDateTimeHelper(new Date(),"YYYY-MM-DD"),"_",calenderDateTimeHelper(new Date(),"hh:mm")));
  }

  useEffect(() => {
    fecthAllPrescribers();
  }, []);

  return (
    <>
      <PageHeader title="Huddle Sheet" />
      <div className="container-fluid  py-2">
        <div className="row justify-content-center">
          <div className="col-sm-10 br-10 border--default py-3 ">
            <div className="d-flex gap-4 justify-content-center flex-wrap">
              <div className="d-flex gap-4">
                <div className="fw-sb flex-column">
                  <div className="fw-sb py-1 font-12">Appointment Date</div>
                  <div className="d-flex gap-2">
                    <div className="align-self-center">
                      <label className="form-label font-12 fw-thin pe-1">
                        From:{" "}
                      </label>
                    </div>
                    <div>
                      {" "}
                      <CalenderDatePicker
                        dobHandler={handleAppointmentStartDate}
                        date={appointmentStartDate}
                        // minDate={appointmentStartDate}
                        f12={true}
                        // borderColor="#d9e8ec"
                        // label="Date of birth"
                        // publishSchedule={true}
                        showNextYear={true}
                        required
                      />
                    </div>
                    <div className="align-self-center">
                      <label className="form-label fw-thin font-12 m-0 d-block">
                        To:{" "}
                      </label>
                    </div>
                    <div>
                      {" "}
                      <CalenderDatePicker
                        dobHandler={handleAppointmentEndDate}
                        date={appointmentEndDate}
                        // minDate={appointmentStartDate}
                        f12={true}
                        // borderColor="#d9e8ec"
                        // label="Date of birth"
                        // publishSchedule={true}
                        showNextYear={true}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="fw-sb flex-column">
                  <div className="fw-sb py-1 font-12">Provider</div>
                  {/* <label className='form-label text-white font-12 m-0 d-block'>Provider </label> */}
                  {isLoading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    <div>
                      <select
                        className={`form-select font-13 shadow-none`}
                        aria-label="Provider select"
                        style={{
                          border: "1px solid #D9E8EC",
                          height: "36px",
                          width: "250px",
                          borderRadius: "10px",
                          // background: "#FAFCFC",
                        }}
                        required
                        onChange={(e) => handleProviderId(e)}
                        onInvalid={(e) => {
                          e.target.setCustomValidity(
                            "Please select provider from the list"
                          );
                        }}
                        onInput={(e) => {
                          e.target.setCustomValidity("");
                        }}
                      >
                        {/* <option value="" disabled selected hidden>
                          Select provider
                        </option> */}
                        <option value="0" selected>All Providers</option>
                        {prescribersList !== undefined &&
                          prescribersList?.length > 0 &&
                          prescribersList?.map((prescriberItem) => {
                            return (
                              <option
                                value={prescriberItem?.userId}
                                key={prescriberItem?.userId}
                                timezone={prescriberItem?.timezone}
                              >
                                {prescriberItem?.name[0]?.prefix}{" "}
                                {prescriberItem?.name[0]?.firstName}{" "}
                                {prescriberItem?.name[0]?.lastName}{" "}
                                {RolesTextConversion(prescriberItem?.role) !==
                                undefined
                                  ? "(" +
                                    RolesTextConversion(prescriberItem?.role) +
                                    ")"
                                  : ""}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex">
                <div className="fw-sb flex-column">
                  <div className="fw-sb py-1 font-12">Appointment Status</div>

                  <div>
                    <select
                      className={`form-select font-13 shadow-none`}
                      aria-label="Provider select"
                      style={{
                        border: "1px solid #D9E8EC",
                        height: "36px",
                        width: "250px",
                        borderRadius: "10px",
                        // background: "#FAFCFC",
                      }}
                      required
                      onChange={(e) => handleAppointmentStatus(e)}
                    >
                      {/* <option value="" disabled selected hidden>
                        Select status
                      </option> */}
                      <option value="all" selected>All Statuses</option>
                      <option value="Pending">Pending</option>
                      <option value="cancelled">Canceled</option>
                      <option value="started">Started</option>
                      <option value="scheduled">Scheduled</option>
                      <option value="complete">Completed</option>
                    </select>

                    <div className="text-end">
                      <button
                        onClick={() => filterAppointments()}
                        className="btn mt-3 font-12 blue_task_button"
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='d-flex justify-content-end mt-3'>
                            <div>
                                <button className='btn font-12 blue_task_button'>Filter</button>
                            </div>
                        </div> */}
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <div className="col-sm-10">
            <div className="row justify-content-end">
              {appointmentTableRows?.length > 0 ? (
                <div className="col-sm-3 text-end me-1">
                  {/* <span disabled={appointmentTableRows?.length === 0} onClick={() => exportAsCsv()} className={`font-12 cursor--pointer fw-sb pn-link-button text--blue ${appointmentTableRows?.length === 0 ? 'disabled' : ''}`}>Export as CSV</span> */}
                  <div
                    className="cursor--pointer align-self-center me-1"
                    onClick={() => exportAsCsv()}
                    hidden={appointmentTableRows?.length === 0 ? true : false}
                  >
                    <PnToolTip title={"Download CSV"} className="">
                      <FileDownloadIcon
                        sx={{
                          color: "#336383",
                          fontWeight: 400,
                          fontSize: "1.5rem",
                        }}
                      />
                    </PnToolTip>
                  </div>
                </div>
              ) : null}
            </div>
            {appointmentTableRows?.length > 0 ? (
              <FilteredAppointmentsTable
                appointmentTableRows={appointmentTableRows}
                columnHeadersList={[
                  "Date & Time",
                  "Patient Name",
                  "Age",
                  "Date of Birth",
                  "Gender",
                  "Chief Complaint",
                  "Provider",
                  "Insurance Group",
                ]}
              />
            ) : appointmentDataMsg}
          </div>
        </div>
      </div>
    </>
  );
};

export default HuddlePage;

function FilteredAppointmentsTable({
  columnHeadersList,
  appointmentTableRows,
}) {
  return (
    <TableContainer sx={{ borderRadius: "10px", border: "1px solid #D9E8EC" }}>
      <Table>
        <TableHead
          sx={{
            borderTopColor: "#FAFCFC",
            borderRightColor: "#FAFCFC",
            borderLeftColor: "#FAFCFC",
            borderBottom: "1px solid #D9E8EC",
          }}
        >
          <StyledTableRow>
            {columnHeadersList?.map((item, idx) => (
              <StyledTableCell sx={item === 'Age' ? {maxWidth:'3rem'}: item === "Chief Complaint" ? {minWidth:'10rem'}: {minWidth:'8rem'}} key={item?.concat(idx)}><span className="font-12 text--secondary">{item}</span></StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {appointmentTableRows?.map((item, idx) => {
            return (
              <StyledTableRow
                key={idx}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell
                // sx={{ color: "#336383", fontWeight: "600" }}
                >
                  <div className="p-0 m-0 font-12 d-flex flex-column">
                    <div>
                      {dateTimeHelper(item?.startDateTime, "MMM D, YYYY")}
                    </div>
                    <div>{dateTimeHelper(item?.startDateTime, "LT")}</div>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                // sx={{ width: "100", color: "#336383" }}
                >
                  <PnToolTip title={item?.patientName}><span>{truncate(item?.patientName,15)}</span></PnToolTip>
                </StyledTableCell>

                <StyledTableCell>{item?.age}</StyledTableCell>

                <StyledTableCell>
                  {calenderDateTimeHelper(item?.dateOfBirth, "MMM D, YYYY")}
                </StyledTableCell>

                <StyledTableCell>{item?.gender}</StyledTableCell>

                <StyledTableCell><PnToolTip title={item?.chiefComplaint}><span>{truncate(item?.chiefComplaint,35)}</span></PnToolTip></StyledTableCell>

                <StyledTableCell><PnToolTip title={item?.providerName}><span>{truncate(item?.providerName,15)}</span></PnToolTip></StyledTableCell>

                <StyledTableCell>{item?.insuranceName}</StyledTableCell>
              </StyledTableRow>
            );
          })}
          {/* </>} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
