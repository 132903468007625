import { value } from "@mui/system";
import Papa from "papaparse";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";

class CSVService {
  /**
   * Converts nested JSON data to CSV by flattening child attributes.
   * @param {Array} data - The data to be converted to CSV.
   * @param {string} fileName - The name of the file to download.
   * @param {Array} selectedColumns - The columns to include in the CSV.
   */

  static prepareDataForCSV(headers, data) {
    return data.map((row) => {
      let newRow = {};
      headers.forEach((header, index) => {
        newRow[header] = row[index] || ""; // Map each header to corresponding data
      });
      return newRow;
    });
  }
  static downloadCSV(data, fileName = "claims_data.csv", headers) {
    // Prepare data with headers
    const formattedData = this.prepareDataForCSV(headers, data);

    // Generate CSV with headers and data
    const csv = Papa.unparse({
      fields: headers,
      data: formattedData,
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a link to download the CSV file
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", fileName);

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Flattens a nested object to handle child attributes for CSV.
   * @param {Object} obj - The object to flatten.
   * @param {string} prefix - The prefix for nested keys.
   */
  static flattenObject(obj, prefix = "") {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? `${prefix}.` : "";
      if (typeof obj[k] === "object" && obj[k] !== null) {
        Object.assign(acc, CSVService.flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  }

  /**
   * Returns default report metadata with column selection.
   * @returns {Array} - An array of column metadata.
   */
  static claimDefaultReportMetaData() {
    return [
      {
        key: "claimNumber",
        value: "claimNumber",
        defaultCheck: true,
        tableName: "Claim",
      },
      {
        key: "tenantId",
        value: "tenantId",
        defaultCheck: false,
        tableName: "Claim",
      },
      {
        key: "claimId",
        value: "claimId",
        defaultCheck: false,
        tableName: "Claim",
      },
      {
        key: "patientId",
        value: "patientId",
        defaultCheck: false,
        tableName: "Claim",
      },
      {
        key: "updatedDate",
        value: "updatedDate",
        defaultCheck: false,
        tableName: "Claim",
      },
      {
        key: "encounter.createdDate",
        value: "createdDate",
        defaultCheck: true,
        tableName: "Claim",
      },
      {
        key: "claimResponse.apexEdiClaimNumber",
        value: "apexEdiClaimNumber",
        defaultCheck: true,
        tableName: "Claim",
      },
      {
        key: "encounter.provider.prefix",
        value: "provider.prefix",
        defaultCheck: true,
        tableName: "Provider",
      },
      {
        key: "encounter.provider.firstName",
        value: "provider.firstName",
        defaultCheck: true,
        tableName: "Provider",
      },
      {
        key: "encounter.provider.lastName",
        value: "provider.lastName",
        defaultCheck: true,
        tableName: "Provider",
      },
      {
        key: "encounter.provider.npi",
        value: "NPI",
        defaultCheck: true,
        tableName: "Provider",
      },
      {
        key: "encounter.patient.firstName",
        value: "patient.firstName",
        defaultCheck: true,
        tableName: "Patient",
      },
      {
        key: "encounter.patient.lastName",
        value: "patient.lastName",
        defaultCheck: true,
        tableName: "Patient",
      },
      {
        key: "encounter.createdDate",
        value: "createdDate",
        defaultCheck: true,
        tableName: "Encounter",
      },
      {
        key: "encounter.patient.patientInsurance[0].payerName",
        value: "payerName",
        defaultCheck: true,
        tableName: "Encounter",
      },
      {
        key: "totalAmount",
        value: "totalAmount",
        defaultCheck: true,
        tableName: "Claim",
      },
      {
        key: "claimResponse.payerAmount",
        value: "payerAmount",
        defaultCheck: true,
        tableName: "Claim",
      },
      {
        key: "claimPayment.patientResponsibility",
        value: "patientResponsibility",
        defaultCheck: true,
        tableName: "Claim",
      },
      {
        key: "status",
        value: "status",
        defaultCheck: true,
        tableName: "Claim",
      },
      {
        key: "claimServiceId",
        value: "claimServiceId",
        defaultCheck: false,
        tableName: "claimServices",
      },
      {
        key: "dateOfServiceFrom",
        value: "dateOfServiceFrom",
        defaultCheck: true,
        tableName: "claimServices",
      },
      {
        key: "dateOfServiceTo",
        value: "dateOfServiceTo",
        defaultCheck: true,
        tableName: "claimServices",
      },
      {
        key: "charges",
        value: "charges",
        defaultCheck: true,
        tableName: "claimServices",
      },
      {
        key: "hcpcsProcedureDescription",
        value: "hcpcsProcedureDescription",
        defaultCheck: true,
        tableName: "claimServices",
      },
      {
        key: "hcpcsProcedureCode",
        value: "hcpcsProcedureCode",
        defaultCheck: true,
        tableName: "claimServices",
      },
      {
        key: "modifiers",
        value: "modifiers",
        defaultCheck: true,
        tableName: "claimServices",
      },
      {
        key: "diagnoses.code",
        value: "Code",
        defaultCheck: true,
        tableName: "diagnoses",
      },
      {
        key: "diagnoses.description",
        value: "Description",
        defaultCheck: true,
        tableName: "diagnoses",
      },
    ];
  }

  /**
   * Gets the selected columns based on default checks.
   * @returns {Array} - An array of keys for the selected columns.
   */
  static getSelectedColumns(item, objectKey) {
    const keys = objectKey.key?.split(".");
    let value = item;
    let finalKey = objectKey.value; // Store the final key for the key-value pair
    // console.log("value: ", value, objectKey);

    for (let key of keys) {
      if(objectKey.key.includes('patientInsurance') ){
        return {
          key: key,
          value: value?.encounter?.patient?.patientInsurance?.length >  0 ?  value?.encounter?.patient?.patientInsurance[0][keys.at(-1)] : null,
        };
      }
      if (value && value.hasOwnProperty(key)) {
        if(key.toLowerCase().includes('date')){
         value = calenderDateTimeHelper(value[key],"MMM DD, YYYY")
        }else{
          value = value[key];
        }
      } else if ("code" === key) {
        // console.log("item code: ", item?.claimServices?.diagnoses[0]?.code);
        // Check if diagnoses exist and return the code
        return {
          key: "code",
          value: item?.claimServices?.diagnoses?.[0]?.code || null,
        };
      } else if ("description" === key) {
        // Check if diagnoses exist and return the description
        return {
          key: "description",
          value: item?.claimServices?.diagnoses?.[0]?.description || null,
        };
      } else if (
        key === "name" &&
        (objectKey.key === "encounter.provider.name" ||
          objectKey.key === "encounter.patient.name")
      ) {
        let prefix = value["prefix"];
        let firstName = value["firstName"];
        let lastName = value["lastName"];
        // console.log("key: ", key,prefix,firstName,lastName);
        let fullName = "".concat(prefix ||"" , firstName || ""," ", lastName||"") ;
        // console.log("fullName: ",fullName);
        
        return { key: finalKey, value: fullName };
      } else {
        return { key: finalKey, value: null }; // Return null if the key doesn't exist
      }
    }

    return { key: finalKey, value: value !== undefined ? value : null }; // Return the key-value pair
  }
}

export default CSVService;
