import { useEffect, useRef, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { DateTime } from "luxon";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { set } from "lodash";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";

function HipaaConsentForm(props) {
    const navigate = useNavigate();

    const [isImagePathLoading, setIsImagePathLoading] = useState(false);
    const [imagePath, setImagePath] = useState(null);
    const { uuid, key } = useParams();
    const [showTextField, setShowTextField] = useState(false);
    const [sigPad, setSigPad] = useState();
    const [filename, setFilename] = useState(null);
    const [signedDate, setSignedDate] = useState(null);
    const [witnessDate, setWitnessDate] = useState(null);
    const [witnessName, setWitnessName] = useState('');
    const [allowContactForAppointments, setAllowContactForAppointments] = useState(false);
    const [leaveMessage, setLeaveMessage] = useState(false);
    const [discussMedicalCondition, setDiscussMedicalCondition] = useState(false);
    const [familyMembersAllowed, setFamilyMembersAllowed] = useState(null);
    const [signedBy, setSignedBy] = useState(null);
    const fd = useRef(new FormData());

    const [checkingFormValidity, setCheckingFormValidity] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSent, setIsSent] = useState(false);


    const [relation, setRelation] = useState('');
    const [relationFirstName, setRelationFirstName] = useState('');
    const [relationLastName, setRelationLastName] = useState('');   

    const handleRelation = (e) =>{
        setRelation(e.target.value);
    }

    const handleRelationName = (e)=>{
        if(e.target.name === "firstName"){
            setRelationFirstName(e.target.value);
        }
        else if(e.target.name === "lastName"){
            setRelationLastName(e.target.value);
        }
    }


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsSent(false);
    };

    const signedDateHandler = (d) => {
        setSignedDate(d);
    };

    const witnessDateHandler = (d) => {
        setWitnessDate(d);
    }






    const handleRadioChange = (e) => {

        if (e.target.name === "discussMedicalCondition" && e.target.value === "yes") {
            setShowTextField(true);
            setDiscussMedicalCondition(e.target.value === "yes");
        } else if (e.target.name === "discussMedicalCondition" && e.target.value === "no") {
            setShowTextField(false);
            setDiscussMedicalCondition(e.target.value === "yes");
        }
        else if (e.target.name === "allowContactForAppointments") {
            // console.log(e.target.value);
            setAllowContactForAppointments(e.target.value === "yes");
        }
        else if (e.target.name === "leaveMessage") {
            // console.log(e.target.value);
            setLeaveMessage(e.target.value === "yes");
        }

    };

    const handleFamilyMembersAllowed = (e) => {
        setFamilyMembersAllowed(e.target.value);
    }


    const convertToImage = () => {
        let trimmedDataURL = sigPad.getTrimmedCanvas().toDataURL("image/png");
        // console.log(trimmedDataURL);
        let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];

        fetch(trimmedDataURL)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], "signature.png", {
                    type: mimeType,
                });
                setFilename(file);
            });
    };
    // console.log("filename:", filename);
    async function fetchTenantLogo() {
        setIsImagePathLoading(true);
        const httpResponse = await sendHttpRequest({
            url: `/tenants/${uuid}/logo`,
            method: "GET",
        });
        if (!httpResponse.error) {
            setImagePath(httpResponse.data.imagePath);
        } else {
            // return false;
        }
        setIsImagePathLoading(false);
    }

    const resetForm = () => {
        document.getElementById("hipaa-form").reset();
        document.querySelectorAll(".react-datepicker__close-icon")
            .forEach((i) => i.click());
        setAllowContactForAppointments(false);
        setDiscussMedicalCondition(false);
        setFamilyMembersAllowed(false);
        setFilename(null);
        setSignedBy('');
        setWitnessDate('');
        sigPad.clear();

    }

    const handleSaveHipaaForm = async (e) => {
        e.preventDefault();
        fd.current.set("allowContactForAppointments", allowContactForAppointments);
        fd.current.set(`leaveMessage`, leaveMessage);
        fd.current.set("discussMedicalCondition", discussMedicalCondition);
        if(discussMedicalCondition === true){
            fd.current.set("allowedFamilyMemberRelation", relation);
            fd.current.set("allowedFamilyMemberFirstName", relationFirstName);
            fd.current.set("allowedFamilyMemberLastName", relationLastName);
        }
        // fd.current.set("familyMembersAllowed", familyMembersAllowed);
        fd.current.set("signedBy", signedBy);
        fd.current.set("signedDate", signedDate);
        if(witnessName !== undefined && witnessName !== null && witnessName !== ''){

            fd.current.set("witnessName", witnessName);
        }
        if(witnessDate !== undefined && witnessDate !== null){
            
            fd.current.set("witnessDate", witnessDate);
        }

        if (filename !== null) {
            fd.current.set("signature", filename);
        }

        const httpResponse = await sendHttpRequest({
            url: `/tenants/${uuid}/hipaa-consent/${key}`,
            method: "POST",
            data: fd.current,
            headers: {
                "Content-Type": "multipart/form-data; boundary=",
            },
        });

        if (!httpResponse.error) {

            fd.current = null;
            resetForm();
            setIsSent(true);
            navigate('/hipaa-success', { replace: true, state: { uuid: uuid } });
        }
        else {
            fd.current = new FormData();
            // navigate('/hipaa-invalid', { replace: true, state:{uuid:uuid} });
        }

    }

    async function checkFormValidity() {
        setCheckingFormValidity(true);
        const httpResponse = await sendHttpRequest({
            url: `/tenants/${uuid}/hipaa-consent/${key}/validate`,
            method: "GET",
        });
        if (httpResponse.error) {
            navigate('/hipaa-invalid', { replace: true, state: { uuid: uuid } });
        }
        else {
            setIsFormValid(httpResponse.data);
            if (httpResponse.data === false) {
                navigate('/hipaa-invalid', { replace: true, state: { uuid: uuid } });
            }
        }
        
        setCheckingFormValidity(false);
    }


    function validateForm() {
        if (
            (allowContactForAppointments === true || allowContactForAppointments === false) &&
            (leaveMessage === true || leaveMessage === false) &&
            signedBy &&
            signedDate 


        ) {

            setIsDisabled(false);
        } else {

            setIsDisabled(true);
        }
    }

    useEffect(() => {
        validateForm();
    }, [allowContactForAppointments, leaveMessage, discussMedicalCondition, signedBy, signedDate]);






    useEffect(() => {
        checkFormValidity();
        fetchTenantLogo();
    }, []);

    return (

        <div className="container pb-5">
            <CustomizedSnackbar
                message={"HIPAA consent form submitted successfully"}
                severity="success"
                open={isSent}
                handleClose={handleClose}
            />
            <div className="row py-4 justify-content-center">
                <div className="col-sm-9 text-center">
                    {
                        isImagePathLoading ? <Skeleton /> :
                            <img src={imagePath} alt="logo" width="300" className="img-fluid" />
                    }
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-sm-8">
                    <h2 className="text-center text--secondary fw-sb">HIPAA Compliance Patient Consent Form</h2>
                    <p className="font-12">
                        Our Notice of Privacy Practices provides information about how we may use or disclose protected health information.
                    </p>
                    <p className="font-12">
                        The terms of the notice may change, if so, you will be notified at your next visit to update your
                        signature/date.
                    </p>
                    <p className="font-12">
                        You have the right to restrict how your protected health information is used and disclosed for treatment,
                        payment, or healthcare operations. We are not required to agree with this restriction, but if we do, we shall
                        honor this agreement. The HIPAA (Health Insurance Portability and Accountability Act of 1996) law allows for
                        the use of the information for treatment, payment, or healthcare operations.
                    </p>
                    <p className="font-12">
                        By signing this form, you consent to our use and disclosure of your protected healthcare information and
                        potentially anonymous usage in a publication. You have the right to revoke this consent in writing, signed by
                        you. However, such a revocation will not be retroactive.
                    </p>
                    <p className="font-12">By signing this form, I understand that:</p>
                    <ul className="font-12">
                        <li>
                            Protected health information may be disclosed or used for treatment, payment, or healthcare operations.
                        </li>
                        <li>
                            The practice reserves the right to change the privacy policy as allowed by law.
                        </li>
                        <li>
                            The practice has the right to restrict the use of the information but the practice does not have to agree to
                            those restrictions.
                        </li>
                        <li>
                            The patient has the right to revoke this consent in writing at any time and all full disclosures will then
                            cease.
                        </li>
                        <li>
                            The practice may condition receipt of treatment upon execution of this consent.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-sm-8">
                    <form onSubmit={handleSaveHipaaForm} id="hipaa-form">
                        <div className="form-group">
                            <div className="row font-12">
                                <div className="col-sm-7 col-12">

                                    <div className="d-flex justify-content-between">
                                        <label className="form-label fw-sb">
                                            May we phone, email, or send a text to you to confirm appointments? <span>&#42;</span>
                                        </label>
                                        <div className="d-flex gap-2">
                                            <div className="form-check me-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="allowContactForAppointments"
                                                    id="allowContactForAppointments"
                                                    value="yes"
                                                    required
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="allowContactForAppointments">
                                                    YES
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="allowContactForAppointments"
                                                    id="allowContactForAppointmentsNo"
                                                    value="no"
                                                    required
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="allowContactForAppointmentsNo">
                                                    NO
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <label className="form-label fw-sb">
                                            May we leave a message on your answering machine at home or on your cell phone? <span>&#42;</span>
                                        </label>
                                        <div className="d-flex gap-2">
                                            <div className="form-check me-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="leaveMessage"
                                                    id="leaveMessageYes"
                                                    value="yes"
                                                    required
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="leaveMessageYes">
                                                    YES
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="leaveMessage"
                                                    id="leaveMessageNo"
                                                    value="no"
                                                    required
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="leaveMessageNo">
                                                    NO
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <label className="form-label fw-sb">
                                            May we discuss your medical condition with any member of your family? <span>&#42;</span>
                                        </label>
                                        <div className="d-flex gap-2">
                                            <div className="form-check me-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="discussMedicalCondition"
                                                    id="discussMedicalConditionYes"
                                                    required
                                                    value="yes"
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="discussMedicalConditionYes">
                                                    YES
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="discussMedicalCondition"
                                                    id="discussMedicalConditionNo"
                                                    value="no"
                                                    required
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="discussMedicalConditionNo">
                                                    NO
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {showTextField && (
                                        <div className="row mb-3">
                                            <div className="col-sm-4">
                                                <label
                                                    htmlFor="relationship"
                                                    className="form-label m-0 fw-sb font-12 text--terciary"
                                                >
                                                    Relationship
                                                </label>
                                                <select
                                                    className="form-select font-12 border--default br-10 shadow-none input"
                                                    aria-label="relation"
                                                    id="relation"
                                                    name="relation"
                                                    onChange={handleRelation}
                                                    style={{ borderRadius: "10px" }}

                                                >
                                                    <option value="">Select</option>
                                                    <option value="Parent">Parent</option>
                                                    <option value="Child">Child</option>
                                                    <option value="Spouse">Spouse</option>
                                                    <option value="Legal Guardian">Legal Guardian</option>
                                                    <option value="Sibling">Sibling</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-4">
                                                <label
                                                    htmlFor="relationship"
                                                    className="form-label m-0 fw-sb font-12 text--terciary"
                                                >
                                                    First Name
                                                </label>

                                                <input
                                                    onChange={handleRelationName}
                                                    type="text"
                                                    className="form-control font-12 br-10 shadow-none border--default"
                                                    id="firstName"
                                                    name="firstName"
                                                />

                                            </div>
                                            <div className="col-sm-4">
                                            <label
                                                    htmlFor="relationship"
                                                    className="form-label m-0 fw-sb font-12 text--terciary"
                                                >
                                                    Last Name
                                                </label>

                                                <input
                                                    onChange={handleRelationName}
                                                    type="text"
                                                    className="form-control font-12 br-10 shadow-none border--default"
                                                    id="lastName"
                                                    name="lastName"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="form-group">
                                        <label htmlFor="signedBy" className="form-label fw-sb">
                                            This consent was signed by: <span>&#42;</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control font-12 br-10 shadow-none border--default"
                                            id="signedBy"
                                            name="signedBy"
                                            required
                                            onChange={e => setSignedBy(e.target.value)}
                                            placeholder="Enter your name"
                                        />
                                    </div>


                                </div>
                            </div>
                            <div className="row mt-2 font-12">
                                <div className="col-sm-7">
                                    <div className="form-group">
                                        <label className="form-label fw-sb">
                                            Signature:<span>&#42;</span>
                                        </label>
                                        <div>
                                            <SignatureCanvas
                                                penColor="green"
                                                ref={(ref) => {
                                                    setSigPad(ref);
                                                }}
                                                onBegin={() => {
                                                    // onDirty();
                                                }}
                                                onEnd={() => {
                                                    convertToImage();
                                                }}
                                                canvasProps={{
                                                    width: 485,
                                                    height: 150,
                                                    className: "telemedicine-canvas",
                                                }}
                                            />

                                            <div className="d-flex ">
                                                <button className='btn text-end btn-outline-secondary shadow-none font-12 br-10'
                                                    onClick={(e) => { e.preventDefault(); sigPad.clear(); setFilename(null) }}
                                                >Clear</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-4">

                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group ">
                                        <label
                                            htmlFor="signedDate"
                                            className="form-label  font-12  fw-sb"
                                        >
                                            Signed Date: <span>&#42;</span>
                                        </label>

                                        <CalenderDatePicker

                                            name="signedDate"
                                            label="Signed Date"
                                            dobHandler={signedDateHandler}
                                            disableFuture={true}
                                            required
                                            maxDate={DateTime.now()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-4 col-12">
                                    <div className="form-group">
                                        <label className="form-label font-12 fw-sb" htmlFor="witnessName">
                                            Witness Name: 
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control font-12 br-10 shadow-none border--default"
                                            id="witnessName"
                                            name="witnessName"
                                            
                                            onChange={e => setWitnessName(e.target.value)}
                                            placeholder="Enter witness name"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-4 col-12">
                                    <div className="form-group">
                                        <label className="form-label font-12 fw-sb" htmlFor="witnessName">
                                            Witness Date: 
                                        </label>
                                        <CalenderDatePicker
                                            
                                            name="witnessDate"
                                            label="Witness Date"
                                            dobHandler={witnessDateHandler}
                                            disableFuture={true}
                                            maxDate={DateTime.now()}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row  mt-3 justify-content-end text-end">
                            <div className="col-sm-8 text-end">
                                <button form="hipaa-form" disabled={isDisabled} type='submit' className='btn blue_task_button font-14 br-10'

                                >Submit</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default HipaaConsentForm;