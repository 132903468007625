import { useCallback } from "react";
import { useState } from "react";
import { search } from "../hooks/sendHttpRequest";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { useDispatch } from "react-redux";

export default function useDiagnosisSearch() {
    const dispatch = useDispatch();
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [diagnosisName, setDiagnosisName] = useState("");
    const [selectedDiagnosis, setSelectedDiagnosis] = useState(undefined);

    const searchDiagnosis = async (diagnosisInput) => {
        setIsLoading(true);
        const httpResponse = await search({
            url: `/diagnosis`,
            method: "GET",
            params: {
                search: diagnosisInput,
            },
        });
        if (!httpResponse.error) {
            setIsLoading(false);
            setSuggestions(httpResponse.data);
            setIsError(false);
        } else {
            if (httpResponse?.status === 400) {
                setIsError(true);
                setIsLoading(false);
            }
        }
    };

    const handleSearchInput = useCallback(
        (icdInput) => {
            if (icdInput.length > 2) {
                // setDiagnosisName(icdInput)
                searchDiagnosis(icdInput);
                setSearchInputValue(icdInput);
            }
            setDiagnosisName(icdInput);
            setSuggestions([]);
            setIsLoading(false);
        },
        [
            searchDiagnosis,
            setSearchInputValue,
            setDiagnosisName,
            setSuggestions,
            setIsLoading,
        ]
    );

    const handleRemoveDiagnosis = (removeId) => {
        dispatch(setDirtyBitInStore(true));
        setSelectedDiagnosis(undefined);
        setDiagnosisName("");
    };

    const handleAddDiagnosis = (diagnosis) => {
        dispatch(setDirtyBitInStore(true));
        setDiagnosisName("");
        setSelectedDiagnosis(diagnosis);
    };

    return {
        suggestions, setSuggestions, isLoading, setIsLoading, isError, setIsError, searchInputValue, setSearchInputValue, diagnosisName, setDiagnosisName, selectedDiagnosis, setSelectedDiagnosis, searchDiagnosis, handleSearchInput, handleAddDiagnosis, handleRemoveDiagnosis
    }
}