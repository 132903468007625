import { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useUnsavedWorkPrompt } from "../../components/hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import { useAuth } from "../../components/hooks/useAuth";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
function BulkFeeScheduleUpdate(props) {
    const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
    const { user } = useAuth();
    const [isSaving, setIsSaving] = useState(false);

    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');

    async function bulkUpdate(tenantId, amount) {
        const httpResponse = await sendHttpRequest({ method: "PUT", "url": `/claims/tenants/${tenantId}/fee-schedule/bulk-update/${amount}` });
        if (!httpResponse.error) {
            props?.setReloadSchedule(p => !p);
            props?.handleModal();
        }
        else {
            console.log(httpResponse);
        }

    }

    function handleChange(event) {
        const value = event.target.value;
        const regex = /^-?\d+(\.\d+)?$/; // Allows '-' followed by digits and optional decimal

        if (value === '' || value == '-' || regex.test(value)) {
            const numValue = Number(value);

            setAmount(value);
            if (numValue >= -1000 && numValue <= 1000) {
                setAmount(value);
                setError('');
            } else {
                setError('Value must be between -1000 and 1000.');
            }
        } else {
            setError('Invalid input! Only numbers and "-" sign for negative values are allowed.');
        }
    }


    return (
        <Modal
            show={props.show}
            onHide={() => {
                unsavedWorkHandler(
                    "modal",
                    "There are unsaved changes, do you wish to continue?",
                    isDirty,
                    props.handleModal,
                    onPristine,
                    props.handleModal
                );
                // props.handleModal();
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={false}
            centered
        >
            <Modal.Header>
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        unsavedWorkHandler(
                            "modal",
                            "There are unsaved changes, do you wish to continue?",
                            isDirty,
                            props.handleModal,
                            onPristine,
                            props.handleModal
                        );
                        // props.handleModal();
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">Bulk Fee Schedule Charge Update</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-sm-12">
                            <span className=" px-0 font-12 text--terciary  d-block">
                                Enter the percentage by which you would like to increase or decrease all CPT codes in the fee schedule. A positive percentage will increase the fees, while a negative percentage will decrease them. This will be applied to all fee schedules.

                            </span>

                            <div className="form-group mt-1">
                                <label htmlFor="percentage" className="form-label fw-sb  text--secondary font-12">Percentage</label>
                                <input
                                    type="text"
                                    id="amount"
                                    className="br-10 shadow-none form-control w-25 input border--default"
                                    value={amount}
                                    onChange={handleChange}
                                />
                                {error && <p className="text--danger font-12">{error}</p>}
                            </div>
                        </div>
                    </div>


                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row justify-content-end">
                    <button
                        disabled={error?.length > 0}
                        onClick={() => { bulkUpdate(user?.tenantId, amount) }} className="btn blue_task_button br-10 shadow-none">Update</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default BulkFeeScheduleUpdate