import { Box, Grid, Stack, Typography, IconButton } from "@mui/material";
import React, { useState } from "react";
import classes from "./PatientItem.module.css";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import TrashIcon from "../../assets/icons/TrashIcon";
import { RolesMap, RolesTextConversion } from "../../utils/helpers/RolesMap";
import { formatPhoneNumber, truncate } from "../../utils/helpers/HelperFunctions";
import { useNavigate } from "react-router-dom";
import LockResetIcon from "@mui/icons-material/LockReset";
import InitiateResetPasswordModal from "../UI/Modals/ProviderModals/InitiateResetPasswordModal";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { numbersEnum } from "../../res/strings";
import { useSelector } from "react-redux";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import DeleteUserConfirmationModal from "../UI/Modals/DeleteUserConfirmationModal";
import { PRACTICE_MANAGEMENT } from "../../utils/helpers/subscriptionsConstants";

const PracticeUsersItem = (props) => {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const navigate = useNavigate();

  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [id, setId] = useState("");
  const [openUserDeleteModal, setOpenUserDeleteModal] = useState(false);
  const [pwdResetRequestResponse, setPwdResetRequestResponse] = useState("");
  const [openPwdResetToast, setOpenPwdResetToast] = useState(false);

  const mobilePhone = props?.provider.communicationNumbers.numbers?.find((numberItem) => {
    return numberItem?.type === numbersEnum.en.cellPhoneExt;
  })?.number || "";

  const initiatePasswordReset = () => {
    setOpenResetPasswordModal(true);
  };

  const closeUserDelete = () => {
    setOpenUserDeleteModal(false);
  };

  const initiateUserDelete = id => {
    setId(id);
    setOpenUserDeleteModal(true);
  };

  const deletePracticeUser = () => {
    props.deleteSingleProvider(id);
  };

  const handleResetPasswordModal = () => {
    setOpenResetPasswordModal(!openResetPasswordModal);
  };

  const handlePasswordResetToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenPwdResetToast(false);
  };

  return (
    <>
      {openResetPasswordModal && (
        <InitiateResetPasswordModal
          show={openResetPasswordModal}
          handleModal={handleResetPasswordModal}
          FirstName={
            props?.provider?.name.length > 0
              ? props?.provider?.name[0].firstName
              : ""
          }
          LastName={
            props?.provider?.name.length > 0
              ? props?.provider?.name[0].lastName
              : ""
          }
          Id={props?.provider?.userId}
          setOpenPwdResetToast={setOpenPwdResetToast}
          setPwdResetRequestResponse={setPwdResetRequestResponse}
        />
      )}

      <DeleteUserConfirmationModal
        show={openUserDeleteModal}
        deletePromptText={"Are you sure you want to delete this user ?"}
        handleDeleteModalClose={closeUserDelete}
        deleteUser={deletePracticeUser}
      />

      <Grid
        sx={{ marginBottom: "-8px" }}
        container
        p={1}
        alignItems="center"
        className={`${classes["row--border"]} mt-2  ${props?.isEven && "bg--background"
          } ${props?.isLast && classes["border--bottom"]}`}
      >
        {/* <Grid item xs={props?.prescriber?.EditFlag ? 2 : 2.5}> */}
        <Grid
          item
          xs={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.apu?.active === true || subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.rpu?.active === true || subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.dpu?.active === true ? 1.5 : 2}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography component="div" style={{ overflowWrap: "break-word" }}>
              <Box
                sx={{
                  textAlign: "left",
                  m: 1,
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#323232",
                }}
              >
                {props?.provider?.name.length > 0
                  ? <PnToolTip title={props?.provider?.name[0].firstName +
                    " " +
                    props?.provider?.name[0].lastName}>{truncate(props?.provider?.name[0].firstName +
                    " " +
                    props?.provider?.name[0].lastName,15)}</PnToolTip>
                  : ""}
              </Box>
            </Typography>
          </Stack>
        </Grid>
        {/* <Grid item xs={props?.provider?.EditFlag ? 2 : 2.5}> */}
        <Grid
          item
          xs={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.apu?.active === true || subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.rpu?.active === true || subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.dpu?.active === true ? 1.5 : 2}
        >
          <Typography component="div" style={{ overflowWrap: "break-word" }}>
            <Box
              sx={{
                textAlign: "left",
                // ml: 1,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {/* Physician Assistant */}
              {RolesTextConversion(props?.provider?.role?.trim()) ?? ""}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {/* {props?.provider?.speciality?.length > 0 ? <PnToolTip title={props?.provider?.speciality?.length > 0 ? props?.provider?.speciality[0]?.speciality : ""}>
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "13px",
                color: "#336383",
                textAlign: "left",
                textOverflow: 'break-word',

              }}
              style={{ overflowWrap: "break-word" }}
            >
              {truncate(props?.provider?.speciality[0]?.speciality, 25)}

            </Box>
          </PnToolTip> : null} */}
          {/* <Typography component="div" style={{ overflowWrap: "break-word" }}>
            <Box
              sx={{
                textAlign: "left",
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {props?.provider?.speciality?.length > 0 ? props?.provider?.speciality[0]?.speciality : ""}
            </Box>
          </Typography> */}
          <Typography component="div" style={{ overflowWrap: "break-word" }}>
            <Box
              sx={{
                textAlign: "left",
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {props?.provider?.speciality?.length > 0 ? props?.provider?.speciality[0]?.speciality : ""}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2.2}>
          <Typography component="div" style={{ overflowWrap: "break-word" }}>
            <Box
              sx={{
                textAlign: "left",
                // ml: -4,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {/* Last request Date */}
              {/* Mar 11, 2022 - 11:20 am */}
              {props?.provider?.communicationNumbers.electronicMail.length > 0
                ? props?.provider?.communicationNumbers.electronicMail[0]
                : ""}
              {/* {props?.patient?.LastRequestDate !== null && props?.patient?.LastRequestDate !== undefined && props?.patient?.LastRequestDate !== '' ? dateTimeHelper(props?.patient?.LastRequestDate, "MMM DD, YYYY  LT") : '-'} */}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.8}>
          <Typography component="div" style={{ overflowWrap: "break-word" }}>
            <Box
              sx={{
                textAlign: "right",
                fontWeight: "400",
                // ml: 5,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {/* Last Prescripton */}
              {props?.provider.communicationNumbers.numbers.length > 0
                ? formatPhoneNumber(
                  mobilePhone
                ) !== null
                  ? formatPhoneNumber(
                    mobilePhone
                  )
                  : ""
                : ""}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "center",
                fontWeight: "400",
                m: 1,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color:
                  props?.provider?.status === false
                    ? "#FC0D1B"
                    : props?.provider?.status === true &&
                      props?.provider?.activated === true
                      ? "#18C383"
                      : props?.provider?.status === true &&
                        props?.provider?.activated === false
                        ? "#004FD4"
                        : "",
              }}
            >
              {props?.provider?.status === false
                ? "Inactive"
                : props?.provider?.status === true &&
                  props?.provider?.activated === true
                  ? "Active"
                  : props?.provider?.status === true &&
                    props?.provider?.activated === false
                    ? "Pending"
                    : ""}
            </Box>
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
        >
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <div className="align-self-center ">
              <div className="d-flex flex-row col-10 justify-content-center">
                <div className="row">
                <PnToolTip title={"Edit User"} placement={"top"}>
                  <div className="col-3" hidden={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.apu?.active === false ? true : false}>
                    <IconButton className="p-0">
                      <img
                        className="cursor--pointer"
                        onClick={(e) =>
                          props?.fetchSingleProvider(
                            props?.provider?.userId ?? ""
                          )
                        }
                        id={props?.provider?.userId}
                        src={EditButtonIcon}
                        alt="edit icon"
                        width={15}
                        height={15}
                      ></img>
                    </IconButton>
                  </div>
                </PnToolTip>
                <PnToolTip title={"Reset Password"} placement={"top"}>
                  <div className="col-3 cursor--pointer" hidden={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.rpu?.active === false ? true : false}>
                    <IconButton
                      className="p-0"
                      disabled={
                        props?.provider?.status === "Inactive" ? true : false
                      }
                    >
                      <LockResetIcon
                        sx={{ fontSize: "28px", width: 20, height: 20 }}
                        className={` ${props?.provider?.status === "Inactive"
                          ? "text--disabled "
                          : "text--secondary"
                          }`}
                        // disabled = {true}
                        onClick={() => initiatePasswordReset()}
                      />
                    </IconButton>
                  </div>
                </PnToolTip>
                  <PnToolTip title={props?.provider?.appointmentPending ? "This user has pending appointments" : "Delete User"}>
                    <div className="col-3" hidden={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.dpu?.active === false ? true : false}>
                      <IconButton disabled={props?.provider?.userId === props?.user?.userId || props?.provider?.appointmentPending === true} className="p-0">
                        <TrashIcon
                          style={{ color: props?.provider?.userId === props?.user?.userId || props?.provider?.appointmentPending === true ? "#a2d1de" : "#ff0000" }}
                          width={15}
                          height={15}
                          onClick={() => initiateUserDelete(props?.provider?.userId)}
                        />
                      </IconButton>
                    </div>
                  </PnToolTip>
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>

      <CustomizedSnackbars
        message={pwdResetRequestResponse}
        severity="success"
        open={openPwdResetToast}
        handleClose={handlePasswordResetToast}
      />

      {/* {console.log(props?.prescriber)} */}
    </>
  );
};

export default PracticeUsersItem;
