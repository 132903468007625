import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import HomeIcon from "../Drawer/DrawerItemIcons/HomeIcon";
import MessagesIcon from "../Drawer/DrawerItemIcons/MessagesIcon";
import AppointmentsIcon from "../Drawer/DrawerItemIcons/AppointmentsIcon";
import PharmacyIcon from "../Drawer/DrawerItemIcons/PharmacyIcon";
import ScriptIcon from "../Drawer/DrawerItemIcons/ScriptIcon";
import MedicalDictionaryIcon from "../Drawer/DrawerItemIcons/MedicalDictionaryIcon";
import ReportsIcon from "../Drawer/DrawerItemIcons/ReportsIcon";
import {
  List,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  ListItemButton,
  Collapse,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerState } from "../../../redux/actions/providerActions";
import drawerButton from "../../../assets/icons/drawerButton.svg";

import {
  localVideoTrack,
  localAudioTrack,
  client,
} from "../../../patientportal/patientvideoconsult/agoraDataObjects";
import { featureFlagMap } from "../../../utils/helpers/ConfigMap";
// import InsuranceIcon from "./DrawerItemIcons/InsuranceIcon";
import UsersIcon from "./DrawerItemIcons/UsersIcon";
import { useAuth } from "../../hooks/useAuth";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { ENCOUNTERS, IMAGING_ORDERS, LAB_ORDERS, PAYMENTS, PRESCRIPTIONS } from "../../../utils/helpers/subscriptionsConstants";
const drawerWidth = 220;

/**
 * If the drawer is open, then set the width to the drawerWidth constant, add a border, and add a
 * transition to the width property.
 * @param theme - the theme object
 */
const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: "1px solid #D9E8EC",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  overflowY: "auto", // Add this line to enable vertical scrolling
  "&::-webkit-scrollbar": {
    width: "4px", // Width of the scrollbar
    borderRadius: '8px'
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#FAFAFC", // Background color of the scrollbar track
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#d9e8ec", // Color of the scrollbar thumb
    borderRadius: "4px", // Rounded corners of the scrollbar thumb
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#c0e1eb", // Color of the scrollbar thumb on hover
  },
});

/**
 * When the drawer is closed, the width of the drawer is set to the width of the drawer icon plus 1px.
 * @param theme - the theme object
 */
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  overflowY: "auto", // Add this line to enable vertical scrolling
  "&::-webkit-scrollbar": {
    width: "4px", // Width of the scrollbar
    borderRadius: '8px'
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#FAFAFC", // Background color of the scrollbar track
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#d9e8ec", // Color of the scrollbar thumb
    borderRadius: "4px", // Rounded corners of the scrollbar thumb
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#c0e1eb", // Color of the scrollbar thumb on hover
  },
  borderRight: "1px solid #D9E8EC",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

/* Creating a styled component called DrawerHeader. */
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderBottom: "1px solid #D9E8EC",
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

/* Creating a styled component that is a MuiDrawer. */
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  zIndex: "0",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/* Creating a style for the selected tab. */
const useStyles = makeStyles({
  root: {
    "&$selected": {
      color: "#EC609B",
      borderRight: "3px solid #EC609B",
      backgroundColor: "#FFFFFF !important",
    },
  },

  selected: {},
});

/**
 * I'm trying to make the drawer open and close on click of the logo.
 *
 * I'm using a redux store to keep track of the drawer state.
 *
 * I'm using a useState hook to keep track of the drawer state.
 *
 * I'm using a useSelector hook to get the drawer state from the redux store.
 *
 * I'm using a useDispatch hook to dispatch the action to change the drawer state in the redux store.
 *
 * I'm using a useEffect hook to set the drawer state in the redux store to the drawer state in the
 * useState hook.
 *
 * I'm using a useEffect hook to set the drawer state in the useState hook to the drawer state in the
 * redux store.
 *
 * I'm using a useEffect hook to set the drawer state in the redux store to the
 * @returns A React component.
 */
export default function PatientDrawer() {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  
  const patientInsuranceData = useSelector(
    (state) => state.patientInsuranceData.patientInsurance
  );
  const { user } = useAuth();
  const featureFlags = featureFlagMap();
  const drawerState = useSelector((state) => state.DrawerState);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(drawerState);
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const handleDrawerOpen = () => {
    setOpen(!open);
    dispatch(setDrawerState(!open));
  };
  const unreadMessageCount = useSelector(
    (state) => state.ProviderUnreadMessageCount.count
  );
  const insuranceURL1 = "/patient-portal/edit-insurance";
  const insuranceURL2 = "/patient-portal/insurance";
  const insuranceURL3 = "/patient-portal/add-insurance";
  const [insuranceURL, setInsuranceURL] = useState(
    insuranceURL1
  );
  const [openMedicalRecord, setOpenMedicalRecord] = useState(false);
  const [openBillingRecord, setOpenBillingRecord] = useState(false);

  useEffect(() => {
    if (patientInsuranceData?.length > 0) {
      setInsuranceURL(insuranceURL2);
    }else{
      setInsuranceURL(insuranceURL3);
    }
  }, [patientInsuranceData]);
  return (
    <Drawer variant="permanent" open={open} className="hide-scrolbar" >
      {/* <DrawerHeader className="py-3" >
        <Box
          component="img"
          sx={(!open) && {height:'36px', width:'36px'}}
          alt="Logo"

          src={open ? LogoMain : LogoSmall}
          onClick={handleDrawerOpen}
         
        />
      </DrawerHeader> */}
      <DrawerHeader className="py-3" />
      <List className={`${classes.font} hide-scrolbar`}>
        <ListItemButton className="drawer-toggle" onClick={handleDrawerOpen}>
          <ListItemIcon style={{ minWidth: "40px" }}>
            <img
              src={drawerButton}
              className={`${!open ? "drawer-toggle-rotate" : ""}`}
            />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton
          onClick={() => navigate("/patient-portal/dashboard")}
          selected={location.pathname === "/patient-portal/dashboard" && true}
          classes={{ root: classes.root, selected: classes.selected }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <HomeIcon
              style={
                location.pathname === "/patient-portal/dashboard"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/patient-portal/dashboard" &&
                "text--secondary"
                } menu__item`}
            >
              Home
            </span>
          </ListItemText>
        </ListItemButton>
        {/* <ListItemButton
          onClick={() => navigate("/patient-portal/appointments")}
          selected={
            location.pathname === "/patient-portal/appointments" && true
          }
          classes={{ root: classes.root, selected: classes.selected }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <AppointmentsIcon
              style={
                location.pathname === "/patient-portal/appointments"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${
                location.pathname !== "/patient-portal/appointments" &&
                "text--secondary"
              } menu__item `}
            >
              Appointments
            </span>
          </ListItemText>
        </ListItemButton> */}

        <ListItemButton
          onClick={() => {
            navigate("/patient-portal/messages");
          }}
          selected={location.pathname === "/patient-portal/messages" && true}
          classes={{ root: classes.root, selected: classes.selected }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <MessagesIcon
              style={
                location.pathname === "/patient-portal/messages"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/patient-portal/messages" &&
                "text--secondary"
                } menu__item `}
            >
              Messages
            </span>
          </ListItemText>
          <ListItemText>
            {unreadMessageCount > 0 && (
              <span
                className="badge  rounded-pill "
                style={{
                  backgroundColor: "#336383",
                }}
              >
                {unreadMessageCount}
              </span>
            )}
          </ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate(insuranceURL);
          }}
          selected={(location.pathname === insuranceURL1 || location.pathname === insuranceURL2 || location.pathname === insuranceURL3 ) && true}
          classes={{ root: classes.root, selected: classes.selected }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <UsersIcon
              style={
                (location.pathname === insuranceURL1 || location.pathname === insuranceURL2 || location.pathname === insuranceURL3)
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${(location.pathname !== insuranceURL1 && location.pathname !== insuranceURL2 || location.pathname === insuranceURL3) && "text--secondary"
                } menu__item `}
            >
              Insurance
            </span>
          </ListItemText>
        </ListItemButton>

        {/* My medical Record */}
        <ListItemButton
          onClick={() => {
            setOpenMedicalRecord(!openMedicalRecord);
          }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <ScriptIcon
              style={
                location.pathname === "/patient-portal/prescriptions"
                  || location.pathname === "/patient-portal/appointments" ?
                  { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span className="text--secondary menu__item">Medical Record</span>
          </ListItemText>
          {openMedicalRecord ? (
            <ExpandLess className="text--primary" />
          ) : (
            <ExpandMore className="text--primary" />
          )}
        </ListItemButton>

        <Collapse in={openMedicalRecord} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => navigate("/patient-portal/appointments")}
              selected={
                location.pathname === "/patient-portal/appointments" && true
              }
              classes={{ root: classes.root, selected: classes.selected }}
            >
          <ListItemIcon style={{ minWidth: "40px" }} hidden={open}>
            <AppointmentsIcon
                style={
                  location.pathname === "/patient-portal/appointments"
                    ? { color: "#EC609B", fill: "#EC609B" }
                    : { color: "#336383", fill: "#336383" }
                }
              />
            
          </ListItemIcon>

              <ListItemText sx={{ pl: 6 }}>
                <span
                  className={`${location.pathname !== "/patient-portal/appointments" &&
                    "text--secondary"
                    } menu__item`}
                >
                  Visits
                </span>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate("/patient-portal/prescriptions")}
              selected={
                location.pathname === "/patient-portal/prescriptions" && true
              }
              classes={{ root: classes.root, selected: classes.selected }}
              hidden={subscriptionPrivilegeGroups[PRESCRIPTIONS] === undefined ? true:false}
            >
              <ListItemIcon style={{ minWidth: "40px" }} hidden={open}>
                <MedicationOutlinedIcon
                  style={
                    location.pathname === "/patient-portal/prescriptions"?
                      { color: "#EC609B", fill: "#EC609B" }
                      : { color: "#336383", fill: "#336383" }
                  }
                />
              </ListItemIcon>
              
              <ListItemText sx={{ pl: 6 }} >
                <span
                  className={`${location.pathname !== "/patient-portal/prescriptions" &&
                    "text--secondary"
                    } menu__item`}
                >
                  Prescriptions
                </span>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate("/patient-portal/lab-tests")}
              selected={
                location.pathname === "/patient-portal/lab-tests" && true
              }
              classes={{ root: classes.root, selected: classes.selected }}
              hidden={subscriptionPrivilegeGroups[LAB_ORDERS] === undefined ? true:false}
            >
              <ListItemIcon style={{ minWidth: "40px" }} hidden={open}>
                <DescriptionOutlinedIcon
                  style={
                    location.pathname === "/patient-portal/lab-tests"?
                      { color: "#EC609B", fill: "#EC609B" }
                      : { color: "#336383", fill: "#336383" }
                  }
                />
              </ListItemIcon>
              <ListItemText sx={{ pl: 6 }}>
                <span
                  className={`${location.pathname !== "/patient-portal/lab-tests" &&
                    "text--secondary"
                    } menu__item`}
                >
                  Labs
                </span>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate("/patient-portal/imaging-orders")}
              selected={
                location.pathname === "/patient-portal/imaging-orders" && true
              }
              classes={{ root: classes.root, selected: classes.selected }}
              hidden={subscriptionPrivilegeGroups[IMAGING_ORDERS] === undefined ? true:false}
            >
              <ListItemIcon style={{ minWidth: "40px" }} hidden={open}>
                <NoteAddOutlinedIcon
                  style={
                    location.pathname === "/patient-portal/imaging-orders"?
                      { color: "#EC609B", fill: "#EC609B" }
                      : { color: "#336383", fill: "#336383" }
                  }
                />
              </ListItemIcon>
              <ListItemText sx={{ pl: 6 }}>
                <span
                  className={`${location.pathname !== "/patient-portal/imaging-orders" &&
                    "text--secondary"
                    } menu__item`}
                >
                  {"Imaging Orders"}
                </span>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate("/patient-portal/history")}
              selected={
                location.pathname === "/patient-portal/history" && true
              }
              classes={{ root: classes.root, selected: classes.selected }}
            >
              <ListItemIcon style={{ minWidth: "40px" }} hidden={open}>
                <WorkHistoryOutlinedIcon
                  style={
                    location.pathname === "/patient-portal/history"?
                      { color: "#EC609B", fill: "#EC609B" }
                      : { color: "#336383", fill: "#336383" }
                  }
                />
              </ListItemIcon>
              <ListItemText sx={{ pl: 6 }}>
                <span
                  className={`${location.pathname !== "/patient-portal/history" &&
                    "text--secondary"
                    } menu__item`}
                >
                  History
                </span>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate("/patient-portal/health-summary")}
              selected={
                location.pathname === "/patient-portal/health-summary" && true
              }
              classes={{ root: classes.root, selected: classes.selected }}
              hidden={subscriptionPrivilegeGroups[ENCOUNTERS] === undefined ? true:false}
            >
              <ListItemIcon style={{ minWidth: "40px" }} hidden={open}>
                <HealthAndSafetyOutlinedIcon
                  style={
                    location.pathname === "/patient-portal/health-summary"?
                      { color: "#EC609B", fill: "#EC609B" }
                      : { color: "#336383", fill: "#336383" }
                  }
                />
              </ListItemIcon>
              <ListItemText sx={{ pl: 6 }}>
                <span
                  className={`${location.pathname !== "/patient-portal/health-summary" &&
                    "text--secondary"
                    } menu__item`}
                >
                  Health Summary
                </span>
              </ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
        {
          subscriptionPrivilegeGroups[PAYMENTS] !== undefined ? 
          <>
            <ListItemButton
              // onClick={() => navigate("/patient-portal/billing-and-payments")}
              // selected={location.pathname === "/patient-portal/billing-and-payments" && true}
              // classes={{ root: classes.root, selected: classes.selected }}
              onClick={() => {
                setOpenBillingRecord(!openBillingRecord);
              }}
              hidden={subscriptionPrivilegeGroups[PAYMENTS] === undefined ? true:false}
            >
              <ListItemIcon style={{ minWidth: "40px" }}>
                <ReceiptLongIcon
                  style={
                    (location.pathname === "/patient-portal/billing-summary" || location.pathname === "/patient-portal/make-payments" || location.pathname === "/patient-portal/estimates")
                      ? { color: "#EC609B", fill: "#EC609B" }
                      : { color: "#336383", fill: "#336383" }
                    // { color: "#A2D1DE" }
                  }
                />
              </ListItemIcon>
              <ListItemText>
                <span
                  className={`${"text--secondary"
                    } menu__item`}
                >
                  Billing
                </span>
              </ListItemText>
              {openBillingRecord ? (
                <ExpandLess className="text--primary" />
              ) : (
                <ExpandMore className="text--primary" />
              )}
            </ListItemButton>
            <Collapse in={openBillingRecord} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  onClick={() => { navigate("/patient-portal/billing-summary"); }}
                  selected={
                    location.pathname === "/patient-portal/billing-summary" && true
                  }
                  classes={{ root: classes.root, selected: classes.selected }}
                >
                  <ListItemIcon style={{ minWidth: "40px" }} hidden={open}>
                    <RequestQuoteOutlinedIcon
                      style={
                        location.pathname === "/patient-portal/billing-summary"?
                          { color: "#EC609B", fill: "#EC609B" }
                          : { color: "#336383", fill: "#336383" }
                      }
                    />
                  </ListItemIcon>

                  <ListItemText sx={{ pl: 6 }}>
                    <span
                      className={`${location.pathname !== "/patient-portal/billing-summary" &&
                        "text--secondary"
                        } menu__item`}
                    >
                      Billing Summary
                    </span>
                  </ListItemText>
                </ListItemButton>
                {/* <ListItemButton
                  onClick={() => navigate("/patient-portal/make-payments")}
                  selected={
                    location.pathname === "/patient-portal/make-payments" && true
                  }
                  classes={{ root: classes.root, selected: classes.selected }}
                >
                  <ListItemText sx={{ pl: 6 }}>
                    <span
                      className={`${location.pathname !== "/patient-portal/make-payments" &&
                        "text--secondary"
                        } menu__item`}
                    >
                      Payments
                    </span>
                  </ListItemText>
                </ListItemButton> */}
                <ListItemButton
                  // onClick={() => navigate("/patient-portal/estimates")}
                  // selected={
                  //   location.pathname === "/patient-portal/estimates" && true
                  // }
                  // classes={{ root: classes.root, selected: classes.selected }}
                  hidden={true}
                >
                  <ListItemText sx={{ pl: 6 }}>
                    <span
                      className={`${location.pathname !== "/patient-portal/estimates" &&
                        "text--secondary"
                        } menu__item disabled-link`}
                    >
                      Estimates
                    </span>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          </>:null
        }

        {featureFlags.get("surescript") === "false" && (
          <ListItemButton
          // onClick={() => navigate("/patient-portal/pharmacy")}
          // selected={location.pathname === "/patient-portal/pharmacy" && true}
          // classes={{ root: classes.root, selected: classes.selected }}
          >
            <ListItemIcon style={{ minWidth: "40px" }}>
              <PharmacyIcon
                style={
                  // location.pathname === "/patient-portal/pharmacy"
                  //   ? { color: "#EC609B", fill: "#EC609B" }
                  //   : { color: "#336383", fill: "#336383" }
                  { color: "#A2D1DE" }
                }
              />
            </ListItemIcon>
            <ListItemText>
              <span
                className={`${location.pathname !== "/patient-portal/pharmacy" &&
                  "text--secondary"
                  } menu__item disabled-link`}
              >
                Pharmacy
              </span>
            </ListItemText>
          </ListItemButton>
        )}
        


        {/* <ListItemButton
          // onClick={() => navigate("/patient-portal/dictionary")}
          // selected={location.pathname === "/patient-portal/dictionary" && true}
          // classes={{ root: classes.root, selected: classes.selected }}
          style={{ color: "#A2D1DE" }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <MedicalDictionaryIcon
              // style={
              //   location.pathname === "/patient-portal/dictionary"
              //     ? { color: "#EC609B", fill: "#EC609B" }
              //     : { color: "#336383", fill: "#336383" }
              // }
              style={{ color: "#A2D1DE" }}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/patient-portal/dictionary" &&
                "text--secondary"
                } menu__item disabled-link`}
            >
              Medical Dictionary
            </span>
          </ListItemText>
        </ListItemButton> */}

        {/* <ListItemButton
          // onClick={() => navigate("/patient-portal/reports")}
          // selected={location.pathname === "/patient-portal/reports" && true}
          // classes={{ root: classes.root, selected: classes.selected }}
          style={{ color: "#A2D1DE" }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <ReportsIcon
              // style={
              //   location.pathname === "/reports"
              //     ? { color: "#EC609B", fill: "#EC609B" }
              //     : { color: "#336383", fill: "#336383" }
              // }
              style={{ color: "#A2D1DE" }}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/patient-portal/reports" &&
                "text--secondary"
                } menu__item disabled-link`}
            >
              Reports
            </span>
          </ListItemText>
        </ListItemButton> */}
      </List>
      {/* <Divider />
      <List>
        <ListItem button onClick={() => navigate("/")}>
          <ListItemIcon>{<img src={SignOutIcon} alt="signout" />}</ListItemIcon>
          <ListItemText primary={"Signout"} />
        </ListItem>
      </List> */}
    </Drawer>
  );
}

function unpublish() {
  if (localVideoTrack !== null) {
    client.unpublish(localVideoTrack);
    localVideoTrack.value.stop();
    localVideoTrack.value.setEnabled(false);
    localAudioTrack.value.setEnabled(false);
    localAudioTrack.value = null;
  }
}
