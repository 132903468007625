import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import AppointmentItem from "./AppointmentItem";
import classes from "../appointments/AppointmentList.module.css";
import { RolesMap } from "../../utils/helpers/RolesMap";
import { useAuth } from "../hooks/useAuth";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
import { useSelector } from "react-redux";
import { ENCOUNTERS } from "../../utils/helpers/subscriptionsConstants";


const AppointmentsList = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    return () => {
      setOpen(null)
    }
  }, [])
  return (
    <>
      {/* {console.log("props.appointmentList",props.appointmentList)} */}
      <div className="d-flex mb-1 mt-3 justify-content-between align-items-baseline">
        <h2 className="font-22">Upcoming Appointments</h2>
        <div>
          <RefreshDataComponent
            callback={() => props.fetchAppointments()}

          />
          <Link
            id="pn-provider-allAppointments-link"
            to="/appointments"
            className={`fw-bold b2 font-12 text-decoration-none`}
            disabled={true}
          >
            View all
          </Link>
        </div>
      </div>
      {/* Dynamically render here <AppointmentItem/> from the API */}
      <div
        id="pn-provider-appointment-list"
        className={` ${props?.appointmentList?.length > 0 ? classes["appointment__list--border"] : ""} `}
      >

        {props?.isLoading ? <Skeleton /> : props?.appointmentList?.length > 0 ? (
          props?.appointmentList?.map((appointment, index) => {
            // If a tenant has the Appointment module but not the Encounter module (Schedule-only tenant),
// the appointment list will not be displayed if we check for the "vpe" (View Pending Encounters) permission.
// Removing this condition and adding a new condition to check if a user has the "vsa" (View Scheduled Appointments) permission.
            // if (subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe?.active === true && index <= 4) {
            if (index <= 4) {
              return <AppointmentItem
                key={index}
                fetchAppointments={props.fetchAppointments}
                appointment={appointment}
                Attachment={true}
                UpcomingAppointment={true}
                startBtn={
                  RolesMap(user?.role) === "doctor" &&
                    RolesMap(user?.role) === "nurse"
                    ? true
                    : false
                }
                providerId={props?.providerId}
                handleToggle={handleToggle}
                setOpen={setOpen}
                isLast={props?.appointmentList?.length === (index + 1) || (user?.role === "MedicalAssistant" && index === 4)}
                handleBackdropClose={handleBackdropClose}
                hidden={appointment?.status.toLowerCase() === "started" ? subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe?.active === false ? true : true : true}
              />
            }

          })
        ) : null}
      </div>

      <Backdrop
        sx={{
          color: "#EC609B",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AppointmentsList;
