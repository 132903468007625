import { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { useNavigate } from "react-router-dom";

const ClaimBarChart = ({ claimsData }) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(""); // Start date filter
  const [endDate, setEndDate] = useState(""); // End date filter
  const chartRef = useRef(null); // Reference to the canvas
  const chartInstance = useRef(null); // To store the Chart.js instance

  const processClaimsData = (data) => {
    const statusCounts = {
      CLAIM_APPROVED: 0,
      CLAIM_SUBMITTED: 0,
      CLAIM_DENIED: 0,
      CLAIM_SUBMISSION_ERROR: 0,
      Not_Submitted: 0,
      CLAIM_READY_FOR_SUBMISSION: 0,
    };

    data.forEach((claim) => {
      const { status } = claim;

      if (statusCounts[status] !== undefined) {
        statusCounts[status] += 1;
      } 
    });

    return statusCounts;
  };

  const handleStartDate = (dateVal) => {
    setStartDate(dateVal);
  };

  const handleEndDate = (dateVal) => {
    setEndDate(dateVal);
  };

  function navigateToClaimsPage(routeVal, status) {
    navigate(routeVal, {
      state: { claimType: status, startDate: startDate, endDate: endDate },
    });
  }

  useEffect(() => {
    
    if(claimsData?.length > 0 && claimsData !== undefined){
      
      const claimsStats = processClaimsData(claimsData);
  
      const data = {
        labels: [
          "Ready for Submission",
          "Approved",
          "Pending",
          "Denied",
          "Submission Error",
        ],
        datasets: [
          {
            label: "Claim Status",
            data: [
              claimsStats?.CLAIM_READY_FOR_SUBMISSION ?? 0,
              claimsStats?.CLAIM_APPROVED ?? 0,
              claimsStats?.CLAIM_SUBMITTED ?? 0,
              claimsStats?.CLAIM_DENIED ?? 0,
              claimsStats?.CLAIM_SUBMISSION_ERROR ?? 0,
            ],
            backgroundColor: [
              "#336383", // Ready for Submission - Grey
              "#18c383", // Approved - Green
              "#f8a02e", // Pending - Orange
              "#fd0d1b", // Rejected - Red
              "#FFEB3B", // Submission Error - Yellow
            ],
            borderColor: "#ccc",
            borderWidth: 1,
          },
        ],
      };
  
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Claim Bar Chart",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };
  
      // Destroy the existing chart instance if it exists to avoid memory leaks
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
  
      // Create the new bar chart instance
      chartInstance.current = new Chart(chartRef.current, {
        type: "bar", // Changed to 'bar'
        data: data,
        options: options,
      });
    }

    // Cleanup the chart when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [claimsData, startDate, endDate]); // Recreate chart when claimsData, startDate, or endDate changes

  return (
    <>
      <div className="row">
        {/* Bar Chart */}
        <div
          className="col-12 cursor--pointer"
          onClick={() => navigateToClaimsPage("/claims-all", "all")}
        >
          <div style={{ width: "100%", height: "300px" }}>
            <canvas ref={chartRef} /> {/* Canvas element for the chart */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimBarChart;
