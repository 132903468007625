import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { fetchLabOrdersURL, rePrintLabOrderURL } from "../../res/endpointurls";
import { useEffect } from "react";
import {
  calenderDateTimeHelper,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
import EditIconComponent from "../../assets/iconComponents/EditIconComponent";
import ReplayIcon from "@mui/icons-material/Replay";
import LabRequisitionFormModal from "../UI/Modals/LabRequisitionFormModal";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    // width: 150,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingRight: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeMedium": {
      padding: "0px", // <-- arbitrary value
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
});

const LabOrdersTable = (props) => {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);


  const [labOrders, setLabOrders] = useState(props?.labOrders || []);
  const [isLabOrdersLoading, setIsLabOrdersLoading] = useState(false);
  const { patientId } = useParams();
  const [isLabOrderPrinting, setIsLabOrderPrinting] = useState(false);
  const [openLabOrder, setOpenLabOrder] = useState(false);
  const [editLabOrderData, setEditLabOrderData] = useState();

  const [openLabOrderSnackBar, setOpenLabOrderSnackBar] = useState(false);

  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState();

  const handleLabOrderSnackBar = useCallback((event, reason) => {
    // console.log("handleLabOrderSnackBar");
    if (reason === "clickaway") {
      return;
    }
    setOpenLabOrderSnackBar(prevState => !prevState);

  }, [setOpenLabOrderSnackBar]);


  const fetchLabOrders = async () => {
    setIsLabOrdersLoading(true);
    setLabOrders([])
    const httpResponse = await sendHttpRequest({
      url: fetchLabOrdersURL(props?.patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setLabOrders(httpResponse.data);
    }
    setIsLabOrdersLoading(false);
  };

  const rePrintLabOrder = async (labOrderId) => {
    setIsLabOrderPrinting(true);
    const httpResponse = await sendHttpRequest({
      url: rePrintLabOrderURL(labOrderId),
      method: "GET",
      responseType: "arraybuffer",
    });
    if (!httpResponse.error && httpResponse.data !== undefined) {
      const link = document.createElement("a");
      link.target = "_blank";
      // link.download = `Lab_Order_${props?.patientId}`;
      link.download = `${httpResponse?.responseHeaders?.pdf_name?.split('=')?.at(1)}`;
      const href = URL.createObjectURL(
        new Blob([httpResponse.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      link.href = href;
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
    }
    setIsLabOrderPrinting(false);
  };

  const handleEditLabOrder = () => {
    setOpenLabOrder(!openLabOrder);
  };
  useEffect(() => {
    console.log("props?.isLabOrderSent", props?.isLabOrderSent);
    fetchLabOrders();
  }, [props?.patientId, props?.isLabOrderSent]);

  useEffect(() => {
    if (openLabOrder === false) {
      fetchLabOrders();
    }
  }, [openLabOrder]);

  return (
    <>
      {openLabOrder && editLabOrderData !== undefined && (
        <LabRequisitionFormModal
          show={openLabOrder}
          handleModal={handleEditLabOrder}
          patientId={props?.patientId}
          editLabOrderData={editLabOrderData}
          setMessage={setMessage}
          setSeverity={setSeverity}
          handleLabOrderSnackBar={handleLabOrderSnackBar}
          assessmentPlan={true}
        />
      )}
      <div className="d-flex justify-content-between">
        <div></div>
        <RefreshDataComponent
          isComponentDataLoading={isLabOrdersLoading}
          callback={fetchLabOrders}
          variant="without-bar"
          classes="pe-2"
        />
      </div>
      <TableContainer
        sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <TableRow>
              <StyledTableCell><span className="font-12">{"Order Date"}</span></StyledTableCell>
              <StyledTableCell><span className="font-12">{"Ordered by"}</span></StyledTableCell>
              <StyledTableCell><span className="font-12">{"Test Name"}</span></StyledTableCell>
              <StyledTableCell><span className="font-12">{"Specimen"}</span></StyledTableCell>
              <StyledTableCell><span className="font-12">{"Reason for Test"}</span></StyledTableCell>
              <StyledTableCell><span className="font-12">{""}</span></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLabOrdersLoading ? (
              <StyledTableRow>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
              </StyledTableRow>
            ) : labOrders?.length > 0 ? (
              labOrders.map((labItem) => {
                let orderByDate = dateTimeHelper(
                  labItem?.submittedDate,
                  "MMM D, YYYY"
                );
                let firstName =
                  labItem?.prescriptionReference?.providerTenantPracticeLocation
                    ?.provider?.name[0]?.firstName;
                let lastName =
                  labItem?.prescriptionReference?.providerTenantPracticeLocation
                    ?.provider?.name[0]?.lastName;
                let examinationTests = labItem?.labOrderTest
                  ?.map((item) => {
                    return item.description;
                  })
                  .join(", ");
                let samples = labItem?.labOrderSample
                  ?.map((item) => {
                    return item.description;
                  })
                  .join(", ");
                samples = samples.replace("Others", labItem?.sampleNamely)

                let reasonForTest = labItem?.reasonForTest;
                return (
                  <StyledTableRow>
                    <StyledTableCell><span className="text--secondary">{orderByDate}</span></StyledTableCell>
                    <StyledTableCell>
                    <span className="text--secondary">{"".concat(firstName || "", " ", lastName || "")}</span>
                    </StyledTableCell>
                    <StyledTableCell><span className="text--secondary">{examinationTests || ""}</span></StyledTableCell>
                    <StyledTableCell><span className="text--secondary">{samples || ""}</span></StyledTableCell>
                    <StyledTableCell><span className="text--secondary">{reasonForTest || ""}</span></StyledTableCell>
                    <StyledTableCell>
                      <div className="d-flex flex-row gap-2">

                        <PnToolTip title={"Edit"}>
                          <div
                            className="cursor--pointer "
                            onClick={() => {
                              setEditLabOrderData(labItem);
                              handleEditLabOrder();
                            }}
                            hidden={props?.patientPortal}
                          >
                            <EditIconComponent
                              style={{ color: "#336383", fill: "#336383" }}
                            />
                          </div>
                        </PnToolTip>
                        <div
                          onClick={() => {
                            rePrintLabOrder(labItem?.labOrderId);
                          }}
                          className="cursor--pointer me-2"
                          hidden={subscriptionPrivilegeGroups['Tasks']?.dlp?.active === false ? true : false}
                        >
                          <PnToolTip title={"Download"}>
                            <FileDownloadIcon
                              sx={{
                                color: "#336383",
                                fontWeight: 400,
                                fontSize: "1.3rem",
                              }}
                            />
                          </PnToolTip>
                        </div>

                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : null}
          </TableBody>

          <CustomizedSnackbar
            message={message}
            severity={severity}
            open={openLabOrderSnackBar}
            handleClose={handleLabOrderSnackBar}
          />

        </Table>
      </TableContainer>


    </>
  );
};

export default LabOrdersTable;
