import { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../../components/UI/General/PageHeader";
import sendHttpRequest from "../../../../components/hooks/sendHttpRequest";
import { useAuth } from "../../../../components/hooks/useAuth";
import { Skeleton } from "@mui/material";
import AllClaimsTable from "../tables/AllClaimsTable";
import { PnSelect } from "../../../../components/UI/PnSelect";
import { RefreshDataComponent } from "../../../../components/UI/General/RefreshDataComponent";
import CalenderDatePicker from "../../../../components/UI/General/CalenderDatePicker";
import { useLocation } from "react-router-dom";
import { PnToolTip } from "../../../../components/UI/Reusable/PnComponents";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CSVService from "../../../../components/Services/CSVService.service";
import ReportColumModal from "../../../../components/reporting/ReportColumModal";
import ClaimTemplateTypeModal from "../../../../components/UI/Modals/ClaimTemplateTypeModal";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import classes from "./AllClaimsPage.module.css";
import { payersListUrl } from "../../../../res/endpointurls";

const CLAIM_STATUS_OPTIONS = [
  { value: "CLAIM_READY_FOR_SUBMISSION", label: "Claim Ready for Submission" },
  { value: "CLAIM_SUBMITTED", label: "Claim Submitted" },
  { value: "CLAIM_APPROVED", label: "Claim Approved" },
  { value: "CLAIM_SUBMISSION_ERROR", label: "Claim Submission Error" },
  { value: "CLAIM_DENIED", label: "Claim Denied" },
  { value: "CLAIM_REJECTED", label: "Claim Rejected" },
];

function AllClaimsPage(props) {
  const { user } = useAuth();
  const location = useLocation();

  const [renderingProvidersList, setRenderingProvidersList] = useState([]);
  const [areRenderingProvidersLoading, setAreRenderingProvidersLoading] =
    useState(false);

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [patientsList, setPatientsList] = useState([]);
  const [arePatientsLoading, setArePatientsLoading] = useState(false);

  const [claims, setClaims] = useState([]);
  const [areClaimsLoading, setAreClaimsLoading] = useState(false);

  const [reloadClaims, setReloadClaims] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [claimNumber, setClaimNumber] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [template, setTemplate] = useState();
  const [templateType, setTemplateType] = useState();

  const [openCSVReportModal, setOpenCSVReportModal] = useState(false);
  const [payersList, setPayersList] = useState({ isLoading: false });

  // Pagination states
  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(undefined);
  const [selectedPayer,setSelectedPayer] = useState(null);

  const fetchReneringProviders = async () => {
    setAreRenderingProvidersLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/providers`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const renderingProviders = httpResponse.data;
      const renderingProviderOptions = renderingProviders?.map((provider) => ({
        value: provider?.providerId,
        label: `${provider?.prefix} ${provider?.firstName} ${provider?.lastName}`,
        provider: provider,
      }));
      setRenderingProvidersList(renderingProviderOptions);
    } else {
      setRenderingProvidersList([]);
      console.log(httpResponse.error);
    }
    setAreRenderingProvidersLoading(false);
  };

  const fetchClaimPatients = async () => {
    setArePatientsLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/patients`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const claimPatients = httpResponse.data;
      const patientDropdownOptions = claimPatients?.map((patient) => ({
        value: patient?.patientId,
        label: `${patient?.firstName} ${patient?.lastName}`,
        patient: patient,
      }));
      setPatientsList(patientDropdownOptions);
    } else {
      setPatientsList([]);
      console.log(httpResponse.error);
    }
    setArePatientsLoading(false);
  };

  const fetchAllClaims = async (
    tenantId,
    claimNumber,
    provider,
    patient,
    status,
    fromDate,
    toDate,
    currentPageValue,
    payer,
  ) => {
    // console.log({ provider, patient, status });
    setAreClaimsLoading(true);
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: `/claims/tenants/${tenantId}/insurance-claims`,
      params: {
        ...(claimNumber !== "" && { claimNumber: claimNumber }),
        providerId: provider?.value,
        patientId: patient?.value,
        claimStatus: status?.value,
        ...(fromDate?.length > 0 && { fromDate: fromDate }),
        ...(toDate?.length > 0 && { toDate: toDate }),
        page: currentPageValue !== undefined ? currentPageValue - 1 : 0,
        ...(payer && {payer:payer.value}),
        size: 10,
      },
    });
    if (!httpResponse.error) {
      setClaims(httpResponse.data?.content);
      let noOfPages = httpResponse?.data?.totalPages;
      setTotalResults(noOfPages);
      setCurrentItems(httpResponse.data?.content);
      setMaxPageLimit(noOfPages);
      let pagesList = [];
      for (let i = 1; i <= noOfPages; i++) {
        pagesList.push(i);
      }
      setPages(pagesList);
    } else {
      console.error(`Error in fetching claims for tenant ${tenantId}`);
    }
    setAreClaimsLoading(false);
  };
  const fetchPayersList = async () => {
    // setPayersList((prev) => ({ ...prev, isLoading: true }));
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: payersListUrl(),
    });
    if (!httpResponse.error) {
      const payerList = httpResponse.data?.map((payer) => ({
        value: payer?.payerCode,
        label: payer?.name,
        payer: payer,
      }));
      setPayersList(payerList);
    }
    // setPayersList((prev) => ({ ...prev, isLoading: false }));
  };
  function handleProviderId(selectedOption) {
    setSelectedProvider(selectedOption);
    // props?.setEncounterDto((dto) => ({ ...dto, "claimProviderDataId": event.target.value, "provider": { "providerId": event.target.value } }))
  }

  function handlePatientId(option) {
    setSelectedPatient(option);
  }

  function handleClaimNumber(event) {
    setClaimNumber(event.target.value);
  }

  function handleClaimStatus(option) {
    setSelectedStatus(option);
  }

  const handleFilter = useCallback(async () => {
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      fromDate,
      toDate,
      currentPage,
      selectedPayer,
    );
  }, [
    user?.tenantId,
    claimNumber,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fromDate,
    toDate,
    selectedPayer,
    fetchAllClaims,
  ]);

  const handleClearFilter = useCallback(async () => {
    setClaimNumber("");
    setSelectedPatient(null);
    setSelectedProvider(null);
    setSelectedStatus(null);
    setFromDate(null);
    setToDate(null);
    setSelectedPayer(null);
    setCurrentPage(1);
    fetchAllClaims(user?.tenantId, "", null, null, null, null, null,1,null);
  }, [
    user?.tenantId,
    claimNumber,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fromDate,
    toDate,
    selectedPayer,
    fetchAllClaims,
  ]);

  const handleFromDate = (dateValue) => {
    setFromDate(dateValue);
  };
  const handleToDate = (dateValue) => {
    setToDate(dateValue);
  };

  // const downloadCSV = () => {
  //   if(claims?.length > 0){
  //     // CSVService.downloadCSV(claims, 'claims_data.csv');
  //     let tableMetaData = CSVService.claimDefaultReportMetaData();
  //     let filterClaimsData = []
  //     for(let claimItem of claims){
  //       console.log("claimItem: ",claimItem);

  //       let claimObject = {}
  //       for(let element of tableMetaData){
  //         // console.log("element: ",element);

  //         let objElement = CSVService.getSelectedColumns(claimItem,element)
  //         // console.log("objElement: ",objElement);

  //         claimObject[objElement.key] = objElement.value;
  //       }
  //       filterClaimsData.push(claimObject);
  //     }
  //     console.log("filterClaimsData: ",filterClaimsData);
  //     CSVService.downloadCSV(filterClaimsData)
  //   }
  // };

  const handleModal = () => {
    setOpenCSVReportModal(false);
  };

  function extractKeyAndIsDisplay(data) {
    return data.reduce((acc, item) => {
      if (item.isDefaultChecked) {
        acc.push({
          key: item.claimReportMetaData.key,
          isDefaultChecked: item.isDefaultChecked,
          value: item.claimReportMetaData.displayColumnName,
          displayTableName: item?.claimReportMetaData?.displayTableName,
          sequence_number: item?.claimReportMetaData?.sequence_number,
        });
      }
      return acc;
    }, []);
  }
  function convertToSingleObject(data) {
    let result = {};
    data.forEach((item) => {
      Object.assign(result, item); // Merge each object into the result
    });
    return result;
  }
  const downloadCSV = (claimsData) => {
    if (claimsData?.length > 0) {
      let filterClaimsData = [];
      console.log("template for csv: ", template);

      for (let claimItem of claimsData) {
        // console.log("claimItem: ", claimItem);
        let services = claimItem.claimServices;
        let claimObject = [];
        let claimServiceObject = [];
        for (let claimServiceItem of services) {
          // console.log("claimItem: ",claimItem);

          for (let element of template) {
            // console.log("element: ",element);

            if (
              element.isDefaultChecked === true &&
              element.displayTableName?.toLowerCase() !== "claim services"
            ) {
              let objElement = CSVService.getSelectedColumns(
                claimItem,
                element
              );
              claimObject.push(objElement.value);
            }
          }
          for (let element of template) {
            // console.log("element: ",element);

            if (
              element.displayTableName?.toLowerCase() === "claim services" &&
              element.isDefaultChecked === true &&
              templateType === 2
            ) {
              // console.log("claimServiceItem: ",claimServiceItem);

              let objElement = CSVService.getSelectedColumns(
                { claimServices: claimServiceItem },
                element
              );
              console.log("objElement: ", objElement, {
                claimServices: claimServiceItem,
              });
              claimObject.push(objElement.value);
            }
          }
          // console.log("claimObject: ",convertToSingleObject(claimObject));
          console.log("claimObject: ", claimObject);

          filterClaimsData.push({ ...claimObject });
          if (templateType === 1) {
            break;
          } else {
            claimObject = [];
          }
        }
      }
      console.log("filterClaimsData: ", filterClaimsData);
      console.log(
        "header: ",
        template.map((item) => item.value)
      );
      let header = template.map((item) => item.value);
      CSVService.downloadCSV(filterClaimsData, "Claim_data", header);
      //   props?.handleModal();
    }
  };

  function sortData(data) {
    return data.sort((a, b) => {
      // If either a or b has displayTableName as "claim services", push it to the end
      if (
        a.displayTableName?.toLowerCase() === "claim services" &&
        b.displayTableName?.toLowerCase() !== "claim services"
      )
        return 1;
      if (
        a.displayTableName?.toLowerCase() !== "claim services" &&
        b.displayTableName?.toLowerCase() === "claim services"
      )
        return -1;

      // First, compare by displayTableName (alphabetical order)
      if (a.displayTableName < b.displayTableName) return -1;
      if (a.displayTableName > b.displayTableName) return 1;

      // If displayTableName is the same, compare by sequence_number (numerical order)
      return a.sequence_number - b.sequence_number;
    });
  }

  // Pagination Handlers
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            // if(number > maxPageNumberLimit){
            //   console.log(number,maxPageNumberLimit + 1,minPageNumberLimit,number < maxPageNumberLimit + 1 ,
            //     number > minPageNumberLimit);
            // }
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${classes["list__item"]} ${
                    currentPage === number ? classes["active__list__item"] : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      fromDate,
      toDate,
      currentPage + 1,
      selectedPayer,
    );
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // setPageNumber(pageNumber - 1);
    // console.log("currentPage: ",currentPage-1);
    if (currentPage - 1 >= 1) {
      setCurrentPage((p) => currentPage - 1);
      fetchAllClaims(
        user?.tenantId,
        claimNumber,
        selectedProvider,
        selectedPatient,
        selectedStatus,
        fromDate,
        toDate,
        currentPage - 1,
      selectedPayer,
      );
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
  };
  const handleFirstPage = (event) => {
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);

    handleCurrentPage(1);
  };
  const handleLastPage = (event) => {
    event.preventDefault();
    let lastPage = Math.ceil(totalResults);
    let maxPageNumberLimitValue = lastPage;
    if (maxPageNumberLimitValue % 10 > 0) {
      maxPageNumberLimitValue = maxPageNumberLimitValue - 5;
    }
    setCurrentPage(totalResults);
    setMaxPageNumberLimit(lastPage);
    setMinPageNumberLimit(lastPage - pageNumberLimit);

    handleCurrentPage(lastPage);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      fromDate,
      toDate,
      pNumber,
      selectedPayer,
    );
  };

  const handlePayer = (option) => {
    setSelectedPayer(option);
  };

  useEffect(() => {
    fetchReneringProviders();
    fetchClaimPatients();
    fetchPayersList();
    if (props?.hierarchical) {
      // console.log("location?.state?.template: ", location?.state?.template);

      let tempateObj = extractKeyAndIsDisplay(location?.state?.template);
      console.log("tempateObj: ", tempateObj);
      tempateObj = sortData(tempateObj);
      console.log("after sorting tempateObj: ", tempateObj);
      setTemplate(tempateObj);
      setTemplateType(location.state.templateType);
    }
  }, []);

  useEffect(() => {
    setFromDate(location?.state?.startDate || null);
    setToDate(location?.state?.endDate || null);
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      location?.state?.startDate,
      location?.state?.endDate,
      currentPage,
      selectedPayer,
    );
  }, [reloadClaims]);

  console.log("templateType: ", templateType);

  return (
    <>
      {/* {openCSVReportModal ? (
        <ReportColumModal
          show={openCSVReportModal}
          handleModal={handleModal}
          claimsData={claims}
        />
      ) : null} */}

      <PageHeader title="Claims" />
      <div className="container">
        <div className="row justify-content-center py-2 mb-1">
          <div className="col-sm-11">
            <div className="row">
              <div className="col-sm-2">
                <label className=" text--secondary font-12 b2 fw-sb">
                  From
                </label>
                {fromDate !== null ? (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleFromDate}
                    dateFormat="MMM d, yyyy"
                    date={fromDate !== null ? fromDate : undefined}
                    //   viewSchedule={true}
                    //   required
                    key={"FromDate"}
                  />
                ) : (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleFromDate}
                    dateFormat="MMM d, yyyy"
                    date={""}
                    key={"FromDateNew"}
                  />
                )}
              </div>
              <div className="col-sm-2">
                <label className=" text--secondary font-12 b2 fw-sb">
                  To
                </label>
                {toDate !== null ? (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleToDate}
                    dateFormat="MMM d, yyyy"
                    date={toDate !== null ? toDate : undefined}
                    //   viewSchedule={true}
                    //   required
                    key={"ToDateNew"}
                  />
                ) : (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleToDate}
                    dateFormat="MMM d, yyyy"
                    date={""}
                    //   viewSchedule={true}
                    //   required
                    key={"ToDate"}
                  />
                )}
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label className=" text--secondary font-12 fw-sb">
                    Claim Number
                  </label>
                  <input
                    type="text"
                    placeholder="Claim Number"
                    style={{ minHeight: "38px" }}
                    onChange={handleClaimNumber}
                    value={claimNumber}
                    className="form-control simple-placeholder br-10 font-12 shadow-none border--default"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2">
          <div className="col-sm-11">
            <div className="row">
            
              

              <div className="col-sm-2">
                <div className="form-group">
                  <label className=" text--secondary font-12 fw-sb">
                    Rendering Provider
                  </label>
                  {areRenderingProvidersLoading === true ? (
                    <Skeleton animation="wave" variant="text" width="100%" />
                  ) : (
                    <PnSelect
                      isClearable={true}
                      isSearchable
                      onChange={handleProviderId}
                      name="providers"
                      closeMenuOnSelect={true}
                      options={renderingProvidersList}
                      value={selectedProvider}
                    />
                    // <select
                    //     className={`form-select br-10 font-12 input border--default shadow-none`}
                    //     aria-label="Provider select"
                    //     onChange={(e) => handleProviderId(e)}
                    //     value={props?.encounterDto?.provider?.providerId || selectedProvider}
                    // >
                    //     <option value="" disabled selected hidden>
                    //         Select provider
                    //     </option>
                    //     {renderingProvidersList?.length > 0
                    //         ? renderingProvidersList?.map((renderingProvider) => {
                    //             return (
                    //                 <option
                    //                     value={renderingProvider?.providerId ?? ""}
                    //                     key={renderingProvider?.providerId ?? ""}
                    //                 >
                    //                     {renderingProvider?.prefix}{" "}
                    //                     {renderingProvider?.firstName}{" "}
                    //                     {renderingProvider?.lastName}{" "}

                    //                 </option>
                    //             );
                    //         })
                    //         : "No prescribers available"}
                    // </select>
                  )}
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label className=" text--secondary font-12 fw-sb">
                    Patient
                  </label>
                  {arePatientsLoading === true ? (
                    <Skeleton animation="wave" variant="text" width="100%" />
                  ) : (
                    <PnSelect
                      isClearable={true}
                      isSearchable
                      onChange={handlePatientId}
                      name="patients"
                      closeMenuOnSelect={true}
                      options={patientsList}
                      value={selectedPatient}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-2">
                <label
                  htmlFor={"Payer List"}
                  className=" m-0 font-12 text--secondary fw-sb"
                >
                  {"Payer"}
                </label>
                <PnSelect
                  isClearable={true}
                  isSearchable
                  onChange={handlePayer}
                  name="payer"
                  closeMenuOnSelect={true}
                  options={payersList}
                  value={selectedPayer}
                />
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label className=" text--secondary font-12 fw-sb">
                    Status
                  </label>
                  <PnSelect
                    isClearable={true}
                    isSearchable
                    onChange={handleClaimStatus}
                    name="claimStatus"
                    closeMenuOnSelect={true}
                    options={CLAIM_STATUS_OPTIONS}
                    value={selectedStatus}
                  />
                </div>
              </div>
              <div className="col-sm-2 align-self-end">
                <button
                  onClick={handleFilter}
                  className="btn blue_task_button br-10 shadow-none"
                >
                  Filter Data
                </button>
              </div>
              <div className="col-sm-1 align-self-end">
                <span
                  onClick={handleClearFilter}
                  className={`pn-link-button d-inline-block  text-decoration-underline fw-sb font-12 text--secondary cursor--pointer ${
                    (claimNumber == "" || claimNumber == null) &&
                    selectedProvider == null &&
                    selectedPayer == null &&
                    selectedPatient == null &&
                    selectedStatus == null &&
                    fromDate == null &&
                    toDate == null
                      ? "disabled-link cursor--disabled"
                      : ""
                  }`}
                >
                  Clear Filters
                </span>
              </div>
              {/* <div
                className="col-1 d-flex align-self-end cursor--pointer"
                onClick={() => {
                  downloadCSV(claims);
                }}
              >
                <PnToolTip title={"Download CSV"} className="">
                  <FileDownloadIcon
                    sx={{
                      color: "#336383",
                      fontWeight: 400,
                      fontSize: "1.5rem",
                    }}
                    disable={claims?.length === 0}
                  />
                </PnToolTip>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-sm-3 "></div>
        </div>

        <div
          style={{ minHeight: "500px" }}
          className="row mt-3  justify-content-center"
        >
          <div className="col-sm-11">
            {areClaimsLoading ? (
              <Skeleton width="100%" />
            ) : claims?.length > 0 ? (
              <>
                <div className="row justify-content-end">
                  <div className="col-sm-3 text-end mb-1">
                    <RefreshDataComponent
                      classes={"pe-2"}
                      isComponentDataLoading={areClaimsLoading}
                      variant="without-bar"
                      callback={() =>
                        fetchAllClaims(
                          user?.tenantId,
                          claimNumber,
                          selectedProvider,
                          selectedPatient,
                          selectedStatus,
                          fromDate,
                          toDate,
                          currentPage,
                          selectedPayer,
                        )
                      }
                    />
                  </div>
                </div>
                <AllClaimsTable
                  setReloadClaims={setReloadClaims}
                  data={currentItems}
                  columnHeadersList={[
                    { key: "claim.claimNumber", value: "Claim Id" },
                    {
                      key: "claim.claimResponse.apexEdiClaimNumber",
                      value: "Apex Claim Id",
                    },
                    {
                      key: "claim.encounter.provider.firstName",
                      value: "Provider",
                    },
                    {
                      key: "claim.encounter.patient.firstName",
                      value: "Patient",
                    },
                    {
                      key: "claim.encounter.createdDate",
                      value: "Encounter Date",
                    },
                    {
                      key: "claim.encounter.patient.patientInsurance[0].payerName",
                      value: "Payer",
                    },
                    { key: "totalAmount", value: "Amount" },
                    {
                      key: "claim.claimResponse.payerAmount",
                      value: "Payer Amount",
                    },
                    {
                      key: "claim.claimPayment.patientResponsibility",
                      value: "Patient Responsibility",
                    },
                    { key: "status", value: "Status" },
                    { key: " ", value: " " },
                  ]}
                  hierarchy={
                    templateType === undefined || templateType === 1
                      ? false
                      : true
                  }
                />{" "}
              </>
            ) : (
              <>
                <span className="d-block font-12 fw-sb text--secondary">
                  No claims were found that matched the specified filters.
                </span>
              </>
            )}

            <div className="row justify-content-center">
              <div
                className={` mt-4 align-self-center col-sm-8 col-8 justify-content-center`}
              >
                {areClaimsLoading ? (
                  <Skeleton />
                ) : (
                  <div className={" d-flex flex-row justify-content-between"}>
                    {pages.length > 1 ? (
                      <div className="ps-2 mt-2">
                        <PnToolTip title="First" placement="top">
                          <button
                            disabled={currentPage === 1}
                            onClick={(e) => {
                              handleFirstPage(e);
                            }}
                            className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                          >
                            {/* <img src={IconNext} alt="icon-previous"></img> */}
                            <FirstPageIcon style={{ color: "#ec609b" }} />
                          </button>
                        </PnToolTip>
                        <PnToolTip title="Previous" placement="top">
                          <button
                            disabled={currentPage === 1}
                            onClick={(e) => {
                              handlePreButton(e);
                            }}
                            className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                          >
                            <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                            {/* <img src={IconNext} alt="icon-previous"></img> */}
                          </button>
                        </PnToolTip>
                      </div>
                    ) : (
                      ""
                    )}

                    <RenderPages />

                    {pages.length > 1 ? (
                      <div className="mt-2">
                        <PnToolTip title="Next" placement="top">
                          <button
                            disabled={currentPage === pages.length}
                            onClick={(e) => {
                              handleNextButton(e);
                            }}
                            className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                          >
                            {/* <img src={IconNext} alt="icon-next"></img> */}
                            <NavigateNextIcon style={{ color: "#ec609b" }} />
                          </button>
                        </PnToolTip>
                        <PnToolTip title="Last" placement="top">
                          <button
                            disabled={currentPage === pages.length}
                            onClick={(e) => {
                              handleLastPage(e);
                            }}
                            className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                          >
                            {/* <img src={IconNext} alt="icon-next"></img> */}
                            <LastPageIcon style={{ color: "#ec609b" }} />
                          </button>
                        </PnToolTip>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {console.log("claims: ", claims)} */}
    </>
  );
}

export default AllClaimsPage;
