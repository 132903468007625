import MessageItem from "./MessageItem";
import classes from "../messages/Messages.module.css";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import drawerButton from "../../assets/icons/drawerButton.svg";
const Messages = (props) => {
  // const patientMessages = useSelector((state)=>state.ProviderMessages)
  // console.log(props.ProviderMessages);
  const [open, setOpen] = useState(props?.open);



  return (
    <>
      <div style={{ marginTop: '2.5rem' }} className="d-flex mb-1  px-2  justify-content-between align-items-baseline">
        <h2 className="font-22">Messages
          <span className="d-inline-block ms-2 cursor--pointer" onClick={() => { setOpen(!open); props?.handleOpenMessages() }}>
            <img
              src={drawerButton}
              className={`${!open ? "" : "drawer-toggle-rotate"}`}
            />
          </span>
        </h2>
        {open && <Link to="/messages" className="text-decoration-none fw-bold b2 font-12 " >
          View all
        </Link>}
      </div>
      <div className={`${classes["messages_list--border"]} mx-2`}>
        {props?.ProviderMessages?.Threads !== undefined && props?.ProviderMessages?.Threads !== null && props?.ProviderMessages?.Threads?.length > 0 &&
          props?.ProviderMessages?.Threads.map((message, index) => {
            const { LastMessage, OtherUserImage, Unread, Name, Time } = message;
            return (
              <>

                <MessageItem
                  key={index}
                  id={index}
                  LastMessage={LastMessage}
                  OtherUserImage={OtherUserImage}
                  Unread={Unread}
                  Name={Name}
                  Time={Time}
                />

                {index !== (props.ProviderMessages.Threads.length - 1) && <Divider style={{ backgroundColor: '#d9e8ec' }} />}
              </>
            );
          })}
      </div>

    </>
  );
};

export default Messages;
