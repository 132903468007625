import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import CalenderDatePicker from "../UI/General/CalenderDatePicker"; // Import date picker component
import { useNavigate } from "react-router-dom";

const ClaimAreaChart = ({ claimsData }) => {
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const chartInstance = useRef(null); // Store the Chart.js instance
  const [startDate, setStartDate] = useState(""); // Start date filter
  const [endDate, setEndDate] = useState(""); // End date filter

  useEffect(() => {
    const monthsArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const claimsArray = new Array(12).fill(0); // Initialize array for monthly claim counts

    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    if(claimsData !== undefined && claimsData?.length > 0){

      claimsData.forEach((claim) => {
        const { status, updatedDate } = claim;
        if (updatedDate) {
          const claimDate = new Date(updatedDate);
          const monthIndex = claimDate.getMonth(); // Get month index

          const isWithinDateRange =
            (!start || claimDate >= start) && (!end || claimDate <= end);

          if (
            isWithinDateRange &&
            (status === "CLAIM_APPROVED" || status === "CLAIM_DENIED")
          ) {
            claimsArray[monthIndex] += 1; // Increment claim count
          }
        }
      });
    }

      const data = {
        labels: monthsArray, // X-axis labels
        datasets: [
          {
            label: "Claims Processed",
            data: claimsArray,
            backgroundColor: "rgba(54, 162, 235, 0.5)", // Light blue area color
            borderColor: "rgba(54, 162, 235, 1)", // Line color
            fill: true, // Fill the area below the line
            tension: 0.4, // Curve the line
          },
        ],
      };

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Claims Processed: Jan-Dec",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };

      // Destroy existing chart instance to avoid memory leaks
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      // Create new chart instance
      chartInstance.current = new Chart(chartRef.current, {
        type: "line", // Line type chart for the area chart
        data: data,
        options: options,
      });

      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy(); // Clean up on unmount
        }
      };
    
  }, [claimsData, startDate, endDate]);

  const handleStartDate = (dateVal) => {
    setStartDate(dateVal);
  };

  const handleEndDate = (dateVal) => {
    setEndDate(dateVal);
  };

  function navigateToClaimsPage(routeVal, status) {
    navigate(routeVal, {
      state: { claimType: status, startDate: startDate, endDate: endDate },
    });
  }

  return (
    <>
      <div className="row">
        {/* Area Chart */}
        <div
          className="col-12 cursor--pointer"
          onClick={() => navigateToClaimsPage("/claims-all", "all")}
        >
          <div style={{ width: "100%", height: "300px" }}>
            <canvas ref={chartRef} /> {/* Canvas element for the chart */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimAreaChart;
