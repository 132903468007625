import { Stack } from "@mui/material";
import { numbersEnum } from "../../res/strings";
import { changeDateTimeFormat, dateTimeHelper } from "../../utils/dateTimeHelper";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import classes from "./PatientProviderNoteSummary.module.css";

const PatientProviderNoteSummary = ({ summary, errorMessage, props }) => {

    const isEditable = summary?.encounter?.status?.toLowerCase() === 'closed' || summary?.encounter?.status?.toLowerCase() === 'dispatched' ? true : false;
    const primaryDiagnosis = summary?.patientChart?.diagnosis?.length > 0 ? summary?.patientChart?.diagnosis?.filter(diagnosis => diagnosis?.type?.toLowerCase() === 'p')[0] : '';
    const secondaryDiagnoses = summary?.patientChart?.diagnosis?.length > 0 ? summary?.patientChart?.diagnosis?.filter(diagnosis => diagnosis?.type?.toLowerCase() === 's') : '';

    const H3 = ({ children, ...props }) => {
        return (
            <h3 {...props}>{children}</h3>
        )
    }

    const EditLink = ({ children, handleModal, handleChange, number, isEditable }) => {
        return (
            <div>
                <button hidden={isEditable} onClick={() => { handleModal(); handleChange(number); }} className='btn btn-link font-14 text--blue shadow-none border-none'>{children}</button>
            </div>
        )
    }

    const Heading = ({ children, edit = '' }) => {
        return (
            <>
                <div className='d-flex justify-content-between align-items-center'>
                    <H3 className='font-16 fw-sb text--terciary'>
                        {children}
                    </H3>
                    {edit}
                </div>
            </>
        )
    }
    return (

        <div className={`container br-10 px-2 py-2 ${classes['scroll__bar']}`} style={{ border: '2px solid #d9e8ec' ,maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }}>
            <div className='row border--bottom pb-2'>
                <div className="col-sm-12 text-danger font-14 mb-2" id="error-div">{errorMessage !== undefined && (<>{errorMessage}<hr /></>)} </div>
                <div className="col-sm-12">
                    <div className='d-flex justify-content-between'>
                        <h3 className='font-16 fw-sb text--terciary'>
                            Patient Information
                        </h3>
                    </div>

                    <Stack direction="row" spacing={6}>
                        <Stack direction="column" item>
                            <h6 className="font-14 m-0 text--terciary">Name</h6>
                            <p className='font-12 m-0'>{summary?.patient?.name[0]?.firstName ?? ""} {summary?.patient?.name[0]?.lastName ?? ""}</p>
                            {/* <p className='font-12 m-0'>{props?.encounter?.status !== 'CLOSED' ? props?.encounter?.HumanPatient?.Name?.FirstName ?? "" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0]?.FirstName : "" ?? ""} {props?.encounter?.status !== 'CLOSED' ? props?.encounter?.HumanPatient?.Name?.LastName ?? "" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0].LastName : "" ?? ""}</p> */}
                            {/* <p className='font-12 m-0'>{props?.encounter?.status !== 'CLOSED' && props?.encounter?.status !== 'DISPATCHED' ? props?.encounter?.HumanPatient?.Name?.FirstName ?? "" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0]?.FirstName : "" ?? ""} {props?.encounter?.status !== 'CLOSED' && props?.encounter?.status !== 'DISPATCHED' ? props?.encounter?.HumanPatient?.Name?.LastName ?? "" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0].LastName : "" ?? ""}</p> */}
                        </Stack>
                        <Stack direction="column" item>
                            <h6 className="font-14 m-0 text--terciary">Date of Birth</h6>

                            <p className='font-12 m-0'>{changeDateTimeFormat(summary?.patient?.dateOfBirth ?? null, "MMM D, YYYY") ?? ""}</p>
                        </Stack>
                        <Stack direction="column" item>
                            <h6 className="font-14 m-0 text--terciary">Biological Sex</h6>
                            <p className='font-12 m-0'>{summary?.patient?.gender ?? ""} </p>
                        </Stack>
                        <Stack direction="column" item>
                        <div>
                            <h6 className='font-14 m-0'>Phone</h6>
                            <p className='font-12 m-0'>
                                {formatPhoneNumber(summary?.patient?.communicationNumbers?.numbers?.filter(number => number?.type?.toLowerCase() === numbersEnum.en.cellPhoneExt?.toLowerCase())[0]?.number) ?? ""}
                            </p>
                        </div>
                        </Stack>
                    </Stack>

                    <div>
                        <h6 className='font-14 m-0'>Address</h6>
                        <p className='font-12 m-0'>
                            {summary?.patient?.address[0]?.addressLine1 ?? ""}{" "}
                            {summary?.patient?.address[0]?.city?.trim() ?? "" ?? " "}
                            &nbsp;
                            {summary?.patient?.address[0]?.stateProvince ?? ""}{" "}
                            {summary?.patient?.address[0]?.postalCode ?? ""}
                        </p>
                    </div>

                    

                    <div>
                        <h6 className='font-14 m-0'>Emergency Contact</h6>
                        <p className='font-12 m-0'>
                            {formatPhoneNumber(summary?.patient?.communicationNumbers?.emergencyContact?.find((numberItem) => { return numberItem?.phoneNumber !== "" })?.phoneNumber || "") ?? ""}
                        </p>
                    </div>

                    {/* <div>
                        <h3 className='font-14 m-0'>Encounter Date</h3>
                        <p className='font-12 m-0'>
                            {summary?.encounter?.encounterCreatedDate ? dateTimeHelper(summary?.encounter?.encounterCreatedDate, "MMM D, YYYY") : ""}
                        </p>
                    </div> */}


                </div>
            </div>

            <div className='row border--bottom py-2'>
                <div className="col-sm-12">
                    <Heading >
                        Subjective
                    </Heading>
                    <div>
                        <h6 className='font-14 m-0'>Chief Complaint</h6>
                        <p className='font-12 m-0'>{summary?.encounter?.chiefComplaint ?? ""}</p>
                    </div>

                    <br />

                    <div>
                        <h6 className='font-14 m-0'>History of Present Illness</h6>
                        <p className='font-12 m-0'>{summary?.encounter?.historyOfPresentIllness ?? ""} </p>
                    </div>

                    <br />

                    <div>
                        <h6 className='font-14 m-0'>Past Medical History</h6>
                        <p className='font-12 m-0'>{summary?.encounter?.pastMedicalHistory ?? ""} </p>
                    </div>

                    <br />

                    <div>
                        <h6 className='font-14 m-0'>Review of Systems</h6>
                        <p className='font-12 m-0'>{summary?.encounter?.reviewOfSystem ?? ""} </p>
                    </div>
                </div>
            </div>
             
            <div className='row border--bottom py-2'>
                <div className="col-sm-12">
                    <Heading>
                        Objective
                    </Heading>
                    <div>
                        <h6 className='font-14 m-0'>Physical Exam</h6>
                        <p className='font-12 m-0'>{summary?.encounter?.physicalExam ?? ""}</p>
                    </div>

                    <br />

                    <div>
                        <h6 className='font-14 m-0'>Vitals</h6>
                        <p className='font-12 m-0'>
                            {props?.encounter?.vital === 0 ? "" : ""}
                            {summary?.patientChart?.vitals?.length === 0 ? "" : ""}
                            {summary?.patientChart?.vitals?.at(0)?.temperature !== null && summary?.patientChart?.vitals?.at(0)?.temperature !== undefined && summary?.patientChart?.vitals?.at(0)?.temperature?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.temperature?.concat("°F, ") : ''}
                            {summary?.patientChart?.vitals?.at(0)?.systolic !== null && summary?.patientChart?.vitals?.at(0)?.systolic !== undefined && summary?.patientChart?.vitals?.at(0)?.systolic?.trim() !== '' && summary?.patientChart?.vitals?.at(0)?.diastolic !== null && summary?.patientChart?.vitals?.at(0)?.diastolic !== undefined && summary?.patientChart?.vitals?.at(0)?.diastolic?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.systolic?.concat("/", summary?.patientChart?.vitals?.at(0)?.diastolic, " mmHg, ") : ''}
                            {summary?.patientChart?.vitals?.at(0)?.pulse !== null && summary?.patientChart?.vitals?.at(0)?.pulse !== undefined && summary?.patientChart?.vitals?.at(0)?.pulse?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.pulse?.concat("bpm, ") : ''}
                            {summary?.patientChart?.vitals?.at(0)?.spo2 !== null && summary?.patientChart?.vitals?.at(0)?.spo2 !== undefined && summary?.patientChart?.vitals?.at(0)?.spo2?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.spo2?.concat("%, ") : ''}
                            {summary?.patientChart?.vitals?.at(0)?.weight !== null && summary?.patientChart?.vitals?.at(0)?.weight !== undefined && summary?.patientChart?.vitals?.at(0)?.weight?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.weight?.concat("lbs, ") : ''}

                            {summary?.patientChart?.vitals?.at(0)?.height !== null && summary?.patientChart?.vitals?.at(0)?.height !== undefined && summary?.patientChart?.vitals?.at(0)?.height?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.height?.concat("in, ") : ''}
                            {summary?.patientChart?.vitals?.at(0)?.bmi !== null && summary?.patientChart?.vitals?.at(0)?.bmi !== undefined && summary?.patientChart?.vitals?.at(0)?.bmi?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.bmi?.concat("bmi ") : ''}
                        </p>
                    </div>
                </div>
            </div>
            

            <div className='row border--bottom py-2'>
                <div className="col-sm-12">
                    <Heading>
                        Assessment
                    </Heading>
                    <div>
                        <p className='font-12 p-0 m-0'>
                            {summary?.encounter?.assessmentNote ?? ""}
                        </p>
                    </div>
                    <br />

                    <div>
                        <h6 className="font-14 m-0">Diagnoses</h6>
                        <p className='font-12 m-0'>
                            {(primaryDiagnosis?.code) ?? (primaryDiagnosis?.code !== undefined && Object.keys(primaryDiagnosis)?.length > 0 ? primaryDiagnosis.code : "")}
                            {(primaryDiagnosis?.code !== undefined && Object.keys(primaryDiagnosis)?.length > 0 ? " - " : "")}
                            {(primaryDiagnosis?.description) ?? (primaryDiagnosis?.description !== undefined && primaryDiagnosis?.description !== null ? primaryDiagnosis?.description : "")}
                            {(primaryDiagnosis?.description !== undefined && primaryDiagnosis?.description !== null ? <span className="fw-sb"> (Primary)</span> : "")}
                            <br />
                            {
                                secondaryDiagnoses !== ''
                                    ?
                                    (secondaryDiagnoses?.length > 0 ? secondaryDiagnoses?.map((item, index) => {
                                        return (
                                            <>
                                                {item?.code ?? ""} - {item?.description ?? ""} <br />
                                            </>
                                        )

                                    }) : '')
                                    :
                                    ""
                            }
                        </p>
                    </div>
                </div>
            </div>
             
            <div className='row border--bottom py-2'>
                <div className="col-sm-12">
                    <Heading>
                        {summary?.providerNote !== null && summary?.providerNote !== undefined ? "Provider Note" : "Plan"}
                    </Heading>
                    <div>
                        <p className='font-12 p-0 m-0'>
                            {summary?.encounter?.recommendationNote ?? ""}
                        </p>
                    </div>
                    <br />
                    <div>
                        <h6 className="font-14 m-0">Medications Ordered</h6>
                        <p className='font-12 m-0'>
                            {
                                summary?.medicationRx !== undefined ?
                                    summary?.medicationRx?.length > 0 ? summary?.medicationRx?.map((item, index) => {
                                        return (
                                            <>
                                                {item?.drugDescription ?? ""}, Sig: {item?.sigText ?? ""} <br />
                                            </>
                                        )

                                    }) : "" : ""
                            }
                        </p>
                    </div>

                    <br />

                    <div>
                        <h6 className="font-14 m-0">Labs Ordered</h6>
                        <p className='font-12 m-0'>
                            {
                                summary?.labOrder !== undefined ?
                                    summary?.labOrder?.labOrderTest?.length > 0 ? summary?.labOrder?.labOrderTest?.map((item, index) => {
                                        return (
                                            <>
                                                {item?.description ?? ""} <br />
                                            </>
                                        )

                                    }) : "" : ""
                            }
                        </p>
                    </div>
                </div>
            </div>

           

            <div className={`row  ${summary?.encounter?.status?.toLowerCase() === 'closed' ? "border--bottom py-2" : true}`} hidden={summary?.encounter?.status?.toLowerCase() === 'closed' ? false : true}>
                <div className="col-sm-12">
                    <div className='d-flex justify-content-between'>
                        <h3 className='font-16 fw-sb text--terciary'>
                            {"Signed by"}
                        </h3>
                    </div>
                    <div>
                        <p className='font-12 m-0'>
                            {summary?.encounter?.signedBy?.name[0]?.firstName} {summary?.encounter?.signedBy?.name[0]?.lastName}{", "} {summary?.encounter?.signedBy?.qualification} {"on "} {summary?.encounter?.signedDateTime !== null && summary?.encounter?.signedDateTime !== undefined ? dateTimeHelper(summary?.encounter?.signedDateTime, 'MMM D, YYYY LT') : ""}
                        </p>
                    </div>
                </div>
            </div>

            {/* <hr hidden={summary?.encounter?.status?.toLowerCase() === 'closed' ? false : true} /> */}
            {/* <hr hidden={props?.encounter?.status === 'CLOSED' ? false : true} /> */}

            <div className='row ' >
                <div className="col-sm-12">
                    <div className='d-flex justify-content-between'>
                        <h3 className='font-16 fw-sb text--terciary'>
                            Addendums
                        </h3>
                    </div>

                    <br />

                    <div>
                        <p className='font-12 m-0'>
                            {
                                summary?.encounter?.addendum?.length > 0 ? summary?.encounter?.addendum?.map((item, index) => {
                                    return (
                                        <>
                                            Title:  <span className={item?.SamePrescriber ? "" : "text--blue"}>{item?.title?.trim() ?? ""}</span> <br />
                                            Addendum: <span className={item?.SamePrescriber ? "" : "text--blue"}>{item?.addendum ?? ""}</span> <br />
                                            Created Date:  <span className={item?.SamePrescriber ? "" : "text--blue"}>{dateTimeHelper(item?.createdAt?.trim(), "MMM D, yyyy LT") ?? ""}</span> <br />
                                            Created By:  <span className={item?.SamePrescriber ? "" : "text--blue"}> {item?.signedUser?.name[0]?.firstName ?? ""} {item?.signedUser?.name[0]?.lastName ?? ""}{item?.signedUser?.qualification && ", "}{item?.signedUser?.qualification ?? ""}</span> <br />
                                            <hr hidden={summary?.addendum?.length - 1 <= index} />
                                        </>
                                    )
                                }) : ""
                            }
                        </p>
                    </div>

                </div>
            </div>
            {/* <hr hidden={props?.encounter?.status === 'CLOSED' ? false : true} /> */}
        </div>
    )
}
export default PatientProviderNoteSummary;