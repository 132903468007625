import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./ClaimTemplatetypeModal.module.css";
import { useEffect, useState } from "react";

const ClaimTemplateTypeModal = (props) => {
  const [templateType, setTemplateType] = useState();


  const handleSubmit = ()=>{
    props?.setClaimTemplateType((p)=>templateType);
    props?.handleNewTemplate();
    props?.handleModal();
  }


  useEffect(()=>{
    return(
        ()=>{setTemplateType(1)}
    )
  },[])
  return (
    <>
      <Modal
        show={props.show}
        contentClassName="modal-border modal-shadow bg--background"
        backdropClassName="modal-backdrop"
        dialogClassName={`${classes["modal__width"]}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb">
            Report Template Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row d-flex flex-row ">
            <div className="col-7">
              <label htmlFor="template-type" className="font-14 text--terciary">
                Do you want template flat or hierarchical ?
              </label>
            </div>
            <div className="col-5">
              <input
                type="radio"
                name="t-type"
                id="flat"
                value="flat"
                className="me-1 cursor--pointer"
                checked={templateType === 1}
                onChange={() => setTemplateType(1)}
              />
              <label htmlFor="flat" className="me-5 font-12 text--terciary">
                {"Flat"}
              </label>
              <input
                type="radio"
                name="t-type"
                id="hierarchical"
                value="hierarchical"
                className="me-1 cursor--pointer"
                checked={templateType === 2}
                onChange={() => setTemplateType(2)}
              />
              <label
                htmlFor="hierarchical"
                className="me-5 font-12 text--terciary"
              >
                {"Hierarchical"}
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="btn"
            className={`btn blue_task_button `}
            onClick={()=>{handleSubmit()}}
            disabled={templateType === undefined}
            >Submit</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClaimTemplateTypeModal;
