import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Skeleton from "@mui/material/Skeleton";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import sendHttpRequest from "../hooks/sendHttpRequest";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import  AddButtonIcon  from "../../../src/assets/icons/Icon-Add.svg";
import { useParams } from "react-router-dom";
import HopitalizationModal from "../UI/Modals/PatientModals/HospitalizationModal";
import HospitalizationEditModal from "../UI/Modals/PatientModals/HospitalizationEditModal";
import TrashIcon from "../../assets/icons/TrashIcon";
import { getHospitalizationUrl, addHospitalizationUrl, updateHospitalizationUrl } from "../../res/endpointurls";
import { hospitalizationStrings } from "../../res/strings";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { truncate } from "../../utils/helpers/HelperFunctions";
import EditIcon from "../../assets/icons/EditIcon";
import { useAuth } from "../hooks/useAuth";
import { fontFamily } from "@mui/system";

const ColoredToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#336383",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#336383",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    width: 150,
    fontFamily:'Montserrat'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingRight: "0px",
    width: 150,
    fontFamily:'Montserrat'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  width: 130,
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeMedium": {
      padding: "0px", // <-- arbitrary value
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
});

const HospitalizationTable = (props) => {
  const _str = hospitalizationStrings.en;
  const { providerId, encounterId, patientId } = useParams();
  const {user} = useAuth();
  const [hospitalization, setHospitalization] = useState("");
  const [reason, setReason] = useState("");
  const [lengthOfStay, setLengthOfStay] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editHospitalization, setEditHospitalization] = useState("");
  const [updateHospitalizationId, setUpdateHospitalizationId] = useState("");
  const [hopitalizationStatusCode, setHopitalizationStatusCode] = useState("");
  const [reasonTextSize, setNoteTextSize] = useState(50);

  const fetchHospitalization = async (patientid) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: getHospitalizationUrl(patientid || props?.patientId),
      method: "GET",

    });
    if (!httpResponse.error) {
      setHospitalization(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setHopitalizationStatusCode(httpResponse.status);
  };

  const addHospitalizationRequestHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: props?.patientPortal ? `/patient/${user?.userId}/hospitalization` : addHospitalizationUrl(encounterId),
      method: "POST",
      data: {
        reason: reason,
        lengthOfStay: lengthOfStay,
      },
    });
    if (!httpResponse.error) {
      emptyStates();
      fetchHospitalization(patientId);
      // console.log(httpResponse.data);
    } else {
      emptyStates();
      console.log(httpResponse.error);
    }
  };

  const editHospitalizationRequestHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: props?.patientPortal ? `/patient/${user?.userId}/hospitalisation/${updateHospitalizationId}` : updateHospitalizationUrl(encounterId, updateHospitalizationId),
      method: "PUT",
      data: {
        hospitalizationId: updateHospitalizationId,
        reason: reason,
        lengthOfStay: lengthOfStay,
      },
    });
    if (!httpResponse.error) {
      emptyStates();
      fetchHospitalization(patientId);
      // console.log(httpResponse.data);
    } else {
      emptyStates();
      console.log(httpResponse.error);
    }
  };

  const emptyStates = () => {
    setReason("");
    setLengthOfStay("");
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleEditModal = (event) => {
    let id = event.target.id;
    setUpdateHospitalizationId(id);

    const editedHospitalizationItem = hospitalization?.filter((hospital) => {
      return hospital.hospitalizationId === Number(id);
    });

    if (editedHospitalizationItem?.length !== 0) {
      const hospital = editedHospitalizationItem[0];
      setReason(hospital?.reason);
      setLengthOfStay(hospital?.lengthOfStay);
      setEditHospitalization(hospital);
    }
    setOpenEditModal(!openModal);
  };

  const OpeneditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  useEffect(() => {
    fetchHospitalization(patientId || props?.patientId);
  }, [patientId, props?.patientId]);

  return (
    <>
      <div
        className="d-flex mb-1 justify-content-between align-items-baseline"
        {...props}
      >
        <div className="d-inline-flex flex-column align-self-center">
          <h4 className="h18">{_str.hospitalization}</h4>
          {/* <p style={{ color: 'var(--secondary)' }}>Lorem Ipsum dolor asit</p> */}
        </div>
        <div className="d-flex align-self-center " style={{height:"40px", width:"40px"}}>
          {!props?.viewOnly && (
            <img
              style={{ color: "var(--primary)" }}
              className="btn shadow-none"
              onClick={() => handleModal()}
            src={AddButtonIcon}
            />
          )}
        </div>
      </div>

      {openModal ? <HopitalizationModal
        show={openModal}
        handleModal={handleModal}
        setReason={setReason}
        setLengthOfStay={setLengthOfStay}
        addHospitalizationRequestHandler={addHospitalizationRequestHandler}
      /> : null}

      {openEditModal ? (
        <HospitalizationEditModal
          show={openEditModal}
          handleModal={OpeneditModal}
          setReason={setReason}
          setLengthOfStay={setLengthOfStay}
          editHospitalization={editHospitalization}
          editHospitalizationRequestHandler={editHospitalizationRequestHandler}
        />
      ) : null}
      <TableContainer
        sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
      >
        <Table>
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <StyledTableRow>
              <StyledTableCell >{_str.table_reason}</StyledTableCell>
              <StyledTableCell>{_str.table_lengthOfStay}</StyledTableCell>
              {!props?.viewOnly && (
                <>
                  <StyledTableCell></StyledTableCell>
                </>
              )}
            </StyledTableRow>
          </TableHead>
          {hospitalization.length>0 ?
          <TableBody>

              {isLoading ? <StyledTableRow>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                {!props?.viewOnly && (
                  <>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                  </>
                )}
              </StyledTableRow> : (hopitalizationStatusCode === 200 ? hospitalization?.map((row, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {/* Display long text with Tooltip */}
                    {row.reason.length > reasonTextSize ?
                      <ColoredToolTip title={row?.reason} arrow placement="top">
                        <StyledTableCell
                        sx={{ fontWeight:600,}}
                        >
                          {row?.reason !== undefined && row?.reason !== ""
                            ? truncate(row.reason, reasonTextSize)
                            : "Not available"}
                        </StyledTableCell>
                      </ColoredToolTip>
                      : <StyledTableCell
                        sx={{ color: "#323232", fontWeight:600 }}
                      >
                        {row?.reason !== undefined && row?.reason !== ""
                          ? row.reason
                          : "Not available"}
                      </StyledTableCell>
                    }
                    <StyledTableCell
                      sx={{ color: "#336383" }}
                    >
                      {row?.lengthOfStay !== undefined &&
                        row?.lengthOfStay !== ""
                        ? row?.lengthOfStay
                        : "Not available"}
                    </StyledTableCell>
                    {!props?.viewOnly && (
                      <>
                        {/* <StyledTableCell /> */}
                        <StyledTableCell
                          style={{ cursor: "pointer" }}
                          disabled
                        >
                          <div className="d-flex flex-row col-11 ms-2 justify-content-end">
                            <div className="row">
                              <div className="col-4">
                                <EditIcon
                                  style={{color: "#336383", height: "15px", width: "15px"}}
                                  onClick={(e) => handleEditModal(e)}
                                  id={row.hospitalizationId}
                                />
                              </div>
                              <div className="col-4">
                                <TrashIcon style={{ color: "#A2D1DE", height: "15px", width: "15px" }} />
                              </div>
                            </div>
                          </div>
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                );
              }) : "")}

          </TableBody>
          : <p className="m-2 font-14 p-1">No data available</p>
        }
        </Table>
      </TableContainer>
    </>
  );
};
export default HospitalizationTable;
