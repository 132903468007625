import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import classes from "./CptCodeSearch.module.css";
import { PnToolTip } from "../UI/Reusable/PnComponents";

import React, { useState } from "react";

const CptCodeSearch = (props) => {
    const [isInteractingWithScrollbar, setIsInteractingWithScrollbar] = useState(false);

    const handleSuggestionClick = (suggestion) => {
        // Handle adding the selected suggestion
        props.handleAddCptCode(suggestion);

        // Clear the suggestions and hide the dropdown
        props.setSuggestions([]);
        props.setIsError(false);
        props.setIsLoading(false);
    };

    return (
        <div id="parentdiv" style={{ position: "relative" }}>
            <div className="input-group" style={{
                border: "1px solid #D9E8EC",
                borderRadius: "10px"
            }}>
                <input
                    autoFocus={props?.focus}
                    type="text"
                    disabled={props?.disabled}
                    className={`form-control font-12 br-10 shadow-none border-0  ${classes.placeholder} `}
                    placeholder="Search CPT Code"
                    value={props.cptCodeName}
                    onChange={(e) => {
                        props.handleSearchInput(e.target.value);
                    }}
                    onBlur={(e) => {
                        if (!isInteractingWithScrollbar) {
                            setTimeout(() => {
                                props.setSuggestions([]);
                                props.setIsError(false);
                                props.setIsLoading(false);
                            }, 10);
                        }
                    }}
                />

                <button
                    disabled={props?.disabled}
                    className={`border-0 outline-0 px-1 ${props?.disabled === true ? 'disabled' : 'bg-white'}  br-10 shadow-none zindex-1`}
                    type="submit"
                    value={props.searchInputValue}
                    onClick={() => {
                        if (props?.selectedCptCode === null || props.selectedCptCode === undefined) {
                            props.handleSearchInput(props?.searchInputValue);
                        } else {
                            props?.handleClearCptCode();
                        }
                    }}
                    onBlur={() => {
                        if (!isInteractingWithScrollbar) {
                            setTimeout(() => {
                                props.setSuggestions([]);
                                props.setIsError(false);
                                props.setIsLoading(false);
                            }, 5);
                        }
                    }}
                >
                    {props?.selectedCptCode != null && props?.selectedCptCode != undefined && props?.cptCodeName !== null && props?.cptCodeName !== undefined && props?.cptCodeName?.length > 0 ? (
                        <ClearIcon fontSize="small" className="text--danger" />
                    ) : (
                        <SearchIcon fontSize="small" className="text--secondary" />
                    )}
                </button>
            </div>

            {/* Wrapping UL with a scrollable div */}
            <div
                className={`${classes["scroll__bar"]}`}
                style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    width: `${document.getElementById('parentdiv')?.offsetWidth}px`,
                    position: "absolute",   // Absolute positioning
                    top: "100%",            // Position below the input field
                    left: "0",
                    zIndex: 10
                }}
                onMouseEnter={() => setIsInteractingWithScrollbar(true)}
                onMouseLeave={() => setIsInteractingWithScrollbar(false)}
            >
                <ul className={`list-group ${classes["cpt_suggestions"]}`}>
                    {props.isError ? (
                        <li className={`list-group-item ${classes["search__list__item"]}`}>
                            No result found
                        </li>
                    ) : props?.isLoading ? (
                        <li className={`list-group-item ${classes["search__list__item"]}`}>
                            Loading...
                        </li>
                    ) : props.suggestions.length > 0 &&
                        props.suggestions.map((suggestion, index) => {
                            return (
                                <PnToolTip title={`${suggestion.code} - ${suggestion.description}`} tabIndex={index}>
                                    <li
                                        tabIndex={index}
                                        className={`border--default ${classes.li} ${classes["search__list__item"]}`}
                                        key={suggestion.code}
                                        onMouseDown={() => handleSuggestionClick(suggestion)}  // Close the dropdown on selection
                                    >
                                        <span className="ms-1">{suggestion.code} - {suggestion.description}</span>
                                    </li>
                                </PnToolTip>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};

export default CptCodeSearch