import SearchPatient from "./Header/SearchPatient";
import CustomDropdown from "../forms/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useEffect, useState } from "react";
import DefaultAvatarIcon from "../../assets/icons/Icon-Default-Avatar.svg";
import NotificationDropdown from "../forms/NotificationDropdown";
// import classes from "../UI/General/Stats.module.css";
import LogoMain from "../../assets/icons/Logo.svg";
import { Box } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { setTenantSubscriptions, setSubscriptionPrivilegeGroups } from "../../redux/actions/providerActions";
import { PATIENTS } from "../../utils/helpers/subscriptionsConstants";

const Navbar = (props) => {
  // const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const timezone = useSelector((state) => state.UserTimeZoneStatus.timezone);
  const [providerTenantsList, setProviderTenantsList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const dispatch = useDispatch();

  // const getUserTimezone = async () => {
  //   const httpResponse = await sendHttpRequest({
  //     url: "/auth/user/timezone",
  //     method: "GET",
  //   });
  //   if (!httpResponse.error) {
  //     setTimexone(httpResponse?.data?.timezone);
  //   } else {
  //     console.log(httpResponse.error);
  //   }
  // };

  const getProviderTenants = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/provider/${user?.userId}/tenants`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setProviderTenantsList(httpResponse?.data);
      let currentTenant = httpResponse?.data?.find((item) => { return item?.tenant?.tenantId === user?.tenantId })
      // console.log("currentTenant: ",currentTenant);
      if (currentTenant !== undefined) {
        let tenantSubscriptions = currentTenant?.tenant?.tenantSubscription?.subscriptionConfiguration;
        // let subscriptionPermissionGroups = tenantSubscriptions?.subscriptionPermissionGroups;
        // // console.log("subscriptionPermissionGroups", subscriptionPermissionGroups);
        // let permissionsWithKeys = generatePermissionsObject(subscriptionPermissionGroups);

        // // console.log("subscriptionRoles: ",subscriptionRoles);
        if (tenantSubscriptions !== undefined) {
          dispatch(setTenantSubscriptions(tenantSubscriptions));
          //   dispatch(setSubscriptionPrivilegeGroups(permissionsWithKeys));
        }
      }

    } else {
      console.log(httpResponse.error);
    }
  };

  function generatePermissionsObject(subscriptionPermissionGroups) {
    let permissionsWithKeys = {}
    for (let i = 0; i < subscriptionPermissionGroups.length; ++i) {
      let privileges = { "name": subscriptionPermissionGroups[i]['name'] }
      subscriptionPermissionGroups[i]['privilege']?.forEach(privilege => {
        privileges[privilege['key']] = {
          name: privilege['name'],
          active: privilege['active']
        }
      })
      permissionsWithKeys[subscriptionPermissionGroups[i]['name']] = { ...privileges };
    }
    console.log(permissionsWithKeys);
    return permissionsWithKeys;
  }


  // const getMapFromSession = async () => {
  //   setIsLoading(false);
  //   const httpResponse = await sendHttpRequest({
  //     url: "/session",
  //     method: "GET",
  //   });
  //   if (!httpResponse.error) {
  //     // console.log(JSON.parse(httpResponse.data.user));
  //     setUserInfo(JSON.parse(httpResponse.data.user));
  //     setIsLoading(true);
  //   } else {
  //   }
  // };

  useEffect(() => {
    // getMapFromSession();
    // setUserInfo(JSON.parse(localStorage.getItem("user")));
    // getUserTimezone();
    getProviderTenants();

    return () => { };
  }, [user?.tenantId]);

  return (
    <div
      id="pn-top-navbar"
      className="container-fluid bg-white "
      style={{
        paddingTop: "1.7px",
        paddingBottom: "3px",
        borderBottom: "1px solid #d9e8ec",
        borderLeft: "none",
        zIndex: "1310",
      }}
    >
      <nav className="navbar navbar-expand-sm  d-flex justify-content-between">
        <Box
          component="img"
          alt="PrescribeNow Logo"
          src={LogoMain}
          className="navbar-brand"
        />

        <div className="align-self-center font-12 text--terciary">
          {"All times in: "} {timezone}
        </div>
        <div className="d-flex flex-row ">
          {/* {console.log("subscriptionPrivilegeGroups[PATIENTS]?.sfp?.active", subscriptionPrivilegeGroups[PATIENTS])} */}
          <div hidden={subscriptionPrivilegeGroups[PATIENTS] !== undefined ? subscriptionPrivilegeGroups[PATIENTS]?.sfp?.active === false ? true : false : true}>
            <SearchPatient removeName={true} disable={false} navigate={true} advanceSearch={true} />
          </div>
          <div className="ms-3 d-flex ">
            <div className="d-flex align-items-center ">
              <div>
                <NotificationDropdown
                  notificationList={props?.notificationList}
                  patient={false}
                />
              </div>

              <div className="ms-1 ">
                <img
                  src={user?.profilePicture ?? DefaultAvatarIcon}
                  width="40px"
                  height="40px"
                  alt="Provider"
                  className="rounded-circle"
                />
              </div>
              <div className="pe-3">
                <CustomDropdown
                  isLoading={isLoading}
                  userInfo={user}
                  videoTrack={props.videoTrack}
                  audioTrack={props.audioTrack}
                  isVideoCallActive={props.isVideoCallActive}
                  resetTracks={props.resetTracks}
                  providerTenantsList={providerTenantsList}
                />
              </div>
            </div>
          </div>
        </div>

      </nav>

    </div>
  );
};

export default Navbar;
