import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UpcomingAppointment from "../dashboard/UpcomingAppointment";
import Medications from "../dashboard/Medications";
import PatientGreeting from "../../components/UI/General/PatientGreeting";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import { Skeleton } from "@mui/material";
import classes from "./PatientDashboard.module.css";
import NewPatientAppointmentModal from "../patientmodals/NewPatientAppointmentModal";
import CustomizedSnackbars from "../../components/UI/General/CustomizedSnackbars";
import PatientApptReservedModal from "../patientmodals/PatientApptReservedModal";
import { setUnreadMessageCount } from "../../redux/actions/providerActions";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../components/hooks/useAuth";
import { RefreshDataComponent } from "../../components/UI/General/RefreshDataComponent";
import { ChevronLeft, ChevronRight, DashLg } from "react-bootstrap-icons";
import CheckPatientInsuredModal from "../patientmodals/CheckPatientInsuredModal";
import UpcomingAppointmentItem from "../dashboard/UpcomingAppointmentItem";
import { PRESCRIPTIONS } from "../../utils/helpers/subscriptionsConstants";

const PatientDashboard = () => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  
  const patientInsuranceData = useSelector((state)=>state.patientInsuranceData.patientInsurance);
  // console.log("patientInsuranceData: ",patientInsuranceData);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [isPatientPastMedLoading, setIsPatientPastMedLoading] = useState(true);
  const [openNewAppointmentModal, setOpenNewAppointmentModal] = useState(false);
  const [isApptCreatedError, setIsApptCreatedError] = useState(false);
  const [patientRefillsList, setPatientRefillsList] = useState([]);
  const [openApptResrvModal, setOpenApptResrvModal] = useState(false);
  const [apptData, setApptData] = useState("");
  const [apptTypeData, setApptTypeData] = useState("");
  const [reloadAppointments, setReloadAppointments] = useState(false);
  const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);
  const location = useLocation();
  const [isMessageCountLoading, setIsMessageCountLoading] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const [appointmentsLength, setAppointmentsLength] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);

  const [openInsuranceCheckModal,setOpenInsuranceCheckModal] = useState(false);

  const [patientInsurance,setPatientInsurance] = useState([]);

  const updateData = () => {
    // Update your data here
    setDataChanged(true);

    // Reset the dataChanged flag after a certain duration
    setTimeout(() => {
      setDataChanged(false);
    }, 300); // Change the duration as needed
  };


  const nextAppointment = () => {
    if (index < appointmentsLength - 1) {
      setIndex(index + 1);
      updateData();
    }
  };

  const previousAppointment = () => {
    if (index > 0) {
      setIndex(index - 1);
      updateData();
    }
  };


  const fecthPatientRefills = async () => {
    setIsPatientPastMedLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/patient/${user.userId}/orders`,
      method: "GET",
      params: {
        isPatientPortal: true,
      }

    });
    if (!httpResponse.error) {
      if (httpResponse.data?.length >= 3) {
        setPatientRefillsList(httpResponse.data.slice(0, 3));
      } else if (
        httpResponse.data?.length < 3 &&
        httpResponse.data?.length > 0
      ) {
        setPatientRefillsList(httpResponse.data);
      }
    } else {
      console.log(httpResponse.error);
    }
    setIsPatientPastMedLoading(false);
  };


  const handleNewApptModal = () => {
    setOpenNewAppointmentModal(!openNewAppointmentModal);
  };

  const handleApptReserveModal = () => {
    setOpenApptResrvModal(!openApptResrvModal);
  };

  const handleInsuranceCheckModal = ()=>{
    setOpenInsuranceCheckModal(false);
  }
  const isPatientInsured = (value)=>{
    if(value === false){
      handleInsuranceCheckModal();
      setOpenNewAppointmentModal(true);
    }else{
      handleInsuranceCheckModal();
      navigate("/patient-portal/add-insurance");
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsApptCreatedError(false);
  };
  const fecthUnreadMessagesCount = async () => {
    setIsMessageCountLoading(true);

    const httpResponse = await sendHttpRequest({
      url: "/messages/unread",
      method: "GET",
      params: {
        userId: user?.userId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      setUnreadMessagesCount(httpResponse?.data?.unreadMessageCount);
      dispatch(setUnreadMessageCount(httpResponse?.data?.unreadMessageCount));
    } else {
      dispatch(setUnreadMessageCount(0));
      console.log(httpResponse.error);
    }
    setIsMessageCountLoading(false);
  };

  
  // const fecthPatientInsuranceInformation = async (setPatientInsurance)=>{
  //   const httpResponse = await sendHttpRequest({
  //     url:`/patients/${user.userId}/insurances`,
  //     method:"GET"
  //   })

  //   if(!httpResponse.error){
  //     setPatientInsurance(httpResponse.data)
  //   }
  // }
  
  const MINUTE_MS = 15000;
  useEffect(() => {
    fecthPatientRefills();
    fecthUnreadMessagesCount();
    // fecthPatientInsuranceInformation(user.userId);

    const interval = setInterval(() => {
      fecthUnreadMessagesCount();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* <PatientNavbar /> */}
      {openApptResrvModal ? <PatientApptReservedModal
        show={openApptResrvModal}
        apptData={apptData}
        type={apptTypeData}
        handleApptReserveModal={handleApptReserveModal}

        url={"/patient-portal/payment"}
      /> : null}
      {openNewAppointmentModal && (
        <NewPatientAppointmentModal
          show={openNewAppointmentModal}
          handleModal={handleNewApptModal}
          setIsApptCreatedError={setIsApptCreatedError}
          setApptData={setApptData}
          setApptTypeData={setApptTypeData}
          handleApptReserveModal={handleApptReserveModal}
        // setIsSentAppointment={setIsSentAppointment}
        // fecthAppointments={fecthAppointments}
        // appointmentDate={appointmentDate}
        // selectedPrescriberId={prescriberInfo.selectedPrescriberId}
        // handleCurrentMonth={handleCurrentMonth}
        // setIsAppointmentNotRescheduled={setIsAppointmentNotRescheduled}
        // setAppointmentNotScheduledError={setAppointmentNotScheduledError}
        />
        
      )}
      {
        openInsuranceCheckModal ? 
        <CheckPatientInsuredModal
          show={openInsuranceCheckModal}
          handleModal={handleInsuranceCheckModal}
          isPatientInsured={isPatientInsured} 
        />:null
      }

      <div
        className="container-fluid py-2 px-4 bg-white"
        style={{ borderBottom: "1px solid #d9e8ec" }}
      >
        <div className="row justify-content-between">
          <div className="col-sm-6">
            <PatientGreeting insured={patientInsuranceData?.length > 0 ? true:false}/>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-2 align-self-center me-2">
            <button
              className={`btn blue_task_button ${classes.task_button}`}
              onClick={(e) => {
                patientInsuranceData?.length !== 0 ? 
                handleNewApptModal(e):
                setOpenInsuranceCheckModal(true);
                // document.activeElement.blur();
              }}
            >
              New Appointment
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12 col-sm-8 py-4 px-4 border--bottom border--right"
          >
            
            <UpcomingAppointmentItem setIndex={setIndex} dataChanged={dataChanged} setAppointmentsLength={setAppointmentsLength} index={index} reload={reloadAppointments} setIsAppointmentLoading={setIsAppointmentLoading} setReloadAppointments={setReloadAppointments}/>
          </div>
          {/* <div
            className="col-12 col-sm-7 py-4 px-4"
            style={{ borderBottom: "1px solid #d9e8ec" }}
          ></div> */}
        </div>

        <div className="row " hidden={subscriptionPrivilegeGroups[PRESCRIPTIONS] === undefined ? true:false}>
          <div
            className="col-12 col-sm-8 py-4 px-4"
            style={{
              borderRight: "1px solid #d9e8ec",
              borderBottom: "1px solid #d9e8ec",
            }}
          >
            <div className="row mb-3 justify-content-between  align-items-baseline">
              <div className="col-6">
                <h2 className="font-18">Prescriptions</h2>
              </div>
              <div className="col-4  align-items-baseline text-end ">
                <RefreshDataComponent
                  callback={fecthPatientRefills}
                  isComponentDataLoading={isPatientPastMedLoading}
                />
                <Link
                  to="/patient-portal/prescriptions"
                  state={{ from: location }}
                  className=" text-decoration-none fw-bold font-12"
                >
                  View all
                </Link>
              </div>
            </div>
            {isPatientPastMedLoading === true ? (
              <Skeleton />
            ) : (
              patientRefillsList?.length > 0 && (
                <div className="card pn-card py-3 ">
                  {patientRefillsList.map((prescriptionItem, index) => {
                    return (
                      <div key={index}>
                        <Medications
                          //   refillModalHandler={refillModalHandler}
                          prescriptionItem={prescriptionItem}
                          // {...(prescriptionItem?.rxStatus?.toLowerCase() === "completed" && { prescriptionItem: prescriptionItem })}
                          fecthPatientRefills={fecthPatientRefills}
                        />
                      </div>
                    );
                  })}
                </div>
              )
            )}
          </div>
          <div
            className="col-12 col-sm-7 py-4 px-4"
            style={{ borderBottom: "1px solid #d9e8ec" }}
          ></div>
        </div>
      </div>
      <CustomizedSnackbars
        message={"Appointment was not reserved"}
        severity="error"
        open={isApptCreatedError}
        handleClose={handleClose}
      />
    </>
  );
};

export default PatientDashboard;
