import { Skeleton, Tab, Tabs } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { TabPanel, theme } from "../UI/Reusable/PnComponents";
import InsurancePage from "../../patientportal/patientpages/InsurancePage";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { payersListUrl } from "../../res/endpointurls";
import { useEffect, useState } from "react";
import classes from "../../../src/patientportal/patientpages/PatientInsurancePage.module.css";

const AddInsuranceForm = (props) => {

  console.log("props ", props?.scannedInsuranceData?.memberId);

  const [primaryInsuranceJson, setPrimaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    // policyNumber: "",
    memberId: props?.scannedInsuranceData?.memberId !== undefined && props?.scannedInsuranceData?.memberId !== null ? props?.scannedInsuranceData?.memberId : "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Primary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });

  console.log("primaryInsuranceJson " , primaryInsuranceJson);

  const [secondaryInsuranceJson, setSecondaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    // policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Secondary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });

  const [tertiaryInsuranceJson, setTertiaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    // policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Tertiary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });
  const [insurancevalue, setInsurancevalue] = useState(0);
  const [primaryInsurance, setPrimaryInsurance] = useState("");
  const [secondaryInsurance, setSecondaryInsurance] = useState("");
  const [tertiaryInsurance, setTertiaryInsurance] = useState("");
  const [payersList, setPayersList] = useState({ isLoading: false });

  const handleInsuranceChange = (event, newValue) => {
    setInsurancevalue(newValue);
  };

  const getInsuranceTabValue = function (insuranceTabsTitle) {
    const insuranceTabsMap = new Map();
    insuranceTabsMap.set("Primary", 0);
    insuranceTabsMap.set("Secondary", 1);
    insuranceTabsMap.set("Tertiary", 2);
    return insuranceTabsMap.get(insuranceTabsTitle);
  };

  const fetchPayersList = async () => {
    setPayersList((prev) => ({ ...prev, isLoading: true }));
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: payersListUrl(),
    });
    if (!httpResponse.error) {
      setPayersList((prev) => ({ ...prev, list: httpResponse.data }));
    }
    setPayersList((prev) => ({ ...prev, isLoading: false }));
  };
  const handleNext = ()=>{
    props?.setPatientJson({...props?.patientJson,patientInsurance:[primaryInsuranceJson]})
    props.setInsuranceAccorionExpanded(false);
    props.setAppointmentAccordionExpanded("aptAccordion");
  }
  
  const goToNextSection = ()=>{
    props.setInsuranceAccorionExpanded(false);
    props.setAppointmentAccordionExpanded("aptAccordion");
  }

  useEffect(() => {
    // fetchPayersList();
    setPayersList((prev) => ({ ...prev, list: props.metaData?.payers }));
  }, []);

  useEffect(() => {
    if (props.scannedInsuranceData) {
      setPrimaryInsuranceJson((prevState) => ({
        ...prevState,
        payerName: props.scannedInsuranceData.insuranceName || "",
        payerCode: props.scannedInsuranceData.payerCode || "",
        payerAddress1: props.scannedInsuranceData.payerAddress1 || "",
        payerCity: props.scannedInsuranceData.payerCity || "",
        zipCode: props.scannedInsuranceData.zipCode || "",
        payerZip: props.scannedInsuranceData.zipCode || "",
        payerState: props.scannedInsuranceData.payerState || "",
        memberId: props.scannedInsuranceData.memberId || "",
        groupNumber: props.scannedInsuranceData.groupNumber || "",
        effectiveDate: props.scannedInsuranceData.effectiveDate || "",
        terminationDate: props.scannedInsuranceData.terminationDate || "",
        contactNumber: props.scannedInsuranceData.contactNumber || "",
        relationshipToInsured: props.scannedInsuranceData.relationshipToInsured || "",

        // Add other fields as needed
      }));
    }
  }, [props.scannedInsuranceData]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div
          className={`container-fluid bg-white px-3 py-3 ${classes["page__title-border"]}`}
        >
          <div className="row justify-content-center">
            
            {payersList?.isLoading ? (
              <Skeleton />
            ) : (
              <TabPanel
                value={insurancevalue}
                index={getInsuranceTabValue("Primary")}
                className=" "
              >
                {/* <form id="insuranceForm"
                  onSubmit={(e)=>{
                      e.preventDefault();
                      handleNext();
                  }}
                > */}
                {
                  payersList?.list?.length > 0 ?
                  <InsurancePage
                    payersList={payersList}
                    insuranceJson={primaryInsuranceJson}
                    setInsuranceJson={setPrimaryInsuranceJson}
                    handleSubmit={handleNext}
                    setPrimaryInsurance={setPrimaryInsurance}
                    type={"primary"}
                    patientInfoForm = {true}
                    goToNextSection = {goToNextSection}
                  />:null
                }
                  {/* <div className="text-end font-12 ">
                    <button 
                      id="insuranceForm" 
                      type="submit" 
                      className={`btn blue_task_button bg--blue fw-sb font-14 text-white btn-lg b2 px-5 br-10 py-1 ${props?.isSending ? "bg--disabled" : ""}`}
                    >
                      {"Next"}
                    </button>
                  </div> */}
                {/* </form> */}
              </TabPanel>
            )}

           
          </div>
        </div>
        {console.log("primaryInsuranceJson: ",primaryInsuranceJson)
        }
      </ThemeProvider>
    </>
  );
};

export default AddInsuranceForm;
