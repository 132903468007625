import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Stack,
  TableSortLabel,
} from "@mui/material";

import sendHttpRequest from "../../hooks/sendHttpRequest";
import {
  calenderDateTimeHelper,
  changeDateTimeFormat,
  dateTimeHelper,
  getAgeByDateOfBirth,
} from "../../../utils/dateTimeHelper";
import CustomizedSnackbars from "../../UI/General/CustomizedSnackbars";
import {
  fieldValidation,
  formatPhoneNumber,
  formatPostalCode,
  pharmacySpecialty,
  removeIfAllEmpty,
  removeIfAllEmptyFromApiData,
  truncate,
} from "../../../utils/helpers/HelperFunctions";
import classes from "./ViewPatientProfileEncounter.module.css";
import { useAuth } from "../../hooks/useAuth";
import { numbersEnum } from "../../../res/strings";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../UI/Reusable/PnComponents";
import {
  billingHistoryURL,
  currentbillURL,
  eligibilitySummaryUrl,
  fecthPatientInformationURL,
  fetchPatientSocialHistoryUrl,
  fetchSocialHistoryQuestionsUrl,
  getPaymentToken,
  providerWithNpiUrl,
} from "../../../res/endpointurls";
import { patient_profile_strings as strings } from "../../../res/strings";
import { common_strings } from "../../../res/strings";
import { RolesTextConversion } from "../../../utils/helpers/RolesMap";
import { EligibilityInformation } from "./EligibilityInformation";
import { ConfigMap, featureFlagMap } from "../../../utils/helpers/ConfigMap";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import PaymentForm from "../../payment/PaymentForm";
import PaymentChequeForm from "../../payment/PaymentChequeForm";
import PaymentCashForm from "../../payment/PaymentCashForm";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty } from "lodash";
// import PrintIcon from "../../../assets/icons/Icon-print.svg";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import { Typeahead } from "react-bootstrap-typeahead";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PayPalForm from "../../payment/PayPalForm";
import SearchIcon from "@mui/icons-material/Search";
import { X } from "react-bootstrap-icons";
import PatientBillingTable from "../billing/PatientBillingTable";
import GooglePayButton from "../../payment/googlePay/GooglePayButton";
import ApplePay from "../../payment/ApplePay";
import { PAYMENTS } from "../../../utils/helpers/subscriptionsConstants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "Montserrat",
    // width: 150,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingRight: "0px",
    fontFamily: "Montserrat",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function InsuranceEligibilityDetails(props) {
  const {
    insuranceType,
    checkType,
    handleEditInsurance,
    eligibilitySummary,
    insuranceInformation,
  } = props;

  return (
    <div className="container-fluid g-0 py-2">
      <div className="row justify-content-between">
        <div className="col-sm-3 align-self-center">
          <span className="font-14 d-inline-block text--terciary fw-sb">
            {insuranceType}
            <button
              className={`btn br-10 shadow-none text-decoration-underline px-2 py-0 mb-02em fw-sb text--blue font-14 `}
              onClick={() => handleEditInsurance(checkType)}
            >
              Edit
            </button>
          </span>
        </div>

        <div className="col-sm-3">
          {fieldValidation(eligibilitySummary, "un") ? (
            eligibilitySummary?.checkType === checkType ? (
              <EligibilityInformation
                type={
                  eligibilitySummary?.active === true ? "success" : "reject"
                }
              />
            ) : (
              <EligibilityInformation notChecked={true} />
            )
          ) : fieldValidation(insuranceInformation, "un") ? (
            <EligibilityInformation
              type={
                insuranceInformation?.status === true ? "success" : "reject"
              }
            />
          ) : (
            <EligibilityInformation notChecked={true} />
          )}
        </div>
        <div className="col-sm-4 align-self-center">
          <span className="font-12 fw-sb px-2 text--secondary d-inline-block">
            {props._str.lastChecked}:
          </span>
          <span className="font-12 text--secondary d-inline-block">
            {fieldValidation(eligibilitySummary, "un")
              ? fieldValidation(eligibilitySummary?.lastChecked, "un")
                ? dateTimeHelper(
                  eligibilitySummary?.lastChecked,
                  "MMM DD, YYYY LT"
                )
                : "No data available"
              : fieldValidation(insuranceInformation?.lastChecked, "un")
                ? dateTimeHelper(
                  insuranceInformation?.lastChecked,
                  "MMM DD, YYYY LT"
                )
                : "No data available"}
          </span>
        </div>
      </div>
      <div
        className={`${classes["insurance-container"]} br-10 row g-0 px-3  py-3`}
      >
        <div className="col-12 col-sm-12 ">
          <div className="row justify-content-between">
            <div className="col-3">
              <div className="font-12 align-self-baseline d-inline-block text--terciary fw-sb">
                <span>{insuranceInformation?.insuranceName}</span>
              </div>
            </div>
            <div className="col-3"></div>
            <div className="col-2  font-12">
              <span className="fw-sb">Id: </span>{" "}
              {insuranceInformation?.memberId ?? "Not available"}
            </div>

            <div className="col-2  font-12">
              <span className="fw-sb">Policy: </span>{" "}
              {insuranceInformation?.policyNumber ?? "Not available"}
            </div>

            <div className="col-2  font-12">
              <span className="fw-sb">Group: </span>{" "}
              {insuranceInformation?.groupNumber ?? "Not available"}
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 mt-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex ">
              <div className="d-flex flex-column">
                <div className="font-10 text--secondary">Provider Network</div>
                <div className="font-12 text--terciary">In-network</div>
                <div className="font-12 text--terciary">Out-network</div>
              </div>
            </div>
            <div className={`d-flex ps-2 ${classes["insurance-border-start"]}`}>
              <div className="d-flex flex-column">
                <div className="font-10 text--secondary">Copay</div>
                <div className="font-12 text--terciary">
                  {eligibilitySummary !== undefined &&
                    eligibilitySummary?.checkType === checkType
                    ? fieldValidation(eligibilitySummary?.inNetworkCopay, "un")
                      ? eligibilitySummary?.inNetworkCopay
                      : "No data available"
                    : fieldValidation(
                      insuranceInformation?.inNetworkCopay,
                      "un"
                    )
                      ? insuranceInformation?.inNetworkCopay
                      : "No data available"}
                </div>
                <div className="font-12 text--terciary">
                  {eligibilitySummary !== undefined &&
                    eligibilitySummary?.checkType === checkType
                    ? fieldValidation(eligibilitySummary?.outNetworkCopay, "un")
                      ? eligibilitySummary?.outNetworkCopay
                      : "No data available"
                    : fieldValidation(
                      insuranceInformation?.outNetworkCopay,
                      "un"
                    )
                      ? insuranceInformation?.outNetworkCopay
                      : "No data available"}
                </div>
              </div>
            </div>

            <div className={`d-flex ps-2 ${classes["insurance-border-start"]}`}>
              <div className="d-flex flex-column">
                <div className="font-10 text--secondary">Deductible</div>
                <div className="font-12 text--terciary">
                  {eligibilitySummary !== undefined &&
                    eligibilitySummary?.checkType === checkType
                    ? fieldValidation(
                      eligibilitySummary?.inNetworkDeductible,
                      "un"
                    )
                      ? eligibilitySummary?.inNetworkDeductible
                      : "No data available"
                    : fieldValidation(
                      insuranceInformation?.inNetworkDeductible,
                      "un"
                    )
                      ? insuranceInformation?.inNetworkDeductible
                      : "No data available"}
                </div>
                <div className="font-12 text--terciary">
                  {eligibilitySummary !== undefined &&
                    eligibilitySummary?.checkType === checkType
                    ? fieldValidation(
                      eligibilitySummary?.outNetworkDeductible,
                      "un"
                    )
                      ? eligibilitySummary?.outNetworkDeductible
                      : "No data available"
                    : fieldValidation(
                      insuranceInformation?.outNetworkDeductible,
                      "un"
                    )
                      ? insuranceInformation?.outNetworkDeductible
                      : "No data available"}
                </div>
              </div>
            </div>

            <div className={`d-flex ps-2 ${classes["insurance-border-start"]}`}>
              <div className="d-flex flex-column">
                <div className="font-10 text--secondary">Coinsurance</div>
                <div className="font-12 text--terciary">
                  {eligibilitySummary !== undefined &&
                    eligibilitySummary?.checkType === checkType
                    ? fieldValidation(
                      eligibilitySummary?.inNetworkCoinsurance,
                      "un"
                    )
                      ? eligibilitySummary?.inNetworkCoinsurance
                      : "No data available"
                    : fieldValidation(
                      insuranceInformation?.inNetworkCoinsurance,
                      "un"
                    )
                      ? insuranceInformation?.inNetworkCoinsurance
                      : "No data available"}
                </div>
                <div className="font-12 text--terciary">
                  {eligibilitySummary !== undefined &&
                    eligibilitySummary?.checkType === checkType
                    ? fieldValidation(
                      eligibilitySummary?.outNetworkCoinsurance,
                      "un"
                    )
                      ? eligibilitySummary?.outNetworkCoinsurance
                      : "No data available"
                    : fieldValidation(
                      insuranceInformation?.outNetworkCoinsurance,
                      "un"
                    )
                      ? insuranceInformation?.outNetworkCoinsurance
                      : "No data available"}
                </div>
              </div>
            </div>
            <div className={`d-flex ps-2 ${classes["insurance-border-start"]}`}>
              <div className="d-flex flex-column">
                <div className="font-10 text--secondary">
                  {props._commonStr.effectiveDate}
                </div>
                <div className="font-12 text--terciary">
                  {insuranceInformation?.effectiveDate !== undefined &&
                    insuranceInformation?.effectiveDate !== null
                    ? changeDateTimeFormat(
                      insuranceInformation?.effectiveDate,
                      "MMM D, YYYY"
                    )
                    : "No data available"}
                  {" - "}
                  {insuranceInformation?.terminationDate !== undefined &&
                    insuranceInformation?.terminationDate !== null
                    ? changeDateTimeFormat(
                      insuranceInformation?.terminationDate,
                      "MMM D, YYYY"
                    )
                    : "No data available"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProviderSearchFilter(props) {

  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [tempFilterList, setTempFilterList] = useState([...props.filterList]);
  const [tags, setTags] = useState([])

  const handleProviderSearch = (providerName) => {
    let name = providerName?.toLowerCase();
    if (name?.length > 0) {
      let providers = props?.uniqueProvidersList?.filter((item) => {
        let firstName = item?.providerTenantPracticeLocation?.provider?.name[0]?.firstName?.toLowerCase()
        let lastName = item?.providerTenantPracticeLocation?.provider?.name[0]?.lastName?.toLowerCase()
        return firstName.includes(name) || lastName.includes(name)
      })
      setSuggestions(providers);
    } else {
      setSuggestions([])
    }
  }
  const handleProviderIds = (event, id) => {
    let ischecked = document.getElementById(id);
    if (event.target.checked) {
      setTempFilterList([...tempFilterList, id])
      addTags(id)
    } else {
      let filterIds = tempFilterList.filter((item) => { return item !== id })
      setTempFilterList([...filterIds])
      ischecked.checked = false;
      removeTags(id);
    }

  }
  const handleDefaultChecked = (id) => {
    const result = props.filterList.find((item) => { return item === id })
    return result !== undefined
  }
  const addTags = (providerId) => {
    // alert(event.target.value);

    let tempList = [...tags];
    // console.log("tempList: ",tempList);
    let providers = props?.uniqueProvidersList.filter((item) => { return item?.providerTenantPracticeLocation?.provider?.userId === Number(providerId) })
    // console.log("providers: ",providers);
    tempList.push(...providers);
    // console.log("tempList after push: ",tempList);

    setTags(() => tempList);
    // props.selectedTags([...tags, user]);
  };
  const removeTags = (providerId, position) => {
    let providers = tags.filter((item) => { return item?.providerTenantPracticeLocation?.provider?.userId !== providerId })
    let providerIds = providers.map((item) => { return item.providerTenantPracticeLocation?.provider?.userId })
    // console.log("remove providers: ",providers);
    setTags(() => [...providers])
    setTempFilterList(providerIds)

    if (position === "chip" && providers?.length === 0) {
      let allProviders = props?.uniqueProvidersList?.map((item) => { return item.providerTenantPracticeLocation?.provider?.userId });
      props.setFilterList(() => [])
      props.handleFiltering(() => allProviders);
    } else if (position === "chip") {
      props.setFilterList(() => [...providerIds])
      props.handleFiltering([...providerIds]);
    }
    handleChecksBox(providerId)
    // props?.setFilterList(providers.map((item)=>{return item?.providerTenantPracticeLocation?.provider?.userId})) 
  }

  const handleChecksBox = (providerId) => {
    document.getElementById(providerId).checked = false
  }

  return (
    <>
      <div
        className={`input-group border--default  br-10 ${classes["search__container"]}`}
      >
        <input
          type="text"
          className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
          id="providerFilter"
          name="providerFilter"
          // ref={searchRef}
          autoComplete="off"
          placeholder="Filter providers"
          value={searchQuery}
          // disabled={props?.disableSendRxButton ? true : props?.updateCheck ? true : false}
          onChange={(event) =>
            handleProviderSearch(event.target.value)
          }
        />
        <button
          className={`btn br-10 shadow-none`}
          type="submit"
        // value={examTestQuery}
        >
          <SearchIcon className=" text--secondary" />
        </button>
      </div>
      <div
        // ref={filterList?.length > 0 ? ref : null}
        className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
        style={{
          // width: `${props?.newPrescriptionRequest ? "44%" : "34%"}`,
          width: "50%",
        }}
      >
        {
          // examTestQuery?.length > 1 &&
          suggestions?.length > 0 &&
          suggestions.map((suggestion) => {
            let id = suggestion?.providerTenantPracticeLocation?.provider?.userId
            let prefix = suggestion?.providerTenantPracticeLocation?.provider?.name[0]?.prefix
            let firstName = suggestion?.providerTenantPracticeLocation?.provider?.name[0]?.firstName
            let lastName = suggestion?.providerTenantPracticeLocation?.provider?.name[0]?.lastName
            return (
              <li
                className={`border--default ${classes.li}`}
                onMouseDown={() => {
                  // handleExamTestList(suggestion);
                }}
              // key={suggestion?.code}
              >
                <input
                  type="checkbox"
                  className="cursor--pointer"
                  id={id}
                  onChange={(e) =>
                    handleProviderIds(e, id)
                  }
                  defaultChecked={handleDefaultChecked(id)}
                />
                <label className="ms-1" htmlFor={id}>
                  {prefix.concat(" ", firstName, " ", lastName)}
                </label>
              </li>
            );
          })
        }
        {
          // examTestQuery?.length > 0 &&
          suggestions?.length > 0 && (
            // filterList[0]?.code !== "-1" ? (
            <div
              style={{
                position: "sticky",
                display: "flex",
                justifyContent: "center",
                padding: "8px",
                backgroundColor: "white",
                gap: "4px",
                bottom: 0,
                left: 0,
              }}
            >
              <button
                className="btn  d9e8ec--button"
                style={{ height: "2.9 em !important" }}
                onClick={() => {
                  setSuggestions([]);
                  setSearchQuery("")
                }}
              >
                {"Cancel"}
              </button>
              <button
                className="btn blue_task_button"
                onClick={() => {
                  setSuggestions([]);
                  setSearchQuery("");
                  props.setFilterList([...tempFilterList])
                  props.handleFiltering([...tempFilterList]);
                  // setExaminationTestList(examinationTempList);
                  // setExaminationListErrors("");
                }}
                disabled={tempFilterList?.length === 0}
              >
                {"Apply"}
              </button>
            </div>
          )
        }
      </div>
      <ul className={`${classes["tags"]}`}>
        {/* {console.log(tags)} */}
        {tags?.map((providerItem, index) => (
          <li key={index} className={`${classes["tag"]}`}>
            <span className={`${classes["tag__title"]}`}>
              {providerItem?.providerTenantPracticeLocation?.provider?.name[0]?.firstName + " " + providerItem?.providerTenantPracticeLocation?.provider?.name[0]?.lastName}
            </span>
            <span
              className={`${classes["tag__close__icon"]}`}
              onClick={() => removeTags(providerItem?.providerTenantPracticeLocation?.provider.userId, "chip")}
              id={providerItem?.providerTenantPracticeLocation?.provider.userId}
            >
              <X
                color="#336383"
                style={{ marginBottom: '2px' }}
                size={16}
              />
            </span>
          </li>
        ))}
      </ul>
    </>
  )
}
function PaymentServiceTable({ columnHeadersList, data, navigateTo }) {
  const { user } = useAuth();
  console.log(data);
  const navigate = useNavigate();
  let firstName = data?.patient?.name[0]?.firstName ?? "";
  let lastName = data?.patient?.name[0]?.lastName ?? "";
  let reasonForVisit = data?.appointment?.chiefComplaint ?? "";
  let amountBilled = data?.amountBilled ?? 0;
  return (
    <TableContainer sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}>
      <Table>
        <TableHead
          sx={{
            borderTopColor: "#FAFCFC",
            borderRightColor: "#FAFCFC",
            borderLeftColor: "#FAFCFC",
            borderBottom: "1px solid #D9E8EC",
          }}
        >
          <StyledTableRow>
            {columnHeadersList?.map((item, idx) => (
              <StyledTableCell key={item?.concat(idx)}>
                <span className="font-12 text--secondary">{item}</span>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {/* {isLoading ? <>
                    {columnHeadersList?.map((item, idx) => {
                        return <StyledTableRow>
                            <StyledTableCell>
                                <Skeleton animation="wave" />
                            </StyledTableCell>
                        </StyledTableRow>
                    })}
                </> : <> */}

          <StyledTableRow key={"Current Bill"}>
            <StyledTableCell>
              {"".concat(firstName, " ", lastName)}
            </StyledTableCell>
            <StyledTableCell>{reasonForVisit}</StyledTableCell>
            <StyledTableCell>{""}</StyledTableCell>
            <StyledTableCell>{""}</StyledTableCell>
            {/* <StyledTableCell>{item?.description}</StyledTableCell> */}
            <StyledTableCell>
              {"$".concat(parseFloat(amountBilled).toFixed(2))}
            </StyledTableCell>
          </StyledTableRow>

          {/* </>} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const ViewPatientProfileEncounter = ({ practiceCodeTypeList, ...props }) => {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const { patientBilling } = useFlags();
  // console.log("patientBilling: ",patientBilling);
  // console.log(practiceCodeTypeList)
  const featureFlags = featureFlagMap();
  const __str = numbersEnum.en;
  const _str = { ...strings.en };
  const _commonStr = common_strings.en;
  let { patientId } = useParams();
  const navigate = useNavigate();
  const pId = props?.patientId ?? patientId;
  const { user } = useAuth();
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const [prescribersList, setPrescribersList] = useState([]);
  const [patientProfileData, setPatientProfileData] = useState();
  const [isPrescriberLoading, setIsPrescriberLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [hideToSP, setHideToSP] = useState(false);
  /* PVerify Insurance check related states*/
  const [providerId, setProviderId] = useState(null);
  const [practiceTypeCode, setPracticeTypeCode] = useState(
    user?.tenant?.practiceTypeCode !== null
      ? user?.tenant?.practiceTypeCode
      : null
  );
  const [practiceCodeValue, setPracticeCodeValue] = useState();
  const [insuranceType, setInsuranceType] = useState("Primary");
  // ===============================================================

  const [patientInsuranceInformation, setPatientInsuranceInformation] =
    useState(undefined);
  const [primaryInsuranceInformation, setPrimaryInsuranceInformation] =
    useState(undefined);
  const [secondaryInsuranceInformation, setSecondaryInsuranceInformation] =
    useState(undefined);
  const [primaryEligibilitySummary, setPrimaryEligibilitySummary] =
    useState(undefined);
  const [secondaryEligibilitySummary, setSecondaryEligibilitySummary] =
    useState(undefined);
  const [eligbilitySummaryLoading, setEligibilitySummaryLoading] =
    useState(false);
  const [billingHistoryData, setBillingHistoryData] = useState([
    // {date:new Date(),provider:"Dr. William Pilcher", placeOfService:"Winnemucca",serviceDescription:"Wellness visit",amount:"$225.00",paymentStatus:"Paid",outstanding:"$0.00"},
    // {date:new Date(),provider:"Dr. James Anderson", placeOfService:"Carson City",serviceDescription:"Consultation",amount:"$150.00",paymentStatus:"Partial",outstanding:"$100.00"},
    // {date:new Date(),provider:"Dr. James Anderson", placeOfService:"Carson City",serviceDescription:"Follow-up Visit",amount:"$75.00",paymentStatus:"Unpaid",outstanding:"$75.00"},
  ]);
  const [isCurrentPaymentLoading, setIsCurrentPaymentLoading] = useState(false);
  const [servicePaymentData, setServicePaymentData] = useState(undefined);
  const [paymentType, setPaymentType] = useState("cash");
  const [isPaymentSent, setIsPaymentSent] = useState(false);
  const [appointmentPrice, setAppointmentPrice] = useState(0);
  const [isBillinghistoryLoading, setIsBillinghistoryLoading] = useState(false);
  const [billingAmount, setBillingAmount] = useState(0);
  const [billingProvider, setBillingProvider] = useState();
  const [copayAmount, setCopayAmount] = useState(0);
  const [appointmentId, setAppointmentId] = useState();



  // console.log("paymentType: ", paymentType);
  const [isSocialHistoryLoading, setIsSocialHistoryLoading] = useState(false);
  const [substanceUse, setSubstanceUse] = useState([]);
  const [patientInformationAccordion, setPatientInformationAccordion] =
    useState(_str.patientInformation);
  const [substanceUseAccordion, setSubstanceUseAccordion] = useState(
    _str.substanceUse
  );
  const [chronicConditionsAccordion, setChronicConditionsAccordion] = useState(
    _str.chronicConditions
  );

  const [insuranceAccordion, setInsuranceAccordion] = useState(_str.insurance);
  const [isBillingAccordian, setIsBillingAccordian] = useState(_str.billing);
  const [isServicePayment, setIsServicePayment] = useState(
    _str.time_service_payment
  );

  const gender = patientProfileData?.gender;
  const dateOfBirth = patientProfileData?.dateOfBirth;

  const emergencyTelephone =
    patientProfileData?.communicationNumbers?.emergencyContact?.find(
      (numberItem) => {
        return numberItem?.phoneNumber !== "";
      }
    ) || "";

  const age = getAgeByDateOfBirth(patientProfileData?.dateOfBirth);

  const primaryTelephone =
    patientProfileData?.communicationNumbers?.numbers?.find((numberItem) => {
      return numberItem?.type === __str.cellPhoneExt;
    })?.number || null;

  // const substanceUse = patientProfileData?.SubstanceUse;
  const chronoicConditions = patientProfileData?.chronicConditions;
  // const recentEncountersList = patientProfileData?.RecentEncounters;

  const patientEmail = patientProfileData?.communicationNumbers?.electronicMail[0];


  // const [brainTreeToken,setBrainTreeToken] = useState();


  // Payment methods list
  const paymentMethodsList = [
    {
      name: "Cash",
      id: "cash",
      value: "cash",
    },
    {
      name: "Credit Card",
      id: "creditCard",
      value: "creditCard",
    },
    // {
    //   name: "Check",
    //   id: "check",
    //   value: "check",
    // },
    {
      name: "PayPal",
      id: "payPal",
      value: "payPal",
    },
    {
      name: "Apple Pay",
      id: "applePay",
      value: "applePay",
    },
    {
      name: "Google Pay",
      id: "googlePay",
      value: "googlePay",
    },
  ];

  const fetchAllPrescribers = async () => {
    setIsPrescriberLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // setisPrescribersFetched(true);
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
      // console.log(httpResponse.error);
    }
    setIsPrescriberLoading(false);
  };

  const fetchSocialHistoryQuestions = async () => {
    const httpResponse = await sendHttpRequest({
      url: fetchSocialHistoryQuestionsUrl(0),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Social History questions", httpResponse.data);
      // populateSubstanceUse(httpResponse.data);
      let substanceUseData = [];
      let arr = httpResponse?.data;
      arr.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (
            (arr[i].question?.toLowerCase() === "tobacco" &&
              arr[i].answerStrings?.length > 0) ||
            (arr[i].question?.toLowerCase() === "alcohol" &&
              arr[i].answerStrings?.length > 0) ||
            (arr[i].question?.toLowerCase() === "recreational drugs" &&
              arr[i].answerStrings?.length > 0)
          ) {
            let question = arr[i].question;
            if (arr[i].question?.toLowerCase() !== "recreational drugs") {
              question = question.split(" ")[0];
            }
            substanceUseData.push({
              Question: question,
              Answer: arr[i].answerStrings,
            });
          }
        }
        setSubstanceUse(substanceUseData);
      } else {
        setSubstanceUse([]);
      }
    } else {
      console.log("Social History Error", httpResponse.error);
    }
  };

  const fetchSocialHistory = async (patientId) => {
    setIsSocialHistoryLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPatientSocialHistoryUrl(patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Social History", httpResponse.data);
      if (httpResponse?.data?.length > 0) {
        let substanceUseData = [];
        let arr = httpResponse?.data;
        arr.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
        if (arr.length > 0) {
          for (let i = 0; i < arr.length; i++) {
            if (
              (arr[i].question.question?.toLowerCase() == "tobacco" &&
                arr[i].answerStrings?.length > 0) ||
              (arr[i].question.question?.toLowerCase() == "alcohol" &&
                arr[i].answerStrings?.length > 0) ||
              (arr[i].question.question?.toLowerCase() ==
                "recreational drugs" &&
                arr[i].answerStrings?.length > 0)
            ) {
              let question = arr[i]?.question?.question;
              if (
                arr[i].question?.question?.toLowerCase() !==
                "recreational drugs"
              ) {
                question = question.split(" ")[0];
              }
              substanceUseData.push({
                Question: question,
                Answer: arr[i].answerStrings,
              });
            }
          }
          setSubstanceUse(substanceUseData);
        } else {
          setSubstanceUse([]);
        }
      } else {
        // fetchSocialHistoryQuestions();
      }
    } else {
      // fetchSocialHistoryQuestions();
    }
    setIsSocialHistoryLoading(false);
  };

  const handleReceipt = async (userId) => {
    setIsPrinting(true);
    await fetch(ConfigMap("APP") + `/patients/${userId}/bills/receipt/latest`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization:
          localStorage.getItem("tkn") !== null
            ? localStorage.getItem("tkn")
            : "",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // console.log(blob);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `receipt.pdf`);
        link.target = "_blank";

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        link.parentNode.removeChild(link);
        setIsPrinting(false);
      });
  };

  const fetchBillingHistory = async (billPaid = false, paymentObject = {}) => {
    setIsBillinghistoryLoading(true);
    let pid = props?.patientId ?? patientId;
    const httpResponse = await sendHttpRequest({
      url: billingHistoryURL(pid),
      method: "GET",
    });

    if (!httpResponse.error) {
      setBillingHistoryData(httpResponse.data.content);
      if (httpResponse.data?.length > 0) {
        setHideToSP(false);

        let toSP = [];
        let patientName =
          patientProfileData?.name?.length > 0 && patientProfileData?.name[0];
        let billingHistory =
          httpResponse.data?.length > 0 && httpResponse.data[0];

        toSP.push({
          patient: patientName?.firstName + " " + patientName?.lastName ?? "",
          reasonForVisit: "Palpitations",
        });
        if (primaryInsuranceInformation?.length > 0) {
          let tempArr = toSP[0];
          tempArr = {
            ...tempArr,
            insurance: primaryInsuranceInformation[0]?.insuranceName,
            due: primaryInsuranceInformation[0]?.copayAmount,
            description: "Copay",
          };
          toSP = [tempArr];
        } else {
          let tempArr = toSP[0];
          tempArr = { ...tempArr, insurance: "", due: 0, description: "" };
          toSP = [tempArr];
        }
        toSP.push({
          due: billingHistory?.outstandingBalance,
          description: "Outstanding Balance",
        });

        if (billPaid) {
          toSP.push(paymentObject);
        }
        // setServicePaymentData(toSP);
        // setBillingAmount(httpResponse.data.reduce(
        //   (sum, item) => sum + item?.outstandingBalance,
        //   0
        // ))
      }
    }
    setIsBillinghistoryLoading(false);
  };

  const fetchCurrentBill = async () => {
    setIsCurrentPaymentLoading(true);
    setServicePaymentData(undefined);
    let pid = props?.patientId ?? patientId;
    const httpResponse = await sendHttpRequest({
      url: currentbillURL(pid),
      method: "GET",
    });
    if (!httpResponse?.error) {
      if (httpResponse.data !== undefined && !isEmpty(httpResponse.data)) {
        setServicePaymentData(httpResponse.data);
        setAppointmentPrice(httpResponse.data?.outstandingBalance);
      }
    }
    setIsCurrentPaymentLoading(false);
  };

  // async function getToken() {
  //   const httpResponse = await sendHttpRequest({
  //     url: getPaymentToken(user?.tenantId),
  //     method: "GET",
  //   });
  //   if (!httpResponse.error) {
  //     setBrainTreeToken(httpResponse.data);
  //   } 
  // }

  const handleChange = (panel) => (event, newExpanded) => {
    // console.log("called: ",newExpanded);
    if (event?.target?.tagName?.toLowerCase() !== "button") {
      if (panel === _str.patientInformation) {
        setPatientInformationAccordion(newExpanded ? panel : false);
      } else if (panel === _str.substanceUse) {
        setSubstanceUseAccordion(newExpanded ? panel : false);
      } else if (panel === _str.chronicConditions) {
        setChronicConditionsAccordion(newExpanded ? panel : false);
      } else if (panel === _str.insurance) {
        setInsuranceAccordion(newExpanded ? panel : false);
      } else if (panel === _str.billing) {
        setIsBillingAccordian(newExpanded ? panel : false);
      } else if (panel === _str.time_service_payment) {
        setIsServicePayment(newExpanded ? panel : false);
      }
    }
  };

  const handleEditPatientModal = () => {
    navigate("/edit-patient", {
      state: {
        patientId: props?.patientId || patientId,
      },
    });
    // setEditPatientModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };

  const fetchPatientInformation = async (patientId) => {
    props?.setIsPatientNotFound(false);
    setIsProfileLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: "/patient/information",
      url: `/tenants/${user?.tenantId}/patients/${patientId}`,
      method: "GET",
      // params: {
      //   patientId: patientId,
      // },
    });
    if (!httpResponse.error) {
      setPatientProfileData(httpResponse.data);
      const insuranceArray = [];
      httpResponse.data.patientInsurance.forEach((insurance) => {
        if (removeIfAllEmptyFromApiData(insurance) === false) {
          insuranceArray.push(insurance);
        }
      });
      setPatientInsuranceInformation(insuranceArray);
      // patientInsuranceInformation?.filter((insurance) => (insurance?.type?.toLowerCase() === 'primary'))[0] !== undefined
      const primaryInsurance = insuranceArray?.filter(
        (insurance) => insurance?.type?.toLowerCase() === "primary"
      )[0];

      if (primaryInsurance !== undefined && primaryInsurance?.inNetworkCopay !== null) {
        // let copayAmount = parseFloat(primaryInsurance?.inNetworkCopay.replace(/[^0-9.-]+/g, ""));
        // setCopayAmount(copayAmount);
        setBillingAmount(copayAmount);
      }
      const secondaryInsurance = insuranceArray?.filter(
        (insurance) => insurance?.type?.toLowerCase() === "secondary"
      )[0];
      if (primaryInsurance !== undefined) {
        setPrimaryInsuranceInformation(primaryInsurance);
      }
      if (secondaryInsurance !== undefined) {
        setSecondaryInsuranceInformation(secondaryInsurance);
      }
    } else {
      if (httpResponse.status === 404) {
        props?.setIsPatientNotFound(true);
      }
    }
    setIsProfileLoading(false);
  };

  const submitEligibilityCheck = async () => {
    // console.log("called==========================================");
    setEligibilitySummaryLoading(true);
    const eligibilityDto = {
      providerId: providerId,
      patientId: patientId || props?.patientId,
      patientInsuranceType: insuranceType,
      // benifits: [practiceTypeCode],
      practiceTypeCode: practiceTypeCode,
    };
    const httpResponse = await sendHttpRequest({
      method: "POST",
      url: eligibilitySummaryUrl(),
      data: eligibilityDto,
    });
    if (!httpResponse.error) {
      let copayAmount = 0;
      let outstandingValue = parseFloat(
        billingHistoryData.reduce(
          (sum, item) =>
            sum + item?.outstandingBalance,
          0
        )
      ).toFixed(2)
      if (httpResponse.data?.inNetworkCopay !== undefined && billingHistoryData?.length > 0 && Number(outstandingValue) > 0) {
        copayAmount = parseFloat(httpResponse.data?.inNetworkCopay.replace(/[^0-9.-]+/g, ""));
      }
      if (insuranceType?.toLowerCase() === "primary") {
        console.log(httpResponse.data);
        setPrimaryEligibilitySummary({
          checkType: "primary",
          ...httpResponse.data,
        });
        setCopayAmount(copayAmount);
        setBillingAmount(copayAmount);
      } else if (insuranceType?.toLowerCase() === "secondary") {
        setSecondaryEligibilitySummary({
          checkType: "secondary",
          ...httpResponse.data,
        });
        setCopayAmount(copayAmount);
        setBillingAmount(copayAmount);
      }
    } else {
      setPrimaryEligibilitySummary(undefined);
    }
    setEligibilitySummaryLoading(false);
  };

  const handlePrescriberId = (event) => {
    event.preventDefault();
    setProviderId((previousId) => event.target.value);
  };

  const handlePracticeTypeCode = (practiceCode) => {
    console.log("practiceCode?.code: ", practiceCode);
    setPracticeTypeCode((previousId) => practiceCode[0]?.code);
  };

  const handleInsurance = (event) => {
    setInsuranceType(event.target.value);
  };

  const handleEditInsurance = (insuranceType) => {
    navigate("/edit-patient", {
      state: {
        patientId: props?.patientId || patientId,
        insuranceType: insuranceType,
      },
    });
  };

  const getEmergencyRelationship = (relationship) => {
    if (fieldValidation(relationship, "all")) {
      return " (" + relationship + "): ";
    } else {
      return "";
    }
  };

  const getPatientId = () => {
    return props?.patientId ?? patientId;
  };

  const handlePaymentMethod = (event) => {
    event.preventDefault();
    setPaymentType(event.target.value);
  };


  useEffect(() => {
    async function billFunc() {
      // if (patientInsuranceInformation === undefined) {
      await fetchPatientInformation(props?.patientId || patientId);
      // }
      if (prescribersList?.length === 0) {
        await fetchAllPrescribers();
      }
      if (substanceUse === undefined) {
        await fetchSocialHistory(props?.patientId || patientId);
      }
      if (patientBilling === true) {
        await fetchCurrentBill();
        await fetchBillingHistory();
      }
    }

    billFunc();

    setIsPaymentSent(false);

    return () => {
      setPatientInsuranceInformation(null);
      setPatientProfileData(null);
      setPrimaryEligibilitySummary(null);
      setSecondaryEligibilitySummary(null);
      setPrimaryInsuranceInformation(null);
      setSecondaryInsuranceInformation(null);
      setSubstanceUse(null);
      setCopayAmount(0);
    };
  }, [props?.patientId, patientId, patientBilling, props.isAppointmentScheduled]);

  useEffect(() => {
    if (practiceCodeTypeList?.list?.length > 0 && practiceCodeValue === undefined) {
      const practiceValue = practiceCodeTypeList?.list?.find(
        (s) => s.code === Number(user?.tenant?.practiceTypeCode)
      );
      if (practiceValue !== undefined) {
        setPracticeCodeValue(`${practiceValue?.code} - ${practiceValue?.name}`);
      }
    }
  }, [practiceCodeTypeList?.list, practiceCodeValue, user?.tenant?.practiceTypeCode]);



  return (
    <>
      <div
        className="row mt-3 justify-content-center "
        style={{ marginBottom: "-20px" }}
      >
        <div className="col-sm-11 text-end "></div>
      </div>
      <div className="row  justify-content-center">

        <div
          className="col-sm-11 mt-4  bg-white br-10 border--default"
          style={{ border: "1px solid #D9E8EC" }}
        >

          {isProfileLoading ? (
            <Skeleton animation="wave" variant="rectangle" width="100%" />
          ) : (
            <>

              <Accordion
                expanded={
                  patientInformationAccordion === _str.patientInformation
                }
                onChange={handleChange(_str.patientInformation)}
              >
                <AccordionSummary
                  aria-controls="patientInformation-content"
                  id="patientInformation-header"
                >
                  <h2 className="font-18 fw-sb m-0">
                    Patient Information
                    <button
                      className={`btn br-10 shadow-none text-decoration-underline  fw-sb text--blue font-14 ${isProfileLoading ? "disabled" : ""
                        } `}
                      onClick={handleEditPatientModal}
                      hidden={subscriptionPrivilegeGroups['Patients']?.epi.active === false ? true : false}
                    >
                      Edit
                    </button>
                  </h2>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={4}>
                    <Grid item>
                      <Stack>
                        <label className="text--secondary font-12 fw-sb">
                          Date of Birth
                        </label>
                        <p className="m-0 text--terciary font-14">
                          {/* January 02, 1992 */}
                          {dateOfBirth !== undefined &&
                            dateOfBirth !== "" &&
                            changeDateTimeFormat(dateOfBirth, "MMM D, YYYY")}

                          {/* {console.log("dateOfBirth",dateOfBirth)} */}
                        </p>
                      </Stack>
                    </Grid>

                    <Grid item>
                      <Stack>
                        <label className="text--secondary font-12 fw-sb">
                          Age
                        </label>
                        <p className="m-0 text--terciary font-14">
                          {age !== undefined && age !== "" && age}
                        </p>
                      </Stack>
                    </Grid>

                    <Grid item>
                      <Stack>
                        <label className="text--secondary font-12 fw-sb">
                          Biological Sex
                        </label>
                        <p className="m-0 text--terciary font-14">
                          {gender !== undefined && gender !== "" && gender}
                        </p>
                      </Stack>
                    </Grid>

                    <Grid item>
                      <Stack>
                        <label className="text--secondary font-12 fw-sb">
                          Address
                        </label>
                        <p className="m-0 text--terciary font-14">
                          {/* Lavender Dr. 5817 Corpus Christi, TX */}
                          {/* {address!==undefined && address!=='' && address  } */}
                          {patientProfileData?.address[0]?.addressLine1}{" "}
                          {patientProfileData?.address[0]?.addressLine2 !==
                            undefined &&
                            patientProfileData?.address[0]?.addressLine2?.length >
                            0
                            ? patientProfileData?.address[0]?.addressLine2 + " "
                            : ""}
                          {patientProfileData?.address[0]?.city?.trim()}
                          {patientProfileData?.address[0]?.city !== undefined &&
                            ", "}
                          {patientProfileData?.address[0]?.stateProvince}{" "}
                          {patientProfileData?.address[0]?.postalCode}
                        </p>
                      </Stack>
                    </Grid>

                    <Grid item>
                      <Stack>
                        <label className="text--secondary font-12 fw-sb">
                          Primary Phone
                        </label>
                        <p className="m-0 text--terciary font-14">
                          {primaryTelephone !== undefined &&
                            primaryTelephone !== "" &&
                            formatPhoneNumber(primaryTelephone) !== null
                            ? formatPhoneNumber(primaryTelephone)
                            : ""}
                        </p>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack>
                        <label className="text--secondary font-12 fw-sb">
                          Email
                        </label>
                        <p className="m-0 text--terciary font-14">
                          {patientEmail !== undefined ? patientEmail : ""}
                        </p>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack>
                        <label className="text--secondary font-12 fw-sb">
                          Emergency Contact
                        </label>
                        <p className="m-0 text--terciary font-14">
                          {emergencyTelephone?.phoneNumber !== undefined &&
                            emergencyTelephone?.phoneNumber !== "" &&
                            formatPhoneNumber(emergencyTelephone?.phoneNumber) !==
                            null
                            ? "".concat(
                              emergencyTelephone?.name || "",
                              getEmergencyRelationship(
                                emergencyTelephone?.relationship
                              ),
                              formatPhoneNumber(
                                emergencyTelephone?.phoneNumber
                              )
                            )
                            : ""}
                        </p>
                      </Stack>
                    </Grid>
                    {featureFlags.get("surescript") === "true" && (
                      <Grid item>
                        <Stack>
                          <label className="text--secondary font-12 fw-sb">
                            Preferred Pharmacy
                          </label>
                          <Grid container spacing={5}>
                            <Grid item>
                              <Stack>
                                {patientProfileData?.preferredPharmacy
                                  ?.standardizedOrganizationName?.length > 0 ? (
                                  <span className="m-0 text--terciary font-14">
                                    {/* {console.log("In order pharmcy")} */}
                                    {
                                      patientProfileData?.preferredPharmacy
                                        ?.standardizedOrganizationName
                                    }
                                    <br />{" "}
                                    {"".concat(
                                      patientProfileData?.preferredPharmacy
                                        ?.standardizedAddressLine1,
                                      ", ",
                                      patientProfileData?.preferredPharmacy
                                        ?.standardizedAddressLine2?.length > 0
                                        ? patientProfileData?.preferredPharmacy
                                          ?.standardizedAddressLine2 + ", "
                                        : "",
                                      patientProfileData?.preferredPharmacy
                                        ?.standardizedCity || "",
                                      ", ",
                                      patientProfileData?.preferredPharmacy
                                        ?.stateProvince || "",
                                      " ",
                                      formatPostalCode(
                                        patientProfileData?.preferredPharmacy
                                          ?.postalCode
                                      ) || ""
                                    )}{" "}
                                    <br />{" "}
                                    {"Phone: " +
                                      formatPhoneNumber(
                                        patientProfileData?.preferredPharmacy
                                          ?.primaryTelephone
                                      )}
                                    &nbsp;&nbsp;
                                    {"Fax: " +
                                      formatPhoneNumber(
                                        patientProfileData?.preferredPharmacy
                                          ?.fax
                                      )}
                                    <br />
                                    {/* {"Specialty:"}{" "}
                                    {pharmacySpecialty(
                                      patientProfileData?.preferredPharmacy
                                        ?.speciality
                                    )} */}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>

              {/* Substance Use accordion */}
              <Accordion
                expanded={substanceUseAccordion === _str.substanceUse}
                onChange={handleChange(_str.substanceUse)}
              >
                <AccordionSummary
                  aria-controls={`${_str.substanceUse} content`}
                  id={`${_str.substanceUse}-header`}
                >
                  <h2 className="font-18 fw-sb ">{_str.substanceUse}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  {isSocialHistoryLoading === true ? (
                    <Grid container spacing={5}>
                      <Grid item>
                        <Skeleton animation="wave" style={{ width: "100%" }} />
                      </Grid>
                    </Grid>
                  ) : (
                    substanceUse !== undefined &&
                    substanceUse?.length > 0 && (
                      <div className="row">
                        <>
                          <div className="col-4">
                            {substanceUse?.map((questionItem, index) => {
                              if (index === 0) {
                                return (
                                  <div className="" key={index + 1}>
                                    <label className="font-12 text--secondary fw-sb">
                                      {questionItem?.Question}
                                    </label>
                                    <div
                                      className={
                                        questionItem?.Answer?.length > 0
                                          ? "font-14"
                                          : "mt-4 font-14"
                                      }
                                    >
                                      {questionItem?.Answer !== undefined &&
                                        questionItem?.Answer !== "" &&
                                        questionItem?.Answer?.length > 0
                                        ? questionItem?.Answer[0]
                                        : ""}
                                    </div>
                                  </div>
                                );
                              }
                              if (
                                questionItem?.Question == "Recreational Drugs"
                              ) {
                                return (
                                  <div className="" key={index + 1}>
                                    <label className="font-12 text--secondary fw-sb">
                                      {questionItem?.Question}
                                    </label>
                                    <div
                                      className={
                                        questionItem?.Answer?.length > 0
                                          ? "font-14"
                                          : "mt-2 font-14"
                                      }
                                    >
                                      {questionItem?.Answer !== undefined &&
                                        questionItem?.Answer?.length > 0
                                        ? questionItem?.Answer?.map(
                                          (rdrug, index) => {
                                            if (
                                              index !==
                                              questionItem?.Answer?.length - 1
                                            ) {
                                              return rdrug + ", ";
                                            } else {
                                              return rdrug;
                                            }
                                          }
                                        )
                                        : ""}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                          <div className="col-6">
                            {substanceUse.map((questionItem, index) => {
                              if (index == 1) {
                                return (
                                  <div className="" key={index + 2}>
                                    <label className="font-12 text--secondary fw-sb">
                                      {questionItem?.Question}
                                    </label>
                                    <div
                                      className={
                                        questionItem?.Answer?.length > 0
                                          ? "font-14"
                                          : "mt-2 font-14"
                                      }
                                    >
                                      {questionItem?.Answer !== undefined &&
                                        questionItem?.Answer?.length > 0
                                        ? questionItem?.Answer?.map(
                                          (rdrug, index) => {
                                            if (
                                              index !==
                                              questionItem?.Answer?.length - 1
                                            ) {
                                              return rdrug + ", ";
                                            } else {
                                              return rdrug;
                                            }
                                          }
                                        )
                                        : ""}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </>
                      </div>
                    )
                  )}
                </AccordionDetails>
              </Accordion>

              {/* Chronic conditions Accordion */}
              <Accordion
                expanded={chronicConditionsAccordion === _str.chronicConditions}
                onChange={handleChange(_str.chronicConditions)}
              >
                <AccordionSummary
                  aria-controls={`${_str.chronicConditions}-content`}
                  id={`${_str.chronicConditions}-header`}
                >
                  <h2 className="font-18 fw-sb m-0">
                    {_str.chronicConditions}
                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={5}>
                    <Grid item>
                      <p className="m-0 text--terciary font-16">
                        {/* Depression, anxiety */}
                        {chronoicConditions !== undefined &&
                          chronoicConditions !== "" &&
                          chronoicConditions}
                      </p>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              {/* Insurances */}
              <Accordion
                expanded={insuranceAccordion === _str.insurance}
                onChange={handleChange(_str.insurance)}
              >
                <AccordionSummary
                  aria-controls="insurance-content"
                  id="insurance-header"
                >
                  <h2 className="font-18 fw-sb m-0">{_str.insurance}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Primary insurance */}
                  {primaryInsuranceInformation !== null &&
                    primaryInsuranceInformation !== undefined ? (
                    <InsuranceEligibilityDetails
                      _str={_str}
                      _commonStr={_commonStr}
                      checkType={"primary"}
                      insuranceType={"Primary Insurance"}
                      insuranceInformation={primaryInsuranceInformation}
                      eligibilitySummary={primaryEligibilitySummary}
                      handleEditInsurance={handleEditInsurance}
                    ></InsuranceEligibilityDetails>
                  ) : null}

                  {/* Secondary Insurance */}
                  {secondaryInsuranceInformation !== undefined &&
                    secondaryInsuranceInformation !== null ? (
                    <InsuranceEligibilityDetails
                      _str={_str}
                      _commonStr={_commonStr}
                      checkType={"secondary"}
                      insuranceType={"Secondary Insurance"}
                      insuranceInformation={secondaryInsuranceInformation}
                      eligibilitySummary={secondaryEligibilitySummary}
                      handleEditInsurance={handleEditInsurance}
                    ></InsuranceEligibilityDetails>
                  ) : null}

                  {/* Insurance Check */}
                  {patientInsuranceInformation?.length > 0 ? (
                    <div
                      className="container-fluid g-0 py-2"
                      hidden={subscriptionPrivilegeGroups['Eligibility Checks/ Claims/ Patient Invoices']?.rec.active === false ? true : false}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-12">
                          <span className="font-14 text--terciary fw-sb">
                            {_str.insuranceCheck}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`${classes["insurance-check-container"]} br-10 row g-0 px-3  py-3`}
                      >
                        <div className="col-12 col-sm-12 ">
                          <form
                            id="pVerifyForm"
                            onSubmit={(e) => {
                              e.preventDefault();
                              submitEligibilityCheck();
                            }}
                          >
                            <div className="row ">
                              <div className="col-sm-3">
                                <div className="form-group ">
                                  <label className="form-label font-10 text--secondary">
                                    {_str.selectInsurance}
                                  </label>

                                  <select
                                    className={`input border--default br-10 form-select font-12 shadow-none ${classes["custom-select"]}`}
                                    aria-label="Insurance select"
                                    required
                                    form="pVerifyForm"
                                    onChange={(e) => {
                                      handleInsurance(e);
                                    }}
                                    onInvalid={(e) => {
                                      e.target.setCustomValidity(
                                        "Please select insurance from the list"
                                      );
                                    }}
                                    onInput={(e) => {
                                      e.target.setCustomValidity("");
                                    }}
                                  >
                                    <option value="">
                                      Select Insurance Type
                                    </option>
                                    {primaryInsuranceInformation !==
                                      undefined ? (
                                      <option selected value="Primary">
                                        {_str.primaryInsurance}
                                      </option>
                                    ) : null}
                                    {secondaryInsuranceInformation !==
                                      undefined ? (
                                      <option value="Secondary">
                                        {_str.secondaryInsurance}
                                      </option>
                                    ) : null}
                                  </select>
                                </div>
                              </div>


                              <div className="col-md-3 mt-2">
                                <label
                                  htmlFor="State"
                                  className="form-label fw-sb m-0 font-12 text--secondary"
                                >
                                  Select Practice Type
                                </label>
                                {/* {console.log("practiceCodeTypeList: ",practiceCodeTypeList)} */}
                                {practiceCodeTypeList?.list?.length > 0 && (
                                  <Typeahead
                                    id="practiceTypeCode"
                                    name="practiceTypeCode"
                                    labelKey={(option) =>
                                      `${option.code} - ${option.name}`
                                    }
                                    onChange={handlePracticeTypeCode}
                                    options={practiceCodeTypeList?.list ?? []}
                                    className="rbt-menu my-select font-12"
                                    // selected={tenantSettingsData?.state !== undefined ? options.find(s => s.code === tenantSettingsData?.state)?.code : null}
                                    defaultInputValue={practiceCodeValue ?? ""}
                                    style={{ borderRadius: "10px" }}
                                    autoComplete="off"
                                    inputProps={{ className: "font-14" }}
                                  />
                                )}
                              </div>

                              <div className="col-sm-3">
                                <div className="form-group ">
                                  <label className="form-label font-10 text--secondary">
                                    {_commonStr.physician}
                                  </label>
                                  {isPrescriberLoading ? (
                                    <Skeleton />
                                  ) : (
                                    <select
                                      className={`input border--default br-10 form-select font-12 shadow-none ${classes["custom-select"]}`}
                                      aria-label="Provider select"
                                      required
                                      form="pVerifyForm"
                                      onChange={(e) => {
                                        handlePrescriberId(e);
                                      }}
                                      onInvalid={(e) => {
                                        e.target.setCustomValidity(
                                          "Please select provider from the list"
                                        );
                                      }}
                                      onInput={(e) => {
                                        e.target.setCustomValidity("");
                                      }}
                                    >
                                      <option value="" disabled selected hidden>
                                        Select physician
                                      </option>
                                      {prescribersList?.length > 0
                                        ? prescribersList?.map(
                                          (prescriberItem) => {
                                            return (
                                              <option
                                                value={
                                                  prescriberItem?.userId ?? ""
                                                }
                                                key={
                                                  prescriberItem?.userId ?? ""
                                                }
                                              >
                                                {
                                                  prescriberItem?.name[0]
                                                    ?.prefix
                                                }{" "}
                                                {
                                                  prescriberItem?.name[0]
                                                    ?.firstName
                                                }{" "}
                                                {
                                                  prescriberItem?.name[0]
                                                    ?.lastName
                                                }{" "}
                                                {RolesTextConversion(
                                                  prescriberItem?.role
                                                ) !== undefined
                                                  ? "(" +
                                                  RolesTextConversion(
                                                    prescriberItem?.role
                                                  ) +
                                                  ")"
                                                  : ""}
                                              </option>
                                            );
                                          }
                                        )
                                        : "No prescribers available"}
                                    </select>
                                  )}
                                </div>
                              </div>

                              <div className="col-sm-3 text-end align-self-end">
                                <div>
                                  <button
                                    disabled={eligbilitySummaryLoading}
                                    style={{ width: "160px" }}
                                    form="pVerifyForm"
                                    className="btn text--blue bg--d9e8ec br-10 font-14 fw-sb"
                                  >
                                    {_commonStr.check}{" "}
                                    {eligbilitySummaryLoading && (
                                      <CircularProgress
                                        className="align-self-center "
                                        size={24}
                                        sx={{
                                          color: "#EC609B",
                                          position: "absolute",
                                          marginLeft: -5,
                                        }}
                                      />
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </AccordionDetails>
              </Accordion>

              {/* Billing Details */}
              <Accordion
                expanded={isBillingAccordian === _str.billing}
                onChange={handleChange(_str.billing)}
                hidden={subscriptionPrivilegeGroups[PAYMENTS] === undefined ? true : !patientBilling}
              >
                <AccordionSummary
                  aria-controls="billing-content"
                  id="billing-header"
                >
                  <h2 className="font-18 fw-sb m-0">{_str.billing}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    {isBillinghistoryLoading ? (
                      <Skeleton />
                    ) : (
                      billingHistoryData?.length > 0 && (props?.patientId ?? patientId) && (
                        <>
                          <div className="col-12">
                            <PatientBillingTable
                              columnHeadersList={[
                                { key: "createdDate", value: "Date" },
                                {
                                  key: "providerTenantPracticeLocation.provider.name.firstName",
                                  value: "Provider",
                                },
                                {
                                  key: "Place of Service",
                                  value: "Place of Service",
                                },
                                {
                                  key: "serviceDescription ",
                                  value: "Service Description",
                                },
                                { key: "paymentType ", value: "Payment Type" },
                                {
                                  key: "amountBilled ",
                                  value: "Amount Billed",
                                },
                                {
                                  key: "paymentStatus",
                                  value: "Payment Status",
                                },
                                { key: "outstandingBalance", value: "Outstanding" },
                                { key: " ", value: " " },
                              ]}
                              data={billingHistoryData}
                              patientFilter={false}
                              patientEmail={patientEmail}
                              patientId={props?.patientId ?? patientId}
                              setBillingAmount={setBillingAmount}
                              setIsBillingAccordian={setIsBillingAccordian}
                              setBillingProvider={setBillingProvider}
                              setAppointmentId={setAppointmentId}

                            />
                          </div>
                          <div className="col-12 text-end mt-2">
                            <p className="text--terciary font-12 p-0 m-0">
                              {"Amount Due: "}{" "}
                              <span className="fw-sb">
                                {"$".concat(
                                  parseFloat(
                                    billingHistoryData.reduce(
                                      (sum, item) =>
                                        sum + item?.outstandingBalance,
                                      0
                                    )
                                  ).toFixed(2)
                                )}
                              </span>
                            </p>
                          </div>
                        </>
                      )
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* Time of Service Payment */}
              <Accordion
                expanded={isServicePayment === _str.time_service_payment}
                onChange={handleChange(_str.time_service_payment)}
              // hidden={!patientBilling || hideToSP}
                hidden={subscriptionPrivilegeGroups[PAYMENTS] === undefined ? true :false}
              >
                <AccordionSummary
                  aria-controls="service-content"
                  id="service-header"
                >
                  <h2 className="font-18 fw-sb m-0">
                    {_str.time_service_payment}
                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    {isBillinghistoryLoading === true && billingHistoryData !== undefined ? (
                      <Skeleton />

                    ) : (
                      // servicePaymentData !== undefined && (
                      <>
                        <div className="col-3">
                          <label
                            htmlFor="title"
                            className="form-label font-12 text--secondary m-0 fw-sb"
                          >
                            {"Payment Method"}
                          </label>
                          <select
                            className={`form-select br-10 border--default font-12 shadow-none fst-italic`}
                            aria-label="action select"
                            style={{
                              height: "36px",
                            }}
                            onChange={(e) => {
                              handlePaymentMethod(e);
                            }}
                            value={paymentType}
                          >
                            {paymentMethodsList.map((item) => {
                              return (
                                <option
                                  key={item.id}
                                  id={item.id}
                                  name={item.name}
                                  value={item.value}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-9"></div>
                        <div className="col-6 mt-2">
                          {paymentType === "cash" ? (
                            <PaymentCashForm
                              setIsPaymentSent={setIsPaymentSent}
                              isPaymentSent={isPaymentSent}
                              amountValue={billingAmount}
                              prescribersList={prescribersList}
                              userId={pId}
                              fetchBillingHistory={fetchBillingHistory}
                              handleReceipt={handleReceipt}
                              isPrinting={isPrinting}
                              fetchCurrentBill={fetchCurrentBill}
                              billingProvider={billingProvider}
                              isCopPay={copayAmount === undefined ? false : true}
                            />
                          ) : paymentType === "check" ? (
                            // <PaymentTypeCheckNumber
                            //   setIsPaymentSent={setIsPaymentSent}
                            //   isPaymentSent={isPaymentSent}
                            //   amountValue={billingAmount}
                            //   prescribersList={prescribersList}
                            //   userId={props?.patientId ?? patientId}
                            //   fetchBillingHistory={fetchBillingHistory}
                            //   handleReceipt={handleReceipt}
                            //   isPrinting={isPrinting}
                            //   fetchCurrentBill={fetchCurrentBill}
                            //   billingProvider={billingProvider}
                            // />
                            <></>
                          ) : paymentType === "creditCard" ? (
                            // <PaymentTypeCreditCard
                            //   setIsPaymentSent={setIsPaymentSent}
                            //   isPaymentSent={isPaymentSe8nt}
                            //   amountValue={billingAmount}
                            //   prescribersList={prescribersList}
                            //   userId={props?.patientId ?? patientId}
                            //   fetchBillingHistory={fetchBillingHistory}
                            //   handleReceipt={handleReceipt}
                            //   isPrinting={isPrinting}
                            //   fetchCurrentBill={fetchCurrentBill}
                            // />
                            <PaymentForm
                              setIsPaymentSent={setIsPaymentSent}
                              isPaymentSent={isPaymentSent}
                              amountValue={billingAmount}
                              prescribersList={prescribersList}
                              userId={props?.patientId ?? patientId}
                              fetchBillingHistory={fetchBillingHistory}
                              handleReceipt={handleReceipt}
                              isPrinting={isPrinting}
                              fetchCurrentBill={fetchCurrentBill}
                              billingProvider={billingProvider}
                              isCopPay={copayAmount === undefined ? false : true}
                            />
                          ) : paymentType === "payPal" ? (
                            <PayPalForm
                              setIsPaymentSent={setIsPaymentSent}
                              isPaymentSent={isPaymentSent}
                              amountValue={billingAmount}
                              prescribersList={prescribersList}
                              userId={props?.patientId ?? patientId}
                              fetchBillingHistory={fetchBillingHistory}
                              handleReceipt={handleReceipt}
                              isPrinting={isPrinting}
                              fetchCurrentBill={fetchCurrentBill}
                              billingProvider={billingProvider}
                              isCopPay={copayAmount === undefined ? false : true}
                            />
                          ) : paymentType === "googlePay" ? (
                            <GooglePayButton
                              setIsPaymentSent={setIsPaymentSent}
                              isPaymentSent={isPaymentSent}
                              amountValue={billingAmount}
                              prescribersList={prescribersList}
                              userId={props?.patientId ?? patientId}
                              fetchBillingHistory={fetchBillingHistory}
                              fetchCurrentBill={fetchCurrentBill}

                              patientPortal={false}
                              billingProvider={billingProvider}
                              appointmentId={appointmentId}
                              isCopPay={copayAmount !== undefined ? true : false}
                              isPatientPayment={true}
                            />
                          ) : paymentType === "applePay" ? (
                            <ApplePay
                              setIsPaymentSent={setIsPaymentSent}
                              isPaymentSent={isPaymentSent}
                              amountValue={billingAmount}
                              prescribersList={prescribersList}
                              userId={props?.patientId ?? patientId}
                              fetchBillingHistory={fetchBillingHistory}
                              fetchCurrentBill={fetchCurrentBill}
                              isCopPay={copayAmount === undefined ? false : true}
                            />
                          )
                            : null}
                        </div>
                      </>
                      // )
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
      </div>
      <CustomizedSnackbars
        message={"Patient updated successfully."}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />

      {/* {console.log("billingHistoryData: ",billingHistoryData)} */}
      {/* {console.log("billingAmount: ",billingAmount)} */}
      {/* {console.log("paymentType: ",paymentType)} */}
      {/* {console.log("isProfileLoading: ",isProfileLoading)} */}
    </>
  );
};

export default ViewPatientProfileEncounter;
