import { useEffect, useState } from "react";
import BadgeButtons from "../billing-specialist-dashboard/BadgeButtons";
import BillingTileContainer from "../billing-specialist-dashboard/BillingTileContainer";
import { useAuth } from "../hooks/useAuth";
import sendHttpRequest, { sendHttpRequestWithCancellation } from "../hooks/sendHttpRequest";
import { Skeleton } from "@mui/material";
import AppointmentPieChart from "./AppointmentPieChart";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";
import AppointmentBarChart from "./AppointmentBarChart";
import ClaimStatusCard from "../billing-specialist-dashboard/ClaimStatusCard";
import { useNavigate } from "react-router-dom";
import EncounterPieChart from "./EncounterPieChart";
import PatientDataLineChart from "./PatientDataLineChart";
import PrescriptionBarChart from "./PrescriptionBarChart";
import { BILLING_AND_CLAIMS, ENCOUNTERS, PRESCRIPTIONS } from "../../utils/helpers/subscriptionsConstants";
import { useSelector } from "react-redux";
import ClaimPieChart from "../billing-specialist-dashboard/ClaimPieChart";
import ClaimAreaChart from "../billing-specialist-dashboard/ClaimAreaChart";
import ClaimsLineChart from "../billing-specialist-dashboard/ClaimsLineChart";
import ClaimsBarChart from "../billing-specialist-dashboard/ClaimsBarChart";

const PracticeAdminDashboardOverview = () => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  
  const { user } = useAuth();
  const navigate = useNavigate();

  const [fromDateVal, setFromDateVal] = useState();
  const [toDateVal, setToDateVal] = useState();

  const [appointmentStatus, setAppointmentStatus] = useState("all");
  const [selectedProviderId, setSelectedProviderId] = useState(0);

  const [appointmentChartData, setAppointmentChartData] = useState();
  const [isAppointmentDataLoading, setIsAppointmentDataLoading] = useState(false);

  const [encounterChartData, setEncounterChartData] = useState();
  const [isEncounterDataLoading, setIsEncounterDataLoading] = useState(false);
  
  const [patientsChartData, setPatientsChartData] = useState([]);
  const [isPatientsDataLoading, setIsPatientsDataLoading] = useState(false);

  const [prescriptionsChartData, setPrescriptionsChartData] = useState([]);
  const [isPrescriptionsDataLoading, setIsPrescriptionsDataLoading] = useState(false);

  // Claims stats
  const [claimsData, setClaimsData] = useState([]);
  const [claimsStats, setClaimsStats] = useState();
  const [isClaimDataLoading, setIsClaimDataLoading] = useState(false);
  

  function calculateClaimPercentage(claimTypeCount, totalClaims) {
    if (totalClaims === 0) {
      return 0; // Avoid division by zero
    }

    const percentage = (claimTypeCount / totalClaims) * 100;
    return Math.floor(percentage); // Round down using Math.floor
  }

  // Function to count claims based on their status
  const processClaimsData = (data) => {
    const statusCounts = {};
    let totalCount = 0;

    // Loop through the data array to count claim statuses
    for (let index = 0; index < data?.length; index++) {
      const element = data[index]; // Correctly accessing the element
      const status = element.status;

      // if (status !== "CLAIM_READY_FOR_SUBMISSION") {
      // Increment the count for each claim status
      if (status in statusCounts) {
        statusCounts[status] += 1;
      } else {
        statusCounts[status] = 1;
      }

      // Increment total count of claims
      totalCount += 1;
      // }
    }

    // Add total count to the statistics
    statusCounts.totalCount = totalCount;

    // Set the state with the updated counts
    setClaimsStats(statusCounts);
  };
  const fetchTenantClaims = async () => {
    setIsClaimDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user?.tenantId}/insurance-claims`,
      method: "GET",
      params: {
        ...(fromDateVal?.length > 0 && { fromDate: fromDateVal }),
        ...(toDateVal?.length > 0 && { toDate: toDateVal }),
      },
    });

    if (!httpResponse.error) {
      setClaimsData(httpResponse.data?.content ?? []);
      processClaimsData(httpResponse.data?.content ?? []);
    } else {
      setClaimsData([]);
      processClaimsData(undefined);
    }
    setIsClaimDataLoading(false);
  };

  const fetchStats = async (type,fromDateVal, toDateVal,setIsLoading,setState)=> {
    setIsLoading(true);
    
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: `/tenants/${user?.tenantId}/${type}/summary`,
      params:{
        ...(fromDateVal?.length > 0 && {from:fromDateVal}),
        ...(toDateVal?.length > 0 && {to:toDateVal}),
      }
    });
    if (!httpResponse.error) {
      
      setState(httpResponse.data);
    }
    setIsLoading(false);
  }
  async function fetchAppointmentsStats(fromDateVal, toDateVal) {
    setIsEncounterDataLoading(true);
    
    const httpResponse = await sendHttpRequestWithCancellation({
      method: "GET",
      url: `/tenants/${user?.tenantId}/encounters/summary`,
      
    });
    if (!httpResponse.error) {
      
      setEncounterChartData(httpResponse.data);
    }
    setIsEncounterDataLoading(false);
  }
  async function fetchAppointmentsStats(fromDateVal, toDateVal) {
    setIsAppointmentDataLoading(true);
    
    const httpResponse = await sendHttpRequestWithCancellation({
      method: "GET",
      url: `/tenants/${user?.tenantId}/appointments/summary?from=${fromDateVal}&to=${toDateVal}`,
    });
    if (!httpResponse.error) {
      
      setAppointmentChartData(httpResponse.data);
    }
    setIsAppointmentDataLoading(false);
  }

  function navigateToClaimsPage(routeVal, status) {
    navigate(routeVal, { state: { startDate: fromDateVal, endDate:toDateVal } });
  }
  function handleNavigaition(routeVal, status) {
    navigate(routeVal, { state: { claimType: status } });
  }

  

  useEffect(() => {
    const currentDate = new Date(); // Current date
    let calculatedStartDate = new Date(
      currentDate.setMonth(currentDate.getMonth() - 1)
    );
    setFromDateVal(calenderDateTimeHelper(calculatedStartDate, "YYYY-MM-DD"));
    setToDateVal(calenderDateTimeHelper(new Date(), "YYYY-MM-DD"));
    
    let fromDate = calenderDateTimeHelper(calculatedStartDate, "YYYY-MM-DD");
    let toDate = calenderDateTimeHelper(new Date(), "YYYY-MM-DD");
    fetchStats("appointments",fromDate,toDate,setIsAppointmentDataLoading,setAppointmentChartData);
    fetchStats("encounters",fromDate,toDate,setIsEncounterDataLoading,setEncounterChartData);
    fetchStats("patients",fromDate,toDate,setIsPatientsDataLoading,setPatientsChartData);
    fetchStats("prescriptions",fromDate,toDate,setIsPrescriptionsDataLoading,setPrescriptionsChartData);
    if(subscriptionPrivilegeGroups[BILLING_AND_CLAIMS] !== undefined){
      fetchTenantClaims();
    }
  }, []);
  useEffect(() => {
    if(fromDateVal?.length > 0 || toDateVal?.length > 0){
      if(subscriptionPrivilegeGroups[BILLING_AND_CLAIMS] !== undefined){
        fetchTenantClaims();
      }
      fetchStats("appointments",fromDateVal,toDateVal,setIsAppointmentDataLoading,setAppointmentChartData);
      fetchStats("encounters",fromDateVal,toDateVal,setIsEncounterDataLoading,setEncounterChartData);
      fetchStats("patients",fromDateVal, toDateVal,setIsPatientsDataLoading,setPatientsChartData);
      fetchStats("prescriptions",fromDateVal, toDateVal,setIsPrescriptionsDataLoading,setPrescriptionsChartData);
    }
  }, [fromDateVal, toDateVal]);

  return (
    <>
      <div className="container mb-3">
        <BadgeButtons
          setFromDateVal={setFromDateVal}
          setToDateVal={setToDateVal}
          borderTop={true}
          practiceAdmin = {true}
        />
        <div className="row py-2">
          {/* <div id="pn-provider-greeting" className="col-3 col-md-3">
            <GreetingMessage />
          </div> */}
          <div className="col-3" hidden={subscriptionPrivilegeGroups[BILLING_AND_CLAIMS] !== undefined}></div>
          {
            subscriptionPrivilegeGroups[BILLING_AND_CLAIMS] !== undefined ? 
            <>
              <div className="col-3 col-md-3 ">
                <BillingTileContainer key={"ready-claims"}>
                  {isClaimDataLoading ? (
                    <Skeleton />
                  ) : (
                    <div
                      className="cursor--pointer"
                      style={{ height: "6rem" }}
                      onClick={() =>
                        handleNavigaition(
                          "/claims/ready-for-submission",
                          "CLAIM_READY_FOR_SUBMISSION"
                        )
                      }
                    >
                      <ClaimStatusCard
                        badgeClass="badge-in-progress"
                        infoColor="text--secondary"
                        claimText={"In-process Claim Rate"}
                        claimPercentage={calculateClaimPercentage(
                          claimsStats?.CLAIM_READY_FOR_SUBMISSION,
                          claimsStats?.totalCount
                        )}
                        noOfClaims={
                          claimsStats !== undefined
                            ? claimsStats?.CLAIM_READY_FOR_SUBMISSION
                            : 0
                        }
                      />
                    </div>
                  )}
                </BillingTileContainer>
              </div>
              <div className="col-3 col-md-3 ">
                <BillingTileContainer key={"approved-claims"}>
                  {isClaimDataLoading ? (
                    <Skeleton />
                  ) : (
                    <div
                      className="cursor--pointer"
                      style={{ height: "6rem" }}
                      onClick={() =>
                        handleNavigaition("/claims/approved", "CLAIM_APPROVED")
                      }
                    >
                      <ClaimStatusCard
                        badgeClass="badge-success"
                        infoColor="text--green"
                        claimText={"Successful Claim Rate"}
                        claimPercentage={calculateClaimPercentage(
                          claimsStats?.CLAIM_APPROVED,
                          claimsStats?.totalCount
                        )}
                        noOfClaims={
                          claimsStats !== undefined
                            ? claimsStats?.CLAIM_APPROVED
                            : 0
                        }
                      />
                    </div>
                  )}
                </BillingTileContainer>
              </div>
              <div className="col-3 col-md-3 ">
                <BillingTileContainer
                  style={{ backgroundColor: "white" }}
                  key={"pending-claims"}
                >
                  {isClaimDataLoading ? (
                    <Skeleton />
                  ) : (
                    <div
                      className="text-white cursor--pointer"
                      style={{ height: "6rem" }}
                      onClick={() => {
                        handleNavigaition("/claims/pending", "CLAIM_SUBMITTED");
                      }}
                    >
                      <ClaimStatusCard
                        badgeClass="badge-pending"
                        infoColor="text--warning"
                        claimText={"Pending Claim Rate"}
                        claimPercentage={calculateClaimPercentage(
                          claimsStats?.CLAIM_SUBMITTED,
                          claimsStats?.totalCount
                        )}
                        noOfClaims={
                          claimsStats !== undefined
                            ? claimsStats?.CLAIM_SUBMITTED
                            : 0
                        }
                      />
                    </div>
                  )}
                </BillingTileContainer>
              </div>
            </>: 
            <>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </>
          }
          <div className="col-3 col-md-3 ">
            <BillingTileContainer key={"upcoming-appointments"}>
              {
                isAppointmentDataLoading ? <Skeleton/>:
                <div
                  className="cursor--pointer"
                  style={{ height: "6rem" }}
                  onClick={() => navigateToClaimsPage("/inpatient-dashboard", "")
                  }
                >
                  <ClaimStatusCard
                    badgeClass="badge-success"
                    infoColor="text--green"
                    claimText={"Upcoming Appointments"}
                    claimPercentage={appointmentChartData?.pending}
                    noOfClaims={"2500"}
                    practiceAdmin={true}
                  />
                </div>
              }
            </BillingTileContainer>
          </div>
          <div className="col-3 col-md-3 " hidden={subscriptionPrivilegeGroups[ENCOUNTERS] === undefined ? true:false}>
            
            <BillingTileContainer
              style={{ backgroundColor: "white" }}
              key={"unsigned-encounters"}
            >
              { isEncounterDataLoading ? <Skeleton/> :
                <div
                  className="text-white cursor--pointer"
                  style={{ height: "6rem" }}
                  // onClick={() => {
                  //   // navigateToClaimsPage("/claims/pending", "CLAIM_SUBMITTED");
                  // }}
                >
                  <ClaimStatusCard
                    badgeClass="badge-pending"
                    infoColor="text--warning"
                    claimText={"Unsigned Encounters"}
                    claimPercentage={encounterChartData?.unsigned ?? 0}
                    noOfClaims={"100"}
                    practiceAdmin={true}
                  />
                </div>
              }
            </BillingTileContainer>
          </div>
          <div className="col-3 col-md-3 " hidden={subscriptionPrivilegeGroups[PRESCRIPTIONS] === undefined ? true:false}>
            <BillingTileContainer key={"prescriptions"}>
              {
                isPrescriptionsDataLoading ? <Skeleton/> :
                <div
                  className="text-white  cursor--pointer "
                  // onClick={}
                  style={{ height: "6rem" }}
                >
                  <ClaimStatusCard
                    badgeClass="badge-in-progress"
                    infoColor="text--secondary"
                    claimText={"Prescriptions"}
                    claimPercentage={prescriptionsChartData?.filter(item=>item?.workflowType === "SURESCRIPTS").length}
                    hideNoOdClaims={true}
                    noOfClaims={0}
                    practiceAdmin={true}
                  />
                </div>
              }
            </BillingTileContainer>
          </div>
        </div>
        {subscriptionPrivilegeGroups[BILLING_AND_CLAIMS] !== undefined ? 
          <>
            <div className="row my-3">
              <div className="col-6 ">
                <BillingTileContainer minHeight={"20rem"} key={"pie-chart"}>
                  {isClaimDataLoading ? (
                    <Skeleton />
                  ) : (
                    <div className="d-flex   text-white font-14 text-center fw-sb  align-self-center px-3 py-2">
                      {claimsData?.length > 0 ? (
                        <ClaimPieChart claimsData={claimsData} />
                      ) : (
                        "No data available"
                      )}
                    </div>
                  )}
                </BillingTileContainer>
              </div>
              <div className="col-6 ">
                <BillingTileContainer minHeight={"20rem"} key={"bar-chart"}>
                  {isClaimDataLoading ? (
                    <Skeleton />
                  ) : (
                    <div className=" font-14 text-center fw-sb  align-self-center px-4 py-2">
                      <ClaimsBarChart claimsData={claimsData} />
                    </div>
                  )}
                </BillingTileContainer>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-6 ">
                <BillingTileContainer
                  minHeight={"15rem"}
                  bgColor={"bg--white"}
                  style={{ minHeight: "20rem" }}
                  key={"area-chart"}
                >
                  {isClaimDataLoading ? (
                    <Skeleton />
                  ) : (
                    <div
                      className="text-white font-14 text-center fw-sb  br-10 px-3 py-2"
                      style={{ maxHeight: "20rem" }}
                    >
                      <ClaimAreaChart claimsData={claimsData} />
                    </div>
                  )}
                </BillingTileContainer>
              </div>
              <div className="col-6 ">
                {isClaimDataLoading ? (
                  <Skeleton />
                ) : (
                  <BillingTileContainer minHeight={"20rem"} key={"line-chart"}>
                    <div className="text-white font-14 text-center fw-sb  px-3 py-2">
                      <ClaimsLineChart claimsData={claimsData} />
                    </div>
                  </BillingTileContainer>
                )}
              </div>
            </div>
          </>:null
        }

        <div className="row">
          {/* <div className="col-6 pt-2">
          <BillingTileContainer minHeight={"20rem"} key={"chart-1"}>
            {
                isAppointmentDataLoading ? <Skeleton/>
                :<AppointmentPieChart appointmentData = {appointmentChartData} />
            }
          </BillingTileContainer>
        </div> */}
          <div className="col-6 pt-2" onClick={() => navigateToClaimsPage("/inpatient-dashboard", "")}>
            <BillingTileContainer minHeight={"20rem"} key={"chart-1"}>
              {isAppointmentDataLoading ? (
                <Skeleton />
              ) : (
                <AppointmentBarChart appointmentData={appointmentChartData} />
              )}
            </BillingTileContainer>
          </div>
          <div className="col-6 pt-2" onClick={() => navigateToClaimsPage("/encounters", "")} hidden={subscriptionPrivilegeGroups[ENCOUNTERS] === undefined ? true:false}>
            <BillingTileContainer minHeight={"20rem"} key={"chart-2"}>
              {
                isEncounterDataLoading ? <Skeleton/> :
                <EncounterPieChart encounterData={encounterChartData} />
              }
            </BillingTileContainer>
          </div>
          <div className="col-6 pt-2 cursor--pointer" onClick={() => navigateToClaimsPage("/patients", "")}>
            <BillingTileContainer minHeight={"20rem"} key={"chart-3"}>
              {
                isPatientsDataLoading ? <Skeleton/> :
                <PatientDataLineChart
                  patientData={patientsChartData}
                />
              }
            </BillingTileContainer>
          </div>
          <div className="col-6 pt-2 cursor--pointer" onClick={() => navigateToClaimsPage("/prescriptions", "")} hidden={subscriptionPrivilegeGroups[PRESCRIPTIONS] == undefined ? true:false}>
            <BillingTileContainer minHeight={"20rem"} key={"chart-4"} >
              {
                isPrescriptionsDataLoading ? <Skeleton/> :
              <PrescriptionBarChart prescriptions={prescriptionsChartData}/>
              }
            </BillingTileContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default PracticeAdminDashboardOverview;
