import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useLocation } from "react-router-dom";
import ReportColumModal from "./ReportColumModal";
import PageHeader from "../UI/General/PageHeader";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { PnSelect } from "../UI/PnSelect";
import { Skeleton } from "@mui/material";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
import AllClaimsTable from "../../claim-management-module/src/components/tables/AllClaimsTable";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CSVService from "../Services/CSVService.service";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableSortLabel,
  IconButton,
  Box,
  Collapse,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { truncate } from "../../utils/helpers/HelperFunctions";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import classes from "./ClaimReportingTable.module.css";
import { fetchClaimSumamryURL } from "../../res/endpointurls";

const CLAIM_STATUS_OPTIONS = [
  { value: "CLAIM_READY_FOR_SUBMISSION", label: "Claim Ready for Submission" },
  { value: "CLAIM_SUBMITTED", label: "Claim Submitted" },
  { value: "CLAIM_APPROVED", label: "Claim Approved" },
  { value: "CLAIM_SUBMISSION_ERROR", label: "Claim Submission Error" },
  { value: "CLAIM_DENIED", label: "Claim Denied" },
];

function ClaimReportingTable(props) {
  const { user } = useAuth();
  const location = useLocation();

  const [renderingProvidersList, setRenderingProvidersList] = useState([]);
  const [areRenderingProvidersLoading, setAreRenderingProvidersLoading] =
    useState(false);

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [patientsList, setPatientsList] = useState([]);
  const [arePatientsLoading, setArePatientsLoading] = useState(false);

  const [claims, setClaims] = useState([]);
  const [areClaimsLoading, setAreClaimsLoading] = useState(false);

  const [reloadClaims, setReloadClaims] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [claimNumber, setClaimNumber] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [template, setTemplate] = useState();
  const [templateType, setTemplateType] = useState();

  const [openCSVReportModal, setOpenCSVReportModal] = useState(false);
  const [columnHeadersList, setColumnHeadersList] = useState([]);
  const [claimTableData, setClaimTableData] = useState([]);

  const [claimAmount, setClaimAmount] = useState();
  const [paidAmount, setPaidAmount] = useState();
  const [patientResponsibility, setPatientResponsibility] = useState();
  const [hierarchyTemplateData, setHierarchyTemplateData] = useState([]);

  // Pagination States
  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(undefined);
  const [totalElements,setTotalElements] = useState(0);
  const [downloadCSVFlag,setDownloadCSVFlag] = useState(false);
  const [dataForCSV,setDataForCSV] = useState([]);
  const [claimSummary,setClaimSummary] = useState();
  const [isClaimSummaryLoading,setIsClaimSummaryLoading] = useState(false);


  const fetchClaimSumamry = async (
    tenantId,
    claimNumber,
    provider,
    patient,
    status,
    fromDate,
    toDate,)=>{
    setIsClaimSummaryLoading(true);
    const httpResponse = await sendHttpRequest({
      url:fetchClaimSumamryURL(tenantId),
      method:"GET",
      params:{
        ...(claimNumber !== "" && { claimNumber: claimNumber }),
        providerId: provider?.value,
        patientId: patient?.value,
        claimStatus: status?.value,
        ...(fromDate?.length > 0 && { fromDate: fromDate }),
        ...(toDate?.length > 0 && { toDate: toDate }),
      }
    })
    if(!httpResponse.error){
      setClaimSummary(httpResponse.data);
    }
    setIsClaimSummaryLoading(false);
  }

  const fetchReneringProviders = async () => {
    setAreRenderingProvidersLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/providers`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const renderingProviders = httpResponse.data;
      const renderingProviderOptions = renderingProviders?.map((provider) => ({
        value: provider?.providerId,
        label: `${provider?.prefix} ${provider?.firstName} ${provider?.lastName}`,
        provider: provider,
      }));
      setRenderingProvidersList(renderingProviderOptions);
    } else {
      setRenderingProvidersList([]);
      console.log(httpResponse.error);
    }
    setAreRenderingProvidersLoading(false);
  };

  const fetchClaimPatients = async () => {
    setArePatientsLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/patients`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const claimPatients = httpResponse.data;
      const patientDropdownOptions = claimPatients?.map((patient) => ({
        value: patient?.patientId,
        label: `${patient?.firstName} ${patient?.lastName}`,
        patient: patient,
      }));
      setPatientsList(patientDropdownOptions);
    } else {
      setPatientsList([]);
      console.log(httpResponse.error);
    }
    setArePatientsLoading(false);
  };

  const calculateSum = (data) => {
    let totalClaimAmount = 0;
    let totalPaidAmount = 0;
    let totalPatientResponsibility = 0;

    data.forEach((item) => {
      // Check if totalAmount exists, and add to totalClaimAmount
      if (item.totalAmount !== undefined && item.totalAmount !== null) {
        totalClaimAmount += Number(item.totalAmount);
      }

      // Check if claimResponse.payerAmount exists, and add to totalPaidAmount
      if (
        item.claimResponse &&
        item.claimResponse.payerAmount !== undefined &&
        item.claimResponse.payerAmount !== null
      ) {
        totalPaidAmount += Number(item.claimResponse.payerAmount);
      }

      // Check if claimPayment.patientResponsibility exists, and add to totalPatientResponsibility
      if (
        item.claimPayment &&
        item.claimPayment.patientResponsibility !== undefined &&
        item.claimPayment.patientResponsibility !== null
      ) {
        totalPatientResponsibility += Number(
          item.claimPayment.patientResponsibility
        );
      }
    });

    // Set the calculated amounts
    setClaimAmount(totalClaimAmount);
    setPaidAmount(totalPaidAmount);
    setPatientResponsibility(totalPatientResponsibility);
  };

  const fetchAllClaims = async (
    tenantId,
    claimNumber,
    provider,
    patient,
    status,
    fromDate,
    toDate,
    currentPageVal,
  ) => {
    // console.log({ provider, patient, status });
    setAreClaimsLoading(true);
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: `/claims/tenants/${tenantId}/insurance-claims`,
      params: {
        ...(claimNumber !== "" && { claimNumber: claimNumber }),
        providerId: provider?.value,
        patientId: patient?.value,
        claimStatus: status?.value,
        ...(fromDate?.length > 0 && { fromDate: fromDate }),
        ...(toDate?.length > 0 && { toDate: toDate }),
        page:currentPageVal !== undefined ? currentPageVal - 1 : 0,  
        size:10
      },
    });
    if (!httpResponse.error) {
      setClaims(httpResponse.data?.content);
      calculateSum(httpResponse.data?.content);
      
      let noOfPages = httpResponse?.data?.totalPages;
      setTotalResults(noOfPages);
      setCurrentItems(httpResponse.data?.content);
      setMaxPageLimit(noOfPages);
      let pagesList = [];
      for (let i = 1; i <= noOfPages; i++) {
        pagesList.push(i);
      }
      // console.log("pagesList : ",pagesList);
      setPages(pagesList);
      setTotalElements(httpResponse.data?.totalElements);
    } else {
      setTotalElements(0);
      setCurrentItems([]);
      setMaxPageLimit(0);
      console.error(`Error in fetching claims for tenant ${tenantId}`);
    }
    setAreClaimsLoading(false);
  };


  const fetchAllClaimsForCSV = async (
    tenantId,
    claimNumber,
    provider,
    patient,
    status,
    fromDate,
    toDate,
    currentPageVal,
  ) => {
    // console.log({ provider, patient, status });
    setDownloadCSVFlag(true);
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: `/claims/tenants/${tenantId}/insurance-claims`,
      params: {
        ...(claimNumber !== "" && { claimNumber: claimNumber }),
        providerId: provider?.value,
        patientId: patient?.value,
        claimStatus: status?.value,
        ...(fromDate?.length > 0 && { fromDate: fromDate }),
        ...(toDate?.length > 0 && { toDate: toDate }),
        page:0,  
        size:totalElements
      },
    });
    if(!httpResponse.error){
      if(location?.state?.templateType === 0){
        claimDataForTable(httpResponse.data?.content,true)
      }else{
        claimHierarchicalDataForTable(httpResponse.data?.content,true)
      }
    }
    setDownloadCSVFlag(false);
  
  }
  function handleProviderId(selectedOption) {
    setSelectedProvider(selectedOption);
    // props?.setEncounterDto((dto) => ({ ...dto, "claimProviderDataId": event.target.value, "provider": { "providerId": event.target.value } }))
  }

  function handlePatientId(option) {
    setSelectedPatient(option);
  }

  function handleClaimNumber(event) {
    setClaimNumber(event.target.value);
  }

  function handleClaimStatus(option) {
    setSelectedStatus(option);
  }

  const handleFilter = useCallback(async () => {
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      fromDate,
      toDate
    );
    fetchClaimSumamry(user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      fromDate,
      toDate);
  }, [
    user?.tenantId,
    claimNumber,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fromDate,
    toDate,
    fetchAllClaims,
  ]);

  const handleClearFilter = useCallback(async () => {
    setClaimNumber("");
    setSelectedPatient(null);
    setSelectedProvider(null);
    setSelectedStatus(null);
    setFromDate(null);
    setToDate(null);
    fetchAllClaims(user?.tenantId, "", null, null, null, null, null);
    fetchClaimSumamry(user?.tenantId, "", null, null, null, null, null);
    
  }, [
    user?.tenantId,
    claimNumber,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fromDate,
    toDate,
    fetchAllClaims,
  ]);

  const handleFromDate = (dateValue) => {
    setFromDate(dateValue);
  };
  const handleToDate = (dateValue) => {
    setToDate(dateValue);
  };

  const handleModal = () => {
    setOpenCSVReportModal(false);
  };

  function extractKeyAndIsDisplay(data) {
    return data.reduce((acc, item) => {
      if (item.isDefaultChecked) {
        acc.push({
          key: item.claimReportMetaData.key,
          isDefaultChecked: item.isDefaultChecked,
          value: item.claimReportMetaData.displayColumnName,
          displayTableName: item?.claimReportMetaData?.displayTableName,
          sequence_number: item?.claimReportMetaData?.sequence_number,
        });
      }
      return acc;
    }, []);
  }
  function convertToSingleObject(data) {
    let result = {};
    data.forEach((item) => {
      Object.assign(result, item); // Merge each object into the result
    });
    return result;
  }
  const downloadCSV = () => {
    fetchAllClaimsForCSV(user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      location?.state?.startDate,
      location?.state?.endDate,
      1)
    // CSVService.downloadCSV(claimTableData, "Claim_data", columnHeadersList);
  };

  const claimHierarchicalDataForTable = (claimsData,csvFlag) => {
    if (claimsData?.length > 0) {
      let filterClaimsData = [];
      let filterClaimServiceData = [];
      for (let claimItem of claimsData) {
        // console.log("claimItem: ", claimItem);
        let services = claimItem.claimServices;
        let claimObject = [];
        let claimServiceObj = [];
        for (let element of template) {
          // console.log("element: ",element);

          if (
            element.isDefaultChecked === true &&
            element.displayTableName?.toLowerCase() !== "claim services"
          ) {
            let objElement = CSVService.getSelectedColumns(claimItem, element);

            claimObject.push(objElement.value);
          }
        }
        for (let claimServiceItem of services) {
          let claimService = [];
          // console.log("claimItem: ",claimItem);

          for (let element of template) {
            // console.log("element: ",element);

            if (
              element.displayTableName?.toLowerCase() === "claim services" &&
              element.isDefaultChecked === true &&
              templateType === 2
            ) {
              // console.log("claimServiceItem: ",claimServiceItem);

              let objElement = CSVService.getSelectedColumns(
                { claimServices: claimServiceItem },
                element
              );
              claimService.push(objElement.value);
            }
          }
          claimServiceObj.push(claimService);
        }
        filterClaimsData.push({
          ...claimObject,
          claimServices: claimServiceObj,
        });
        claimObject = [];
      }
      if(csvFlag === false){
        setHierarchyTemplateData(filterClaimsData);
      }else{
        CSVService.downloadCSV(filterClaimsData, "Claim_data", columnHeadersList);
        // setDataForCSV(filterClaimsData);
        setDownloadCSVFlag(false);
      }
      console.log("Hierarchical Claim filter data: ", filterClaimsData);
    }
  };
  const claimDataForTable = (claimsData,csvFlag) => {
    if (claimsData?.length > 0) {
      let filterClaimsData = [];
      for (let claimItem of claimsData) {
        // console.log("claimItem: ", claimItem);
        let services = claimItem.claimServices;
        let claimObject = [];
        for (let claimServiceItem of services) {
          // console.log("claimItem: ",claimItem);

          for (let element of template) {
            // console.log("element: ",element);

            if (
              element.isDefaultChecked === true &&
              element.displayTableName?.toLowerCase() !== "claim services"
            ) {
              let objElement = CSVService.getSelectedColumns(
                claimItem,
                element
              );

              claimObject.push(objElement.value);
            }
          }
          for (let element of template) {
            // console.log("element: ",element);

            if (
              element.displayTableName?.toLowerCase() === "claim services" &&
              element.isDefaultChecked === true &&
              templateType === 2
            ) {
              // console.log("claimServiceItem: ",claimServiceItem);

              let objElement = CSVService.getSelectedColumns(
                { claimServices: claimServiceItem },
                element
              );
              claimObject.push(objElement.value);
            }
          }

          filterClaimsData.push({ ...claimObject });
          if (templateType === 1) {
            break;
          } else {
            claimObject = [];
          }
        }
      }

      // let header = template.map((item) => item.value);
      // setColumnHeadersList(header);
      if(csvFlag === false){
        setClaimTableData(filterClaimsData);
      }else{
        CSVService.downloadCSV(filterClaimsData, "Claim_data", columnHeadersList);
        setDownloadCSVFlag(false);
        // setDataForCSV(filterClaimsData);
      }
    }
  };

  function sortData(data) {
    return data.sort((a, b) => {
      // If either a or b has displayTableName as "claim", push it to the start
      if (
        a.displayTableName?.toLowerCase() === "claim" &&
        b.displayTableName?.toLowerCase() !== "claim"
      )
        return -1;
      if (
        a.displayTableName?.toLowerCase() !== "claim" &&
        b.displayTableName?.toLowerCase() === "claim"
      )
        return 1;

      // If either a or b has displayTableName as "claim services", push it to the end
      if (
        a.displayTableName?.toLowerCase() === "claim services" &&
        b.displayTableName?.toLowerCase() !== "claim services"
      )
        return 1;
      if (
        a.displayTableName?.toLowerCase() !== "claim services" &&
        b.displayTableName?.toLowerCase() === "claim services"
      )
        return -1;

      // First, compare by displayTableName (alphabetical order)
      if (a.displayTableName < b.displayTableName) return -1;
      if (a.displayTableName > b.displayTableName) return 1;

      // If displayTableName is the same, compare by sequence_number (numerical order)
      return a.sequence_number - b.sequence_number;
    });
  }


  // Pagination Handlers
  
  // Pagination Handlers
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            // if(number > maxPageNumberLimit){
            //   console.log(number,maxPageNumberLimit + 1,minPageNumberLimit,number < maxPageNumberLimit + 1 ,
            //     number > minPageNumberLimit);
            // }
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${classes["list__item"]} ${
                    currentPage === number ? classes["active__list__item"] : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      location?.state?.startDate,
      location?.state?.endDate,
      currentPage + 1,
    );
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // setPageNumber(pageNumber - 1);
    // console.log("currentPage: ",currentPage-1);
    if (currentPage - 1 >= 1) {
      setCurrentPage((p) => currentPage - 1);
      fetchAllClaims(
        user?.tenantId,
        claimNumber,
        selectedProvider,
        selectedPatient,
        selectedStatus,
        location?.state?.startDate,
        location?.state?.endDate,
        currentPage - 1,
      );

    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
  };
  const handleFirstPage = (event) => {
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);

    handleCurrentPage(1);
  };
  const handleLastPage = (event) => {
    event.preventDefault();
    let lastPage = Math.ceil(totalResults);
    let maxPageNumberLimitValue = lastPage;
    if (maxPageNumberLimitValue % 10 > 0) {
      maxPageNumberLimitValue = maxPageNumberLimitValue - 5;
    }
    setCurrentPage(totalResults);
    setMaxPageNumberLimit(lastPage);
    setMinPageNumberLimit(lastPage - pageNumberLimit);

    handleCurrentPage(lastPage);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      location?.state?.startDate,
      location?.state?.endDate,
      pNumber
    );
  };

  useEffect(() => {
    fetchClaimSumamry(user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      fromDate,
      toDate);
    fetchReneringProviders();
    fetchClaimPatients();
    // console.log("location?.state?.template: ", location?.state?.template);

    let tempateObj = extractKeyAndIsDisplay(location?.state?.template);
    console.log("tempateObj: ", tempateObj);
    tempateObj = sortData(tempateObj);
    console.log("after sorting tempateObj: ", tempateObj);
    setTemplate(tempateObj);
    setTemplateType(location.state.templateType);
    let header = tempateObj.map((item) => item.value);
    setColumnHeadersList(header);
  }, []);

  useEffect(() => {
    setFromDate(location?.state?.startDate || null);
    setToDate(location?.state?.endDate || null);
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      location?.state?.startDate,
      location?.state?.endDate,
      currentPage
    );
        fetchClaimSumamry(user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      fromDate,
      toDate);
  }, [reloadClaims]);

  console.log("templateType: ", templateType);
  console.log("hierarchyTemplateData: ", hierarchyTemplateData);

  useEffect(() => {
    if (currentItems?.length > 0 && downloadCSVFlag === false) {
      claimHierarchicalDataForTable(currentItems,false);
      claimDataForTable(currentItems,false);
    }
  }, [currentItems]);
  return (
    <>
      {openCSVReportModal ? (
        <ReportColumModal
          show={openCSVReportModal}
          handleModal={handleModal}
          claimsData={claims}
        />
      ) : null}

      <PageHeader title="Claims" />
      <div className="container">
        <div className="row justify-content-center py-2 mb-1">
          <div className="col-sm-11">
            <div className="row">
              <div className="col-sm-2">
                <label className="text--secondary font-12 b2 fw-sb">From</label>
                {fromDate !== null ? (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleFromDate}
                    dateFormat="MMM d, yyyy"
                    date={fromDate !== null ? fromDate : undefined}
                    //   viewSchedule={true}
                    //   required
                    key={"FromDate"}
                  />
                ) : (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleFromDate}
                    dateFormat="MMM d, yyyy"
                    date={""}
                    key={"FromDateNew"}
                  />
                )}
              </div>
              <div className="col-sm-2">
                <label className="text--secondary font-12 b2 fw-sb">To</label>
                {toDate !== null ? (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleToDate}
                    dateFormat="MMM d, yyyy"
                    date={toDate !== null ? toDate : undefined}
                    //   viewSchedule={true}
                    //   required
                    key={"ToDateNew"}
                  />
                ) : (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleToDate}
                    dateFormat="MMM d, yyyy"
                    date={""}
                    //   viewSchedule={true}
                    //   required
                    key={"ToDate"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2">
          <div className="col-sm-11">
            <div className="row">
              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label text--secondary font-12 fw-sb">
                    Claim Number
                  </label>
                  <input
                    type="text"
                    placeholder="Claim Number"
                    style={{ minHeight: "38px" }}
                    onChange={handleClaimNumber}
                    value={claimNumber}
                    className="form-control simple-placeholder br-10 font-12 shadow-none border--default"
                  />
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label text--secondary font-12 fw-sb">
                    Rendering Provider
                  </label>
                  {areRenderingProvidersLoading === true ? (
                    <Skeleton animation="wave" variant="text" width="100%" />
                  ) : (
                    <PnSelect
                      isClearable={true}
                      isSearchable
                      onChange={handleProviderId}
                      name="providers"
                      closeMenuOnSelect={true}
                      options={renderingProvidersList}
                      value={selectedProvider}
                    />
                    // <select
                    //     className={`form-select br-10 font-12 input border--default shadow-none`}
                    //     aria-label="Provider select"
                    //     onChange={(e) => handleProviderId(e)}
                    //     value={props?.encounterDto?.provider?.providerId || selectedProvider}
                    // >
                    //     <option value="" disabled selected hidden>
                    //         Select provider
                    //     </option>
                    //     {renderingProvidersList?.length > 0
                    //         ? renderingProvidersList?.map((renderingProvider) => {
                    //             return (
                    //                 <option
                    //                     value={renderingProvider?.providerId ?? ""}
                    //                     key={renderingProvider?.providerId ?? ""}
                    //                 >
                    //                     {renderingProvider?.prefix}{" "}
                    //                     {renderingProvider?.firstName}{" "}
                    //                     {renderingProvider?.lastName}{" "}

                    //                 </option>
                    //             );
                    //         })
                    //         : "No prescribers available"}
                    // </select>
                  )}
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label text--secondary font-12 fw-sb">
                    Patient
                  </label>
                  {arePatientsLoading === true ? (
                    <Skeleton animation="wave" variant="text" width="100%" />
                  ) : (
                    <PnSelect
                      isClearable={true}
                      isSearchable
                      onChange={handlePatientId}
                      name="patients"
                      closeMenuOnSelect={true}
                      options={patientsList}
                      value={selectedPatient}
                    />
                    // <select
                    //     className={`form-select br-10 font-12 input border--default shadow-none`}
                    //     aria-label="Provider select"
                    //     onChange={(e) => handlePatientId(e)}
                    //     value={props?.encounterDto?.patient?.patientId || selectedPatient}
                    // >
                    //     <option value="" disabled selected hidden>
                    //         Select patient
                    //     </option>
                    //     {patientsList?.length > 0
                    //         ? patientsList?.map((patient) => {
                    //             return (
                    //                 <option
                    //                     value={patient?.patientId ?? ""}
                    //                     key={patient?.patientId ?? ""}
                    //                 >

                    //                     {patient?.firstName}{" "}
                    //                     {patient?.lastName}{" "}

                    //                 </option>
                    //             );
                    //         })
                    //         : "No patients available"}
                    // </select>
                  )}
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label text--secondary font-12 fw-sb">
                    Status
                  </label>
                  <PnSelect
                    isClearable={true}
                    isSearchable
                    onChange={handleClaimStatus}
                    name="claimStatus"
                    closeMenuOnSelect={true}
                    options={CLAIM_STATUS_OPTIONS}
                    value={selectedStatus}
                  />
                </div>
              </div>
              <div className="col-sm-2 align-self-end">
                <button
                  onClick={handleFilter}
                  className="btn blue_task_button br-10 shadow-none"
                >
                  Filter Data
                </button>
              </div>
              <div className="col-sm-1 align-self-end">
                <span
                  onClick={handleClearFilter}
                  className={`pn-link-button d-inline-block  text-decoration-underline fw-sb font-12 text--secondary cursor--pointer ${
                    (claimNumber == "" || claimNumber == null) &&
                    selectedProvider == null &&
                    selectedPatient == null &&
                    selectedStatus == null &&
                    fromDate == null &&
                    toDate == null
                      ? "disabled-link cursor--disabled"
                      : ""
                  }`}
                >
                  Clear Filters
                </span>
              </div>
              <div
                className="col-1 d-flex align-self-end cursor--pointer"
                onClick={() => {
                  downloadCSV(claims);
                }}
              >
                <PnToolTip title={"Download CSV"} className="">
                  <FileDownloadIcon
                    sx={{
                      color: "#336383",
                      fontWeight: 400,
                      fontSize: "1.5rem",
                    }}
                    disable={claims?.length === 0}
                  />
                </PnToolTip>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-sm-3 "></div>
        </div>

        <div
          style={{ minHeight: "500px" }}
          className="row mt-3  justify-content-center"
        >
          <div
            className="row d-flex justify-content-center mt-3"
            hidden={claims?.length === 0}
          >
            {
              isClaimSummaryLoading ? <Skeleton/> :
              <div className="col-8 d-flex flex-row justify-content-between">
                <div className="font-12 text--terciary fw-sb">
                  <span>Total Claims: { 
                  //claims?.length ?? 0
                  claimSummary?.totalClaims ?? ""
                  }</span>
                </div>
                <div className="font-12 text--terciary fw-sb">
                  <span>Claimed Amount: ${claimSummary?.totalClaimedAmount ?? ""}</span>
                </div>
                <div className="font-12 text--terciary fw-sb">
                  <span>Paid Amount: ${claimSummary?.totalPaidAmount ?? ""}</span>
                </div>
                <div className="font-12 text--terciary fw-sb">
                  <span>Patient Responsibility: ${claimSummary?.totalPatientResponsibility ?? ""}</span>
                </div>
              </div>
            }
          </div>
          <div className="col-sm-11">
            {areClaimsLoading ? (
              <Skeleton width="100%" />
            ) : claims?.length > 0 ? (
              <>
                <div className="row justify-content-end">
                  <div className="col-sm-3 text-end">
                    <RefreshDataComponent
                      classes={"pe-2"}
                      isComponentDataLoading={areClaimsLoading}
                      variant="without-bar"
                      callback={() =>{
                        fetchAllClaims(
                          user?.tenantId,
                          claimNumber,
                          selectedProvider,
                          selectedPatient,
                          selectedStatus
                        );
                        fetchClaimSumamry(user?.tenantId,
                          claimNumber,
                          selectedProvider,
                          selectedPatient,
                          selectedStatus,
                          fromDate,
                          toDate);
                      }
                      }
                    />
                  </div>
                </div>
                {location?.state?.templateType === 1 ? (
                  <ReportTable
                    setReloadClaims={setReloadClaims}
                    data={claimTableData}
                    columnHeadersList={columnHeadersList}
                    hierarchy={
                      templateType === undefined || templateType === 1
                        ? false
                        : true
                    }
                  />
                ) : (
                  <HierarchicalReportTable
                    setReloadClaims={setReloadClaims}
                    data={hierarchyTemplateData}
                    columnHeadersList={columnHeadersList}
                    serviceHeadersList={columnHeadersList}
                  />
                )}
              </>
            ) : (
              <>
                <span className="d-block font-12 fw-sb text--secondary">
                  No claims were found that matched the specified filters.
                </span>
              </>
            )}
          </div>
          <div
            className={` mt-4 align-self-center col-sm-8 col-8 justify-content-center`}
          >
            {areClaimsLoading ? (
              <Skeleton />
            ) : (
              <div className={" d-flex flex-row justify-content-between"}>
                {pages.length > 1 ? (
                  <div className="ps-2 mt-2">
                    <PnToolTip title="First" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handleFirstPage(e);
                        }}
                        className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                        <FirstPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Previous" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handlePreButton(e);
                        }}
                        className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                      >
                        <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}

                <RenderPages />

                {pages.length > 1 ? (
                  <div className="mt-2">
                    <PnToolTip title="Next" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleNextButton(e);
                        }}
                        className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <NavigateNextIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Last" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleLastPage(e);
                        }}
                        className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <LastPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {console.log("claims: ", claims)} */}
    </>
  );
}

export default ClaimReportingTable;

const ReportTable = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableContainer
        sx={{
          borderRadius: "10px",
          border: "1px solid #D9E8EC",
          maxHeight: "60vh",
          maxWidth: "100%",
          overflowX: "auto",
          "&::-webkit-scrollbar": { height: "8px", width: "4px" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#D9E8EC",
            borderRadius: "4px",
          },
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <TableRow>
              {props?.columnHeadersList?.map((item, idx) => {
                return (
                  <TableCell key={item?.concat(idx)}>
                    <>
                      <span className="font-12 text--secondary">{item}</span>
                    </>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.data.map((item, idx) => {
              //   const keys = Object.keys(item);
              //   let firstName = item?.encounter?.provider?.firstName;
              //   let lastName = item?.encounter?.provider?.lastName;
              //   let prefix = item?.encounter?.provider?.prefix;
              //   console.log("item: ", item);

              return (
                <>
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {Object.values(item).map((valueItem, index) => {
                      return (
                        <TableCell
                          sx={{ color: "#336383", userSelect: "text" }}
                        >
                          <PnToolTip title={valueItem !== null ? valueItem : ""}>
                              <span
                                style={{ userSelect: "text" }}
                                className="d-flex flex-row justify-content-between font-12 "
                              >
                                {valueItem !== null ? truncate(valueItem,20) : "N/A"}
                              </span>
                          </PnToolTip>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
const HierarchicalReportTable = (props) => {
  const [open, setOpen] = useState(false);

  const [parentColHeaders, setParentColHeaders] = useState([]);
  const [childColHeaders, setChildColHeaders] = useState([]);

  useEffect(() => {
    if (props.data?.length > 0) {
      // console.log("props?.data[0]?.claimServices.length: ",props?.data[0]?.claimServices[0].length);

      setParentColHeaders(
        props.columnHeadersList.slice(
          0,
          props.columnHeadersList.length -
            props?.data[0]?.claimServices[0].length 
        )
      );
      setChildColHeaders(
        props.columnHeadersList.slice(
          props.columnHeadersList.length -
            props?.data[0]?.claimServices[0].length
        )
      );
    }
  }, [props?.columnHeadersList]);

  return (
    <>
      <TableContainer
        sx={{
          borderRadius: "10px",
          border: "1px solid #D9E8EC",
          maxHeight: "60vh",
          maxWidth: "100%",
          overflowX: "auto",
          "&::-webkit-scrollbar": { height: "8px", width: "4px" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#D9E8EC",
            borderRadius: "4px",
          },
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <TableRow>
              {parentColHeaders?.map((item, idx) => {
                return (
                  <TableCell key={item?.concat(idx)}>
                    <>
                      <span className="font-12 text--secondary">{item}</span>
                    </>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.data.map((item, idx) => {
              //   const keys = Object.keys(item);
              //   let firstName = item?.encounter?.provider?.firstName;
              //   let lastName = item?.encounter?.provider?.lastName;
              //   let prefix = item?.encounter?.provider?.prefix;
              // console.log("item: ", item.claimServices);
              // item = item.filter((element)=>{return Object.key(element) !== "claimServices"})
              return (
                <>
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {Object.values(item).map((valueItem, index) => {
                      if (!Array.isArray(valueItem)) {
                        return (
                          <TableCell
                            sx={{ color: "#336383", userSelect: "text" }}
                          >
                            <PnToolTip title={valueItem !== null ? valueItem : ""}>
                              <span
                                style={{ userSelect: "text" }}
                                className="d-flex flex-row justify-content-between font-12 "
                              >
                                {valueItem !== null ? truncate(valueItem,20) : "N/A"}
                              </span>
                          </PnToolTip>
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={12}
                    >
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Table size="small" aria-label="claim services">
                            <TableHead>
                              <TableRow>
                                {childColHeaders?.map((headerItem) => {
                                  return (
                                    <TableCell>
                                      <span className="font-12 text--secondary">
                                        {headerItem}
                                      </span>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.claimServices.map(
                                (claimServiceItem, index) => {
                                  return (
                                    <TableRow key={index}>
                                      {Object.values(claimServiceItem).map(
                                        (itemVal, index) => {
                                          return (
                                            <TableCell
                                              sx={{
                                                color: "#336383",
                                                userSelect: "text",
                                              }}
                                            >
                                              <PnToolTip title={itemVal}>
                                                <span
                                                  style={{ userSelect: "text" }}
                                                  className="font-12 "
                                                >
                                                  {itemVal !== null
                                                    ? truncate(itemVal, 20)
                                                    : "N/A"}
                                                </span>
                                              </PnToolTip>
                                            </TableCell>
                                          );
                                        }
                                      )}
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
