import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ReasonOfVisit from "../../prescription/ReasonOfVisit";
import AllergyTable from "../../patient/AllergyTable";
import MedicationHistoryTable from "../../patient/MedicationHistoryTable";
import FamilyMedicalHistoryTable from "../../patient/FamilyMedicalHistoryTable";
import ReviewOfSystems from "../../patient/ReviewOfSystems";
import PhysicalExam from "../../patient/PhysicalExam";
import AssesmentPlan from "../../patient/AssesmentPlan";
import SurgeryTable from "../../patient/SurgeryTable";
import PatientVitals from "../../patient/PatientVitals";
import HospitalizationTable from "../../patient/HospitalizationTable";
import SocialHistoryTable from "../../patient/SocialHistoryTable";
import { Backdrop, LinearProgress } from "@mui/material";
import PatientProfileEncounter from "../../patient/PatientProfileEncounter";
import { useSelector, useDispatch } from "react-redux";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import SummaryModal from "../Modals/ProviderModals/SummaryModal";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomizedSnackbars from "../General/CustomizedSnackbars";
import { RolesMap } from "../../../utils/helpers/RolesMap";
import ScrollSpy from "react-ui-scrollspy";
import DocumentTabPatientProfile from "../../patient/DocumentTabPatientProfile";
import {
  encounterSummaryUrl,
  updateEncounterUrl,
} from "../../../res/endpointurls";
import { summaryGeneration } from "../../../utils/helpers/DataLoaders";
import { useEffect } from "react";
import { TabPanel, theme } from "../Reusable/PnComponents";
import {
  setAllPhysicalSystemsStatus,
  setDirtyBitInStore,
  setPatientEncounterData,
} from "../../../redux/actions/providerActions";
import useEventListener from "../../hooks/useEventListener";
import { UnloadHandler } from "../../../utils/helpers/HelperFunctions";
import DiagnosisHistoryTable from "../../patient/DiagnosisHistoryTable";
import { useAuth } from "../../hooks/useAuth";
import InPatientAppointmentService from "../../Services/InPatientAppointment.service";
import EndEncounterConsultationModal from "./EndEncounterConsultationModal";
import { ENCOUNTERS, PATIENTS } from "../../../utils/helpers/subscriptionsConstants";



const getTabValue = function (tabTitle) {
  const tabsMap = new Map();
  tabsMap.set("Patient Information", 0);
  tabsMap.set("Documents", 1);
  tabsMap.set("Reason for Visit", 2);
  tabsMap.set("Vitals", 3);
  tabsMap.set("Allergies", 4);
  tabsMap.set("History", 5);
  tabsMap.set("Review of Systems", 6);
  tabsMap.set("Physical Exam", 7);
  tabsMap.set("Assessment & Plan", 8);
  return tabsMap.get(tabTitle);
};

function BottomNavigation(props) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [status, setStatus] = useState(props?.status);
  function handleStatus(event) {
    setStatus(event.target.value);
  }

  if (user?.role === "MedicalAssistant") {
    async function makeAppointmentProviderReady() {
      const response = await InPatientAppointmentService.markAppointmentProviderReady(props?.appointmentId, status);
      if (!response.error) {
        // navigate('/dashboard', {
        //   replace: true
        // })
      }
    }

    return (
      <div className="position-fixed row bg-white w-100 bottom-0 mx-auto bottom__nav--shadow">

        <LinearProgress variant="determinate" style={{
          backgroundColor: "#D9E8EC",
          height: "1px"
        }} value={props?.value} />


        <div className="col-12 p-3">
          <div className="row justify-content-between">







            <div className="col-3 gap-2">
              <button className="btn  br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button" style={{
                paddingLeft: "24px",
                paddingRight: "24px"
              }}
                disabled={props?.previous === undefined}
                onClick={event => {
                  props.handleChange(event, props.previous);
                }}>
                Previous
              </button>
              <button className="btn br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button" style={{
                paddingLeft: "42px",
                paddingRight: "42px"
              }}
                disabled={props?.next === undefined ? true : false}
                onClick={event => {
                  props.handleChange(event, props.next);
                }}>
                Next
              </button>
              {/* <span className={`text-danger ${isCheckInDisabled ? 'd-block' : 'd-none'} font-12 m-0 p-0`}>No Rooms Available</span> */}
            </div>

            <div className="col-5 me-4">
              <div className="d-flex">
                <div className="">
                  <div className="form-group">
                    <select
                      className={`form-select font-13 shadow-none`}
                      aria-label="Provider select"
                      style={{
                        border: "1px solid #D9E8EC",
                        height: "36px",
                        width: "250px",
                        borderRadius: "10px",
                        // background: "#FAFCFC",
                      }}
                      required
                      onChange={handleStatus}
                      value={status || props?.status}
                    >
                      <option value="" disabled selected hidden>
                        Select status
                      </option>

                      <option value="ma-reviewed">MA Review</option>
                      <option value="provider-ready">Provider Ready</option>
                      <option value="in-consultation">In Consultation</option>
                      <option value="consultation-completed">Consultation Complete</option>
                      <option value="departed">Visit Complete</option>
                      <option value="no-show">No-show</option>
                      <option value="canceled">Canceled</option>

                    </select>
                  </div>
                </div>
                <div className="">
                  <button className="btn br-10 font-14 fw-sb text--blue px-5 d9e8ec--button btn__bottom" style={{
                    zIndex: "1000"
                  }} onClick={async () => {
                    await props.handleUpdateEncounter();
                    await makeAppointmentProviderReady();
                  }} disabled={props.isSaving}>
                    Save
                    {props.isSaving && <CircularProgress className="align-self-center " size={24} sx={{
                      color: "#EC609B",
                      position: "absolute",
                      marginBottom: "50px",
                      marginLeft: "5px"
                    }} />}
                  </button>
                </div>
              </div>
            </div>


          </div>
        </div>


        {/* <CustomizedSnackbar
          message={checkInSnackbarMessage}
          severity={checkInSnackbarSeverity}
          open={openCheckInSnackbar}
          handleClose={handleCheckInSnackbarClose}
        /> */}
      </div>
    )
  }
  else {
    return (<>
      <div className="position-fixed row bg-white w-100 bottom-0 mx-auto bottom__nav--shadow">

        <LinearProgress variant="determinate" style={{
          backgroundColor: "#D9E8EC",
          height: "1px"
        }} value={props?.value} />


        <div className="col-12 p-3">
          <div className="row justify-content-between">

            <div className="col-3 gap-2">
              <button className="btn  br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button outlined" style={{
                paddingLeft: "24px",
                paddingRight: "24px"
              }}
                disabled={props?.previous === undefined}
                onClick={event => {
                  props.handleChange(event, props.previous);
                }}>
                Previous
              </button>
              <button
                disabled={props?.next === undefined}
                className="btn br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button outlined" style={{
                  paddingLeft: "42px",
                  paddingRight: "42px"
                }} onClick={event => {
                  props.handleChange(event, props.next);
                }}>
                Next
              </button>
              {/* <span className={`text-danger ${isCheckInDisabled ? 'd-block' : 'd-none'} font-12 m-0 p-0`}>No Rooms Available</span> */}
            </div>

            <div className="col-6">
              <div className="d-flex">

                <div className="">
                  <button className="btn br-10 font-14 fw-sb text--blue px-5 d9e8ec--button btn__bottom" style={{
                    zIndex: "1000"
                  }} onClick={() => {
                    props.handleUpdateEncounter();
                  }} disabled={props.isSaving}>
                    Save
                    {props.isSaving && <CircularProgress className="align-self-center " size={24} sx={{
                      color: "#EC609B",
                      position: "absolute",
                      marginBottom: "50px",
                      marginLeft: "5px"
                    }} />}
                  </button>
                </div>

                <div className="">
                  <button
                    disabled={props?.summaryModal || props?.next !== undefined}
                    className="btn ms-2  d9e8ec--button"
                    style={{
                      paddingLeft: "42px",
                      paddingRight: "42px",
                    }}
                    onClick={function (event) {
                      props.generateSummary();
                      // setTimeout(() => {
                      // }, 600);
                    }}
                    hidden={props?.hideSummary === false ? true : false}
                  >
                    Summary
                  </button>
                </div>

                <div className="">
                  <button
                    className="btn ms-2 blue_task_button"
                    style={{
                      paddingLeft: "42px",
                      paddingRight: "42px",
                    }}
                    onClick={() => { props.handleEndEncounterConsultationModal() }}
                  >
                    End Consultation
                  </button>
                </div>
              </div>
            </div>


          </div>
        </div>


        {/* <CustomizedSnackbar
  message={checkInSnackbarMessage}
  severity={checkInSnackbarSeverity}
  open={openCheckInSnackbar}
  handleClose={handleCheckInSnackbarClose}
/> */}
      </div>
    </>

    );
  }




}


export default function EncounterTabs({ prescriptionReferenceId, setIsNotFound, referringPhysician }) {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const { user } = useAuth();
  const [summary, setSummary] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { providerId, encounterId, patientId } = useParams();
  const [isSent, setIsSent] = useState(false);
  const [encounterApiData, setEncounterApiData] = useState(null);
  const [appointmentId, setAppointmentId] = useState(null);
  const location = useLocation();
  console.log("REFERRING", referringPhysician);
  const [status, setStatus] = useState(location?.state?.selectedStatus !== undefined ? location.state.selectedStatus : null);

  //   console.log("Is Video Call: ", isVideoCall);
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);

  const tabsList =
    RolesMap(prescriberInfo?.role) !== "staff"
      ? [
        "Patient Information",
        "Documents",
        "Reason for Visit",
        "Vitals",
        "Allergies",
        "History",
        "Review of Systems",
        "Physical Exam",
        // "Diagnosis",
        // "Orders",
        "Assessment & Plan",
      ]
      : [
        "Patient Information",
        "Documents",
        "Reason for Visit",
        "Vitals",
        "Allergies",
        "History",
        "Review of Systems",

        "Physical Exam",
      ];

  const [value, setValue] = useState(0);
  const [summaryModal, showSummaryModal] = useState(false);
  const [isSummaryModalLoading, setIsSummaryModalLoading] = useState(false);
  const [showEndEncounterConsultationModal, setShowEndConsultationModal] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [isNotFoundError, setIsNotFoundError] = useState(false); //local not found state related to tabs 
  const dispatch = useDispatch();
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );
  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter.Encounter
  );

  const handleUnsavedChanges = () => {
    setUnsavedChanges(true);
    dispatch(setDirtyBitInStore(true));
  };


  useEventListener("beforeunload", UnloadHandler(dirtyBitStatus));

  function handleEndEncounterConsultationModal() {

    setShowEndConsultationModal(prev => !prev);
  }

  const handleChange = (event, newValue) => {
    // console.log("requiredDataCheckValue",requiredDataCheckValue);

    setValue(newValue);
    if (unsavedChanges) {
      handleUpdateEncounter(true);
      setUnsavedChanges(false);
      dispatch(setDirtyBitInStore(false));
    }
  };

  const changeTab = (newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };

  const fetchEncounterApiData = async (encounterId) => {
    setIsNotFound(false);
    setIsNotFoundError(false);
    const httpResponse = await sendHttpRequest({
      url: encounterSummaryUrl(encounterId),
      method: "GET",
    });
    if (!httpResponse.error) {
      console.log("Data ref", httpResponse?.data);
      setAppointmentId(httpResponse?.data?.appointment?.id);
      setEncounterApiData({
        ...httpResponse.data,
        encounter: {
          ...httpResponse.data?.encounter,
          notes: httpResponse.data?.encounter?.notes || "",
        },
      });
      // console.log("patientEncounterInformation====================", patientEncounterInformation);
      dispatch(
        setPatientEncounterData({
          ...patientEncounterInformation,
          prescriptionReference: {
            ...patientEncounterInformation?.prescriptionReference,
            encounter: {
              ...patientEncounterInformation.prescriptionReference.encounter,
              reviewOfSystem: httpResponse.data?.encounter?.reviewOfSystem,
            },
          }
        })
      );
      dispatch(
        setAllPhysicalSystemsStatus(httpResponse.data?.encounter?.allSystemsOk)
      );
    }
    else {
      if (httpResponse.status === 404) {
        setIsNotFound(true);
        setIsNotFoundError(true);

      }
    }

  };
  const generateSummary = async () => {
    setIsSummaryModalLoading(true);
    await handleUpdateEncounter();
    const data = await summaryGeneration(encounterId);
    console.log(data);
    await setSummary(data);
    setIsSummaryModalLoading(false);
    handleSummaryModal();
  };

  const handleSummaryModal = () => {
    showSummaryModal(!summaryModal);
  };

  const handleUpdateEncounter = async (autosave = false) => {
    /* Setting the isSaving state to true. */
    setIsSaving(true);
    const encounterObjectKeys = Object?.keys(updateEncounterData);
    // console.log(encounterObjectKeys)
    let data = {};
    for (let i = 0; i < encounterObjectKeys?.length; i++) {
      if (encounterObjectKeys[i] === "notes") {
        data = {
          ...data,
          notes: updateEncounterData?.notes,
          encounterId: updateEncounterData?.encounterId,
        };
      } else if (encounterObjectKeys[i] === "chiefComplaints") {
        data = {
          ...data,
          chiefComplaint: updateEncounterData?.chiefComplaints,
          encounterId: updateEncounterData?.encounterId,
        };
      } else if (encounterObjectKeys[i] === "historyOfPresentIllness") {
        data = {
          ...data,
          historyOfPresentIllness: updateEncounterData?.historyOfPresentIllness,
          encounterId: updateEncounterData?.encounterId,
        };
      } else if (encounterObjectKeys[i] === "reviewOfSystem") {
        data = {
          ...data,
          encounterId: updateEncounterData?.encounterId,
          allSystemsOk: updateEncounterData?.allSystemOk,
          reviewOfSystem: updateEncounterData?.reviewOfSystem,
        };
      } else if (encounterObjectKeys[i] === "intakeFormReviewed") {
        data = {
          ...data,
          encounterId: updateEncounterData?.encounterId,
          intakeFormReviewed: updateEncounterData?.intakeFormReviewed,
        };
      } else if (encounterObjectKeys[i] === "physicalExam") {
        data = {
          ...data,
          encounterId: updateEncounterData?.encounterId,
          physicalExam: updateEncounterData?.physicalExam,
        };
      } else if (encounterObjectKeys[i] === "assessmentNote") {
        data = {
          ...data,
          encounterId: updateEncounterData?.encounterId,
          assessmentNote: updateEncounterData?.assessmentNote,
        };
      } else if (encounterObjectKeys[i] === "recommendationNote") {
        data = {
          ...data,
          encounterId: updateEncounterData?.encounterId,
          recommendationNote: updateEncounterData?.recommendationNote,
        };
      }
      else if (encounterObjectKeys[i] === "pastStatus") {
        console.log("Call to update Past Diagnosis {}", updateEncounterData?.pastStatus)
      }
      // else if (encounterObjectKeys[i] === "Diagnosis") {
      //   let diagnosisData = [];
      //   let secondaryListOfCode = [];
      //   let primaryCode = null;
      //   if (updateEncounterData?.Diagnosis?.Primary?.length > 0) {
      //     primaryCode = updateEncounterData?.Diagnosis.Primary[0].Code;
      //     diagnosisData.push({
      //       type: "P",
      //       code: updateEncounterData?.Diagnosis.Primary[0].code,
      //       note: updateEncounterData?.Diagnosis?.Note,
      //     });
      //   }
      //   if (updateEncounterData?.Diagnosis?.Secondary?.length > 0) {
      //     for (
      //       let i = 0;
      //       i < updateEncounterData?.Diagnosis?.Secondary?.length;
      //       i++
      //     ) {
      //       secondaryListOfCode.push({
      //         Code: updateEncounterData?.Diagnosis?.Secondary[i].Code,
      //       });
      //       diagnosisData.push({
      //         type: "S",
      //         code: updateEncounterData?.Diagnosis.Secondary[i].code,
      //         note: updateEncounterData?.Diagnosis?.Note,
      //       });
      //     }
      //   }
      //   // console.log("diagnosisData", diagnosisData);
      //   data = {
      //     ...data,
      //     encounterId: updateEncounterData?.EncounterId,
      //     diagnosis: diagnosisData,
      //   };
      // }
      if (
        encounterObjectKeys[i] !== undefined &&
        encounterObjectKeys[i] === "vital"
      ) {
        // console.log(updateEncounterData.vital);
        data = {
          ...data,
          encounterId: updateEncounterData?.encounterId,
          vital: { ...updateEncounterData?.vital },
        };
      }
    }
    const httpResponse = await sendHttpRequest({
      url: updateEncounterUrl(encounterId),
      method: "PUT",
      data: data,
    });
    if (!httpResponse.error) {
      if (!autosave) {
        setIsSent(true);
      }
    } else {
      console.log(httpResponse?.error);
    }
    setUnsavedChanges(false);
    dispatch(setDirtyBitInStore(false));
    setIsSaving(false);
    return true;
  };

  const SidebarLinkComponent = ({ children, id }) => {
    return (
      <a className="text-decoration-none" href={`#${id}`}>
        <p className="m-0 p-0 ps-2 text--secondary font-12" data-to-scrollspy-id={`${id}`}>{children}</p>
      </a>
    )
  }

  useEffect(() => {
    fetchEncounterApiData(encounterId);
    if (user?.role === "MedicalAssistant") {
      //Change tab to Vitals
      setValue(2);
    }
    else if (user?.role === "Physician") {
      setValue(2); //RFV
    }

    return () => {
      setEncounterApiData(null);
    };
  }, [encounterId]);

  return (
    <ThemeProvider theme={theme}>
      <>
        {summaryModal && (
          <SummaryModal
            show={summaryModal}
            allergy={summary?.allergies ?? []}
            handleModal={handleSummaryModal}
            handleChange={changeTab}
            summary={summary}

          />
        )}

        {showEndEncounterConsultationModal ? <EndEncounterConsultationModal
          show={showEndEncounterConsultationModal}
          handleEndConsulationModal={handleEndEncounterConsultationModal}
          patientName={patientEncounterInformation?.prescriptionReference?.patient?.name?.at(0)?.firstName?.concat(" ", patientEncounterInformation?.prescriptionReference?.patient?.name?.at(0)?.lastName)}
          appointmentId={appointmentId}
        /> : null}

        {/* {isNotFoundError === false ? <> */}
        <Box
          sx={{
            borderBottom: 1,
            borderTop: 1,
            borderColor: "#D9E8EC",
          }}
        >
          <Tabs
            indicatorColor="secondary"
            value={value}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
          >
            {tabsList.map((item, key) => {
              if (item === "Patient Information" && subscriptionPrivilegeGroups[PATIENTS]?.vpi?.active === true) {
                return (
                  <Tab
                    key={key}
                    label={item}
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                    }}
                  />
                );
              }
              else if (item === "Documents" && subscriptionPrivilegeGroups[PATIENTS]?.vpd?.active === true) {
                return (
                  <Tab
                    key={key}
                    label={item}
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                    }}
                  />
                );
              }
              else if (item === "Allergies" && subscriptionPrivilegeGroups[ENCOUNTERS]?.apa?.active === true) {
                return (
                  <Tab
                    key={key}
                    label={item}
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                    }}
                  />
                );
              }
              else if (item !== "Documents" && item !== "Patient Information" && item !== "Allergies") {
                return (
                  <Tab
                    key={key}
                    label={item}
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                    }}
                  />
                );

              }
            })}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0} className="bg--background h-100">
          <PatientProfileEncounter value={encounterApiData} />
          <BottomNavigation status={status} setStatus={setStatus} appointmentId={appointmentId} previous={undefined} next={getTabValue("Documents")} value={getTabValue("Patient Information") * 10} isSaving={isSaving} handleChange={handleChange} handleUpdateEncounter={handleUpdateEncounter} handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}></BottomNavigation>
        </TabPanel>

        <TabPanel value={value} index={1} className="bg--background vh-100">
          <div className="container-fluid bg--background">
            <div className="row justify-content-center ">
              <div className="col-sm-10 mt-5" style={{ marginBottom: "8%" }}>
                <DocumentTabPatientProfile patientId={patientId} />
              </div>
            </div>
          </div>
          <BottomNavigation handleEndEncounterConsultationModal={handleEndEncounterConsultationModal} status={status} setStatus={setStatus} appointmentId={appointmentId} previous={getTabValue("Patient Information")} next={getTabValue("Reason for Visit")} value={getTabValue("Documents") * 10} isSaving={isSaving} handleChange={handleChange} handleUpdateEncounter={handleUpdateEncounter}></BottomNavigation>
        </TabPanel>


        <TabPanel value={value} index={2} className="bg--background vh-100">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-sm-8">
                <ReasonOfVisit
                  setUnsavedChanges={handleUnsavedChanges}
                  notes={["Note 1", "Note 2"]}
                  cheifComplainit={"Fever, upset stomach"}
                  encounterApiData={encounterApiData}

                ></ReasonOfVisit>
              </div>
            </div>
          </div>
          <BottomNavigation
            appointmentId={appointmentId}
            status={status}
            setStatus={setStatus}
            previous={getTabValue("Documents")}
            next={getTabValue("Vitals")}
            value={getTabValue("Reason for Visit") * 10}
            isSaving={isSaving}
            handleChange={handleChange}
            handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}
            handleUpdateEncounter={handleUpdateEncounter}></BottomNavigation>
        </TabPanel>

        <TabPanel value={value} index={4} className="bg--background vh-100">
          <div className="container-fluid mb-5 bg--background">
            <div className="row justify-content-center mb-5">
              <div className="col-sm-8 mb-5">
                {/* Add component here */}
                <AllergyTable />
              </div>
            </div>
          </div>
          <BottomNavigation
            appointmentId={appointmentId}
            status={status} setStatus={setStatus}
            previous={getTabValue("Vitals")}
            handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}
            next={getTabValue("History")}
            value={getTabValue("Allergies") * 10}
            isSaving={isSaving}
            handleChange={handleChange}
            handleUpdateEncounter={handleUpdateEncounter}></BottomNavigation>
        </TabPanel>

        <TabPanel value={value} index={5} className="bg--background">
          <div className="container-fluid bg--background ">
            <div className="row justify-content-center">
              <div className="col-md-2 col-sm-2 col-lg-2 ps-5 pt-3">
                <div class="position-relative w-100">
                  <div className="d-flex flex-column position-fixed" style={{ "marginTop": "calc(20vh)" }}>
                    <SidebarLinkComponent id={"diagnosis"} > Diagnosis History </SidebarLinkComponent>
                    <SidebarLinkComponent id={"medicationHistory"} > Medication History </SidebarLinkComponent>
                    <SidebarLinkComponent id={"familyMedicationHistory"} > Family Medical History </SidebarLinkComponent>
                    <SidebarLinkComponent id={"surgery"} > Surgical History </SidebarLinkComponent>
                    <SidebarLinkComponent id={"hospitalization"} > Hospitalizations </SidebarLinkComponent>
                    <SidebarLinkComponent id={"socialHistory"} > Social History </SidebarLinkComponent>
                  </div>
                </div>
              </div>
              <div
                className="col-md-8 col-sm-8 col-lg-9 mx-auto my-auto mt-3"
                ref={element => { if (element) element.style.setProperty('margin-bottom', '5.5rem', 'important'); }}
              >
                {/* Add component here */}
                {/* <PatientIntake /> */}

                {/* <Divider
                  sx={{
                    marginTop: 6,
                    marginBottom: 3,
                    color: "#336383",
                  }}
                /> */}
                <ScrollSpy updateHistoryStack={false}>
                  <div id={"diagnosis"}>
                    <DiagnosisHistoryTable prescriptionReferenceId={prescriptionReferenceId} />
                  </div>
                  <Divider
                    sx={{
                      marginTop: 6,
                      marginBottom: 3,
                      color: "#336383",
                    }}
                  />
                  <MedicationHistoryTable id={"medicationHistory"} />
                  <Divider
                    sx={{
                      marginTop: 6,
                      marginBottom: 3,
                      color: "#336383",
                    }}
                  />
                  <FamilyMedicalHistoryTable id={"familyMedicationHistory"} />
                  <Divider
                    sx={{
                      marginTop: 6,
                      marginBottom: 3,
                      color: "#336383",
                    }}
                  />
                  <SurgeryTable id={"surgery"} />
                  <Divider
                    sx={{
                      marginTop: 6,
                      marginBottom: 3,
                      color: "#336383",
                    }}
                  />
                  <HospitalizationTable id={"hospitalization"} />
                  <Divider
                    sx={{
                      marginTop: 6,
                      marginBottom: 3,
                      color: "#336383",
                    }}
                  />
                  <SocialHistoryTable id={"socialHistory"} />
                </ScrollSpy>
              </div>
            </div>
          </div>
          <BottomNavigation
            appointmentId={appointmentId}
            status={status} setStatus={setStatus}
            previous={getTabValue("Allergies")}
            next={getTabValue("Review of Systems")}
            value={getTabValue("History") * 10}
            handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}
            isSaving={isSaving}
            handleChange={handleChange}
            handleUpdateEncounter={handleUpdateEncounter}></BottomNavigation>

        </TabPanel>

        <TabPanel value={value} index={6} className="bg--background vh-100">
          <div className="container-fluid bg--background">
            <div className="row justify-content-center">
              <div className="col-sm-8 mb-5 pb-5">
                {/* Add component here */}
                <ReviewOfSystems
                  setUnsavedChanges={handleUnsavedChanges}
                  encounterApiData={encounterApiData}
                />
              </div>
            </div>
          </div>
          <BottomNavigation
            appointmentId={appointmentId}
            status={status} setStatus={setStatus}
            previous={getTabValue("History")}
            next={getTabValue("Physical Exam")}
            value={getTabValue("Review of Systems") * 10}
            isSaving={isSaving}
            handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}
            handleChange={handleChange}
            handleUpdateEncounter={handleUpdateEncounter}></BottomNavigation>
        </TabPanel>

        <TabPanel value={value} index={3} className="bg--background ">
          <div className="container-fluid bg--background">
            <div className="row justify-content-center">
              <div className="col-sm-9 mb-5 pb-5">
                <PatientVitals
                  appointmentId={appointmentId}
                  encounterApiData={encounterApiData}
                  setUnsavedChanges={handleUnsavedChanges}
                  handleUpdateEncounter={handleUpdateEncounter}
                  status={status} setStatus={setStatus}
                // setStatus={setStatus}
                />
              </div>
            </div>
          </div>
          <BottomNavigation
            appointmentId={appointmentId}
            status={status} setStatus={setStatus}
            previous={getTabValue("Reason for Visit")}
            next={getTabValue("Allergies")}
            value={getTabValue("Vitals") * 10}
            handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}
            isSaving={isSaving}
            handleChange={handleChange}
            handleUpdateEncounter={handleUpdateEncounter}></BottomNavigation>
        </TabPanel>

        <TabPanel value={value} index={7} className="bg--background vh-100">
          <div className="container-fluid bg--background">
            <div className="row justify-content-center">
              <div className="col-sm-8">
                {/* Add component here */}
                <PhysicalExam
                  setUnsavedChanges={handleUnsavedChanges}
                  encounterApiData={encounterApiData}
                />
              </div>
            </div>
          </div>
          <BottomNavigation
            appointmentId={appointmentId}
            status={status} setStatus={setStatus}
            previous={getTabValue("Review of Systems")}
            next={getTabValue("Assessment & Plan")}
            value={getTabValue("Physical Exam") * 10}
            isSaving={isSaving}
            handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}
            handleChange={handleChange}
            handleUpdateEncounter={handleUpdateEncounter}></BottomNavigation>
        </TabPanel>


        {RolesMap(prescriberInfo?.role) !== "staff" ? (
          <TabPanel value={value} index={8} className="bg--background ">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-sm-8" style={{ marginBottom: "8%" }}>
                  {/* Add component here */}
                  <AssesmentPlan
                    encounterApiData={encounterApiData}
                    prescriptionReferenceId={prescriptionReferenceId}
                    setUnsavedChanges={handleUnsavedChanges}
                  />
                </div>
              </div>
            </div>
            <BottomNavigation
              appointmentId={appointmentId}
              status={status} setStatus={setStatus}
              previous={getTabValue("Physical Exam")}
              next={undefined}
              value={getTabValue("Assessment & Plan") * 10 + 10}
              isSaving={isSaving}
              handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}
              summaryModal={summaryModal}
              generateSummary={generateSummary}
              handleChange={handleChange}
              handleUpdateEncounter={handleUpdateEncounter}
              hideSummary={subscriptionPrivilegeGroups[ENCOUNTERS]?.ves?.active}></BottomNavigation>


          </TabPanel>
        ) : (
          ""
        )}
        {/* </> : <h1>Not found</h1>} */}
        <CustomizedSnackbars
          message={"Encounter saved!"}
          severity="success"
          open={isSent}
          handleClose={handleClose}
        />
        {
          <Backdrop
            sx={{
              color: "#EC609B",
              zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open={isSummaryModalLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      </>
    </ThemeProvider>
  );
}
