import { useEffect, useRef, useState } from "react";
import sendHttpRequest, { search } from "../../hooks/sendHttpRequest";
import DrugItem from "../../prescription/DrugItem";
import classes from "./SearchMedication.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { search_medication } from "../../../res/strings";
import { Skeleton } from "@mui/material";
import _ from 'lodash';

const SearchMedication = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [updateCheck, setUpdateCheck] = useState(false);
  const [medicineName, setMedicineName] = useState("");
  const searchRef = useRef()
  const __str = search_medication.en;
  const [isMedicinesLoading, setIsMedicinesLoading] = useState(false)
  const [isError, setIsError] = useState(false);


  const searchDrug = async (drugInputValue) => {
    setSuggestions([]);
    setIsMedicinesLoading(true)
    setIsError(false);
    const httpResponse = await search({
      url: "/drugs",
      method: "GET",
      params: {
        name: drugInputValue,
      },
    });
    if (!httpResponse.error) {
      setSuggestions(httpResponse.data);


    } else {

      // if (httpResponse?.isCancelled){
      //   setIsError(false);
      //   setIsMedicinesLoading(true);

      // }else{
      //   setIsError(true);
      //   setIsMedicinesLoading(false);  
      // }
      // props?.setSig([]);
      if (httpResponse?.status !== undefined) {
        setSuggestions([
          {
            drugDbCode: "-1",
          },
        ]);
      }

    }
    setIsMedicinesLoading(false)
    setIsError(false)
    // console.log("httpResponse",httpResponse);
  };

  const onChangeHandler = (drugsearch) => {
    if (props?.replaceRequest === true) {
      props?.setUpdateCheck(false);
    }
    setMedicineName(drugsearch);
    if (drugsearch.length > 2) {
      _.debounce(() => searchDrug(drugsearch), 300)();
    } else {
      setIsError(false);
      setIsMedicinesLoading(false);
      setSuggestions([]);
    }
  };

  const handleSearchIconBtn = (search) => {
    onChangeHandler(search);
  }

  useEffect(() => {
    if (props?.focus) {
      searchRef?.current?.focus();
    }
  }, [])

  return (
    <div className="row">
      <div className=" col-sm-12 col-12">
        {/* <p className="font-12 fw-sb text--terciary mb-0">{__str.title}</p> */}
        <div
          className={`input-group border--default  br-10 ${classes["search__container"]}`}

        >

          <input
            type="text"
            className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
            id="drugsearch"
            name="drugsearch"
            ref={searchRef}
            autoComplete="off"
            placeholder="Search for medications"
            value={medicineName}
            disabled={props?.updateCheck === true && props?.replaceRequest === false ? true : false}
            // disabled={props?.disableSendRxButton ? true : props?.updateCheck ? true : false}
            onChange={(event) => onChangeHandler(event.target.value)}
            onBlur={() => {
              setTimeout(() => {
                setSuggestions([]);
              }, 10);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                searchDrug(medicineName);
              }
            }}
          />
          <button
            className={props?.disable === true ? `btn br-10 shadow-none bg--disabled` : `btn br-10 shadow-none`}
            type="submit"
            value={medicineName}
            onClick={() => handleSearchIconBtn(medicineName)}
            disabled={props?.updateCheck ? true : false}
            onBlur={() => {
              setTimeout(() => {
                setSuggestions([]);
                //   setIsError(false);
              }, 5);
            }}
          >
            <SearchIcon className=" text--secondary" />
          </button>

        </div>
            {/*  */}
        <ul
          className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
          style={{
            // width: `${props?.newPrescriptionRequest ? "44%" : "34%"}`,
            width: "94%",
          }}
          onBlur={() => {
            setTimeout(() => {
              setSuggestions([]);
              //   setIsError(false);
            }, 5);
          }}
        >
          {
            isMedicinesLoading ? <><li>Loading...</li></> :

            suggestions?.length > 0 && suggestions.map((suggestion) => {
                return suggestion.drugDbCode !== "-1" ? (
                  <DrugItem
                    key={suggestion.drugDbCode + Math.floor(Math.random() * 100)}
                    selectedDrug={suggestion}
                    prescribeDrug={props?.prescribeDrug}
                    fetchAvailableStrengths={props?.fetchAvailableStrengths}
                    setMedicineName={setMedicineName}
                    clearDrugData={props?.clearDrugData}
                    replaceRequest={props?.replaceRequest}
                    setSuggestions = {setSuggestions}
                  />
                ) :
                (
                  <li key={suggestions.drugDbCode}>{__str?.med_not_found}</li>
                )
              })
          }

          {/* {console.log("DD:",drugData)} */}
          {/* {console.log(isMedicinesLoading,isError)} */}
        </ul>
      </div>
    </div>
  );
};

export default SearchMedication;
