import { useEffect } from "react";
import BillingCardContainer from "../../patientportal/patientpages/billing/BillingCardContainer";
import { useAuth } from "../hooks/useAuth";
import GreetingMessage from "../UI/General/GreetingMessage";
import BillingTileContainer from "./BillingTileContainer";
import ClaimAreaChart from "./ClaimAreaChart";
import ClaimPieChart from "./ClaimPieChart";
import ClaimsBarChart from "./ClaimsBarChart";
import ClaimStatusCard from "./ClaimStatusCard";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useState } from "react";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ClaimsLineChart from "./ClaimsLineChart";
import BadgeButtons from "./BadgeButtons";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";

const BillingSpecialistDashboard = () => {
  const { user } = useAuth();
  const [claimsData, setClaimsData] = useState([]);
  const [claimsStats, setClaimsStats] = useState();
  const [isClaimDataLoading, setIsClaimDataLoading] = useState(false);
  const navigate = useNavigate();

  const currentDate = new Date(); // Current date
  let calculatedStartDate = new Date(
    currentDate.setMonth(currentDate.getMonth() - 6)
  );
  const [fromDateVal, setFromDateVal] = useState(calenderDateTimeHelper(calculatedStartDate, "YYYY-MM-DD"));
  const [toDateVal, setToDateVal] = useState(calenderDateTimeHelper(new Date(new Date().setDate(new Date().getDate() + 1)), "YYYY-MM-DD"));

  function calculateClaimPercentage(claimTypeCount, totalClaims) {
    if (totalClaims === 0) {
      return 0; // Avoid division by zero
    }

    const percentage = (claimTypeCount / totalClaims) * 100;
    return Math.floor(percentage); // Round down using Math.floor
  }

  // Function to count claims based on their status
  const processClaimsData = (data) => {
    const statusCounts = {};
    let totalCount = 0;

    // Loop through the data array to count claim statuses
    for (let index = 0; index < data?.length; index++) {
      const element = data[index]; // Correctly accessing the element
      const status = element.status;

      // if (status !== "CLAIM_READY_FOR_SUBMISSION") {
      // Increment the count for each claim status
      if (status in statusCounts) {
        statusCounts[status] += 1;
      } else {
        statusCounts[status] = 1;
      }

      // Increment total count of claims
      totalCount += 1;
      // }
    }

    // Add total count to the statistics
    statusCounts.totalCount = totalCount;

    // Set the state with the updated counts
    setClaimsStats(statusCounts);
  };
  const fetchTenantClaims = async () => {
    setIsClaimDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user?.tenantId}/insurance-claims`,
      method: "GET",
      params: {
        ...(fromDateVal?.length > 0 && { fromDate: fromDateVal }),
        ...(toDateVal?.length > 0 && { toDate: new Date(new Date(toDateVal).setDate(new Date(toDateVal).getDate() + 1)).toISOString().split("T")[0] }),
  
      },
    });

    if (!httpResponse.error) {
      setClaimsData(httpResponse.data?.content ?? []);
      processClaimsData(httpResponse.data?.content ?? []);
    } else {
      setClaimsData([]);
      processClaimsData(undefined);
    }
    setIsClaimDataLoading(false);
  };

  // function navigateToDeniedClaimsPage() {
  //   navigate("/denied-claims",{state:{claimType:"CLAIM_DENIED"}});
  // }

  function navigateToClaimsPage(routeVal, status) {
    navigate(routeVal, { state: { claimType: status } });
  }

  useEffect(() => {
    fetchTenantClaims();
  }, [fromDateVal, toDateVal]);

  // useEffect(()=>{
  //   const currentDate = new Date(); // Current date
  //   let calculatedStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
  //   setFromDateVal(calenderDateTimeHelper(calculatedStartDate,"YYYY-MM-DD"));
  //   setToDateVal(calenderDateTimeHelper(new Date(),"YYYY-MM-DD"));
  // },[])

  return (
    <>
      <div className="container">
        <BadgeButtons
          setFromDateVal={setFromDateVal}
          setToDateVal={setToDateVal}
        />
        <div className="row py-2">
          {/* <div id="pn-provider-greeting" className="col-3 col-md-3">
            <GreetingMessage />
          </div> */}

          <div className="col-3 col-md-3 ">
            <BillingTileContainer key={"ready-claims"}>
              {isClaimDataLoading ? (
                <Skeleton />
              ) : (
                <div
                  className="cursor--pointer"
                  style={{ height: "6rem" }}
                  onClick={() =>
                    navigateToClaimsPage(
                      "/claims/ready-for-submission",
                      "CLAIM_READY_FOR_SUBMISSION"
                    )
                  }
                >
                  <ClaimStatusCard
                    badgeClass="badge-in-progress"
                    infoColor="text--secondary"
                    claimText={"In-process Claim Rate"}
                    claimPercentage={calculateClaimPercentage(
                      claimsStats?.CLAIM_READY_FOR_SUBMISSION,
                      claimsStats?.totalCount
                    )}
                    noOfClaims={
                      claimsStats !== undefined
                        ? claimsStats?.CLAIM_READY_FOR_SUBMISSION
                        : 0
                    }
                  />
                </div>
              )}
            </BillingTileContainer>
          </div>
          <div className="col-3 col-md-3 ">
            <BillingTileContainer key={"approved-claims"}>
              {isClaimDataLoading ? (
                <Skeleton />
              ) : (
                <div
                  className="cursor--pointer"
                  style={{ height: "6rem" }}
                  onClick={() =>
                    navigateToClaimsPage("/claims/approved", "CLAIM_APPROVED")
                  }
                >
                  <ClaimStatusCard
                    badgeClass="badge-success"
                    infoColor="text--green"
                    claimText={"Successful Claim Rate"}
                    claimPercentage={calculateClaimPercentage(
                      claimsStats?.CLAIM_APPROVED,
                      claimsStats?.totalCount
                    )}
                    noOfClaims={
                      claimsStats !== undefined
                        ? claimsStats?.CLAIM_APPROVED
                        : 0
                    }
                  />
                </div>
              )}
            </BillingTileContainer>
          </div>
          <div className="col-3 col-md-3 ">
            <BillingTileContainer
              style={{ backgroundColor: "white" }}
              key={"pending-claims"}
            >
              {isClaimDataLoading ? (
                <Skeleton />
              ) : (
                <div
                  className="text-white cursor--pointer"
                  style={{ height: "6rem" }}
                  onClick={() => {
                    navigateToClaimsPage("/claims/pending", "CLAIM_SUBMITTED");
                  }}
                >
                  <ClaimStatusCard
                    badgeClass="badge-pending"
                    infoColor="text--warning"
                    claimText={"Pending Claim Rate"}
                    claimPercentage={calculateClaimPercentage(
                      claimsStats?.CLAIM_SUBMITTED,
                      claimsStats?.totalCount
                    )}
                    noOfClaims={
                      claimsStats !== undefined
                        ? claimsStats?.CLAIM_SUBMITTED
                        : 0
                    }
                  />
                </div>
              )}
            </BillingTileContainer>
          </div>
          <div className="col-3 col-md-3 ">
            <BillingTileContainer key={"rejected-claims"}>
              {isClaimDataLoading ? (
                <Skeleton />
              ) : (
                <div
                  className="text-white  cursor--pointer "
                  onClick={() =>
                    navigateToClaimsPage("/claims/denied", "CLAIM_DENIED")
                  }
                  style={{ height: "6rem" }}
                >
                  <ClaimStatusCard
                    badgeClass="badge-rejection"
                    infoColor="text--danger"
                    claimText={"Rejection Claim Rate"}
                    claimPercentage={calculateClaimPercentage(
                      claimsStats?.CLAIM_DENIED,
                      claimsStats?.totalCount
                    )}
                    noOfClaims={
                      claimsStats !== undefined ? claimsStats?.CLAIM_DENIED : 0
                    }
                  />
                </div>
              )}
            </BillingTileContainer>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-6 ">
            <BillingTileContainer minHeight={"20rem"} key={"pie-chart"}>
              {isClaimDataLoading ? (
                <Skeleton />
              ) : (
                <div className="d-flex   text-white font-14 text-center fw-sb  align-self-center px-3 py-2">
                  {claimsData?.length > 0 ? (
                    <ClaimPieChart claimsData={claimsData} />
                  ) : (
                    "No data available"
                  )}
                </div>
              )}
            </BillingTileContainer>
          </div>
          <div className="col-6 ">
            <BillingTileContainer minHeight={"20rem"} key={"bar-chart"}>
              {isClaimDataLoading ? (
                <Skeleton />
              ) : (
                <div className=" font-14 text-center fw-sb  align-self-center px-4 py-2">
                  <ClaimsBarChart claimsData={claimsData} />
                </div>
              )}
            </BillingTileContainer>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-6 ">
            <BillingTileContainer
              minHeight={"15rem"}
              bgColor={"bg--white"}
              style={{ minHeight: "20rem" }}
              key={"area-chart"}
            >
              {isClaimDataLoading ? (
                <Skeleton />
              ) : (
                <div
                  className="text-white font-14 text-center fw-sb  br-10 px-3 py-2"
                  style={{ maxHeight: "20rem" }}
                >
                  <ClaimAreaChart claimsData={claimsData} />
                </div>
              )}
            </BillingTileContainer>
          </div>
          <div className="col-6 ">
            {isClaimDataLoading ? (
              <Skeleton />
            ) : (
              <BillingTileContainer minHeight={"20rem"} key={"line-chart"}>
                <div className="text-white font-14 text-center fw-sb  px-3 py-2">
                  <ClaimsLineChart claimsData={claimsData} />
                </div>
              </BillingTileContainer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingSpecialistDashboard;
