import React from 'react';

const HipaaVerified = () => {
    return (
        <div title='Patient has filled the HIPAA consent form' className="verified-badge">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="verified-icon"
                aria-hidden="true"
            >
                <circle cx="12" cy="12" r="10"></circle>
                <path
                    d="M9.5 13.5l2 2 4-4"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                ></path>
            </svg>
        </div>
    );
};

export default HipaaVerified;