import SearchIcon from "@mui/icons-material/Search";
import classes from "./NdcCodeSearch.module.css";

const NdcCodeSearch = (props) => {
    return (
        <div style={{ minHeight: '32px' }}>
            <div className="input-group" style={{
                border: "1px solid #D9E8EC",
                borderRadius: "10px"
            }}>
                <input type="text" className="form-control br-10 font-12 shadow-none border-0  " placeholder="Search NDC code" value={props.ndcCodeName} onChange={e => {
                    props.handleSearchInput(e.target.value);
                }} onBlur={() => {
                    setTimeout(() => {
                        props.setSuggestions([]);
                        props.setIsError(false);
                        props.setIsLoading(false);
                    }, 10);
                }} />

                <button className="border-0 outline-0 px-1  br-10 shadow-none " type="submits" value={props.searchInputValue} onClick={() => props.handleSearchInput(props.searchInputValue)} onBlur={() => {
                    setTimeout(() => {
                        props.setSuggestions([]);
                        props.setIsError(false);
                        props.setIsLoading(false);
                    }, 5);
                }}>
                    <SearchIcon fontSize="small" className="text--secondary font-12" />
                </button>
            </div>

            <ul className={`list-group ${classes["ndc_suggestions"]}`}>
                {props.isError ? <li className={`list-group-item ${classes["search__list__item"]}`}>
                    No result found
                </li> : props?.isLoading ? <li className={`list-group-item ${classes["search__list__item"]}`}>
                    Loading...
                </li> : props.suggestions.length > 0 && props.suggestions.map((suggestion, index) => {
                    return <li tabIndex={index} // className="list-group-item"
                        className={`border--default ${classes.li} ${classes["search__list__item"]}`} key={suggestion.drugDbCode} onMouseDown={() => props.handleAddNdcCode(suggestion)} // onKeyDown = {()=>console.log("works")}
                    >
                        {/* <span>{suggestion.ndc} - </span> */}
                        <span className="ms-1">{suggestion.drugDescription} ({suggestion.ndc})</span>
                    </li>;
                })}
            </ul>
        </div>
    )
}

export default NdcCodeSearch