import { useCallback, useEffect, useRef, useState } from "react";
import PageHeader from "../../../../components/UI/General/PageHeader";
import PendingClaimEncountersTable from "../tables/PendingClaimEncountersTable";
import { useAuth } from "../../../../components/hooks/useAuth";
import { LinearProgress, setRef, Skeleton } from "@mui/material";
import sendHttpRequest from "../../../../components/hooks/sendHttpRequest";
import AddIcon from "@mui/icons-material/Add";
import CalenderDatePicker from "../../../../components/UI/General/CalenderDatePicker";
import { DateTime } from "luxon";
import useDiagnosisSearch from "../../../../components/diagnosis-search/useDiagnosisSearch";
import DiagnosisSearch from "../../../../components/diagnosis-search/DiagnosisSearch";
import useClaimPlaceOfServiceCodes from "../../claim-hooks/useClaimPlaceOfServiceCodes";
import TrashButtonIcon from "../../../../assets/icons/Icon-Trash.svg";
import useSnackbar from "../../../../components/hooks/useSnackbar";
import CustomizedSnackbar from "../../../../components/UI/General/CustomizedSnackbars";
import { useDispatch, useSelector } from "react-redux";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { BILLING_AND_CLAIMS } from "../../../../utils/helpers/subscriptionsConstants";
import {
  fieldValidation,
  formatPhoneNumber,
  unformatPhoneNumber,
} from "../../../../utils/helpers/HelperFunctions";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  PnToolTip,
} from "../../../../components/UI/Reusable/PnComponents";
import ClaimReferringPhysicianModal from "../UI/Modals/ClaimReferringPhysicianModal";
import { dateTimeHelper } from "../../../../utils/dateTimeHelper";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import classes from "./PendingClaimEncounters.module.css";
import { setDirtyBitInStore } from "../../../../redux/actions/providerActions";
import { PnSelect } from "../../../../components/UI/PnSelect";
const CLAIM_STATUS_OPTIONS = [
  { value: "ALL", label: "All" },
  { value: "AWAITING_PREPARATION", label: "Awaiting Claim Preparation" },
  { value: "CLAIM_READY_FOR_SUBMISSION", label: "Ready for Submission" },
  { value: "CLAIM_SUBMITTED", label: "Claim Submitted" },
  // { value: "CLAIM_APPROVED", label: "Claim Approved" },
  // { value: "CLAIM_SUBMISSION_ERROR", label: "Claim Submission Error" },
  // { value: "CLAIM_DENIED", label: "Claim Denied" },
];
const PendingClaimEncounters = (props) => {
  const { user } = useAuth();
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } =
    useSelector((state) => state.subscriptionPrivilegeGroups);
  const [pendingClaimEncounters, setPendingClaimEncounters] = useState([]);
  const [
    arePendingClaimEncountersLoading,
    setArePendingClaimEncountersLoading,
  ] = useState(false);
  const [reloadEncounters, setReloadEncounters] = useState(false);
  const [isTabular, setIsTabular] = useState(true);
  const [encounterDto, setEncounterDto] = useState({ diagnosis: [] });

  const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } =
    useClaimPlaceOfServiceCodes();
  const placeOfServiceCodesMap = new Map();
  for (const place in placeOfServiceCodes) {
    placeOfServiceCodesMap.set(place.code, place.name);
  }

  const [
    openEncounterSnackbar,
    encounterSnackbarMessage,
    encounterSnackbarSeverity,
    setOpenEncounterSnackbar,
    setEncounterSnackbarMessage,
    setEncounterSnackbarSeverity,
    handleEncounterSnackbarClose,
  ] = useSnackbar();

  //=========Referring Physician=========//
  const [referringPhysiciansList, setReferringPhysiciansList] = useState([]);
  const [areReferringPhysiciansLoading, setAreReferringPhysiciansLoading] =
    useState(false);
  const [referringPhysician, setReferringPhysician] = useState({});
  const [showReferringPhysicianFields, setShowReferringPhysicianFields] =
    useState(false);
  const [regexErrors, setRegexErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    zipcode: false,
  });

  // Filter states
  const [renderingProvidersList, setRenderingProvidersList] = useState([]);
  const [areRenderingProvidersLoading, setAreRenderingProvidersLoading] =
    useState(false);

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  
  const [patientsList, setPatientsList] = useState([]);
  console.log("patientsList: ",patientsList);
  const [arePatientsLoading, setArePatientsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // Pagination states
  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(undefined);

  function handleReferringPhysicianData(eventOrDate) {
    if (
      typeof eventOrDate === "string" &&
      eventOrDate !== "Invalid DateTime" &&
      eventOrDate !== undefined
    ) {
      const dateValue = eventOrDate;
      setReferringPhysician((previous) => ({
        ...previous,
        referralDate: dateValue,
      }));
    } else if (eventOrDate !== undefined) {
      const name = eventOrDate.target.name;
      const value = eventOrDate.target.value;

      if (name === "referringPhysicianDropdown") {
        const referringPhysicianId = value;
        const referringPhysicianData = referringPhysiciansList?.find(
          (physician) => physician?.referringPhysicianId == referringPhysicianId
        );
        setReferringPhysician(referringPhysicianData);
      } else {
        setReferringPhysician((previous) => {
          const newData = { ...previous };
          newData[name] = value;
          return newData;
        });
      }
    }
  }

  const handlePhysicianPhone = (phoneValue) => {
    // console.log("Handling Physcian Phone", phoneValue);
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length == 12
    ) {
      // console.log("Valed Part");
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      setReferringPhysician((previous) => {
        const newData = { ...previous };
        newData["phone"] = mobileNumber;
        return newData;
      });
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
      setReferringPhysician((previous) => {
        const newData = { ...previous };
        newData["phone"] = "";
        return newData;
      });
    } else if (phoneValue?.length === 2 || phoneValue === undefined) {
      // dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));

      setReferringPhysician((previous) => {
        const newData = { ...previous };
        newData["phone"] = "";
        return newData;
      });
    }
  };

  async function loadReferringPhysicians(tenantId) {
    setAreReferringPhysiciansLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/claims/${tenantId}/referring-physicians/all`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Referring Physicians-> ", httpResponse.data);
      setReferringPhysiciansList(httpResponse.data);
    }
    setAreReferringPhysiciansLoading(false);
  }

  function switchView() {
    setIsTabular(!isTabular);
    // props.setTabView(p => !p);
  }

  const [editMode, setEditMode] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  function revertEditMode() {
    setEditMode(false);
    switchView();
    setEncounterDto({ diagnosis: [] });
  }

  async function fetchPendingClaimEncounters(currentPageNo,provider,patient,status) {
    setArePendingClaimEncountersLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/encounters`,
      method: "GET",
      params: {
        page: currentPageNo - 1,
        size: 10,
        ...(provider  && { providerId: provider?.value }),
        ...(patient   && { patientId: patient?.value }),
        ...(status  && { status: status?.value }),
      },
    });
    if (!httpResponse.error) {
      const reversedData = httpResponse.data?.content;
        
      // const reversedData = httpResponse.data?.content
      //   .sort((a, b) => b.encounterId - a.encounterId)
      //   .reverse();
      setPendingClaimEncounters(reversedData);
      let noOfPages = httpResponse?.data?.totalPages;
      setTotalResults(noOfPages);
      setCurrentItems(reversedData);
      setMaxPageLimit(noOfPages);
      let pagesList = [];
      for (let i = 1; i <= noOfPages; i++) {
        pagesList.push(i);
      }
      setPages(pagesList);
    } else {
      setPages([]);
      setTotalResults(0);
      setCurrentItems([]);
      setMaxPageLimit(0);

      setPendingClaimEncounters([]);
      console.log(httpResponse.error);
    }
    setArePendingClaimEncountersLoading(false);
  }
  
  const fetchClaimPatients = async () => {
    setArePatientsLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/patients`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const claimPatients = httpResponse.data;
      const patientDropdownOptions = claimPatients?.map((patient) => ({
        value: patient?.patientId,
        label: `${patient?.firstName} ${patient?.lastName}`,
        patient: patient,
      }));
      setPatientsList(patientDropdownOptions);
    } else {
      setPatientsList([]);
      console.log(httpResponse.error);
    }
    setArePatientsLoading(false);
  };
  async function saveEncounter() {
    let httpResponse = null;
    if (editMode === true) {
      httpResponse = await sendHttpRequest({
        url: `/claims/patients/${encounterDto?.patient?.patientId}/${encounterDto?.encounterId}/encounters`,
        method: "PUT",
        data: { ...encounterDto, tenant: { tenantId: user?.tenantId } },
      });
    } else {
      const data = { ...encounterDto, tenant: { tenantId: user?.tenantId } };
      if (
        typeof referringPhysician === "object" &&
        Object.keys(referringPhysician)?.length > 2
      ) {
        data["referringPhysician"] = referringPhysician;
        data["referralDate"] = `${referringPhysician?.referralDate}T00:00:00`;
        data["referralCode"] = referringPhysician?.referralCode;
        if (referringPhysician?.referralNote?.length > 0) {
          data["referralNote"] = referringPhysician?.referralNote;
        }
      }
      httpResponse = await sendHttpRequest({
        url: `/claims/patients/${encounterDto?.patient?.patientId}/encounters`,
        method: "POST",
        data: data,
      });
    }
    if (!httpResponse.error) {
      setEncounterDto({ diagnosis: [] });
      setReloadEncounters((p) => !p);
      setIsTabular(true);
    } else {
      setEncounterSnackbarMessage("Error occur while saving Encounter");
      setEncounterSnackbarSeverity("error");
      setOpenEncounterSnackbar(true);
      console.log(httpResponse);
    }
  }
  const fetchReneringProviders = async () => {
    setAreRenderingProvidersLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/providers`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const renderingProviders = httpResponse.data;
      const renderingProviderOptions = renderingProviders?.map((provider) => ({
        value: provider?.providerId,
        label: `${provider?.prefix} ${provider?.firstName} ${provider?.lastName}`,
        provider: provider,
      }));
      setRenderingProvidersList(renderingProviderOptions);
    } else {
      setRenderingProvidersList([]);
      console.log(httpResponse.error);
    }
    setAreRenderingProvidersLoading(false);
  };

  function handleClaimStatus(option) {
    setSelectedStatus(option);
  }

  const handleFilter = useCallback(async () => {
    fetchPendingClaimEncounters(currentPage,selectedProvider,selectedPatient,selectedStatus);
  }, [
    currentPage,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fetchPendingClaimEncounters
    ]);

  const handleClearFilter = useCallback(async () => {
    setSelectedPatient(null);
    setSelectedProvider(null);
    setSelectedStatus(null);
    setCurrentPage(1);
    fetchPendingClaimEncounters(1,null,null,null);
  
  }, [
    currentPage,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fetchPendingClaimEncounters
    ]);

  const handleFromDate = (dateValue) => {
    setFromDate(dateValue);
  };
  function handleProviderId(selectedOption) {
    setSelectedProvider(selectedOption);
    // props?.setEncounterDto((dto) => ({ ...dto, "claimProviderDataId": event.target.value, "provider": { "providerId": event.target.value } }))
  }

  function handlePatientId(option) {
    setSelectedPatient(option);
  }

  // Pagination Handlers
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            // if(number > maxPageNumberLimit){
            //   console.log(number,maxPageNumberLimit + 1,minPageNumberLimit,number < maxPageNumberLimit + 1 ,
            //     number > minPageNumberLimit);
            // }
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${classes["list__item"]} ${
                    currentPage === number ? classes["active__list__item"] : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    fetchPendingClaimEncounters(currentPage + 1,selectedProvider,selectedPatient,selectedStatus);
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // setPageNumber(pageNumber - 1);
    // console.log("currentPage: ",currentPage-1);
    if (currentPage - 1 >= 1) {
      setCurrentPage((p) => currentPage - 1);
      fetchPendingClaimEncounters(currentPage - 1,selectedProvider,selectedPatient,selectedStatus);
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
    // searchPharmacy(inputValue, "Retail,MailOrder", currentPage - 1);
  };
  const handleFirstPage = (event) => {
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);

    handleCurrentPage(1);
  };
  const handleLastPage = (event) => {
    event.preventDefault();
    let lastPage = Math.ceil(totalResults);
    let maxPageNumberLimitValue = lastPage;
    if (maxPageNumberLimitValue % 10 > 0) {
      maxPageNumberLimitValue = maxPageNumberLimitValue - 5;
    }
    setCurrentPage(totalResults);
    setMaxPageNumberLimit(lastPage);
    setMinPageNumberLimit(lastPage - pageNumberLimit);

    handleCurrentPage(lastPage);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    fetchPendingClaimEncounters(pNumber,selectedProvider,selectedPatient,selectedStatus);
  };

  useEffect(() => {
    fetchReneringProviders();
    fetchClaimPatients();
    fetchPendingClaimEncounters(currentPage,selectedProvider,selectedPatient,selectedStatus);
    loadReferringPhysicians(user?.tenantId);
  }, [user?.tenantId, reloadEncounters]);

  return (
    <>
      <PageHeader
        title={
          editMode
            ? "Edit Encounter Data"
            : !isTabular
            ? "Add Encounter Data"
            : "Encounters"
        }
        revertEditMode={revertEditMode}
      />

      <div className="container py-4" style={{minHeight:"500px"}}>
        {isTabular ? (
          subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bae?.active ===
          true ? (
            <>
                <div className="row justify-content-center">
                    <div className="col-sm-10 text-end">
                    <button
                        onClick={switchView}
                        className="btn blue_task_button mb-2  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--secondary font-12"
                    >
                        <span className=" pe-1">
                        <AddIcon color="#336383" size={14} />{" "}
                        </span>
                        <span
                        className="d-inline-block "
                        style={{ verticalAlign: "middle" }}
                        >
                        Add Encounter Data
                        </span>
                    </button>
                    </div>
                </div>
            </>
          ) : null
        ) : null}
        
        {isTabular ? (
          <>
            <div className="row mb-1 justify-content-center">
              <div className="col-10">
                  <div className="row">
                      <div className="col-sm-3">
                      <div className="form-group">
                          <label className="form-label text--secondary font-12 fw-sb">
                          Rendering Provider
                          </label>
                          {areRenderingProvidersLoading === true ? (
                          <Skeleton animation="wave" variant="text" width="100%" />
                          ) : (
                          <PnSelect
                              isClearable={true}
                              isSearchable
                              onChange={handleProviderId}
                              name="providers"
                              closeMenuOnSelect={true}
                              options={renderingProvidersList}
                              value={selectedProvider}
                          />
                          )}
                      </div>
                      </div>

                      <div className="col-sm-3">
                      <div className="form-group">
                          <label className="form-label text--secondary font-12 fw-sb">
                          Patient
                          </label>
                          {arePatientsLoading === true ? (
                          <Skeleton animation="wave" variant="text" width="100%" />
                          ) : (
                          <PnSelect
                              isClearable={true}
                              isSearchable
                              onChange={handlePatientId}
                              name="patients"
                              closeMenuOnSelect={true}
                              options={patientsList}
                              value={selectedPatient}
                          />
                          )}
                          {console.log("renderingProvidersList: ",renderingProvidersList)}
                          
                      </div>
                      </div>

                      <div className="col-sm-3">
                      <div className="form-group">
                          <label className="form-label text--secondary font-12 fw-sb">
                          Status
                          </label>
                          <PnSelect
                          isClearable={true}
                          isSearchable
                          onChange={handleClaimStatus}
                          name="claimStatus"
                          closeMenuOnSelect={true}
                          options={CLAIM_STATUS_OPTIONS}
                          value={selectedStatus}
                          />
                      </div>
                      </div>
                      <div className="col-sm-3 align-self-end d-flex justify-content-between">
                          <button
                              onClick={handleFilter}
                              className="btn blue_task_button br-10 shadow-none"
                          >
                              Filter Data
                          </button>
                          <div className="">
                              <span
                                  onClick={handleClearFilter}
                                  className={`pn-link-button d-inline-block  text-decoration-underline fw-sb font-12 text--secondary cursor--pointer ${
                                  // (claimNumber == "" || claimNumber == null) &&
                                  selectedProvider == null &&
                                  selectedPatient == null &&
                                  selectedStatus == null &&
                                  fromDate == null &&
                                  toDate == null
                                      ? "disabled-link cursor--disabled"
                                      : ""
                                  }`}
                              >
                                  Clear Filters
                              </span>
                          </div>
                      </div>
                      
                  </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-10">
                  {arePendingClaimEncountersLoading ? <Skeleton/> :
                  <PendingClaimEncountersTable
                      data={currentItems}
                      setEditMode={setEditMode}
                      editMode={editMode}
                      setEncounterToEdit={setEncounterDto}
                      setTabView={setIsTabular}
                      switchView={switchView}
                      setReloadEncounters={setReloadEncounters}
                      columnHeadersList={[
                      { key: "provider.firstName", value: "Provider" },
                      { key: "patient.firstName", value: "Patient" },
                      {
                          key: "encounterMetadata.dateOfServiceTo",
                          value: "Service Date",
                      },
                      {
                          key: "encounterMetadata.serviceLocation",
                          value: "Service Location",
                      },
                      {
                          key: "encounterMetadata.placeOfService ",
                          value: "Place of Service",
                      },
                      { key: "diagnosis ", value: "Diagnosis" },
                      {
                          key: "dateOfCurrentIllnessOrSymptom ",
                          value: "Date of Current Illnes",
                      },
                      { key: "claim.status", value: "Status" },
                      { key: " ", value: " " },
                      ]}
                  />
                  }
              </div>
              <div className="row justify-content-center">
                <div
                  className={` mt-4 align-self-center col-sm-8 col-8 justify-content-center`}
                >
                  {arePendingClaimEncountersLoading ? (
                    <Skeleton />
                  ) : (
                    <div className={" d-flex flex-row justify-content-between"}>
                      {pages.length > 1 ? (
                        <div className="ps-2 mt-2">
                          <PnToolTip title="First" placement="top">
                            <button
                              disabled={currentPage === 1}
                              onClick={(e) => {
                                handleFirstPage(e);
                              }}
                              className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                            >
                              {/* <img src={IconNext} alt="icon-previous"></img> */}
                              <FirstPageIcon style={{ color: "#ec609b" }} />
                            </button>
                          </PnToolTip>
                          <PnToolTip title="Previous" placement="top">
                            <button
                              disabled={currentPage === 1}
                              onClick={(e) => {
                                handlePreButton(e);
                              }}
                              className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                            >
                              <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                              {/* <img src={IconNext} alt="icon-previous"></img> */}
                            </button>
                          </PnToolTip>
                        </div>
                      ) : (
                        ""
                      )}

                      <RenderPages />

                      {pages.length > 1 ? (
                        <div className="mt-2">
                          <PnToolTip title="Next" placement="top">
                            <button
                              disabled={currentPage === pages.length}
                              onClick={(e) => {
                                handleNextButton(e);
                              }}
                              className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                            >
                              {/* <img src={IconNext} alt="icon-next"></img> */}
                              <NavigateNextIcon style={{ color: "#ec609b" }} />
                            </button>
                          </PnToolTip>
                          <PnToolTip title="Last" placement="top">
                            <button
                              disabled={currentPage === pages.length}
                              onClick={(e) => {
                                handleLastPage(e);
                              }}
                              className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                            >
                              {/* <img src={IconNext} alt="icon-next"></img> */}
                              <LastPageIcon style={{ color: "#ec609b" }} />
                            </button>
                          </PnToolTip>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="row justify-content-center">
            <div className="col-sm-10">
              <EncounterForm
                saveEncounter={saveEncounter}
                encounterDto={encounterDto}
                editMode={editMode}
                changesMade={changesMade}
                setChangesMade={setChangesMade}
                showReferringPhysicianFields={showReferringPhysicianFields}
                setShowReferringPhysicianFields={
                  setShowReferringPhysicianFields
                }
                setEncounterDto={setEncounterDto}
                referringPhysician={referringPhysician}
                setReferringPhysician={setReferringPhysician}
                referringPhysiciansList={referringPhysiciansList}
                handlePhysicianPhone={handlePhysicianPhone}
                regexErrors={regexErrors}
                setRegexErrors={setRegexErrors}
                tenantId={user?.tenantId}
                handleReferringPhysicianData={handleReferringPhysicianData}
              />
            </div>
          </div>
        )}
      </div>

      {isTabular ? null : (
        <BottomNavigation
          editMode={editMode}
          changesMade={changesMade}
          encounterDto={encounterDto}
          saveEncounter={saveEncounter}
        />
      )}
      {openEncounterSnackbar ? (
        <CustomizedSnackbar
          open={openEncounterSnackbar}
          handleClose={handleEncounterSnackbarClose}
          message={encounterSnackbarMessage}
          severity={encounterSnackbarSeverity}
        />
      ) : null}
    </>
  );
};

export default PendingClaimEncounters;

function EncounterForm(props) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [renderingProvidersList, setRenderingProvidersList] = useState([]);
  const [areRenderingProvidersLoading, setAreRenderingProvidersLoading] =
    useState(false);
  const {
    handleReferringPhysicianData,
    tenantId,
    regexErrors,
    setShowReferringPhysicianFields,
    showReferringPhysicianFields,
    handlePhysicianPhone,
    referringPhysician,
    referringPhysiciansList,
    setReferringPhysician,
    editMode,
  } = props;

  const [patientsList, setPatientsList] = useState([]);
  const [arePatientsLoading, setArePatientsLoading] = useState(false);

  const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } =
    useClaimPlaceOfServiceCodes();
  const [isTabular, setIsTabular] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(
    props?.encounterDto?.provider?.providerId ?? null
  );
  const [selectedPlaceOfService, setSelectedPlaceOfService] = useState(
    props?.encounterDto?.encounterMetadata?.placeOfService ?? null
  );
  const [selectedServiceLocation, setSelectedServiceLocation] = useState(
    props?.encounterDto?.encounterMetadata?.serviceLocation ?? null
  );
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientGender, setSelectedPatientGender] = useState(
    props?.encounterDto?.patient?.gender ?? null
  );
  const [serviceLocations, setServiceLocations] = useState([]);
  const [areServiceLocationsLoading, setAreServiceLocationsLoading] =
    useState(false);

  const serviceOptionsRef = useRef([]);

  const [serviceDate, setServiceDate] = useState(null);
  const [lastSeenDate, setLastSeenDate] = useState(null);
  const [dateOfCurrentIllness, setDateOfCurrentIllness] = useState(null);
  const [lastMensturalPeriodDate, setLastMensturalPeriodDate] = useState(null);

  const {
    suggestions,
    setSuggestions,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    searchInputValue,
    setSearchInputValue,
    diagnosisName,
    setDiagnosisName,
    selectedDiagnosis,
    setSelectedDiagnosis,
    searchDiagnosis,
    handleSearchInput,
    handleAddDiagnosis,
    handleRemoveDiagnosis,
  } = useDiagnosisSearch();
  const [diagnosisList, setDiagnosisList] = useState([]);

  const [phone, setPhone] = useState("");

  function handlePhone(phoneValue) {
    setPhone(phoneValue);
    handlePhysicianPhone(phoneValue);
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  const handleDiagnosisList = (addedDiagnosis) => {
    handleAddDiagnosis(addedDiagnosis);
    if (diagnosisList?.length === 0) {
      addedDiagnosis["type"] = "P";
    }
    setDiagnosisList((prevList) => [...prevList, addedDiagnosis]);
    props?.setEncounterDto((dto) => ({
      ...dto,
      diagnosis: [...diagnosisList, addedDiagnosis],
    }));
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  };

  function handleRemoveDiagnosisFromList(diagnosisToRemove) {
    handleRemoveDiagnosis(diagnosisToRemove?.code);
    const diagnosisToRemoveId = diagnosisToRemove.code;
    setDiagnosisList((prevList) =>
      prevList.filter((diagnosis) => diagnosis.code !== diagnosisToRemove.code)
    );
    props?.setEncounterDto((dto) => ({
      ...dto,
      diagnosis: diagnosisList.filter(
        (diagnosis) => diagnosis?.code !== diagnosisToRemove?.code
      ),
    }));
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  function handleProviderId(event) {
    dispatch(setDirtyBitInStore(true));
    setSelectedProvider(event.target.value);
    props?.setEncounterDto((dto) => ({
      ...dto,
      claimProviderDataId: event.target.value,
      provider: { providerId: event.target.value },
    }));
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  function handlePatientId(event) {
    dispatch(setDirtyBitInStore(true));
    setSelectedPatient(event.target.value);
    props?.setEncounterDto((dto) => ({
      ...dto,
      patient: { patientId: event.target.value, gender: patient?.gender },
    }));
    const patient = patientsList?.find(
      (p) => p?.patientId == event.target.value
    );
    setSelectedPatientGender(patient?.gender);

    if (patient?.gender?.toLowerCase() === "female") {
      setLastMensturalPeriodDate(undefined);
      props?.setEncounterDto((dto) => ({
        ...dto,
        lastMenstrualPeriodDate: "",
      }));
    }
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  function handlePlaceOfService(event) {
    dispatch(setDirtyBitInStore(true));
    setSelectedPlaceOfService(event.target.value);
    props?.setEncounterDto((dto) => ({
      ...dto,
      encounterMetadata: {
        ...dto?.encounterMetadata,
        placeOfService: event.target.value,
      },
    }));
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  function handleServiceLocation(event) {
    dispatch(setDirtyBitInStore(true));
    setSelectedServiceLocation(event.target.value);
    const facilityId =
      serviceOptionsRef.current[event.target.selectedIndex - 1].getAttribute(
        "data-fid"
      );
    props?.setEncounterDto((dto) => ({
      ...dto,
      facility: { facilityId: parseInt(facilityId) },
      encounterMetadata: {
        ...dto?.encounterMetadata,
        serviceLocation: event.target.value,
      },
    }));
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  function handleServiceDate(date) {
    dispatch(setDirtyBitInStore(true));
    const tempDate = `${date}T00:00:00`;
    setServiceDate(date);
    props?.setEncounterDto((dto) => ({
      ...dto,
      encounterMetadata: {
        ...dto?.encounterMetadata,
        dateOfServiceTo: tempDate,
      },
    }));
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  function handleCurrentIllnessDate(date) {
    const tempDate = `${date}T00:00:00`;
    setDateOfCurrentIllness(date);
    props?.setEncounterDto((dto) => ({
      ...dto,
      dateOfCurrentIllnessOrSymptom: `${date}T00:00:00`,
    }));
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  function handleLastSeenDate(date) {
    dispatch(setDirtyBitInStore(true));
    setLastSeenDate(date);
    props?.setEncounterDto((dto) => ({
      ...dto,
      lastSeenDate: `${date}T00:00:00`,
    }));
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  function handleLastMenstrualPeriodDate(date) {
    dispatch(setDirtyBitInStore(true));
    setLastMensturalPeriodDate(date);
    props?.setEncounterDto((dto) => ({
      ...dto,
      lastMenstrualPeriodDate: `${date}T00:00:00`,
    }));
    if (props?.editMode) {
      props?.setChangesMade((p) => true);
    }
  }

  async function fetchServiceLocations() {
    setAreServiceLocationsLoading(true);
    // /claims/tenants/{tenantId}/facilities
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/facilities`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse?.data);
      setServiceLocations(httpResponse.data);
    } else {
      setServiceLocations([]);
      // console.log(httpResponse.error);
    }
    setAreServiceLocationsLoading(false);
  }

  const fetchReneringProviders = async () => {
    setAreRenderingProvidersLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/providers`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const setOfProviders = new Set(httpResponse.data);
      const arr = [...setOfProviders];
      setRenderingProvidersList(arr);
    } else {
      setRenderingProvidersList([]);
      console.log(httpResponse.error);
    }
    setAreRenderingProvidersLoading(false);
  };

  const fetchClaimPatients = async () => {
    setArePatientsLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/patients`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setPatientsList(httpResponse.data);
    } else {
      setPatientsList([]);
      console.log(httpResponse.error);
    }
    setArePatientsLoading(false);
  };

  function switchView() {
    setIsTabular(!isTabular);
    props.setTabView((p) => !p);
  }

  useEffect(() => {
    fetchReneringProviders();
    fetchClaimPatients();
    fetchServiceLocations();
    if (editMode) {
      setReferringPhysician(props?.encounterDto?.referringPhysician);
    }
    return () => {
      setReferringPhysician({});
      dispatch(setDirtyBitInStore(false));
    };
  }, []);

  useEffect(() => {
    // console.log(props?.encounterDto?.diagnosis);
    setDiagnosisList(props?.encounterDto?.diagnosis);
  }, [props?.encounterDto?.diagnosis]);

  return (
    <>
      {showReferringPhysicianFields ? (
        <ClaimReferringPhysicianModal
          show={showReferringPhysicianFields}
          handleModalClose={() => {
            setShowReferringPhysicianFields(false);
          }}
          referringPhysician={referringPhysician}
          regexErrors={regexErrors}
          setShowReferringPhysicianFields={setShowReferringPhysicianFields}
          tenantId={user?.tenantId}
          setReferringPhysician={setReferringPhysician}
          referringPhysiciansList={referringPhysiciansList}
          handleReferringPhysicianData={handleReferringPhysicianData}
          handlePhysicianPhone={handlePhysicianPhone}
        />
      ) : null}
      <div className="row py-1 justify-content-center">
        <div className="col-sm-3">
          <div className="form-group">
            <label className="form-label text--secondary font-12 fw-sb">
              Rendering Provider <span>&#42;</span>
            </label>
            {areRenderingProvidersLoading === true ? (
              <Skeleton animation="wave" variant="text" />
            ) : (
              <select
                className={`form-select br-10 font-12 input border--default shadow-none`}
                aria-label="Provider select"
                onChange={(e) => handleProviderId(e)}
                required={true}
                value={
                  props?.encounterDto?.provider?.providerId || selectedProvider
                }
              >
                <option value="" disabled selected hidden>
                  Select provider
                </option>
                {renderingProvidersList?.length > 0
                  ? renderingProvidersList?.map((renderingProvider) => {
                      return (
                        <option
                          value={renderingProvider?.providerId ?? ""}
                          key={
                            renderingProvider?.providerId + Math.random() ?? ""
                          }
                        >
                          {renderingProvider?.prefix}{" "}
                          {renderingProvider?.firstName}{" "}
                          {renderingProvider?.lastName}{" "}
                        </option>
                      );
                    })
                  : "No prescribers available"}
              </select>
            )}
          </div>
        </div>

        <div className="col-sm-3">
          <div className="form-group">
            <label className="form-label text--secondary font-12 fw-sb">
              Patient <span>&#42;</span>
            </label>
            {arePatientsLoading === true ? (
              <Skeleton animation="wave" variant="text" />
            ) : (
              <select
                className={`form-select br-10 font-12 input border--default shadow-none`}
                aria-label="Provider select"
                required={true}
                onChange={(e) => handlePatientId(e)}
                value={
                  props?.encounterDto?.patient?.patientId || selectedPatient
                }
              >
                <option value="" disabled selected hidden>
                  Select patient
                </option>
                {patientsList?.length > 0
                  ? patientsList?.map((patient) => {
                      return (
                        <option
                          value={patient?.patientId ?? ""}
                          key={patient?.patientId ?? ""}
                        >
                          {patient?.firstName} {patient?.lastName}{" "}
                        </option>
                      );
                    })
                  : "No patients available"}
              </select>
            )}
          </div>
        </div>
        <div className="col-sm-3">
          <div className="form-group">
            <label className="form-label text--secondary font-12 fw-sb">
              Place of Service <span>&#42;</span>
            </label>
            {isPlaceOfServiceCodeLoading ? (
              <Skeleton />
            ) : (
              <select
                className={`input border--default br-10 form-select font-12 shadow-none`}
                onChange={(e) => {
                  handlePlaceOfService(e);
                }}
                required={true}
                value={
                  props?.encounterDto?.encounterMetadata?.placeOfService ||
                  selectedPlaceOfService
                }
              >
                <option value="" disabled selected hidden>
                  Select Place of Service
                </option>
                {placeOfServiceCodes?.map((place) => {
                  return (
                    <option value={place.code}>
                      {place.code} - {place.name}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        </div>

        <div className="col-sm-3">
          <div className="form-group">
            <label className="form-label text--secondary font-12 fw-sb">
              Service Location <span>&#42;</span>
            </label>
            {areServiceLocationsLoading ? (
              <Skeleton />
            ) : (
              <select
                className={`input border--default br-10 form-select font-12 shadow-none`}
                onChange={(e) => {
                  handleServiceLocation(e);
                }}
                required={true}
                value={
                  props?.encounterDto?.encounterMetadata?.serviceLocation ||
                  selectedServiceLocation
                }
              >
                <option value="" disabled selected hidden>
                  Select Service Location
                </option>
                {serviceLocations?.map((item, idx) => {
                  return (
                    <option
                      data-fid={item.facilityId}
                      ref={(ref) => (serviceOptionsRef.current[idx] = ref)}
                      key={item?.facilityId}
                      value={item?.name}
                    >
                      {item?.name}
                    </option>
                  );
                })}
                {/* <option value={`${user?.tenant?.businessName}`}>{user?.tenant?.businessName}</option> */}
              </select>
            )}
          </div>
        </div>
      </div>
      <div className="row py-1">
        <div className="col-sm-3">
          <div className="form-group">
            <label className="font-12 fw-sb text--secondary">
              Service Date <span>&#42;</span>
            </label>

            <CalenderDatePicker
              name="serviceDate"
              className="font-12 simple-placeholder"
              label="Service Date"
              required={true}
              f12={true}
              {...(props?.encounterDto?.encounterMetadata?.dateOfServiceTo && {
                date: props?.encounterDto?.encounterMetadata?.dateOfServiceTo,
              })}
              dobHandler={handleServiceDate}
              disableFuture={true}
              maxDate={DateTime.now()}
            />
          </div>
        </div>

        <div className="col-sm-3">
          <div className="form-group">
            <label className="font-12 fw-sb text--secondary">
              Last Seen Date <span>&#42;</span>
            </label>
            <CalenderDatePicker
              name="lastSeenDate"
              className="font-12 simple-placeholder"
              label="Service Date"
              f12={true}
              {...(props?.encounterDto?.lastSeenDate && {
                date: props?.encounterDto?.lastSeenDate,
              })}
              required={true}
              dobHandler={handleLastSeenDate}
              disableFuture={true}
              maxDate={DateTime.now()}
            />
          </div>
        </div>

        <div className="col-sm-3">
          <div className="form-group">
            <label className="font-12 fw-sb text--secondary">
              Date of Current Illness <span>&#42;</span>
            </label>
            <CalenderDatePicker
              name="dateOfCurrentIllnes"
              className="font-12 simple-placeholder"
              required={true}
              label="Service Date"
              f12={true}
              {...(props?.encounterDto?.dateOfCurrentIllnessOrSymptom && {
                date: props?.encounterDto?.dateOfCurrentIllnessOrSymptom,
              })}
              dobHandler={handleCurrentIllnessDate}
              disableFuture={true}
              maxDate={DateTime.now()}
            />
          </div>
        </div>

        {selectedPatientGender?.toLowerCase() === "female" ? (
          <div className="col-sm-3">
            <div className="form-group">
              <label className="font-12 fw-sb text--secondary">
                Last Menstural Period Date <span>&#42;</span>
              </label>
              <CalenderDatePicker
                name="lastMensturalPeriodDate"
                className="font-12 simple-placeholder"
                label="Service Date"
                f12={true}
                {...(props?.encounterDto?.lastMenstrualPeriodDate && {
                  date: props?.encounterDto?.lastMenstrualPeriodDate,
                })}
                dobHandler={handleLastMenstrualPeriodDate}
                disableFuture={true}
                required={true}
                maxDate={DateTime.now()}
              />
            </div>
          </div>
        ) : null}

        <div className="col-sm-12">
          <div className="row mt-2">
            <div className="col-sm-12">
              {(props?.encounterDto?.referringPhysician !== null &&
                props?.encounterDto?.referringPhysician !== undefined &&
                editMode === true) ||
              (typeof referringPhysician === "object" &&
                referringPhysician !== null &&
                Object.keys(referringPhysician).length > 2 &&
                showReferringPhysicianFields === false) ? (
                <>
                  <span className="font-14 fw-sb text--terciary d-block">
                    Referring Physician
                  </span>
                  <div className="row ">
                    <div className="col-sm-3">
                      <span className="font-12 d-block fw-sb text--secondary">
                        Name
                      </span>
                      <span className="font-12 fw-thin d-block">
                        Dr. {referringPhysician?.firstName}{" "}
                        {referringPhysician?.lastName}
                      </span>
                    </div>
                    <div className="col-sm-2">
                      <span className="font-12 d-block fw-sb text--secondary">
                        NPI{" "}
                      </span>
                      <span className="font-12 d-block fw-thin">
                        {referringPhysician?.npi}
                      </span>
                    </div>
                    <div className="col-sm-2">
                      <span className="font-12 d-block fw-sb text--secondary">
                        Phone{" "}
                      </span>
                      <span className="font-12 d-block fw-thin">
                        {referringPhysician?.phone}
                      </span>
                    </div>
                    <div className="col-sm-2">
                      <span className="font-12 d-block fw-sb text--secondary">
                        Referral Code{" "}
                      </span>
                      <span className="font-12 d-block fw-thin">
                        {props?.encounterDto?.referralCode ?? "N/A"}
                      </span>
                    </div>
                    <div className="col-sm-2">
                      <span className="font-12 d-block fw-sb text--secondary ">
                        Referral Date{" "}
                      </span>
                      <span className="font-12 d-block fw-thin">
                        {props?.encounterDto?.referralDate !== null &&
                        props?.encounterDto?.referralDate !== undefined
                          ? dateTimeHelper(
                              props?.encounterDto?.referralDate,
                              "MMM DD, YYYY"
                            )
                          : "N/A"}
                      </span>
                    </div>
                    {fieldValidation(
                      props?.encounterDto?.referralNote,
                      "all"
                    ) ? (
                      <div className="col-sm-12">
                        <span className="font-12 d-block fw-sb text--secondary">
                          Referral Note{" "}
                        </span>
                        <span className="font-12 d-block fw-thin">
                          {props?.encounterDto?.referralNote}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="form-group mt-2">
                  {/* <span className="text-decoration-underline fw-sb text--secondary font-14 d-flex align-items-center gap-1"><PersonAddAltIcon />{__str.addReferringPhysician}</span> */}
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (showReferringPhysicianFields === false) {
                        setShowReferringPhysicianFields(true);
                      }
                    }}
                    className="btn pn-button-icon-blue  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12"
                  >
                    <span className="pn-button-icon-blue pe-1">
                      <PersonAddAltIcon
                        color="#336383"
                        sx={{ fontSize: "20px" }}
                      />
                    </span>
                    <span
                      className={`d-inline-block pn-link-button `}
                      style={{ verticalAlign: "sub" }}
                    >
                      Add Referring Physician
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className="col-sm-12">
                <div className="row mt-2">
                    <div className="col-sm-12">
                        <label className="font-12 fw-sb text--secondary">Referring Physician</label>
                    </div>


                </div>

            </div>
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-3 col-12">
                        <div className="form-group">


                            <select
                                className={`border--default br-10 col-sm-12 form-select cursor--pointer font-12 shadow-none `}
                                aria-label="Referring Physician select"
                                name="referringPhysicianDropdown"
                                // required={showReferringPhysicianFields}
                                onChange={(e) => {
                                    handleReferringPhysicianData(e)
                                    const phone = referringPhysiciansList?.find(rp => rp.referringPhysicianId == e.target.value)?.phone;
                                    handlePhone("+1" + unformatPhoneNumber(phone));

                                }}
                            >
                                <option selected disabled>Select Referring Physician</option>
                                {referringPhysiciansList?.map((referringPhysician) => {
                                    const fullName = referringPhysician?.middleName?.length > 0 ? `${referringPhysician?.firstName} ${referringPhysician?.middleName} ${referringPhysician?.lastName}` : `${referringPhysician?.firstName} ${referringPhysician?.lastName}`
                                    return <option value={referringPhysician?.referringPhysicianId}>
                                        {fullName}
                                    </option>
                                })}
                            </select>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-3 col-12">
                        <label className="font-12 fw-sb text--secondary">First Name</label>
                        <input
                            type="text"
                            name="firstName"
                            id={"firstName"}
                            value={referringPhysician?.firstName ?? ''}
                            className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                            onChange={handleReferringPhysicianData}
                            maxLength={35}
                        />
                    </div>

                    <div className="col-sm-3 col-12">
                        <label className="font-12 fw-sb text--secondary">Middle Name</label>
                        <input
                            type="text"
                            name="middleName"
                            id={"middleName"}
                            value={referringPhysician?.middleName ?? ''}
                            className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                            maxLength={35}
                            onChange={handleReferringPhysicianData}
                        />
                    </div>

                    <div className="col-sm-3 col-12">
                        <label className="font-12 fw-sb text--secondary">Last Name</label>
                        <input
                            type="text"
                            name="lastName"
                            id={"lastName"}
                            className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                            onChange={handleReferringPhysicianData}
                            // required={showReferringPhysicianFields}
                            maxLength={35}
                            value={referringPhysician?.lastName ?? ''}
                        />
                    </div>

                    <div className="col-sm-3 col-12">
                        <label className="font-12 fw-sb text--secondary">NPI</label>
                        <input
                            type="text"
                            name="npi"
                            id={"npi"}
                            className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                            onChange={handleReferringPhysicianData}
                            // required={showReferringPhysicianFields}
                            maxLength={10}
                            value={referringPhysician?.npi ?? ''}
                        />
                    </div>
                </div>

            </div> */}
      </div>

      <div className="row py-1">
        <div className="col-sm-4">
          <label className="form-label font-12 text--secondary fw-sb">
            Diagnoses <span>&#42;</span>
          </label>
          <DiagnosisSearch
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isError={isError}
            setIsError={setIsError}
            searchInputValue={searchInputValue}
            setSearchInputValue={setSearchInputValue}
            diagnosisName={diagnosisName}
            setDiagnosisName={setDiagnosisName}
            selectedDiagnosis={selectedDiagnosis}
            setSelectedDiagnosis={setSelectedDiagnosis}
            searchDiagnosis={searchDiagnosis}
            handleSearchInput={handleSearchInput}
            handleAddDiagnosis={handleDiagnosisList}
            handleRemoveDiagnosis={handleRemoveDiagnosis}
          />

          <div className="py-2">
            {diagnosisList?.map((diagnosis, index) => (
              <span
                key={diagnosis.code}
                className="font-12 px-2 text--terciary d-flex gap-3 d-block"
              >
                {`${diagnosis?.code} - ${diagnosis.description} ${
                  diagnosis?.type === "P" ? `(Primary)` : ""
                }`}
                <span
                  className="cursor--pointer"
                  onClick={() => handleRemoveDiagnosisFromList(diagnosis)}
                >
                  <img
                    src={TrashButtonIcon}
                    alt={"deleteIcon"}
                    id="encounter-delete"
                    style={{ width: "15px", height: "15px" }}
                  ></img>
                </span>
              </span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function BottomNavigation(props) {
  const [disabled, setDisabled] = useState(false);

  function validateDto(encounterDto) {
    const encounterMetadata = encounterDto?.encounterMetadata;
    const patient = encounterDto?.patient;
    const provider = encounterDto?.provider;
    const facility = encounterDto?.facility;

    if (
      fieldValidation(encounterMetadata?.dateOfServiceTo, "all") &&
      fieldValidation(encounterMetadata?.placeOfService, "all") &&
      fieldValidation(encounterMetadata?.serviceLocation, "all") &&
      fieldValidation(encounterDto?.claimProviderDataId, "all") &&
      fieldValidation(encounterDto?.dateOfCurrentIllnessOrSymptom, "all") &&
      fieldValidation(encounterDto?.lastSeenDate, "all") &&
      fieldValidation(patient?.patientId, "all") &&
      fieldValidation(provider?.providerId, "all") &&
      fieldValidation(facility?.facilityId, "all") &&
      encounterDto?.diagnosis?.length > 0 &&
      (patient?.gender?.toLowerCase() === "female"
        ? encounterDto?.lastMenstrualPeriodDate?.length > 5
          ? true
          : false
        : true)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  useEffect(() => {
    validateDto(props?.encounterDto);
  }, [props?.encounterDto]);

  return (
    <div
      style={{ zIndex: "1050" }}
      className="position-fixed pb-4  w-100 bottom-0 mx-auto bg-white bottom__nav--shadow"
    >
      <LinearProgress
        variant="determinate"
        style={{
          backgroundColor: "#D9E8EC",
          height: "1px",
        }}
        value={100}
      />
      <div className="d-flex p-3 ">
        <div
          className="position-fixed "
          style={{
            left: "88%",
          }}
        >
          <button
            className="btn  br-10 font-14 ms-2 mb-1 fw-sb blue_task_button btn__bottom d9e8ec--button"
            style={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
            onClick={() => {
              props?.saveEncounter();
            }}
            disabled={disabled || (props?.editMode === true && !props?.changesMade)}
            hidden={props?.hideSaveButton}
          >
            {props?.editMode === true ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}
