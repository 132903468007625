import React, { useEffect, useState } from "react";
import Navbar from "../UI/Navbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import PropTypes from "prop-types";
import { ChevronLeft } from "@mui/icons-material";
import classes from "../pages/AddUserPage.module.css";
import { useStateWithCallback } from "../hooks/useStateWithCallback";
import {
  navigateOnDirtyBit,
  UnloadHandler,
  fieldValidation
} from "../../utils/helpers/HelperFunctions";
import AddFacilityForm from "../user/AddFacilityForm";
import AlertModal from "../UI/Modals/ProviderModals/AlertModal";
import { useAuth } from "../hooks/useAuth";
import PageHeader from "../../patientportal/generic/PageHeader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#336383",
      light: "#336383",
      dark: "#336383",
      contrastText: "#336383",
    },
    secondary: {
      main: "#EC609B",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 14,
  },
});
//========Tabs Code End=======//
const AddFacilityPage = ({ editMode }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [facility, setFacility] = useState(
    editMode
      ? location?.state
      : {
        businessName: "",
        email: "",
        addressLine1: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        phoneNumber: "",
        faxNumber: "",
        numberOfRooms:0,
        prefix:"",
        placeOfService: null,
      }
  );
  const [editFacility, setEditFacility] = useState(
    editMode ? location?.state : null
  );
  const [isSending, setIsSending] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [isFacilityAdded, setIsFacilityAdded] = useState(false);
  const [isFacilityUpdated, setIsFacilityUpdated] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const navigate = useNavigate();
  const [hideStatus, setHideStatus] = useStateWithCallback(false);
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const [isPrimary, setIsPrimary] = useState();
  const [primaryFacilityModal, setPrimaryFacilityModal] = useState(false);
  const [makePrimary, setMakePrimary] = useState(false);
  const [type, setType] = useState("");
  const { user } = useAuth();
console.log("dirtyBitStatus: ",dirtyBitStatus);

  const showPrimaryFacilityModal = (type) => {
    // console.log("facility?.primary: ",facility?.primary,isPrimary?.facilityId ,facility?.facilityId);
    if (facility?.primary && isPrimary?.facilityId !== facility?.facilityId) {
      // if () {
        // console.log("Open primary facility modal");
        setType(type);
        setPrimaryFacilityModal(!primaryFacilityModal);
      // } else {
      //   type === "add" ? addFacilityHandler() : editFacilityHandler();
      // }
    } else {
      type === "add" ? addFacilityHandler() : editFacilityHandler();
    }
  };

  const checkUpdateOnEditMode = () => {
    dispatch(setDirtyBitInStore(true));
    if (editMode !== undefined) {
      setUpdateDisabled(true);
    }
  }

  const primaryFacilityModalAction = (returnVal) => {
    if (returnVal) {
      type === "add" ? addFacilityHandler() : editFacilityHandler();
    }
    setPrimaryFacilityModal(!setPrimaryFacilityModal);
  };

  const addFacilityHandler = async () => {
    const facilityForm = document.getElementById('facilityAddForm');
    if (facilityForm?.reportValidity()) {
      setIsSending(true);
      const httpResponse = await sendHttpRequest({
        url: `/facilities/tenant/${user?.tenantId}`,
        method: "POST",
        data: facility,
      });
      if (!httpResponse.error) {
        dispatch(setDirtyBitInStore(false));
        // console.log("facility added");
        setIsFacilityAdded(true);
        setTimeout(() => {
          navigate("/facility");
        }, 1500);
      } else {
        console.log(httpResponse);
        // console.log("HTTPRESPONSE=", httpResponse);
        setServerErrors(httpResponse.errorMessage?.errorDetails ?? []);
      }
      setIsSending(false);
    }
  };

  const getCountriesHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/countries",
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setCountriesList(httpResponse.data);
    } else {
      console.log(httpResponse.error);
      setServerErrors(httpResponse.errorMessage);
    }
  };

  const getFacilityHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/facilities/tenant/${user?.tenantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      checkIfPrimary(httpResponse.data);
    } else {
      console.log(httpResponse.error);
      setServerErrors(httpResponse.errorMessage);
    }
  };

  const checkIfPrimary = (facilities) => {
    if (facilities.length > 0) {
      facilities.forEach((facility) => {
        if (facility?.primary) {
          setIsPrimary(facility);
        }
      });
    }
  };

  const editFacilityHandler = async () => {
    setIsSending(true);
    const httpResponse = await sendHttpRequest({
      url: `/facilities/${editFacility.facilityId}/tenant/${user?.tenantId}`,
      method: "PUT",
      data: facility,
    });
    if (!httpResponse.error) {
      dispatch(setDirtyBitInStore(false));
      // console.log("facility updated");
      setIsFacilityUpdated(true);
      setTimeout(() => {
        navigate("/facility");
      }, 1500);
    } else {
      console.log(httpResponse);
      // console.log("HTTPRESPONSE=", httpResponse);
      setServerErrors(httpResponse.errorMessage?.errorDetails ?? []);
    }
    setIsSending(false);
  };

  const closeToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsFacilityAdded(false);
    setIsFacilityUpdated(false);
  };

  const removeServerError = (execeptionsArray, fieldName) => {
    let errors = [];
    fieldName?.forEach((element) => {
      errors = execeptionsArray?.filter((o) => o?.name !== element);
      execeptionsArray = errors
    });
    setServerErrors(errors);
  };

  window.addEventListener("beforeunload", UnloadHandler(dirtyBitStatus));

  useEffect(() => {
    getFacilityHandler();
    setUpdateDisabled(false);
    return () => {
      window.removeEventListener("beforeunload", UnloadHandler(dirtyBitStatus));
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <>
        {primaryFacilityModal && (
          <AlertModal
            show={primaryFacilityModal}
            onYes={primaryFacilityModalAction}
            onNo={primaryFacilityModalAction}
            rightButtonText={"Yes"}
            leftButtonText={"No"}
            heading={
              "Would you like to replace the primary facility set earlier with the current facility?"
            }
          />
        )}
        <PageHeader title={editMode ? "Edit Facility" : "Add Facility"} />
        {/* <div
          className={`container-fluid bg-white  justify-content-center px-3 py-3 ${classes["page__title-border"]}`}
        >
          <div className="row">
            <div className="col-sm-4 text--terciary fw-sb font-28 align-self-center ">
              <div className="d-flex flex-row">
                <div
                  className={`bg--background ${classes["chevron__left__div"]} br-10 me-3 cross-sign border--default border text-center my-auto `}
                >
                  <ChevronLeft
                    style={{ color: "#EC609B", height: "20px", weight: "20px" }}
                    onClick={() => {
                      navigateOnDirtyBit(
                        dirtyBitStatus,
                        navigate,
                        -1,
                        dispatch,
                        setDirtyBitInStore
                      );
                    }}
                    className="my-auto my-auto"
                  />
                </div>
                <div>{editMode ? "Edit Facility" : "Add Facility"}</div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container-fluid bg--background vh-100">
          <div className="row pt-4 pb-1 pe-5 justify-content-center">
            <div className="col-sm-6">
              <h2 className="font-16 fw-sb text--terciary">
                Facility Information
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-8">
              <AddFacilityForm
                setFacility={setFacility}
                checkUpdateOnEditMode={checkUpdateOnEditMode}
                updateDisabled={updateDisabled}
                editMode={editMode}
                editFacility={editFacility}
                isSending={isSending}
                submitHandler={showPrimaryFacilityModal}
                setIsSending={setIsSending}
                countriesList={countriesList}
                getCountriesHandler={getCountriesHandler}
                serverErrors={serverErrors}
                removeServerError={removeServerError}
                facility={facility}
              />
            </div>
          </div>
        </div>
        <CustomizedSnackbars
          message={"Facility added successfully"}
          severity="success"
          open={isFacilityAdded}
          handleClose={closeToast}
        />
        <CustomizedSnackbars
          message={"Facility updated successfully"}
          severity="success"
          open={isFacilityUpdated}
          handleClose={closeToast}
        />
      </>
    </ThemeProvider>
  );
};

// AddFacilityPage.propTypes = {
//   editMode: PropTypes.bool || PropTypes.undefined
// };

export default AddFacilityPage;
