import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { useState } from "react";

import LogoMain from "../../assets/icons/Logo.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../UI/Reusable/PnComponents";
import AddPatientForm from "./AddPatientForm";
import PatientInsurnacePage from "../../patientportal/patientpages/PatientInsurancePage";
import AddInsuranceForm from "./AddInsuranceForm";
import PatientVitals from "../patient/PatientVitals";
import AddPatientVitalsForm from "./AddPatientVitalsForm";
import MakeAppointmentForm from "./MakeAppointmentForm";
import PatientReferral from "./PatientReferral";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useEffect } from "react";
import { fieldValidation } from "../../utils/helpers/HelperFunctions";
import { addressType, numbersEnum } from "../../res/strings";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import classes from "./PatientInformationForm.module.css";
import { useLocation, useParams } from "react-router-dom";
import PhoneIcon from '@mui/icons-material/Phone';
import { FileUploader } from "react-drag-drop-files";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";


const PatientInformationForm = () => {
  const _numberTypes = numbersEnum["en"];
  const _addressTypes = addressType["en"];

  let { uuid } = useParams();

  const [imagePath, setImagePath] = useState();
  const [patientInformationAccordion, setPatientInformationAccordion] =
    useState("patientInformation");
  const [insuranceAccorionExpanded, setInsuranceAccorionExpanded] =
    useState("");
  const [appointmentAccordionExpanded, setAppointmentAccordionExpanded] =
    useState("");
  const [referralAccordionExpanded, setReferralAccordionExpanded] =
    useState("");
  const [patientJson, setPatientJson] = useState({});

  const [metaData, setMetaData] = useState();
  const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
  const [isDataSaving, setIsDataSaving] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [isError, setIsError] = useState();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const [selectedInsuranceFrontPreview, setSelectedInsuranceFrontPreview] = useState(null);
  const [invalidFrontFileType, setInvalidFrontFileType] = useState(false);
  const [isFrontFileValid, setIsFrontFileValid] = useState(false);
  const [frontFileName, setFrontFileName] = useState([]);

  const [selectedInsuranceBackPreview, setSelectedInsuranceBackPreview] = useState(null);
  const [invalidBackFileType, setInvalidBackFileType] = useState(false);
  const [isBackFileValid, setIsBackFileValid] = useState(false);
  const [backFileName, setBackFileName] = useState([]);
  const [selectedInsuranceFrontFile, setSelectedInsuranceFrontFile] = useState(null)
  const [selectedInsuranceBackFile, setSelectedInsuranceBackFile] = useState(null)

  const MAX_UPLOAD_SIZE = parseInt(process.env.REACT_APP_LOGO_UPLOAD_FILE_SIZE);

  const [scannedInsuranceData, setScannedInsuranceData] = useState(null);

  const [requiredFileError, setRequiredFileError] = useState("");

  const [isScanning, setIsScanning] = useState();

  const fetchData = async (uuid) => {
    setIsMetaDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${uuid}/metadata`,
      method: "GET",
    });

    if (!httpResponse.error) {
      setMetaData(httpResponse.data);
    }
    setIsMetaDataLoading(false);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    if (panel === "patientInformation") {
      setPatientInformationAccordion(newExpanded ? panel : false);
    } else if (panel === "insuranceAccordion") {
      setInsuranceAccorionExpanded(newExpanded ? panel : false);
    } else if (panel === "aptAccordion") {
      setAppointmentAccordionExpanded(newExpanded ? panel : false);
    } else if (panel === "refAccordion") {
      setReferralAccordionExpanded(newExpanded ? panel : false);
    }
  };

  const formatData = (referralObj) => {
    let patient = {
      username: patientJson?.Email,
      communicationNumbers: {
        electronicMail: [patientJson?.Email],
        numbers: [
          {
            number: patientJson?.CellPhone,
            type: "PT",
          },
        ],
      },
      gender: patientJson?.biologicalSex,
      dateOfBirth: patientJson?.dob,
      name: [
        {
          firstName: patientJson?.FirstName,
          lastName: patientJson?.LastName,
          middleName: patientJson?.MiddleName,
        },
      ],
      // address: [],
      //   {
      //     addressLine1: patientJson?.AddressLine1 ?? "",
      //     addressLine2: patientJson?.AddressLine2 ?? "",
      //     city: patientJson?.City ?? "",
      //     stateProvince:
      //       patientJson?.state?.length > 0 ? patientJson?.state[0]?.code : "",
      //     postalCode: patientJson?.ZipCode ?? "",
      //   },
      // ],
      ...(patientJson?.patientInsurance && {patientInsurance: patientJson?.patientInsurance}),
    };

    if (fieldValidation(patientJson?.Landline, "all")) {
      patient?.communicationNumbers?.numbers.push({
        number: patientJson?.Landline,
        type: _numberTypes?.homeTelephoneExt,
      });
    }
    return {
      patient: patient,
      appointment: patientJson?.appointment,
      diagnoses: patientJson?.diagnoses,
      referringPhysician: referralObj?.patientReferral,
    };
  };

  const saveData = async (referralObj) => {
    setIsDataSaving(true);
    // formatData();

    const httpResponse = await sendHttpRequest({
      url: `/tenants/${metaData?.tenantId}/appointments`,
      method: "POST",
      data: formatData(referralObj),
    });
    if (!httpResponse.error) {
      setIsFormSubmitted(true);
      setSeverity("success");
      setMessage("Referral submitted successfully");
      setIsDataSaved(true);
      setPatientJson({});
      setIsError(undefined);
    } else {

      setPatientInformationAccordion("patientInformation");
      setReferralAccordionExpanded(false);
      setInsuranceAccorionExpanded(false);
      setAppointmentAccordionExpanded(false);
      setIsError({
        status: httpResponse.errorMessage?.status,
        message: httpResponse.errorMessage?.message,
      });
      setSeverity("error");
      setMessage(httpResponse.errorMessage?.message !== undefined ? httpResponse.errorMessage?.message :  "Something went wrong");
      setIsDataSaved(true);
    }
    setIsDataSaving(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsDataSaved(false);
  };

  const fileTypes = ["JPEG", "PNG",  "JPG",];

  const handleInsuranceFrontInput = (file) => {
    handleFileInput(file, setFrontFileName, setIsFrontFileValid, setInvalidFrontFileType, setSelectedInsuranceFrontPreview);
  };
  
  const handleInsuranceBackInput = (file) => {
    handleFileInput(file, setBackFileName, setIsBackFileValid, setInvalidBackFileType, setSelectedInsuranceBackPreview);
  };

  const handleFileInput = (file, setFileName, setIsFileValid, setInvalidFileType, setFilePreview) => {
    if (file.size < MAX_UPLOAD_SIZE) {
      let filePath = file.name;
      var allowedExtensions = /(\.png|\.jpeg|\.jpg\.PNG|\.JPEG|\.JPG)$/i;
      if (!allowedExtensions.exec(filePath)) {
        file = "";
        setInvalidFileType(true);
      } else {
        // setSelectedFile(file);
        setIsFileValid(true);
        const temp = [];
        temp.push(file);
        setFileName(temp);
        setInvalidFileType(false);
        setFilePreview(file);
      }
    } else {
      file = "";
      setIsFileValid(false);
    }
  };

  const uploadAndScanInsuranceCards = async () => {

    setIsScanning(true);


    const formData = new FormData();
    formData.append('frontImage', selectedInsuranceFrontPreview);
    formData.append('backImage', selectedInsuranceBackPreview);
    
    const httpResponse = await sendHttpRequest({
      url: '/api/ocr/extractInsuranceInfo2',
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data; boundary=",
      },
    });

    if (!httpResponse.error) {

      console.log("HttpResponse ", httpResponse);

    // var insuranceAddressLine1 = insuranceAddress

      const scannedInsuranceData = {
        insuranceName: httpResponse?.data?.INSURANCE_CARD_PROVIDER,
        // payerCode: "00004",
        effectiveDate: httpResponse?.data?.INSURANCE_CARD_EFFECTIVE_DATE !== undefined ? calenderDateTimeHelper( httpResponse?.data?.INSURANCE_CARD_EFFECTIVE_DATE, "YYYY-MM-DD") : "",
        payerAddress1: httpResponse?.data?.INSURANCE_ADDRESS_LINE_1,
        payerAddress2: httpResponse?.data?.INSURANCE_ADDRESS_LINE_2,
        payerCity: httpResponse?.data?.INSURANCE_CITY,
        payerState: httpResponse?.data?.INSURANCE_STATE,
        zipCode: httpResponse?.data?.INSURANCE_ZIPCODE,
        memberId: httpResponse?.data?.INSURANCE_CARD_MEMBER_ID,
        groupNumber: httpResponse?.data?.INSURANCE_CARD_PLAN_GROUP_NUMBER,
        terminationDate: httpResponse?.data?.INSURANCE_CARD_PLAN_TERMINATION_NUMBER,
        contactNumber: httpResponse?.data?.INSURANCE_CARD_CONTACT_NUMBER,
        relationshipToInsured: "Self"
      };

      setScannedInsuranceData(scannedInsuranceData);

      setIsScanning(undefined);

    }

    


    
    

    // console.log("Json Data ", jsonData);


  }

  useEffect(() => {
    if (uuid !== undefined) {
      fetchData(uuid);
    }
  }, [uuid]);

  return (
    <>

      <div className="row bg--background p-3">
        {isMetaDataLoading ? (
          <Skeleton />
        ) : (
          <div className="col-12 d-flex flex-row justify-content-between mx-2">
            {metaData?.imagePath !== undefined ? (
              <img
                src={metaData?.imagePath}
                alt="logo"
                width="200px"
                height="70px"
                className="img-fluid"
              />
            ) : (
              <span className="d-bloc">&nbsp;</span>
            )}
            <div className="">
              <span className="font-14 fw-sb text--terciary">
                {metaData?.businessName !== undefined
                  ? metaData?.businessName
                  : ""}
              </span>
              <div className="text-end">
                <span className="font-12 fw-sb text--secondary">
                  {metaData?.contactNumber !== undefined
                    ? <><PhoneIcon color={"#336383"} style={{height:"18px",width:"18px"}}/>: {metaData?.contactNumber}</> 
                    : ""}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {isError !== undefined ? (
        <div className="container pb-5 bg--background">
          <div className="row text-center text--danger">
            <div className="col-12 mt-3">
              {
                isError?.status === 500 ? 
                <span className="font-12">{"Error: Please check all the mandatory fields with esterick(*) symbol are properly filled"}</span>
                :
                <span className="font-12">{"Error: ".concat(isError?.message)}</span>
              }
            </div>
          </div>
        </div>
      ) : null}
      {isFormSubmitted ? (
        <div className="container pb-5 bg--background">
          <div className="row text-center">
            <div className="col-12 mt-3">
              <span>{"Do you want to resubmit the referral data? "}</span>
              <button
                className={`btn blue_task_button bg--blue fw-sb font-14 text-white btn-lg b2 px-5 br-10 py-1`}
                type="submit"
                onClick={() => {
                  setPatientInformationAccordion("patientInformation");
                  setReferralAccordionExpanded(false);
                  setIsFormSubmitted(false);
                }}
              >
                {"Resubmit"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="container pb-5 bg--background">
          <div className="row pt-5 justify-content-center">
            <div className="col-sm-9 text-center" hidden={true}>
              <img
                src={LogoMain}
                alt="logo"
                width="200px"
                height="70px"
                className="img-fluid"
              />
            </div>
            {isMetaDataLoading ? (
              <Skeleton />
            ) : (
              <div className="col-8">
                <Accordion
                  sx={{ paddingTop: 0, paddingBottom: 0, pt: 0, pb: 0 }}
                  //   defaultExpanded={true}
                  expanded={
                    patientInformationAccordion === "patientInformation"
                  }
                  onChange={handleChange("patientInformation")}
                >
                  <AccordionSummary>
                    <h2 className="font-18 fw-sb m-0">Patient Information</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row">
                      <div className="col-12">
                        <AddPatientForm
                          setPatientInformationAccordion={
                            setPatientInformationAccordion
                          }
                          setInsuranceAccorionExpanded={
                            setInsuranceAccorionExpanded
                          }
                          handleChange={handleChange}
                          patientJson={patientJson}
                          setPatientJson={setPatientJson}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{ paddingTop: 0, paddingBottom: 0, pt: 0, pb: 0 }}
                  //   defaultExpanded={true}
                  expanded={insuranceAccorionExpanded === "insuranceAccordion"}
                  onChange={handleChange("insuranceAccordion")}
                >
                  <AccordionSummary>
                    <h2 className="font-18 fw-sb m-0">Patient Insurance</h2>
                  </AccordionSummary>
                  <AccordionDetails>

                    <form
                      id="fileUploadForm"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (e.target.reportValidity()) {
                          uploadAndScanInsuranceCards();
                        }
                      }}
                    >

                      <div className="row">
                        <div className="col-6">
                          <label
                            htmlFor={"PayerAddress1"}
                            className="form-label m-0 font-12 text--secondary fw-sb"
                          >
                            Card Front Image<span>&nbsp;&#42;</span>
                          </label>

                          <FileUploader
                            handleChange={(f) => {
                              handleInsuranceFrontInput(f);
                              // onDirty();
                            }}
                            name="file"
                            label="Drop a file or click here"
                            types={fileTypes}
                            required
                          >
                            <div className="py-4 border--dashed bg--white br-10 d-flex justify-content-center">
                              <div className={`text-center`}>

                                {selectedInsuranceFrontPreview !== null ? <img className="img img-fluid   img-thumbnail" src={URL.createObjectURL(selectedInsuranceFrontPreview)} alt={selectedInsuranceFrontPreview.name} /> : null}

                                <u className="file-link">{"select a file"}</u>&nbsp;
                                {"or drag and drop here"}
                              </div>
                            </div>
                          </FileUploader>
                        </div>

                        <div className="col-6">
                          <label
                            htmlFor={"PayerAddress1"}
                            className="form-label m-0 font-12 text--secondary fw-sb"
                          >
                            Card Back Image <span>&#42;</span>
                          </label>

                          <FileUploader
                            handleChange={(f) => {
                              handleInsuranceBackInput(f);
                              // onDirty();
                            }}
                            name="file"
                            label="Drop a file or click here"
                            types={fileTypes}
                            required
                          >
                            <div className="py-4 border--dashed bg--white br-10 d-flex justify-content-center">
                              <div className={`text-center`}>
                                
                                {selectedInsuranceBackPreview !== null ? <img className="img img-fluid   img-thumbnail" src={URL.createObjectURL(selectedInsuranceBackPreview)} alt={selectedInsuranceBackPreview.name} /> : null}

                                <u className="file-link">{"select a file"}</u>&nbsp;
                                {"or drag and drop here"}
                              </div>
                            </div>
                          </FileUploader>
                        </div>

                        <div className="text-end">
                          <button
                            id="patientForm"
                            type="submit"
                            className={`btn blue_task_button bg--blue fw-sb font-14 text-white btn-lg b2 px-5 br-10 py-1 }`}
                            // onClick={(e)=>{handleNext(e)}}
                            disabled={isScanning !== undefined}
                          >
                            {isScanning ? "Uploading" : "Upload & Scan"}
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="row">
                      <div className="col-12">
                        <AddInsuranceForm
                        
                          handleChange={handleChange}
                          patientJson={patientJson}
                          setPatientJson={setPatientJson}
                          setInsuranceAccorionExpanded={
                            setInsuranceAccorionExpanded
                          }
                          setAppointmentAccordionExpanded={
                            setAppointmentAccordionExpanded
                          }
                          metaData={metaData}
                          scannedInsuranceData = {scannedInsuranceData}
                        />
                      </div>
                    </div>

                  </AccordionDetails>
                </Accordion>
                {/* <Accordion
                  sx={{ paddingTop: 0, paddingBottom: 0, pt: 0, pb: 0 }}
                  defaultExpanded={true}
                >
                  <AccordionSummary>
                    <h2 className="font-18 fw-sb m-0">Patient Vitals</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row">
                      <div className="col-12">
                        <AddPatientVitalsForm />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion> */}
                <Accordion
                  sx={{ paddingTop: 0, paddingBottom: 0, pt: 0, pb: 0 }}
                  // defaultExpanded={true}
                  expanded={appointmentAccordionExpanded === "aptAccordion"}
                  onChange={handleChange("aptAccordion")}
                >
                  <AccordionSummary>
                    <h2 className="font-18 fw-sb m-0">Appointment</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row">
                      <div className="col-12">
                        {metaData?.providers?.length > 0 ? (
                          <MakeAppointmentForm
                            patientJson={patientJson}
                            setPatientJson={setPatientJson}
                            setAppointmentAccordionExpanded={
                              setAppointmentAccordionExpanded
                            }
                            setReferralAccordionExpanded={
                              setReferralAccordionExpanded
                            }
                            metaData={metaData}
                          />
                        ) : null}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{ paddingTop: 0, paddingBottom: 0, pt: 0, pb: 0 }}
                  // defaultExpanded={true}
                  expanded={referralAccordionExpanded === "refAccordion"}
                  onChange={handleChange("refAccordion")}
                >
                  <AccordionSummary>
                    <h2 className="font-18 fw-sb m-0">Referring Physician</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row">
                      <div className="col-12">
                        <PatientReferral
                          patientJson={patientJson}
                          setPatientJson={setPatientJson}
                          saveData={saveData}
                          isDataSaved={isDataSaved}
                          setIsDataSaved={setIsDataSaved}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </div>

          {/* <div className={`${classes["footer"]}`}> */}
          {/* </div> */}
        </div>
      )}
      <div
        className={`row text-center mb-3 ${isFormSubmitted ? "footer" : ""}`}
        hidden={isMetaDataLoading}
      >
        <div className="col-12 font-12 text--terciary">Powered by</div>
        <div className="col-12 text-center">
          <img
            src={LogoMain}
            alt="logo"
            width="200px"
            height="70px"
            className="img-fluid"
          />
        </div>
      </div>

      <CustomizedSnackbars
        message={message}
        severity={severity}
        open={isDataSaved}
        handleClose={handleClose}
      />

      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDataSaving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {console.log("patientJson: ", patientJson)}
    </>
  );
};

export default PatientInformationForm;
