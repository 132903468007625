import { useEffect, useRef, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";

function HipaaConsentSuccess(props) {

    const [isImagePathLoading, setIsImagePathLoading] = useState(false);
    const [imagePath, setImagePath] = useState(null);
    const location = useLocation();


    async function fetchTenantLogo() {
        setIsImagePathLoading(true);
        const httpResponse = await sendHttpRequest({
            url: `/tenants/${location.state.uuid}/logo`,
            method: "GET",
        });
        if (!httpResponse.error) {
            setImagePath(httpResponse.data.imagePath);
        } else {
            // return false;
        }
        setIsImagePathLoading(false);
    }

   
   


   

    useEffect(() => {
        fetchTenantLogo();
    }, []);

    return (

        <div className="container pb-5">
           
            <div className="row pt-5 justify-content-center">
                <div className="col-sm-9 text-center">
                    {
                        isImagePathLoading ? <Skeleton /> :
                            <img src={imagePath} alt="logo" width="400" className="img-fluid" />
                    }
                </div>
            </div>
            {props?.valid === true ? <div className="row pt-5 justify-content-center">
                <div className="col-sm-9 text-center">
                    <h1 className="text-success">Success!</h1>
                    <p className="text-muted">You have successfully submitted the HIPAA consent form.</p>
                </div>
            </div> : <div className="row pt-5 justify-content-center">
                <div className="col-sm-9 text-center">
                    <h1 className="text-danger">Invalid Link!</h1>
                    <p className="text-muted">The link you have followed is expired.</p>
                </div>
            </div>}
            
        </div>
    )
}

export default HipaaConsentSuccess;