import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useCallback, useEffect, useRef, useState } from "react";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { calenderDateTimeHelper } from "../../../utils/dateTimeHelper";
import PrintIcon from "@mui/icons-material/Print";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import IconButton from "@mui/material/IconButton";
import { X } from "react-bootstrap-icons";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import classes from "./PatientBillingTable.module.css";
import SearchIcon from "@mui/icons-material/Search";
import {
  forwardReceiptUrl,
  tenantBillingHistoryURL,
} from "../../../res/endpointurls";
import ForwardReceiptModal from "../../UI/Modals/PatientModals/ForwardReceiptModal";
import { PnToolTip } from "../../UI/Reusable/PnComponents";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PaidIcon from "@mui/icons-material/Paid";
import { styled } from "@mui/material/styles";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FAFCFC",
      color: "#336383",
      fontWeight: 400,
      fontSize: 12,
      fontFamily: "Montserrat",
      // width: 150,
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      paddingRight: "0px",
      fontFamily: "Montserrat",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
      backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
      border: 0,
  },
}));


function ProviderSearchFilter(props) {
  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [tempFilterList, setTempFilterList] = useState([...props.filterList]);
  const [tags, setTags] = useState([]);
  const handleProviderSearch = (providerName) => {
    setSearchQuery(providerName);
    let name = providerName?.toLowerCase();
    // console.log("props?.uniqueProvidersList: ",props?.uniqueProvidersList,name);
    if (name?.length > 0) {
      let providers = props?.uniqueProvidersList?.filter((item) => {
        let firstName = item?.name[0]?.firstName?.toLowerCase();
        let lastName = item?.name[0]?.lastName?.toLowerCase();
        // console.log(firstName,lastName);
        return firstName.includes(name) || lastName.includes(name);
      });
      setSuggestions(providers);
    } else {
      setSuggestions([]);
    }
  };
  const handleProviderIds = (event, id) => {
    let ischecked = document.getElementById(id);
    if (event.target.checked) {
      setTempFilterList([...tempFilterList, id]);
      addTags(id);
    } else {
      let filterIds = tempFilterList.filter((item) => {
        return item !== id;
      });
      setTempFilterList([...filterIds]);
      ischecked.checked = false;
      removeTags(id);
    }
  };
  const handleDefaultChecked = (id) => {
    const result = props.filterList.find((item) => {
      return item === id;
    });
    return result !== undefined;
  };
  const addTags = (providerId) => {
    // alert(event.target.value);

    let tempList = [...tags];
    // console.log("tempList: ",tempList);
    let providers = props?.uniqueProvidersList.filter((item) => {
      return item?.userId === Number(providerId);
    });
    // console.log("providers: ",providers);
    tempList.push(...providers);
    // console.log("tempList after push: ",tempList);

    setTags(() => tempList);
    // props.selectedTags([...tags, user]);
  };
  const removeTags = (providerId, position) => {
    let providers = tags.filter((item) => {
      return item?.userId !== providerId;
    });
    let providerIds = providers.map((item) => {
      return item?.userId;
    });
    setTags(() => [...providers]);
    setTempFilterList(providerIds);

    if (position === "chip" && providers?.length === 0) {
      let allProviders = props?.uniqueProvidersList?.map((item) => {
        return item?.userId;
      });
      props.setFilterList(() => []);
      props.handleFiltering(() => allProviders, props.patientFilterList);
    } else if (position === "chip") {
      props.setFilterList(() => [...providerIds]);
      props.handleFiltering([...providerIds], [...props.patientFilterList]);
    }
    handleChecksBox(providerId);
    // props?.setFilterList(providers.map((item)=>{return item?.providerTenantPracticeLocation?.provider?.userId}))
  };

  const handleChecksBox = (providerId) => {
    document.getElementById(providerId).checked = false;
  };

  return (
    <>
      <div
        className={`input-group border--default  br-10 ${classes["search__container"]}`}
      >
        <input
          type="text"
          className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
          id="providerFilter"
          name="providerFilter"
          // ref={searchRef}
          autoComplete="off"
          placeholder="Filter providers"
          value={searchQuery}
          // disabled={props?.disableSendRxButton ? true : props?.updateCheck ? true : false}
          onChange={(event) => handleProviderSearch(event.target.value)}
        />
        <button
          className={`btn br-10 shadow-none`}
          type="submit"
          // value={examTestQuery}
        >
          <SearchIcon className=" text--secondary" />
        </button>
      </div>
      <ul
        // ref={filterList?.length > 0 ? ref : null}
        className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
        style={{
          width: "35%",
        }}
      >
        {suggestions?.length > 0 &&
          suggestions.map((suggestion) => {
            let id = suggestion?.userId;
            let prefix = suggestion?.name[0]?.prefix ?? "";
            let firstName = suggestion?.name[0]?.firstName ?? "";
            let lastName = suggestion?.name[0]?.lastName ?? "";
            return (
              <li
                className={`border--default ${classes.li}`}
                onChange={(e) => {
                  handleProviderIds(e, id);
                }}
                key={id}
              >
                <input
                  type="checkbox"
                  // className="cursor--pointer"
                  id={id}
                  onChange={(e) => handleProviderIds(e, id)}
                  defaultChecked={handleDefaultChecked(id)}
                />
                <label className="ms-1" htmlFor={id}>
                  {prefix.concat(" ", firstName, " ", lastName)}
                </label>
              </li>
            );
          })}
        {suggestions?.length > 0 && (
          // filterList[0]?.code !== "-1" ? (
          <div
            style={{
              position: "sticky",
              display: "flex",
              justifyContent: "center",
              padding: "8px",
              backgroundColor: "white",
              gap: "4px",
              bottom: 0,
              left: 0,
            }}
          >
            <button
              className="btn  d9e8ec--button"
              style={{ height: "2.9 em !important" }}
              onClick={() => {
                setSuggestions([]);
                setSearchQuery("");
              }}
            >
              {"Cancel"}
            </button>
            <button
              className="btn blue_task_button"
              onClick={() => {
                setSuggestions([]);
                setSearchQuery("");
                props.setFilterList([...tempFilterList]);
                // props.handleFiltering([...tempFilterList],[...props.patientFilterList]);
              }}
              // disabled={tempFilterList?.length === 0}
            >
              {"Apply"}
            </button>
          </div>
        )}
      </ul>
      <ul className={`${classes["tags"]}`} hidden={true}>
        {/* {console.log(tags)} */}
        {tags?.map((providerItem, index) => (
          <li key={index} className={`${classes["tag"]}`}>
            <span className={`${classes["tag__title"]}`}>
              {providerItem?.name[0]?.firstName +
                " " +
                providerItem?.name[0]?.lastName}
            </span>
            <span
              className={`${classes["tag__close__icon"]}`}
              onClick={() => removeTags(providerItem?.userId, "chip")}
              id={providerItem?.userId}
            >
              <X color="#336383" style={{ marginBottom: "2px" }} size={16} />
            </span>
          </li>
        ))}
      </ul>
    </>
  );
}
function PatientSearchFilter(props) {
  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [tempFilterList, setTempFilterList] = useState([
    ...props.patientFilterList,
  ]);
  const [tags, setTags] = useState([]);

  const handlePatientSearch = (patientName) => {
    // console.log("props?.uniquePatientsList: ",props?.uniquePatientsList);
    setSearchQuery(patientName);
    let name = patientName?.toLowerCase();
    if (name?.length > 0) {
      let patients = props?.uniquePatientsList?.filter((item) => {
        let firstName = item?.name[0]?.firstName?.toLowerCase();
        let lastName = item?.name[0]?.lastName?.toLowerCase();
        return firstName.includes(name) || lastName.includes(name);
      });
      setSuggestions(patients);
    } else {
      setSuggestions([]);
    }
  };
  const handlePatientIds = (event, id) => {
    let ischecked = document.getElementById(id);
    if (event.target.checked) {
      setTempFilterList([...tempFilterList, id]);
      addTags(id);
      event.target.checked = true;
    } else {
      let filterIds = tempFilterList.filter((item) => {
        return item !== id;
      });
      setTempFilterList([...filterIds]);
      ischecked.checked = false;
      removeTags(id);
    }
  };
  const handleDefaultChecked = (id) => {
    const result = props.patientFilterList.find((item) => {
      return item === id;
    });
    return result !== undefined;
  };
  const addTags = (patientId) => {
    // alert(event.target.value);

    let tempList = [...tags];
    let patients = props?.uniquePatientsList.filter((item) => {
      return item?.userId === Number(patientId);
    });
    tempList.push(...patients);

    setTags(() => tempList);
    // props.selectedTags([...tags, user]);
  };
  const removeTags = (patientId, position) => {
    let patients = tags.filter((item) => {
      return item?.userId !== patientId;
    });
    let patientIds = patients.map((item) => {
      return item?.userId;
    });
    setTags(() => [...patients]);
    setTempFilterList(patientIds);

    if (position === "chip" && patients?.length === 0) {
      let allpatients = props?.uniquePatientsList?.map((item) => {
        return item?.userId;
      });
      props.setFilterList(() => []);
      props.handleFiltering(() => props.filterList, allpatients);
    } else if (position === "chip") {
      props.setFilterList(() => [...patientIds]);
      props.handleFiltering([...props.filterList], [...patientIds]);
    }
    handleChecksBox(patientId);
    // props?.setFilterList(providers.map((item)=>{return item?.providerTenantPracticeLocation?.provider?.userId}))
  };

  const handleChecksBox = (patientId) => {
    document.getElementById(patientId).checked = false;
  };

  return (
    <>
      <div
        className={`input-group border--default  br-10 ${classes["search__container"]}`}
      >
        <input
          type="text"
          className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
          id="patientFilter"
          name="patientFilter"
          // ref={searchRef}
          autoComplete="off"
          placeholder="Filter patients"
          value={searchQuery}
          // disabled={props?.disableSendRxButton ? true : props?.updateCheck ? true : false}
          onChange={(event) => handlePatientSearch(event.target.value)}
          // onBlur={() => {
          //   setTimeout(() => {
          //     setSuggestions([]);
          //   }, 10);
          // }}
        />
        <button
          className={`btn br-10 shadow-none`}
          type="submit"
          // value={examTestQuery}
        >
          <SearchIcon className=" text--secondary" />
        </button>
      </div>
      <div
        // ref={filterList?.length > 0 ? ref : null}
        className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
        style={{
          // width: `${props?.newPrescriptionRequest ? "44%" : "34%"}`,
          width: "30%",
        }}
      >
        {
          // examTestQuery?.length > 1 &&
          suggestions?.length > 0 &&
            suggestions.map((suggestion) => {
              let id = suggestion?.userId;
              let prefix = suggestion?.name[0]?.prefix ?? "";
              let firstName = suggestion?.name[0]?.firstName ?? "";
              let lastName = suggestion?.name[0]?.lastName ?? "";
              return (
                <li
                  className={`border--default ${classes.li}`}
                  onMouseDown={() => {
                    // handleExamTestList(suggestion);
                  }}
                  key={id}
                >
                  <input
                    type="checkbox"
                    className="cursor--pointer"
                    id={id}
                    onChange={(e) => handlePatientIds(e, id)}
                    defaultChecked={handleDefaultChecked(id)}
                  />
                  <label className="ms-1" htmlFor={id}>
                    {prefix.concat(" ", firstName, " ", lastName)}
                  </label>
                </li>
              );
            })
        }
        {
          // examTestQuery?.length > 0 &&
          suggestions?.length > 0 && (
            // filterList[0]?.code !== "-1" ? (
            <div
              style={{
                position: "sticky",
                display: "flex",
                justifyContent: "center",
                padding: "8px",
                backgroundColor: "white",
                gap: "4px",
                bottom: 0,
                left: 0,
              }}
            >
              <button
                className="btn  d9e8ec--button"
                style={{ height: "2.9 em !important" }}
                onClick={() => {
                  setSuggestions([]);
                  setSearchQuery("");
                }}
              >
                {"Cancel"}
              </button>
              <button
                className="btn blue_task_button"
                onClick={() => {
                  setSuggestions([]);
                  setSearchQuery("");
                  props.setFilterList([...tempFilterList]);
                  props.handleFiltering(
                    [...props?.filterList],
                    [...tempFilterList]
                  );
                }}
                // disabled={tempFilterList?.length === 0}
              >
                {"Apply"}
              </button>
            </div>
          )
        }
      </div>
      <ul className={`${classes["tags"]}`} hidden={true}>
        {/* {console.log(tags)} */}
        {tags?.map((patientItem, index) => (
          <li key={index} className={`${classes["tag"]}`}>
            <span className={`${classes["tag__title"]}`}>
              {patientItem?.name[0]?.firstName +
                " " +
                patientItem?.name[0]?.lastName}
            </span>
            <span
              className={`${classes["tag__close__icon"]}`}
              onClick={() => removeTags(patientItem.userId, "chip")}
              id={patientItem.userId}
            >
              <X color="#336383" style={{ marginBottom: "2px" }} size={16} />
            </span>
          </li>
        ))}
      </ul>
    </>
  );
}

export function PatientBillingTable({
  columnHeadersList,
  data,
  navigateTo,
  patientFilter,
  patientEmail,
  patientId,
  setBillingAmount,
  setIsBillingAccordian,
  setBillingProvider,
  setAppointmentId,
  patientPortal,
  noAction,
}) {
  const filteredColumns = columnHeadersList.filter((item) => {
    if (patientPortal) {
      return ![
        "Place of Service",
        "Service Description",
        "Payment Type",
        "Amount Billed",
      ].includes(item.value);
    }
    return true;
  });

  const { user } = useAuth();
  const navigate = useNavigate();
  const [isPrinting, setIsPrinting] = useState(false);
  const [disabledItem, setDisabledItem] = useState();

  const printBillReceipt = async (patientBillId) => {
    setDisabledItem(patientBillId);
    setIsPrinting(true);
    const httpResponse = await sendHttpRequest({
      url: `/bills/${patientBillId}/receipt`,
      method: "GET",
      responseType: "arraybuffer",
    });
    if (!httpResponse.error) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `Payment_Receipt_${patientBillId}`;
      const href = URL.createObjectURL(
        new Blob([httpResponse.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      link.href = href;
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
    }
    setIsPrinting(false);
    setDisabledItem(undefined);
  };
  const [sortBy, setSortBy] = useState("outstandingBalance");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isOpen, setIsOpen] = useState(false);
  const [filterList, setFilterList] = useState([]); // for provider
  const toggleOpen = () => setIsOpen(!isOpen);
  const menuClass = `dropdown-menu br-7 p-2 bg--white ${isOpen ? " show" : ""}`;
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setIsOpen((p) => false));
  const [uniqueProvidersList, setUniqueProvidersList] = useState([]);
  const [uniquePatientsList, setUniquePatinetsList] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [patientFilterList, setPatientFilterList] = useState(
    patientId !== undefined ? [patientId] : []
  );
  // console.log("patientFilterList: ", patientFilterList, patientId);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState();

  const [openForwardModal, setOpenForwardModal] = useState(false);
  const [receiverEmail, setReceiverEmail] = useState(patientEmail);
  const [patientBillId, setPatientBillId] = useState();

  const [billingHistoryData, setBillingHistoryData] = useState([]);
  const [isBillinghistoryLoading, setIsBillinghistoryLoading] = useState(false);

  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(undefined);
  const [sortDate, setSortDate] = useState([]);
  const [sortOutStandingDues, setSortOutStandingDues] = useState([
    "outstandingBalance",
    "desc",
  ]);

  const fetchBillingHistory = async (
    page = 0,
    providerFilterList = [],
    paymentStatus = undefined,
    sortDate = [],
    sortOutStandingDues = [],
    patientFilterList = []
  ) => {
    setIsBillinghistoryLoading(true);
    const sortParams = [...(sortOutStandingDues || []), ...(sortDate || [])];

    const httpResponse = await sendHttpRequest({
      url: tenantBillingHistoryURL(user?.tenantId),
      method: "GET",
      params: {
        page: page,
        size: 10,
        ...(sortParams.length > 0 && { sort: sortParams.join(",") }),
        // ...(sortOutStandingDues?.length > 0 && {sort:sortOutStandingDues.join(",")}),
        ...(providerFilterList?.length > 0 && {
          providerIds: providerFilterList.join(","),
        }),
        ...(patientFilterList?.length > 0 && {
          patientIds: patientFilterList.join(","),
        }),
        ...(paymentStatus?.length > 0 && { paymentStatus: paymentStatus }),
        // patientIds:"3889",
        // providerIds:"1235,1221",
        // paymentStatus:'Paid'
      },
    });

    if (!httpResponse.error) {
      setBillingHistoryData(httpResponse.data.content);
      // setUpData(httpResponse.data.content)
      // setSortedData(sortData(httpResponse.data.content));
      setSortedData(httpResponse.data.content);
      let noOfPages = httpResponse?.data?.totalPages;
      setTotalResults(noOfPages);
      setCurrentItems(httpResponse.data?.content);
      setMaxPageLimit(noOfPages);
      let pagesList = [];
      for (let i = 1; i <= noOfPages; i++) {
        pagesList.push(i);
      }
      // console.log("pagesList : ",pagesList);
      setPages(pagesList);
    }
    setIsBillinghistoryLoading(false);
  };

  const fetchProviders = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${user?.tenantId}/providers/withBills`,
      method: "GET",
    });

    if (!httpResponse.error) {
      setUniqueProvidersList(httpResponse.data);
    } else {
      setUniqueProvidersList([]);
    }
  };
  const fetchPatients = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${user?.tenantId}/patients/withBills`,
      method: "GET",
    });

    if (!httpResponse.error) {
      // console.log("httpResponse.data patients : ",httpResponse.data);
      setUniquePatinetsList(httpResponse.data);
    } else {
      setUniquePatinetsList([]);
    }
  };
  const handleSort = (column) => {
    // console.log(column);
    // console.log("sortBy ", sortBy);
    if (sortBy === column) {
      let sortDateDir = sortDirection === "asc" ? "desc" : "asc";
      // console.log("sortDateDir ", sortDateDir);
      setSortDirection(() => sortDateDir);
      if (column === "createdDate") {
        setSortOutStandingDues([]);
        setSortDate(["createdDate", sortDateDir]);
        fetchBillingHistory(
          currentPage,
          filterList,
          paymentStatusFilter,
          ["createdDate", sortDateDir],
          [],
          patientFilterList
        );
      } else {
        setSortOutStandingDues(["outstandingBalance", sortDateDir]);
        setSortDate([]);
        fetchBillingHistory(
          currentPage,
          filterList,
          paymentStatusFilter,
          [],
          ["outstandingBalance", sortDateDir],
          patientFilterList
        );
      }
    } else {
      setSortBy((p) => column);
      setSortDirection((p) => "asc");
      if (column === "createdDate") {
        setSortOutStandingDues([]);
        setSortDate(["createdDate", "asc"]);
        fetchBillingHistory(
          currentPage,
          filterList,
          paymentStatusFilter,
          ["createdDate", "asc"],
          [],
          patientFilterList
        );
      } else {
        setSortOutStandingDues(["outstandingBalance", "asc"]);
        setSortDate([]);
        fetchBillingHistory(
          currentPage,
          filterList,
          paymentStatusFilter,
          [],
          ["outstandingBalance", "asc"],
          patientFilterList
        );
      }
    }
  };
  const getValue = (item, column) => {
    const keys = column.split(".");
    let value = item;
    for (let key of keys) {
      if (key === "name") {
        value = value["name"][0];
      } else if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        return null;
      }
    }
    return value;
  };
  const sortData = (data) => {
    return data.slice().sort((a, b) => {
      if (sortBy) {
        const aValue = getValue(a, sortBy);
        const bValue = getValue(b, sortBy);

        if (aValue < bValue) {
          return sortDirection === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      }
      return 0;
    });
  };

  const handleFilterChecks = (event) => {
    if (event.target.checked) {
      const tempList = [...filterList];
      tempList.push(event.target.id);
      setFilterList(tempList);
    } else {
      const dummy = filterList.filter((uid) => uid !== event.target.id);
      setFilterList(dummy);
    }
  };
  const handleClearFiltering = () => {
    setFilterList([]);
    setIsOpen(!isOpen);
    sortedData.forEach((item, index) => {
      let providerId = item?.userId;
      document.getElementById(providerId).checked = false;
    });
    setSortedData(() => sortData(data));
    // fetchAllAttachments();
  };
  const handleFiltering = (providerFilterList, patientFilterList) => {
    let filteredData = billingHistoryData;

    // Check if both filters are empty
    if (providerFilterList?.length === 0 && patientFilterList?.length === 0) {
      // setSortedData(sortData(billingHistoryData));
      setSortedData(billingHistoryData);
      // return sortData(billingHistoryData);
      return billingHistoryData;
    }

    // Apply provider filter if providerFilterList is not empty
    if (providerFilterList?.length > 0) {
      filteredData = filteredData.filter((item) =>
        providerFilterList.includes(item?.userId)
      );
    }
    if (patientFilter === true) {
      // Apply patient filter if patientFilterList is not empty
      if (patientFilterList?.length > 0) {
        filteredData = filteredData.filter((item) =>
          patientFilterList.includes(item?.userId)
        );
      }
    }

    // Set sorted data and return filtered data
    setSortedData(filteredData);
    return filteredData;
  };

  const handlePaymentStatusFilter = (status) => {
    // let filteredDataTemp = handleFiltering(filterList,patientFilterList);
    // console.log("filteredDataTemp: ",status,filterList,patientFilterList,filteredDataTemp);
    // let filteredData = [];
    // if(filteredDataTemp !== undefined && status !== "clear"){
    //   filteredData = filteredDataTemp.filter((item)=>{return item.paymentStatus === status})
    // }else if (status !== "clear"){
    //   filteredData = sortedData.filter((item)=>{return item.paymentStatus === status})
    // }
    if (status === "clear") {
      setPaymentStatusFilter(undefined);
      fetchBillingHistory(
        currentPage,
        filterList,
        undefined,
        sortDate,
        sortOutStandingDues,
        patientFilterList
      );
    } else {
      setPaymentStatusFilter(status);
      fetchBillingHistory(
        currentPage,
        filterList,
        status,
        sortDate,
        sortOutStandingDues,
        patientFilterList
      );
    }

    // setSortedData([...filteredData])
  };

  const forwardReceipt = async (patientBillId) => {
    const httpResponse = await sendHttpRequest({
      url: forwardReceiptUrl(patientBillId),
      method: "POST",
      data: {
        email: receiverEmail,
      },
    });
    if (!httpResponse.error) {
      handleForwardReceiptCloseModal();
      // emptyStates();
      // setOpenForwardModal(!openForwardModal);
    } else {
      console.log(
        `Error in ${forwardReceiptUrl(patientBillId)}`,
        httpResponse?.error,
        " m = ",
        httpResponse?.errorMessage?.error
      );
    }
  };

  function handleForwardModal(patientBillId) {
    setPatientBillId(patientBillId);
    setOpenForwardModal(true);
  }
  function handleForwardReceiptCloseModal() {
    setPatientBillId(undefined);
    setOpenForwardModal(false);
    setReceiverEmail(patientEmail);
  }

  // Pagination Handlers
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            // if(number > maxPageNumberLimit){
            //   console.log(number,maxPageNumberLimit + 1,minPageNumberLimit,number < maxPageNumberLimit + 1 ,
            //     number > minPageNumberLimit);
            // }
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${classes["list__item"]} ${
                    currentPage === number ? classes["active__list__item"] : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    fetchBillingHistory(
      currentPage + 1,
      filterList,
      paymentStatusFilter,
      sortDate,
      sortOutStandingDues,
      patientFilterList
    );
    // searchPharmacy(inputValue, searchFilter, currentPage + 1,proximity);
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // setPageNumber(pageNumber - 1);
    // console.log("currentPage: ",currentPage-1);
    if (currentPage - 1 >= 1) {
      setCurrentPage((p) => currentPage - 1);
      fetchBillingHistory(
        currentPage - 1,
        filterList,
        paymentStatusFilter,
        sortDate,
        sortOutStandingDues,
        patientFilterList
      );

      // searchPharmacy(inputValue, searchFilter, currentPage - 1,proximity);
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
    // searchPharmacy(inputValue, "Retail,MailOrder", currentPage - 1);
  };
  const handleFirstPage = (event) => {
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);

    handleCurrentPage(1);
  };
  const handleLastPage = (event) => {
    event.preventDefault();
    let lastPage = Math.ceil(totalResults);
    let maxPageNumberLimitValue = lastPage;
    if (maxPageNumberLimitValue % 10 > 0) {
      maxPageNumberLimitValue = maxPageNumberLimitValue - 5;
    }
    setCurrentPage(totalResults);
    setMaxPageNumberLimit(lastPage);
    setMinPageNumberLimit(lastPage - pageNumberLimit);

    handleCurrentPage(lastPage);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    fetchBillingHistory(
      pNumber,
      filterList,
      paymentStatusFilter,
      sortDate,
      sortOutStandingDues,
      patientFilterList
    );
    // searchPharmacy(inputValue, searchFilter, pNumber,proximity);
  };

  // useEffect(() => {
  //   setSortedData(() => sortData(sortedData));
  // }, [sortBy, sortDirection]);
  useEffect(() => {
    const fetchBillingHistoryWithFilters = () => {
      if (patientFilter) {
        fetchBillingHistory(
          currentPage,
          filterList,
          paymentStatusFilter,
          sortDate,
          sortOutStandingDues,
          patientFilterList
        );
      } else {
        if (patientId !== undefined && patientId !== null) {
          fetchBillingHistory(
            currentPage,
            filterList,
            paymentStatusFilter,
            sortDate,
            sortOutStandingDues,
            [patientId]
          );
        } else {
          fetchBillingHistory(
            currentPage,
            filterList,
            paymentStatusFilter,
            sortDate,
            sortOutStandingDues,
            []
          );
        }
      }
    };

    fetchBillingHistoryWithFilters();
  }, [
    currentPage,
    filterList,
    paymentStatusFilter,
    patientFilter,
    patientFilterList,
    patientId,
  ]);

  useEffect(() => {
    fetchProviders();
    if (patientFilter) {
      fetchPatients();
    }
  }, []);
  return (
    <>
      {openForwardModal ? (
        <ForwardReceiptModal
          show={openForwardModal}
          handleForwardReceiptCloseModal={handleForwardReceiptCloseModal}
          emailValue={patientEmail}
          forwardReceipt={forwardReceipt}
          setReceiverEmail={setReceiverEmail}
          patientBillId={patientBillId}
        />
      ) : null}
      <div className="container">
        <div className="row">
          <div className={`${patientFilter ? "col-4" : "col-6"} mb-3`}>
            <ProviderSearchFilter
              uniqueProvidersList={uniqueProvidersList}
              setFilterList={setFilterList}
              filterList={filterList}
              patientFilterList={patientFilterList}
              handleFiltering={handleFiltering}
            />
          </div>
          {patientFilter ? (
            <div className={`${patientFilter ? "col-4" : "col-6"} mb-3`}>
              <PatientSearchFilter
                uniquePatientsList={uniquePatientsList}
                setFilterList={setPatientFilterList}
                filterList={filterList}
                patientFilterList={patientFilterList}
                handleFiltering={handleFiltering}
              />
            </div>
          ) : null}
          <div className={`${patientFilter ? "col-4" : "col-6"} mb-3`}>
            <select
              className={`input border--default br-10 form-select font-12 shadow-none custom-select`}
              aria-label="Payment type status"
              onChange={(e) => {
                handlePaymentStatusFilter(e.target.value);
              }}
              style={{
                height: "40px",
              }}
            >
              <option value={"clear"}>{"Select payment status"}</option>
              {["Paid", "Unpaid", "Partial"].map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          {/* <Paper sx={{ width: "100%", overflow: "hidden" }} className="mt-3"> */}
          <TableContainer
            sx={{
              borderRadius: "10px",
              border: "1px solid #D9E8EC",
              maxHeight: 440,
            }}
            // className="hide-scrolbar"
            className={`${classes["scroll__bar"]}`}
          >
            <Table stickyHeader aria-label="sticky table">
            <TableHead
                sx={{
                  borderTopColor: "#FAFCFC",
                  borderRightColor: "#FAFCFC",
                  borderLeftColor: "#FAFCFC",
                  borderBottom: "1px solid #D9E8EC",
                }}
              >
                <StyledTableRow>
                  {filteredColumns?.map((item, idx) => (
                    <StyledTableCell key={item?.key?.concat(idx)}>
                      <TableSortLabel
                        direction={sortBy === item?.key ? sortDirection : "desc"}
                        onClick={() => {
                          if (
                            sortedData !== undefined &&
                            ("Date" === item?.value || "Outstanding" === item?.value)
                          ) {
                            handleSort(item?.key);
                          }
                        }}
                        hideSortIcon={true}
                        sx={{
                          cursor:
                            "Date" === item?.value || "Outstanding" === item?.value
                              ? "pointer"
                              : "default",
                        }}
                      >
                        <span className=" text--secondary">{item?.value}</span>
                        {("Date" === item?.value || "Outstanding" === item?.value) && (
                          <span style={{ color: "#EC609B" }}>
                            {sortDirection === "asc" && sortBy === item?.key ? (
                              <KeyboardArrowUpIcon />
                            ) : sortDirection === "desc" && sortBy === item?.key ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <UnfoldMoreIcon />
                            )}
                          </span>
                        )}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {sortedData?.length > 0 ? (
                  sortedData?.map((item, idx) => {
                    const keys = Object.keys(item);
                    let firstName =
                      item?.providerTenantPracticeLocation?.provider?.name[0]
                        ?.firstName;
                    let lastName =
                      item?.providerTenantPracticeLocation?.provider?.name[0]
                        ?.lastName;
                    let prefix =
                      item?.providerTenantPracticeLocation?.provider?.name[0]
                        ?.prefix;
                    let patientFName = item?.patient?.name[0]?.firstName ?? "";
                    let patientLName = item?.patient?.name[0]?.lastName ?? "";
                    let result;
                    try {
                      let outstandingBalance = parseFloat(
                        item?.outstandingBalance
                      );
                      let amountBilled = parseFloat(item?.amountBilled);

                      if (isNaN(outstandingBalance) || isNaN(amountBilled)) {
                        throw new Error(
                          "Invalid outstanding balance or amount billed"
                        );
                      }

                      if (patientPortal === true) {
                        let balance = outstandingBalance - amountBilled;
                        balance = Math.abs(balance); // Convert any negative balance to positive
                        result = `$${balance.toFixed(2)}`;
                      } else {
                        result = `$${amountBilled.toFixed(2)}`;
                      }
                    } catch (error) {
                      console.error(
                        "Error calculating balance: ",
                        error.message
                      );
                    }
                    return (
                      <StyledTableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        className={`${patientPortal ? "": noAction ? "":"cursor--pointer"}`}
                        // onClick={()=>{console.log(item?.invoiceNumber)}}
                      >
                        <StyledTableCell sx={{ color: "#336383" }}>
                          <span className="">
                            {calenderDateTimeHelper(
                              item?.createdDate,
                              "MMM dd, yyyy"
                            )}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell sx={{ color: "#336383" }}>
                          <span className="">
                            {"".concat(
                              prefix ?? "",
                              " ",
                              firstName,
                              " ",
                              lastName
                            )}
                          </span>
                        </StyledTableCell>
                        {patientPortal === true ? (
                          <>
                            <StyledTableCell sx={{ color: "#336383" }}>
                              <span className="">
                                {item?.paymentStatus ?? ""}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell sx={{ color: "#336383" }}>
                              <span className="">{result}</span>
                            </StyledTableCell>
                          </>
                        ) : null}
                        {patientFilter ? (
                          <StyledTableCell sx={{ color: "#336383" }}>
                            <span className="">
                              {"".concat(patientFName, " ", patientLName)}
                            </span>
                          </StyledTableCell>
                        ) : null}
                        {patientPortal !== true ? (
                          <>
                            <StyledTableCell sx={{ color: "#336383" }}>
                              <span className="">
                                {
                                  item?.providerTenantPracticeLocation?.facility
                                    ?.businessName
                                }
                              </span>
                            </StyledTableCell>
                            <StyledTableCell sx={{ color: "#336383" }}>
                              <span className="">
                                {item?.serviceDescription !== undefined
                                  ? item.serviceDescription
                                      .charAt(0)
                                      .toUpperCase() +
                                    item.serviceDescription
                                      .slice(1)
                                      .toLowerCase()
                                  : ""}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell sx={{ color: "#336383" }}>
                              <span className="">
                                {item?.patientBillAppointmentTransactionHistory
                                  ?.length > 0
                                  ? item
                                      ?.patientBillAppointmentTransactionHistory[0]
                                      ?.transactionType
                                  : ""}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell sx={{ color: "#336383" }}>
                              <span className="">
                                {"$".concat(
                                  parseFloat(item?.amountBilled).toFixed(2)
                                )}
                              </span>
                            </StyledTableCell>
                          </>
                        ) : null}

                        {patientPortal !== true ? (
                          <StyledTableCell sx={{ color: "#336383" }}>
                            <span className="">
                              {item?.paymentStatus ?? ""}
                            </span>
                          </StyledTableCell>
                        ) : null}
                        {/* <StyledTableCell sx={{ color: "#336383" }}>
                          <span 
                          className="">{item?.paymentStatus ?? ""}</span>
                        </StyledTableCell> */}
                        <StyledTableCell sx={{ color: "#336383" }}>
                          <span className="">
                            {"$".concat(
                              parseFloat(item?.outstandingBalance).toFixed(2)
                            )}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell sx={{ color: "#336383" }}>
                          <div className="d-flex flex-row justify-content-between">
                            {patientPortal !== true ? (
                              <>
                                <IconButton
                                  disabled={
                                    item?.paymentStatus?.toLowerCase() ===
                                    "unpaid"
                                      ? true
                                      : disabledItem === item?.patientBillId
                                      ? true
                                      : false
                                  }
                                >
                                  <PnToolTip title={"Print"}>
                                    <PrintIcon
                                      onClick={() => {
                                        printBillReceipt(item?.patientBillId);
                                      }}
                                      style={
                                        item?.paymentStatus?.toLowerCase() ===
                                          "unpaid" ||
                                        disabledItem === item?.patientBillId
                                          ? {
                                              color: "#d9e8ec",
                                              height: "18px",
                                              width: "18px",
                                              background: "none",
                                            }
                                          : {
                                              color: "#336383",
                                              height: "18px",
                                              width: "18px",
                                              background: "none",
                                            }
                                      }
                                      className="cursor--pointer disabled"
                                    />
                                  </PnToolTip>
                                </IconButton>

                                <IconButton
                                // disabled={
                                //   item?.paymentStatus?.toLowerCase() === "unpaid"
                                //     ? true
                                //     : disabledItem === item?.patientBillId ? true
                                //       : false
                                // }
                                >
                                  <PnToolTip
                                    title={"Email"}
                                    hidden={patientPortal}
                                  >
                                    <ForwardToInboxIcon
                                      onClick={() => {
                                        handleForwardModal(item?.patientBillId);
                                      }}
                                      style={{
                                        color: "#336383",
                                        height: "18px",
                                        width: "18px",
                                      }}
                                      className="cursor--pointer disabled"
                                    />
                                  </PnToolTip>
                                </IconButton>
                              </>
                            ) : <span className="font-12 text--secondary fw-sb cursor--pointer" onClick={(e)=>{e.preventDefault();navigate("/patient-portal/view-bill-summary",{state:{billItem:item}})}}>{"View"}</span>}

                            {patientFilter !== true ? (
                              <IconButton
                                disabled={
                                  item?.paymentStatus?.toLowerCase() === "paid"
                                    ? true
                                    : disabledItem === item?.patientBillId
                                    ? true
                                    : false
                                }
                                hidden={patientPortal}
                              >
                                <PnToolTip title={"Pay"}>
                                  <PaidIcon
                                    onClick={(e) => {
                                      if (patientFilter !== true) {
                                        setBillingProvider(
                                          item?.providerTenantPracticeLocation
                                            ?.provider?.userId
                                        );
                                        setIsBillingAccordian(false);
                                        setBillingAmount(
                                          item?.outstandingBalance
                                        );
                                        setAppointmentId(item?.appointment?.id);
                                      }
                                    }}
                                    style={{
                                      color: "#336383",
                                      height: "18px",
                                      width: "18px",
                                    }}
                                    className="cursor--pointer disabled"
                                  />
                                </PnToolTip>
                              </IconButton>
                            ) : null}
                          </div>
                        </StyledTableCell>
                        
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <StyledTableRow>
                    <span className="font-12 text--terciary">
                      {"No data available"}
                    </span>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* </Paper> */}
          <div className="col-2"></div>
          <div
            className={` mt-4 align-self-center col-sm-8 col-8 justify-content-center`}
          >
            {isBillinghistoryLoading ? (
              <Skeleton />
            ) : (
              <div className={" d-flex flex-row justify-content-between"}>
                {pages.length > 1 ? (
                  <div className="ps-2 mt-2">
                    <PnToolTip title="First" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handleFirstPage(e);
                        }}
                        className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                        <FirstPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Previous" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handlePreButton(e);
                        }}
                        className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                      >
                        <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}

                <RenderPages />

                {pages.length > 1 ? (
                  <div className="mt-2">
                    <PnToolTip title="Next" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleNextButton(e);
                        }}
                        className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <NavigateNextIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Last" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleLastPage(e);
                        }}
                        className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <LastPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div className="col-2"></div>
        </div>
      </div>

      {/* {console.log("billingHistoryData: ",billingHistoryData)} */}
    </>
  );
}
export default PatientBillingTable;
