import {
  calenderDateTimeHelper,
  getAgeByDateOfBirth,
} from "../../../utils/dateTimeHelper";
import classes from "../Header/PatientSearch.module.css";

// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const PatientSearchItem = (props) => {
  return (
    <li
      className={`list-group-item font-14 ${classes.li} ${props.isSelected ? classes['current-selection'] : ""}`}

      key={props.id}
      style={{
        // width:props?.showFullInfo ? '26vw':'25.5vw',
        width: props?.showFullInfo ? "29em" : "31.4em",
        paddingTop: props?.showFullInfo ? "8px" : "12px",
        paddingBottom: props?.showFullInfo ? "8px" : "12px",
        paddingLeft: "8px",
        display: "flex",
        fontFamily: "Montserrat",
        fontSize: "14px",
        border: 0,
        backgroundColor: "#ffffff",
        zIndex: "99999",
        // borderBottom:'1px solid #d9e8ec'
      }}

      onMouseDown={() => props.startConsult(props.Patient)}
    >
      {props?.showFullInfo ? (
        <div className="d-flex flex-column container">
          <div className=" font-14 fw-sb text--terciary">
            {props.PatientName}
          </div>
          <div className="d-flex flex-row font-12 text--secondary">
            <div className="me-2">
              {props.Patient?.gender} -{" "}
              {getAgeByDateOfBirth(props.Patient?.dateOfBirth)} {" years"}
            </div>
            <div
              className="me-2 d-flex my-auto"
              style={{ borderRight: "1px solid #D9E8EC", height: "16px" }}
            ></div>
            <div className="me-2">
              {calenderDateTimeHelper(
                props?.Patient?.dateOfBirth,
                "MMM D, YYYY"
              )}
            </div>
            <div
              className="me-2 d-flex my-auto"
              style={{ borderRight: "1px solid #D9E8EC", height: "16px" }}
            ></div>
            <div className="me-2">
              {"PID: "} {props.Patient?.patientTenantIdentification}
            </div>
          </div>
        </div>
      ) : (
        props.PatientName
      )}
    </li>
  );
};

export default PatientSearchItem;
