import { Skeleton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  changeDateTimeFormat,
  getLocalTimeInUTC,
} from "../../utils/dateTimeHelper";
import sendHttpRequest from "../hooks/sendHttpRequest";
import FileShowModal from "../UI/Modals/ProviderModals/FileShowModal";
import FileShowEditModal from "../UI/Modals/ProviderModals/FileShowEditModal";
import FileUploadModal from "../UI/Modals/ProviderModals/FileUploadModal";
import { ReactComponent as EditButtonIcon } from "../../assets/icons/Icon-Edit.svg";
// import { Empty } from 'antd';
import TrashIcon from "../../assets/icons/TrashIcon";
import { useSelector } from "react-redux";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { ReactComponent as SortIcon } from "../../assets/icons/icon-sort-by-date.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import classes from "./DocumentTabPatientProfile.module.css";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { useRef } from "react";
import { useAuth } from "../hooks/useAuth";
import DeleteUserConfirmationModal from "../UI/Modals/DeleteUserConfirmationModal";
import { fetchPatientDocumentsUrl, fetchPatientSingleDocumentUrl, reviewPatientDocumentUrl } from "../../res/endpointurls";
import { PATIENTS } from "../../utils/helpers/subscriptionsConstants";

export default function DocumentTabPatientProfile(props) {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const { user } = useAuth();
  const patientId = props?.patientId;
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [openFileShowModal, setOpenFileShowModal] = useState(false);
  const [isLoadingAtt, setIsLoadingAtt] = useState(true);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBar, setSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState("");

  const [fileToShow, setFileToShow] = useState("");
  const [fileType, setFileType] = useState("");
  const [provider, setProvider] = useState("");
  const [updatePatientId, setUpdatePatientId] = useState("");
  const [showUploadData, setShowUploadData] = useState();
  const [attachments, setAttachments] = useState("");
  const [openEditFileModal, setOpenEditFileModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [uploadInformation, setUploadInformation] = useState("");
  const [fileTypeOnShow, setFileTypeOnShow] = useState("");
  const [fileShowDocId, setFileShowDocId] = useState("");
  const [review, setReview] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileNameShow, setFileNameShow] = useState("");
  const [serviceDate, setServiceDate] = useState(null);
  const [docAlias, setDocAlias] = useState("");
  const [isLoadingFetch, setIsLoadingFetch] = useState(true);
  const [statusCodeAttachments, setStatusCodeAttachments] = useState(null);
  const [providerRole, setProviderRole] = useState(
    prescriberInfo.selectedPrescriberId === 0
      ? prescriberInfo.role
      : prescriberInfo.selectedPrescriberRole
  );
  const [uploadStatus, setUploadStatus] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [sort, setSort] = useState("desc");
  const toggleOpen = () => setIsOpen(!isOpen);
  const [openUserDeleteModal, setOpenUserDeleteModal] = useState(false);
  const menuClass = `dropdown-menu br-7 p-2 bg--white ${isOpen ? " show" : ""}`;
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setIsOpen((p) => false));

  // For file uploading
  const [selectedFile, setSelectedFile] = useState();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingFileError, setUploadingFileError] = useState("");
  const [file, setFile] = useState("");
  const [id, setId] = useState(null);
  const [requiredFileError, setRequiredFileError] = useState("");
  const [assignee, setAssignee] = useState();

  const handleFiltering = () => {
    setIsOpen(!isOpen);
    fetchAttachments();
  };

  const handleClearFiltering = () => {
    setFilterList([]);
    setIsOpen(!isOpen);
    showUploadData.forEach((uploadData, index) => {
      document.getElementById(uploadData.id).checked = false;
    });
    fetchAllAttachments();
  };

  const handleFilterChecks = (event) => {
    if (event.target.checked) {
      const tempList = [...filterList];
      tempList.push(event.target.id);
      setFilterList(tempList);
    } else {
      const dummy = filterList.filter((uid) => uid !== event.target.id);
      setFilterList(dummy);
    }
  };

  const handleFileUploadModal = () => {
    setServiceDate(null)
    setFileUploadModal(!fileUploadModal);
  };

  const initiateUserDelete = id => {
    setId(id);
    setOpenUserDeleteModal(true);
  };

  const closeUserDelete = () => {
    setOpenUserDeleteModal(false);
  };

  const deletePracticeUser = () => {
    deleteDocument(id)
  };

  const handleFileUploadModalClose = () => {
    setFileUploadModal(false);
  };

  const handleFileUploadToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setUploadStatus(false);
    setUpdatedStatus(false);
    setErrorStatus(false);
    setSnackBar(false);
  };
  const fetchDocument = async (patientId, fileId) => {
    setIsLoadingFetch(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPatientSingleDocumentUrl(patientId, fileId),
      method: "GET",
      params: {
        documentId: fileId,
      },
    });
    if (!httpResponse.error) {
      createFileData(httpResponse.data);
      // console.log("httpResponse.data: ", httpResponse.data);
      // setIsLoadingAtt(false);
    } else {
      console.log(httpResponse.error);
      // setIsLoadingAtt(false);
    }
    setStatusCodeAttachments(httpResponse.status);
  };

  const fetchUploadData = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/patient/document/categories",
      method: "GET",
    });
    if (!httpResponse.error) {
      setShowUploadData(httpResponse.data);
      setIsLoading(false);
    } else {
      console.log(httpResponse.error);
    }
    // setIsLoading(false);
  };

  const deleteDocument = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPatientSingleDocumentUrl(patientId, id),
      method: "DELETE",
    });
    if (!httpResponse.error) {

      setSnackBarStatus("success");
      setSnackBarMessage("File deleted successfully");
      setSnackBar(true);

      fetchUploadData();
      fetchAttachments();
      closeUserDelete();

    } else {
      setSnackBarStatus("error");
      setSnackBarMessage(httpResponse?.errorMessage?.message);
      setSnackBar(true);
      closeUserDelete();
      console.log(httpResponse.error);
    }
    setIsLoading(false);
  };

  const fetchAllAttachments = async () => {
    setIsLoadingAtt(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPatientDocumentsUrl(patientId || props?.patientId),
      method: "GET",
      params: {
        sort: sort,
      },
    });
    if (!httpResponse.error) {
      setAttachments(httpResponse.data);
      console.log(httpResponse.data);
    }
    setIsLoadingAtt(false);
    setStatusCodeAttachments(httpResponse.status);
  };

  const fetchAttachments = async (sortParam) => {
    setIsLoadingAtt(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPatientDocumentsUrl(patientId || props?.patientId),
      method: "GET",
      params: {
        sort: sortParam !== undefined ? sortParam : sort,
        category: filterList.length === 0 ? null : filterList.toString(),
      },
    });
    if (!httpResponse.error) {
      setAttachments(httpResponse.data);
      setIsLoadingAtt(false);
    } else {
      console.log(httpResponse.error);
      setIsLoadingAtt(false);
    }
    setStatusCodeAttachments(httpResponse.status);
  };

  const editFileUploadRequestHandler = async () => {
    setIsUploading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPatientSingleDocumentUrl(patientId, uploadInformation?.patientDocumentId),
      method: "PUT",
      data: {
        patientDocumentId: uploadInformation?.patientDocumentId,
        categoryId: fileType,
        alias: docAlias,
        serviceDate: serviceDate,
        documentReview: assignee !== undefined ? [
          {
            assignedTo: {
              userId: assignee,
            },
          },
        ] : null,
      },
    });
    if (!httpResponse.error) {
      setAssignee(undefined);
      fetchAttachments();
      setUpdatedStatus(true);
      editFileModal();
      setUploadInformation("")
    } else {
      setErrorStatus(true);
      console.log(httpResponse.error);
    }
    setUploadInformation("");
    setIsUploading(false);
  };

  const createFileData = (documentData) => {
    // let docType = "";
    // let blob = "";
    // if (documentData !== null) {
    //   let docName = documentData?.Name.split(".");
    //   console.log(docName[docName.length - 1].toLowerCase());
    //   if (docName[docName.length - 1].toLowerCase() === "pdf") {
    //     docType = "application/pdf";
    //   } else if (docName[docName.length - 1].toLowerCase() === "txt") {
    //     docType = "text/plain";
    //   } else if (docName[docName.length - 1].toLowerCase() === "jpg") {
    //     docType = "image/jpg";
    //   } else if (docName[docName.length - 1].toLowerCase() === "png") {
    //     docType = "image/png";
    //   } else if (docName[docName.length - 1].toLowerCase() === "jpeg") {
    //     docType = "image/jpeg";
    //   }
    //   blob = b64toBlob(documentData.FileData, docType);
    //   const blobURL = window.URL.createObjectURL(
    //     new Blob([blob], { type: docType })
    //   );
    // }
    setIsLoadingFetch(false);
    setFileToShow(documentData);
  };

  function b64toBlob(base64, type = "application/octet-stream") {
    try {
      const binStr = window.atob(base64);
      const len = binStr?.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    } catch (e) {
      setIsLoadingFetch(true);
    }
  }

  const fileToShowModal = (patientId, isReviewed, fileName, fileCategory, fileId) => {
    if (fileCategory !== "") {
      setFileTypeOnShow(fileCategory);
    }

    setFileShowDocId(fileId);
    setFileNameShow(fileName);
    setReview(isReviewed);
    setOpenFileShowModal(true);
    fetchDocument(patientId, fileId);
  };

  const handleServieDate = (date) => {
    // console.log(date);
    if (date !== "" && date !== undefined && date !== null) {
      setServiceDate(getLocalTimeInUTC(date, true));
      // } else if (date === "" && date === undefined && date === null) {
      //   setServiceDate(null);
    } else {
      setServiceDate(date);
    }
  };

  const handleDocAlias = (event) => {
    setDocAlias(event.target.value);
  };

  const fileShowModalClose = () => {
    setOpenFileShowModal(false);
  };

  const editFileModal = () => {
    setIsOpenEditModal(!isOpenEditModal);
    setOpenEditFileModal(!openEditFileModal);
  };

  const handleEditFileModal = (patientDocumentId) => {
    const attachmentResult = attachments.filter((attachment) => {
      return attachment.patientDocumentId == patientDocumentId;
    });

    setUpdatePatientId(patientDocumentId);
    setUploadInformation(attachmentResult[0]);
    setFileType(attachmentResult[0]?.category?.id);
    setDocAlias(attachmentResult[0]?.alias ?? "");
    setServiceDate(attachmentResult[0]?.serviceDate ?? null);
    if (attachmentResult[0]?.documentReview?.length > 0) {
      setAssignee(attachmentResult[0]?.documentReview[0]?.assignedTo?.userId ?? undefined)
    }
    setOpenEditFileModal(!openEditFileModal);
    setIsOpenEditModal(!isOpenEditModal);
  };

  const getFileTypeId = (showUploadData, documentType) => {
    let docId = "";
    showUploadData.forEach((element) => {
      if (element.name === documentType) {
        docId = element.id;
      }
    });

    return docId;
  };

  const reviewFile = async () => {
    const httpResponse = await sendHttpRequest({
      // url: "/patient/document/review",
      url: reviewPatientDocumentUrl(patientId, fileShowDocId),
      method: "POST",
      data: {
        patientDocumentId: fileShowDocId,
        documentReview: [
          {
            reviewedBy: {
              userId: user?.userId,
            },
          },
        ],
      },
    });
    if (!httpResponse.error) {
      // emptyStates();
      fetchAttachments();
    }
  };

  const handleServiceDateSorting = () => {
    let sortParam = sort;
    if (sort === "asc") {
      sortParam = "desc";
      setSort("desc");
    } else {
      sortParam = "asc";
      setSort("asc");
    }
    fetchAttachments(sortParam);
  };

  function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + `...` : str;
  }

  const convertToMultipart = (fileV, trimmedDataURL) => {
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];
    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileV?.name, {
          type: mimeType,
        });
        setFile(file);
        //form data
        const formData = new FormData();
        formData.append("fileData", file);
        if (
          serviceDate !== null &&
          serviceDate !== undefined &&
          serviceDate !== "null"
        ) {
          formData.append("serviceDate", serviceDate);
        }
        formData.append("patientId", patientId || props?.patientId);
        formData.append("name", selectedFile.name);
        formData.append("categoryId", fileType);
        formData.append("alias", docAlias);
        if (assignee !== undefined) {
          formData.append("documentReview[0].assignedTo.userId", assignee);
        }

        uploadFile(formData, patientId);
      });
  };

  const sendFileAndType = () => {
    let reader = new FileReader();
    // console.log("Select File: ",selectedFile);
    if (selectedFile !== undefined) {
      setRequiredFileError("");
      reader.readAsDataURL(selectedFile);
      reader.onload = (e) => {
        // console.log(e.target.result);
        convertToMultipart(selectedFile, e.target.result);
        // let bsfe = e.target.result.split(',')[1];
      };
    } else {
      setRequiredFileError("Please select a file to upload.");
    }
  };

  const uploadFile = async (fileData, patientId) => {
    setIsUploading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPatientDocumentsUrl(patientId),
      method: "POST",
      data: fileData,
      headers: {
        "Content-Type": "multipart/form-data; boundary=",
      },
    });
    if (!httpResponse.error) {
      fetchAttachments();
      setUploadStatus(true);
      handleFileUploadModalClose();
      // emptyStates();
    } else {
      setErrorStatus(true);
    }
    setIsUploading(false);
  };

  useEffect(() => {
    fetchUploadData();
    fetchAttachments();
    setFilterList([]);

    return () => {
      setFileToShow(null);
      setFileType(null);
      setProvider(null);
      setUpdatePatientId(null);
      setShowUploadData(null);
      setAttachments(null);
      setOpenEditFileModal(null);
      setIsOpenEditModal(null);
      setUploadInformation(null);
      setFileTypeOnShow(null);
      setFileShowDocId(null);
      setReview(null);
      setFileNameShow(null);
      setServiceDate(null);
      setDocAlias(null);
      setIsLoadingFetch(null);
      setStatusCodeAttachments(null);
      setUploadStatus(null);
      setUpdatedStatus(null);
      setErrorStatus(null);
      setIsOpen(null);
      setFilterList(null);
      setSelectedFile(null);
      setRequiredFileError(null);
      setUploadInformation("")
    };
  }, [patientId]);

  return (
    <>
      <DeleteUserConfirmationModal
        show={openUserDeleteModal}
        deletePromptText={"Are you sure you want to delete this document ?"}
        handleDeleteModalClose={closeUserDelete}
        deleteUser={deletePracticeUser}
      />

      {fileUploadModal ? (
        <FileUploadModal
          show={fileUploadModal}
          handleDocAlias={handleDocAlias}
          setDocAlias={setDocAlias}
          handleServieDate={handleServieDate}
          setServiceDate={setServiceDate}
          showUploadData={showUploadData}
          setProvider={setProvider}
          handleFileUploadModalClose={handleFileUploadModalClose}
          handleFileUploadModal={handleFileUploadModal}
          isUploading={isUploading}
          setSelectedFile={setSelectedFile}
          setFileType={setFileType}
          sendFileAndType={sendFileAndType}
          requiredFileError={requiredFileError}
          setRequiredFileError={setRequiredFileError}
          setAssignee={setAssignee}
        />
      ) : null}
      {fileToShow != "" ? (
        <FileShowModal
          show={openFileShowModal}
          review={review}
          reviewFile={reviewFile}
          fileTypeOnShow={fileTypeOnShow}
          file={fileToShow}
          fileShowModalClose={fileShowModalClose}
          providerRole={providerRole}
          isLoadingFetch={isLoadingFetch}
        />
      ) : null}
      {!isLoading && openEditFileModal ? (
        <FileShowEditModal
          show={openEditFileModal}
          handleDocAlias={handleDocAlias}
          handleServieDate={handleServieDate}
          showUploadData={showUploadData}
          uploadInformation={uploadInformation}
          editFileModal={editFileModal}
          setProvider={setProvider}
          setFileType={setFileType}
          isUploading={isUploading}
          editFileUploadRequestHandler={editFileUploadRequestHandler}
          setAssignee={setAssignee}
          serviceDate={serviceDate}
          setUploadInformation={setUploadInformation}
          fileType={fileType}
        />
      ) : null}
      <div className="row justify-content-center">
        <div
          className="col-sm-11 mt-4 py-3 bg-white br-10 border--default"
          style={{ border: "1px solid #D9E8EC" }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <h3 className="font-16 fw-sb">Documents</h3>
            </div>
            <button
              className="btn d9e8ec--button"
              style={
                {
                  // paddingLeft: "24px",
                  // paddingRight: "24px",
                }
              }
              onClick={() => handleFileUploadModal()}
              hidden={subscriptionPrivilegeGroups[PATIENTS]?.upd?.active === false ? true : false}
            >
              Upload
            </button>
          </div>
          {/* <Divider className="my-2 text--d9e8ec" sx={{ borderBottomWidth: 2 }} /> */}
          <div className="row mt-3 justify-content-center mb-4">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-3">
                  <span className="font-12 text--secondary">Document Name</span>
                </div>
                <div className="col-sm-2">
                  <div className="dropdown" ref={dropdownRef}>
                    <span
                      className="font-12 text--secondary cursor-pointer"
                      onClick={toggleOpen}
                    >
                      Document Type
                      <span style={{ color: "#EC609B" }}>
                        {isOpen === true ? (
                          <KeyboardArrowUpIcon
                            style={{ width: 18, height: 18 }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            style={{ width: 18, height: 18 }}
                          />
                        )}
                      </span>
                    </span>
                    <div
                      className={menuClass}
                      style={{
                        minWidth: 220,
                        maxWidth: 300,
                        opacity: 1,
                      }}
                    >
                      <div className={`${classes["scroll"]}`}>
                        {showUploadData?.length > 0
                          ? showUploadData?.map((uploaddata, index) => {
                            return (
                              <div className="form-check ms-1" key={index}>
                                <input
                                  type="checkbox"
                                  id={uploaddata?.id}
                                  name={uploaddata?.name}
                                  onClick={handleFilterChecks}
                                  className="form-check-input mt-1 shadow-none"
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                                <label
                                  htmlFor={uploaddata?.name}
                                  className="form-check-label font-12 ms-1 text--secondary"
                                >
                                  {uploaddata?.name}
                                </label>
                              </div>
                            );
                          })
                          : ""}
                      </div>
                      <div className="text-end pe-1 mt-2">
                        <button
                          type="button"
                          onClick={handleClearFiltering}
                          className="btn btn-link text-decoration-underline font-12 fw-sb text-blue "
                        >
                          Clear All
                        </button>
                        <button
                          type="button"
                          onClick={handleFiltering}
                          className="btn px-4 br-7 btn-sm text-white font-12 fw-sb blue_task_button"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2">
                  <span className="font-12 text--secondary">Service Date</span>
                  <span
                    className="ms-1 text--secondary cursor-pointer"
                    onClick={() => handleServiceDateSorting()}
                  >
                    <SortIcon />
                  </span>
                </div>
                <div className="col-sm-3">
                  <span className="font-12 text--secondary">Reviewed by</span>
                </div>
              </div>
            </div>
          </div>
          {statusCodeAttachments === 200 ? (
            isLoadingAtt ? (
              <Skeleton />
            ) : attachments?.length > 0 ? (
              attachments?.map((attachment, index) => {
                return (
                  // <FileItem />
                  <div
                    key={index}
                    className="row mt-3 justify-content-between "
                  >
                    <div className="col-sm-3 d-flex flex-row btn-link">
                      <Tooltip
                        title={
                          attachment?.alias !== undefined
                            ? attachment?.alias
                            : "Not Available"
                        }
                        arrow
                        placement="top"
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            cursor: "pointer",
                            fontWeight: 600,
                          }}
                          onClick={() =>
                            fileToShowModal(
                              patientId,
                              attachment?.documentReview !== null
                                ? attachment?.documentReview[0]?.reviewed
                                : false,
                              attachment?.alias,
                              attachment?.category?.id ?? "Not Available",
                              attachment?.patientDocumentId
                            )

                          }
                        >
                          {truncate(attachment?.alias ?? "Not Available", 22)}
                        </Typography>
                      </Tooltip>
                    </div>
                    <div className="col-sm-2 d-flex flex-row ">
                      <Typography style={{ fontSize: "12px" }}>
                        {attachment?.category?.name ?? "Not Available"}
                      </Typography>
                    </div>
                    <div className="col-sm-2 d-flex flex-row">
                      <Typography style={{ fontSize: "12px" }}>
                        {attachment?.serviceDate !== null &&
                          attachment?.serviceDate !== undefined &&
                          attachment?.serviceDate !== "" &&
                          attachment?.serviceDate !== "null"
                          ? changeDateTimeFormat(
                            attachment?.serviceDate,
                            "MMM D, YYYY"
                          )
                          : "Not available"}
                      </Typography>
                    </div>
                    <div className="col-sm-3 d-flex flex-row ">
                      {/* <Typography style={{ fontSize: '14px' }}>{attachment?.AssignedTo?.NonVeterinarian?.Name?.FirstName ?? ""}{' '}{attachment?.AssignedTo?.NonVeterinarian?.Name?.LastName ?? ""}</Typography> */}
                      <Typography style={{ fontSize: "12px" }}>
                        {attachment?.documentReview !== undefined &&
                          attachment?.documentReview !== null ? (
                          <>
                            {
                              attachment?.documentReview[0]?.reviewedBy?.name[0]
                                ?.firstName
                            }{" "}
                            {
                              attachment?.documentReview[0]?.reviewedBy?.name[0]
                                ?.lastName
                            }
                            {attachment?.documentReview[0]?.reviewedBy
                              ?.qualification !== undefined
                              ? ", ".concat(
                                attachment?.documentReview[0]?.reviewedBy
                                  ?.qualification
                              )
                              : ""}
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                      {/* <Typography style={{ fontSize: '14px' }}>{attachment?.Reviewed ? <CheckIcon style={{ color: "#336383" }} /> : ""}</Typography> */}
                    </div>
                    <div className="col-sm-2 d-flex flex-row justify-content-end">
                      <div className="row">
                        <div
                          className="col-sm-4"
                          hidden={subscriptionPrivilegeGroups[PATIENTS]?.epd?.active === false ? true : false}
                        >
                          <EditButtonIcon
                            style={{
                              cursor: "pointer",
                              height: "15px",
                              width: "15px",
                            }}
                            onClick={() =>
                              handleEditFileModal(attachment?.patientDocumentId)
                            }
                          />
                        </div>
                        <div className="col-sm-4 cursor-pointer">
                          <TrashIcon
                            style={{
                              color: "#ff0000",
                              height: "15px",
                              width: "15px",
                            }}
                            onClick={() =>
                              initiateUserDelete(attachment?.patientDocumentId)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <CustomizedSnackbars
        message="File uploaded successfully."
        severity="success"
        open={uploadStatus}
        handleClose={handleFileUploadToastClose}
      />
      <CustomizedSnackbars
        message={snackBarMessage}
        severity={snackBarStatus}
        open={snackBar}
        handleClose={handleFileUploadToastClose}
      />
      <CustomizedSnackbars
        message="File updated successfully."
        severity="success"
        open={updatedStatus}
        handleClose={handleFileUploadToastClose}
      />
      <CustomizedSnackbars
        message="Something went wrong."
        severity="error"
        open={errorStatus}
        handleClose={handleFileUploadToastClose}
      />
      {/* {console.log("uploadInformation: ",uploadInformation)} */}
    </>
  );
}
