import { Backdrop, Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { PnToolTip } from '../../components/UI/Reusable/PnComponents';
import { dateTimeHelper } from '../../utils/dateTimeHelper';
import PageHeader from '../generic/PageHeader';
import { ReactComponent as ViewSummary } from "../../../src/assets/icons/Icon-Preview-Summary.svg";

import { useAuth } from '../../components/hooks/useAuth';
import { useEffect, useState } from 'react';
import sendHttpRequest from '../../components/hooks/sendHttpRequest';
import { ConfigMap } from '../../utils/helpers/ConfigMap';
import { downloadPatientAfterVisitSummaryURL } from '../../res/endpointurls';
import { summaryGeneration } from '../../utils/helpers/DataLoaders';
import PatientVisitSummaryModal from '../appointments/PatientVisitSummaryModal';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useSelector } from 'react-redux';
import { ENCOUNTERS, PAYMENTS } from '../../utils/helpers/subscriptionsConstants';

const PatientAppointmentItem = ({ isEven, isLast, appointment, generateEncounterSummary, userId, ...props }) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  
  const providerName = appointment?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name?.at(0);
  const diagnosis = appointment?.prescriptionReference?.patientChart?.diagnosis?.length > 0 ?
    appointment?.prescriptionReference?.patientChart?.diagnosis : "Not available";

  const primaryDiagnosis = Array.isArray(diagnosis) ? (diagnosis?.filter(d => d.type === 'P')?.at(0)?.description) : "Primary Diagnosis not available";
  const secondaryDiagnoses = Array.isArray(diagnosis) ? (diagnosis?.filter(d => d.type === 'S')) : [];

  const handleDownload = async () => {
    await fetch(ConfigMap("APP") + downloadPatientAfterVisitSummaryURL(appointment?.prescriptionReference?.encounter?.encounterId), {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "Authorization": localStorage.getItem("tkn") !== null ? localStorage.getItem("tkn") : ""
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // console.log(blob);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `after-summary.pdf`,
        );
        link.target = "_blank"

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        link.parentNode.removeChild(link);
      })
  }

  const downloadAppointmentReceipt = async () => {
    console.log("User Id ", userId);
    const httpResponse = await sendHttpRequest({
      url: `/appointments/${appointment?.id}/receipt`,
      method: 'GET',
      responseType: "arraybuffer",
      params: {
        userId: userId
      }
    })
    if (!httpResponse.error) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `Receipt_${appointment?.id}`;
      const href = URL.createObjectURL(
        new Blob([httpResponse.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      link.href = href;
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
    }
  }


  return (
    <Grid
      sx={{ marginBottom: "-8px" }}
      container
      p={1}
      alignItems="center"
      className={`row--border mt-2  ${isEven ? "bg--background" : "bg-white"
        } ${isLast ? "border--bottom-appointments" : ""}`}
    >
      <Grid item xs={1.5}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
              p: 1,
            }}
          >
            {appointment?.appointmentSlot?.startDateTime ? dateTimeHelper(
              appointment?.appointmentSlot?.startDateTime,
              "MMM D, YYYY"
            ) : 'Not available'}
            <br />
            {appointment?.appointmentSlot?.startDateTime ? dateTimeHelper(
              appointment?.appointmentSlot?.startDateTime,
              "LT"
            ) : ''}
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={2.5}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383"
            }}
          >
            {(providerName?.prefix ?? '') + " " + providerName?.firstName + " " + providerName?.lastName}
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",

            }}
          >
            {appointment?.chiefComplaint !== null ? appointment?.chiefComplaint?.length > 0 ? appointment?.chiefComplaint : 'Not available' : 'Not available'}
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",

            }}
          >
            <span className='d-block'>{primaryDiagnosis}</span>
            {secondaryDiagnoses?.map((diagnosis) => (<span className='d-block m-0'>{diagnosis.description}</span>))}
            {/* {appointment?.chiefComplaint !== null ? appointment?.chiefComplaint?.length > 0 ? appointment?.chiefComplaint : '-' : 'Not available'} */}
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={3}>

        <Stack direction="row" justifyContent="end" spacing={-1}>
          <Box
            sx={{
              textAlign: "right",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
              p: 1,
            }}
          >
            <PnToolTip title="Patient Visit Summary" arrow placement="top">
              <button
                style={{
                  backgroundColor: "#FAFCFC",
                  border: "2px solid #d9e8ec",
                }}
                disabled={subscriptionPrivilegeGroups[ENCOUNTERS] === undefined ? true: appointment?.status?.toLowerCase() !== "complete"}
                onClick={() => {
                  generateEncounterSummary(appointment?.prescriptionReference?.prescriptionReferenceId);
                }}
                className="btn shadow-none br-10"
              >
                <ViewSummary style={{
                  color: "#336383",
                  fill: "#336383",
                  width: 16,
                  height: 16
                }} />
              </button>
            </PnToolTip>
          </Box>

          <Box
            sx={{
              textAlign: "right",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
              p: 1,
            }}
          >
            <PnToolTip title="Download patient visit summary" arrow placement="top">

              <button
                style={{
                  backgroundColor: "#FAFCFC",
                  border: "2px solid #d9e8ec",
                }}
                disabled={subscriptionPrivilegeGroups[ENCOUNTERS] === undefined ? true: appointment?.status?.toLowerCase() !== "complete"}
                onClick={handleDownload}
                className="btn shadow-none br-10"
              >

                <SummarizeIcon style={{
                  color: "#336383",
                  fill: "#336383",
                  width: 16,
                  height: 16
                }} />
              </button>
            </PnToolTip>
          </Box>
          <Box
            sx={{
              textAlign: "right",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
              p: 1,
            }}
          >
            <PnToolTip title="Download appointment receipt" arrow placement="top">

              <button
                style={{
                  backgroundColor: "#FAFCFC",
                  border: "2px solid #d9e8ec",
                }}
                // disabled={appointment?.status?.toLowerCase() !== "complete"}
                onClick={downloadAppointmentReceipt}
                className="btn shadow-none br-10"
                disabled={subscriptionPrivilegeGroups[PAYMENTS] === undefined ? true: !appointment?.receiptAvailable}
              >

                <ReceiptLongIcon style={{
                  color: "#336383",
                  fill: "#336383",
                  width: 16,
                  height: 16
                }} />
              </button>
            </PnToolTip>
          </Box>
        </Stack>

      </Grid>
    </Grid>
  )
}

const PatientAppointmentsPage = () => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
    
  const navigate = useNavigate();
  const { user } = useAuth();
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState(null);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [openSummaryBackdrop, setOpenSummaryBackdrop] = useState(false);

  const handleBackdropClose = () => {
    setOpenSummaryBackdrop(false);
  };

  const generateEncounterSummary = async (encId) => {
    setIsSummaryLoading(true);
    setOpenSummaryBackdrop(true);
    const data = await summaryGeneration(encId);
    setSummary(data);
    handleBackdropClose();
    setShowSummaryModal((previous) => !previous);
    setIsSummaryLoading(false);
  };



  const fetchPastAppointments = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/appointment/patient/past",
      method: "GET",
      params: {
        patientId: user?.userId,
        pageSize:10,
        sortOrder: "currentDateFirst"
      }
    });
    if (!httpResponse.error) {
      setAppointments(httpResponse.data);
      console.log(httpResponse.data);
    }
    setIsLoading(false);
  };


  useEffect(() => {
    fetchPastAppointments();
  }, [user?.userId])


  if (isLoading) {
    return (<Backdrop
      sx={{
        color: "#EC609B",
        zIndex: (theme) => theme.zIndex.drawer + 1000,
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>)
  }

  return (
    <>
      {!isSummaryLoading ?
        <PatientVisitSummaryModal
          patientVisitSummary={true}
          summary={summary}
          show={showSummaryModal}
          handleModal={() => { setShowSummaryModal(false) }}
        />
        : null}
      <PageHeader
        title="Visits"
      />
      <div className='container my-3'>
        <div className='row justify-content-center'>
          <div className='col-10'>
            <Grid
              container
              sx={{
                marginBottom: "-8px", borderTop: '1px solid #d9e8ec',
                borderLeft: '1px solid #d9e8ec',
                borderRight: '1px solid #d9e8ec',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                fontFamily:'Montserrat',
              }}
              p={1}

              className={` bg-white `}
            >
              {/* Date and Time */}
              <Grid item xs={1.5}>
                {/* <Stack direction="row" alignItems="center" spacing={2}> */}
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      m: 1,
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Date & Time
                  </Box>
                </Typography>
                {/* </Stack> */}
              </Grid>

              {/* Provider Name */}
              <Grid item xs={2.5}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      // mr: 4,
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Provider's Name
                  </Box>
                </Typography>
              </Grid>

              {/* Chief complaint */}
              <Grid item xs={3}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      // ml: 4,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Chief Complaint
                  </Box>
                </Typography>
              </Grid>

              {/* Diagnosis */}
              <Grid item xs={2}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      // ml: 4,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Diagnosis
                  </Box>
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      // ml: 4,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >

                  </Box>
                </Typography>
              </Grid>

            </Grid>
            {appointments?.map((appointment, idx) => {
              return <PatientAppointmentItem
                isEven={idx % 2 === 0 ? true : false}
                isLast={(appointments?.length - 1) === idx}
                appointment={appointment}
                key={appointment?.prescriptionReference?.prescriptionReferenceId}
                generateEncounterSummary={generateEncounterSummary}
                userId={user?.userId}
              />
            })}
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openSummaryBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default PatientAppointmentsPage;