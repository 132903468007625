import { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";

import { ChevronLeft, UndoRounded } from "@mui/icons-material";
import { changeDateTimeFormat } from "../../utils/dateTimeHelper";
import { X } from "react-bootstrap-icons";
import classes from "../pages/AddUserPage.module.css";
import AddUserForm from "../user/AddUserForm";
import CareTeam from "../user/CareTeam";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import { useStateWithCallback } from "../hooks/useStateWithCallback";
import { navigateOnDirtyBit, UnloadHandler } from "../../utils/helpers/HelperFunctions";
import { PnToolTip, TabPanel, theme } from "../UI/Reusable/PnComponents";
import { useAuth } from "../hooks/useAuth";
import { validationSchemas } from "../validations/validationschemas";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import RolesTapMap from "../../utils/helpers/RolesTabMap";
import { ClaimsSettings } from "./TenantSettingsPage";
import { BILLING_AND_CLAIMS, EHR_ONLY } from "../../utils/helpers/subscriptionsConstants";


const getTabValue = function (tabTitle) {
  const tabsMap = new Map();
  tabsMap.set("General", 0);
  tabsMap.set("Claims Settings", 1);
  // tabsMap.set("Medical Credentials", 2);
  // tabsMap.set("Care Team", 3);
  return tabsMap.get(tabTitle);
};

//Medical License Row Component
// const LicenseRow = ({ license, handleRemoveMedicalLicense }) => {
//   return (
//     <div className="row ps-3  pb-2 ">
//       <div className="col-sm-3 py-1 border-bottom border-bottom-color text-start">
//         <span className="fw-sb font-14 text--terciary">
//           {license?.StateLicense}
//         </span>
//       </div>
//       <div className="col-sm-3 py-1 border-bottom border-bottom-color text-start">
//         <span className=" font-14 text--secondary">{license?.State}</span>
//       </div>
//       <div className="col-sm-3 py-1 border-bottom border-bottom-color text-start">
//         <div className="row justify-content-around">
//           <div className="col-sm-10">
//             <span className="font-14 text--secondary">
//               {changeDateTimeFormat(license?.ExpiryDate, "dddd, MMM D, YYYY")}
//             </span>
//           </div>
//           <div className="col-sm-2">
//             <span
//               className="cross-sign"
//               onClick={() =>
//                 handleRemoveMedicalLicense(
//                   license?.StateLicense,
//                   license?.State
//                 )
//               }
//             >
//               <X color="#336383" style={{ marginBottom: "2px" }} size={24} />
//             </span>
//           </div>
//         </div>
//       </div>
//       {/* <div className="col-sm-1 text-end py-1 border-bottom">
// 												</div> */}
//     </div>
//   );
// };

const DeaRow = ({ license, handleRemoveDeaLicense }) => {
  return (
    <div className="row ps-3  pb-2 ">
      <div className="col-sm-3 py-1 border-bottom border-bottom-color text-start">
        <span className="fw-sb font-14 text--terciary">
          {license?.DeaNumber}
        </span>
      </div>
      <div className="col-sm-3 py-1 border-bottom border-bottom-color text-start">
        <div className="row justify-content-around">
          <div className="col-sm-10">
            <span className=" font-14 text--secondary">{license?.State}</span>
          </div>
          <div className="col-sm-2">
            <span
              className="cross-sign"
              onClick={() =>
                handleRemoveDeaLicense(license?.DeaNumber, license?.State)
              }
            >
              <X color="#336383" style={{ marginBottom: "2px" }} size={24} />
            </span>
          </div>
        </div>
      </div>

      {/* <div className="col-sm-1 text-end py-1 border-bottom">
													</div> */}
    </div>
  );
};

const AddUserPage = ({ editMode }) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  
  const schema = validationSchemas.user.userSchema;
  const dispatch = useDispatch();
  const location = useLocation();
  const { getUser } = useAuth();
  console.log("getUser: ", getUser().tenant);
  // console.log("editMode===========================",editMode);
  const [value, setValue] = useState(0);
  const [editUser, setEditUser] = useState(editMode ? location?.state?.provider : null);
  const [user, setUser] = useState(
    editMode ? populateProviderObject() : createProviderObject()
  );
  const [userRole, setUserRole] = useState("");
  const [filterRole, setFilterRole] = useState("all");
  const [isSending, setIsSending] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [error, setError] = useState(false);
  const [isUserAdded, setIsUserAdded] = useState(false);
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const navigate = useNavigate();
  const [hideStatus, setHideStatus] = useStateWithCallback(false);
  const [facilitiesCheckedList, setFacilitiesCheckedList] = useState(
    populateFaciliyCheckedList(editMode ? editUser?.providerTenantPracticeLocationList : [])
  );
  const [facilitiesIdsCheckedList, setFacilitiesIdsCheckedList] = useState(
    populateFaciliyIdsCheckedList(editMode ? editUser?.providerTenantPracticeLocationList : [])
  );
  const [validationErrors, setValidationErrors] = useState({});
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);

  const [selectedPayers, setSelectedPayers] = useState([])
  const [enableClaimSettingsTab, setEnableClaimSettingsTab] = useState(() => {
    if (editMode === true && editUser?.identification?.npi?.length > 0) {
      return editUser?.displayClaimsTab !== true;
    } else {
      return getUser()?.tenant?.claimProcessingTenantLevel === true && (getUser()?.tenant?.groupNpi?.length > 0 || user?.identification?.npi?.length > 0);
    }
  })

  const [isValidUserData, setIsValidUserData] = useState(false);

  const handleChange = (event, newValue) => {
    console.log("In hanlde Change");
    console.log("editUser: ",editUser);
    console.log("location?.state?.provider: ",location?.state?.provider);
    
    if (newValue === getTabValue("Claims Settings")) {
      if (editMode === true && location?.state?.provider?.displayClaimsTab === true  && editUser?.identification?.npi?.length > 0) {
        setValue(newValue);
      } else if (getUser()?.tenant?.claimProcessingTenantLevel === false && (getUser()?.tenant?.groupNpi?.length > 0 || user?.identification?.npi?.length > 0)) {
        setValue(newValue);
      }
    }
    else if (newValue !== getTabValue("Claims Settings")) {
      setValue(newValue);
    }
  };

  const addToCareTeam = (id) => {
    //   setCareTeam((prevStateSnapshot)=>{
    // 	  	return [...prevStateSnapshot, id];
    // 	  });

    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      if (!tempUser.NonVeterinarian.CareTeam) {
        tempUser.NonVeterinarian.CareTeam = [];
      }
      tempUser.NonVeterinarian.CareTeam = [
        ...tempUser.NonVeterinarian.CareTeam,
        parseInt(id),
      ];
      tempUser.NonVeterinarian.CareTeam = [
        ...new Set(tempUser.NonVeterinarian.CareTeam),
      ];

      return { ...previousSnapshot, ...tempUser };
    });
  };

  function createProviderObject() {
    let provider = {};

    provider.name = [{ firstName: "", lastName: "", middleName: "", suffix: "", type: "cn" }];
    provider.communicationNumbers = {};
    provider.identification = {};
    provider.communicationNumbers.numbers = [{ number: "", type: "" }];
    provider.communicationNumbers.electronicMail = [""];
    provider.identification.npi = null;
    provider.identification.taxIdNumber = null;
    provider.identification.socialSecurity = null;
    provider.identification.upin = "";
    provider.facility = [];
    provider.role = "";
    provider.status = false;
    provider.tenant = getUser()?.tenant;
    provider.speciality = undefined;
    provider.timezone = "";
    provider.timezoneOffset = "";
    provider.providerContact = [{
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      type: {
        internalValue: "enrollment",
      },
    }, {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      type: {
        internalValue: "primary",
      },
    }];
    provider.dbaName = "";
    provider.previousClearingHouse = "";

    provider.aaddress = {};
    provider.aaddress.addressLine1 = "";
    provider.aaddress.addressLine2 = "";
    provider.aaddress.city = "";
    provider.aaddress.stateProvince = "";
    provider.aaddress.postalCode = "";
    provider.aaddress.type = "B";


    return provider;
  }

  function populateProviderObject() {
    let provider = {};

    provider.userId = setId();
    provider.name = [
      {
        firstName: setNames("firstName"),
        lastName: setNames("lastName"),
        middleName: setNames("middleName"),
        suffix: setNames("suffix"),
        type: "cn",
      },
    ];
    provider.communicationNumbers = {};
    provider.identification = {};
    provider.address = {};
    // provider.communicationNumbers.numbers = [
    //   { number: setCellPhoneValue("PT"), type: "PT" },
    //   { number: setCellPhoneValue("HT"), type: "HT" }
    // ];

    provider.communicationNumbers.numbers = [];

    if (setCellPhoneValue("PT") !== '') {
      provider.communicationNumbers.numbers.push({ number: setCellPhoneValue("PT"), type: "PT" })
    }

    if (setCellPhoneValue("HT") !== '') {
      provider.communicationNumbers.numbers.push({ number: setCellPhoneValue("HT"), type: "HT" })
    }

    provider.communicationNumbers.electronicMail = hydrateEmail();
    provider.supervisor = hydrateSupervisor();
    provider.providerTenantPracticeLocationList = hydratePTPLList(editUser?.providerTenantPracticeLocationList);
    provider.facility = hydrateFacility(provider.providerTenantPracticeLocationList);
    provider.role = setRole();
    provider.identification.npi = setNpi();
    provider.identification.taxIdNumber = setTin();
    provider.identification.socialSecurity = setSsn();
    provider.identification.upin = setUpin();
    provider.status = setStatus();
    provider.tenant = getUser()?.tenant;
    provider.speciality = setSpecialtyValue();
    provider.timezone = setTimezone();
    provider.timezoneOffset = setTimezoneOffset();
    provider.providerContact = setProviderContact();
    provider.dbaName = setDbaNam();
    provider.previousClearingHouse = setPreviousClearingHouse();
    provider.address.addressLine1 = setAddressLine1();
    provider.address.addressLine2 = setAddressLine2();
    provider.address.city = setCity();
    provider.address.stateProvince = setState();
    provider.address.postalCode = setPostalCode();
    provider.address.type = "B";

    return provider;
  }

  function hydrateFacility(ptplList) {
    return ptplList?.length > 0 ? [ptplList[0]?.facility] : [];
  }

  function hydratePTPLList(providerTenantPracticeLocationList) {
    let tempList = [];
    if (providerTenantPracticeLocationList?.length > 0) {
      providerTenantPracticeLocationList.forEach((ptpl) => {
        if (!ptpl?.deleted && ptpl?.facility !== undefined) {
          tempList.push(ptpl);
        }
      });
    }
    return tempList;
  }

  function populateFaciliyCheckedList(facility) {
    let tempList = [];
    if (facility?.length > 0) {
      facility.forEach((fac) => {
        if (!fac?.deleted && fac?.facility?.tenant?.tenantId === getUser()?.tenantId) {
          tempList.push(fac?.facility?.businessName);
        }
      });
    }
    return tempList;
  }

  function populateFaciliyIdsCheckedList(facility) {
    let tempList = [];
    if (facility?.length > 0) {
      facility.forEach((fac) => {
        if (!fac?.deleted && fac?.facility?.tenant?.tenantId === getUser()?.tenantId) {
          tempList.push(fac?.facility?.facilityId);
        }
      });
    }
    return tempList;
  }


  function setCellPhoneValue(type) {

    return editUser?.communicationNumbers?.numbers?.find(number => number?.type?.toLowerCase() === type?.toLowerCase())?.number || ''
  }

  function setNames(param) {
    let val = "";
    if (editUser?.name?.length > 0) {
      val = editUser?.name[0][param];
    }
    return val;
  }

  function setId() {
    return editUser?.userId;
  }

  function setRole() {
    return editUser?.role;
  }

  function setNpi() {
    return editUser?.identification?.npi;
  }
  function setTin() {
    return editUser?.identification?.taxIdNumber;
  }
  function setSsn() {
    return editUser?.identification?.socialSecurity;
  }
  function setUpin() {
    return editUser?.identification?.upin;
  }

  function setStatus() {
    return editUser?.status;
  }
  function setSpecialtyValue() {
    if (editUser?.speciality !== undefined) {
      return editUser?.speciality;
    } else return [{ specialityId: "", speciality: "" }]
  }

  function hydrateEmail() {
    let val = [];
    if (editUser?.communicationNumbers?.electronicMail?.length > 0) {
      val = editUser?.communicationNumbers?.electronicMail;
    }
    return val;
  }

  function hydrateSupervisor() {
    return editUser?.supervisor;
  }
  function setTimezone() {
    return editUser?.timezone ?? "";
  }
  function setTimezoneOffset() {
    return editUser?.timezoneOffset ?? "";
  }
  function setProviderContact() {
    return editUser?.providerContact ?? [];
  }
  function setDbaNam() {
    return editUser?.dbaName;
  }
  function setPreviousClearingHouse() {
    return editUser?.previousClearingHouse;
  }
  function setAddressLine1() {
    return editUser?.address[0]?.addressLine1 ?? ""
  }
  function setAddressLine2() {
    return editUser?.address[0]?.addressLine2 ?? ""
  }
  function setCity() {
    return editUser?.address[0]?.city ?? ""
  }
  function setState() {
    return editUser?.address[0]?.stateProvince ?? ""
  }
  function setPostalCode() {
    return editUser?.address[0]?.postalCode ?? ""
  }

  const removeFromCareTeam = (id) => {
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.NonVeterinarian.CareTeam = [
        ...tempUser.NonVeterinarian.CareTeam,
      ];
      const idx = tempUser.NonVeterinarian.CareTeam.indexOf(parseInt(id));
      tempUser.NonVeterinarian.CareTeam.splice(idx, 1);
      return { ...previousSnapshot, ...tempUser };
    });
  };

  // const fetchEnrollmentStatus = async ()=>{
  //   const httpResponse = await sendHttpRequest({
  //     url:`/providers/${user?.userId}?includeDisplayClaimsTab=true`,
  //     method:"GET"
  //   })
  //   if(!httpResponse.error){
  //     setDisplayClaimsTab(httpResponse.data?.displayClaimsTab ?? false);
  //   }
  // }
  const addUserHandler = async () => {
    if (user?.role !== "Physician") {
      user.speciality = null
    }
    const form = document.getElementById('userAddForm');
    //Validate first on frontend
    if (form.reportValidity()) {
      setIsSending(true);

      try {
        await schema.validate(user, { abortEarly: false });
        //Validation success
        setValidationErrors({});
        // let providerContactObject = user?.providerContact
        // if(user?.dbaName !== undefined){
        //   providerContactObject.push({
        //     firstName: user?.name[0]?.firstName,
        //     lastName: user?.name[0]?.lastName,
        //     phoneNumber: user?.communicationNumbers?.numbers?.find(number => number?.type?.toLowerCase() === "PT".toLowerCase())?.number || '',
        //     email: user?.communicationNumbers?.electronicMail[0],
        //     type: {
        //       internalValue: "primary",
        //     },
        //   })
        // }
        let userData = { ...user }
        if (user?.address?.addressLine1?.length > 0) {
          userData = { ...userData, address: [user.address] }
        } else {
          delete userData.address
        }

        //submit form
        const httpResponse = await sendHttpRequest({
          url: "/provider",
          method: "POST",
          data: userData,
        });
        if (!httpResponse.error) {
          setIsUserAdded(true);
          dispatch(setDirtyBitInStore(false));
          setTimeout(() => {
            navigate("/users");
          }, 400);
        } else {
          setServerErrors(httpResponse.errorMessage?.errorDetails ?? []);
          setIsSending(false);
        }
      }
      catch (err) {
        const newErrors = {};
        err.inner.forEach(e => {
          newErrors[e.path] = e.message;
        });
        setValidationErrors(p => newErrors);
      }
    }

  };

  const updateUserHandler = async () => {
    const form = document.getElementById('userAddForm');
    if (form.reportValidity()) {
      setIsSending(true);
      let userData = { ...user }
      if (user?.address?.addressLine1?.length > 0) {
        userData = { ...userData, address: [user.address] }
      } else {
        delete userData.address
      }

      const httpResponse = await sendHttpRequest({
        url: `/provider/${user.userId}/tenant/${getUser().tenantId}`,
        method: "PUT",
        data: userData,
      });
      if (!httpResponse.error) {
        setIsUserUpdated(true);
        dispatch(setDirtyBitInStore(false));
        setTimeout(() => {
          navigate("/users");
        }, 400);
      } else {
        // console.log(httpResponse.errorMessage);
        // setErrorMessage(httpResponse.errorMessage)
        setServerErrors(httpResponse.errorMessage?.errorDetails ?? []);
        setIsSending(false);
      }
    }
  };

  const removeServerError = (execeptionsArray, fieldName) => {
    setServerErrors(execeptionsArray?.filter((o) => o?.name !== fieldName));
  }

  const closeToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsUserAdded(false);
    setError(false);
  };

  // window.addEventListener("beforeunload", UnloadHandler(dirtyBitStatus));

  // useEffect(() => {
  //   return () => {
  //     window.removeEventListener("beforeunload", UnloadHandler(dirtyBitStatus));
  //   };
  // });
  // useEffect(()=>{
  //   setEnableClaimSettingsTab(
  //     ()=>{ 
  //       if (editMode === true && editUser?.identification?.npi?.length > 0) {
  //         return editUser?.displayClaimsTab !== true;
  //       } else {
  //         return getUser()?.tenant?.claimProcessingTenantLevel === true && (getUser()?.tenant?.groupNpi?.length > 0 || user?.identification?.npi?.length > 0);
  //       }
  //     }
  //   )
  // },[])

  console.log("getUser()?.tenant?.claimProcessingTenantLevel: ", getUser()?.tenant?.claimProcessingTenantLevel);

  return (
    <ThemeProvider theme={theme}>
      <>
        <div
          className={`container-fluid bg-white  justify-content-center px-3 py-3 ${classes["page__title-border"]}`}
        >
          <div className="row">
            <div className="col-sm-4 text--terciary fw-sb font-28 align-self-center ">
              <div className="d-flex flex-row">
                <div
                  className={`bg--background ${classes["chevron__left__div"]} br-10 me-3 cross-sign border--default border text-center my-auto `}
                >
                  <ChevronLeft
                    style={{ color: "#EC609B" }}
                    onClick={() => {
                      navigateOnDirtyBit(
                        dirtyBitStatus,
                        navigate,
                        -1,
                        dispatch,
                        setDirtyBitInStore
                      );
                    }}
                    className="my-auto mt-2 mb-2"
                  />
                </div>
                <div className="mt-1 font-22">
                  {editMode ? "Edit User" : "Add User"}
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <Tabs
                indicatorColor="secondary"
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
              // sx={{
              //   visibility: `${RolesTapMap.get(
              //     RolesTextConversion(user?.role)
              //   ) === undefined
              //     ? "hidden"
              //     : hideStatus
              //       ? "hidden"
              //       : "visible"
              //     }`,
              // }}
              // sx={{
              //   visibility: 'hidden'
              // }}
              >
                {/* {RolesTapMap.get(
                  RolesTextConversion(user?.role)
                ) === undefined ? (
                  
                ) : (
                  RolesTapMap.get(
                    RolesTextConversion(user?.role)
                  )?.map((item) => {
                    return (
                      <Tab
                        key={getTabValue(item)}
                        label={item}
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "initial",
                          color: "#336383",
                          fontFamily: "Montserrat",
                        }}
                      />
                    );
                  })
                )} */}
                <Tab
                  key={getTabValue("General")}
                  label={"General"}
                  // sx={{ visibility: "hidden" }}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "initial",
                    color: "#336383",
                    fontFamily: "Montserrat",
                    // display: "hidden",
                  }}
                />
                {subscriptionPrivilegeGroups[BILLING_AND_CLAIMS] !== undefined ? <PnToolTip
                  // title={editMode === true ? editUser?.displayClaimsTab !== true ?  "Claim settings not enabled" :  "": getUser()?.tenant?.claimProcessingTenantLevel === true ? "Claim settings not enabled":""}
                  title={enableClaimSettingsTab ? "Claim settings not enabled" : ""}
                  arrow
                  placement="top"
                >

                  <Tab
                    key={getTabValue("Claims Settings")}
                    label={"Claims Settings"}
                    // sx={{ visibility: "hidden" }}
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                      fontFamily: "Montserrat",
                      // display: "hidden",
                    }}
                  // disabled = {getUser()?.tenant?.claimProcessingTenantLevel}

                  />
                </PnToolTip> : null}
              </Tabs>
            </div>
          </div>
        </div>
        <div className="container-fluid vh-100 bg--background">
          <TabPanel value={value} index={getTabValue("General")}>
            <div className="row pt-4 pb-1 justify-content-center">
              <div className="col-sm-8">
                <h2 className="font-16 ms-1 fw-sb text--terciary">
                  Personal Information
                </h2>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-7">
                <AddUserForm
                  setUserRole={setUserRole}
                  setHideStatus={setHideStatus}
                  setUser={setUser}
                  user={user}
                  validationErrors={validationErrors}
                  handleChange={handleChange}
                  editMode={editMode}
                  isSending={isSending}
                  submitHandler={addUserHandler}
                  editHandler={updateUserHandler}
                  hydrateEmail={hydrateEmail}
                  hydrateSupervisor={hydrateSupervisor}
                  setNpiFromProvider={setNpi}
                  setTinFromProvider={setTin}
                  setSsnFromProvider={setSsn}
                  facilitiesCheckedList={facilitiesCheckedList}
                  facilitiesIdsCheckedList={facilitiesIdsCheckedList}
                  setNames={setNames}
                  setCellPhoneValue={setCellPhoneValue}
                  setIsSending={setIsSending}
                  getTabValue={getTabValue}
                  serverErrors={serverErrors}
                  removeServerError={removeServerError}
                  setSpecialtyValue={setSpecialtyValue}
                  setProviderContact={setProviderContact}
                  setIsValidUserData={setIsValidUserData}
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} sx={{ display: 'none' }} index={getTabValue("Medical Credentials")}>
            {/* <MedicalCredentialsForm editMode /> */}
          </TabPanel>

          <TabPanel value={value} sx={{ display: 'none' }} index={getTabValue("Care Team")}>
            <CareTeam
              isSending={isSending}
              submitHandler={addUserHandler}
              addToCareTeam={addToCareTeam}
              removeFromCareTeam={removeFromCareTeam}
              user={user}
              setUser={setUser}
              filterRole={filterRole}
              setFilterRole={setFilterRole}
            />
          </TabPanel>

          <TabPanel value={value} index={getTabValue("Claims Settings")}>
            <div className="row justify-content-center">
              <div className="col-8 ">
                <ClaimsSettings
                  setValidationErrors={""}
                  validationErrors={""}
                  tenantSettingsData={user}
                  setTenantSettingsData={setUser}
                  selectedPayers={selectedPayers}
                  setSelectedPayers={setSelectedPayers}
                  providerPage={true}
                  isValidUserData={isValidUserData}
                  submitHandler={addUserHandler}
                  editHandler={updateUserHandler}
                  setValue={setValue}
                  setEditUser={setEditUser}
                  editMode={editMode}
                  isSending={isSending}
                // setUpinFromProvider={setUpin}

                />
              </div>
            </div>
          </TabPanel>
        </div>
        <CustomizedSnackbar
          message={"User created successfully"}
          severity="success"
          open={isUserAdded}
          handleClose={closeToast}
        />
        <CustomizedSnackbar
          message={"User updated successfully"}
          severity="success"
          open={isUserUpdated}
          handleClose={closeToast}
        />
        <CustomizedSnackbar
          message={"Something went wrong"}
          severity="error"
          open={error}
          handleClose={closeToast}
        />
      </>
      {console.log("user: ", user, isValidUserData)}
    </ThemeProvider>
  );
};

export default AddUserPage;
