import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableSortLabel,
  IconButton,
  Box,
  Collapse,
} from "@mui/material";
import useOnClickOutside from "../../../../components/hooks/useOnClickOutside";
import PreviewIcon from "@mui/icons-material/Preview";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { calenderDateTimeHelper } from "../../../../utils/dateTimeHelper";
import TrashButtonIcon from "../../../../assets/icons/Icon-Trash.svg";
import TrashButtonIconDisabled from "../../../../assets/icons/Icon-Trash-Disabled.svg";
import { PnToolTip } from "../../../../components/UI/Reusable/PnComponents";
import useModal from "../../../../components/hooks/useModal";
import ReplayIcon from "@mui/icons-material/Replay";
import { EditIconComponent } from "../../../../assets/icons/EditIconComponent";
import DeletePatientClaimModal from "../UI/Modals/DeletePatientClaimModal";
import { STATUS_MAP } from "../../../../billing-and-claims/BillingAndClaimsConstants";
import {
  currencyFormatter,
  truncate,
} from "../../../../utils/helpers/HelperFunctions";
import { useSelector } from "react-redux";
import { BILLING_AND_CLAIMS } from "../../../../utils/helpers/subscriptionsConstants";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

function statusToString(status) {
  return STATUS_MAP.get(status);
}

function AllClaimsTable({
  columnHeadersList,
  data,
  navigateTo,
  switchView,
  setEncounterToEdit,
  setEditMode,
  setReloadClaims,
  hierarchy,
}) {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } =
    useSelector((state) => state.subscriptionPrivilegeGroups);

  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [isOpen, setIsOpen] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const toggleOpen = () => setIsOpen(!isOpen);
  const menuClass = `dropdown-menu br-7 p-2 bg--white ${isOpen ? " show" : ""}`;
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setIsOpen((p) => false));
  const [uniqueProvidersList, setUniqueProvidersList] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  const { open: openDeleteClaimModal, handleModal: handleDeleteClaimModal } =
    useModal(false);
  const [deleteClaimId, setDeleteClaimId] = useState(null);

  const handleSort = (column) => {
    console.log("column: ", column);
    if (sortBy === column) {
      setSortDirection(() => (sortDirection === "asc" ? "desc" : "asc"));
    } else {
      setSortBy((p) => column);
      setSortDirection((p) => "asc");
    }
  };
  const getValue = (item, column) => {
    const keys = column.split(".");
    let value = item;
    for (let key of keys) {
      if (key === "provider.firstName") {
        value = value["firstName"][0];
      } else if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        return null;
      }
    }
    return value;
  };
  const sortData = () => {
    return data.slice().sort((a, b) => {
      if (sortBy) {
        const aValue = getValue(a, sortBy);
        const bValue = getValue(b, sortBy);

        console.log("aValue,bValue", aValue, bValue);
        if (aValue < bValue) {
          return sortDirection === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      }
      return 0;
    });
  };

  const handleFilterChecks = (event) => {
    if (event.target.checked) {
      const tempList = [...filterList];
      tempList.push(event.target.id);
      setFilterList(tempList);
    } else {
      const dummy = filterList.filter((uid) => uid !== event.target.id);
      setFilterList(dummy);
    }
  };
  const handleClearFiltering = () => {
    setFilterList([]);
    setIsOpen(!isOpen);
    sortedData.forEach((item, index) => {
      let providerId = item?.providerTenantPracticeLocation.provider?.userId;
      document.getElementById(providerId).checked = false;
    });
    setSortedData(() => sortData());
    // fetchAllAttachments();
  };
  const handleFiltering = () => {
    setIsOpen(!isOpen);
    let filterData = [];
    if (filterList?.length == 0) {
      setSortedData(sortData());
    } else {
      filterList.forEach((filterItem) => {
        filterData.push(
          ...data.filter((item) => {
            return (
              item?.providerTenantPracticeLocation?.provider?.userId ===
              Number(filterItem)
            );
          })
        );
      });

      setSortedData(() => filterData);
    }
    // sortedData = data.filter((item)=>{ item?.providerTenantPracticeLocation?.provider?.userId})
    // fetchAttachments();
  };



  useEffect(() => {
    setSortedData(() => sortData());
  }, [sortBy, sortDirection]);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    const uniqueObjects = data.filter((item, index, arr) => {
      const firstName = item.encounter.provider.firstName;
      if (!firstName) return false; // Skip items without a firstName
      return (
        arr.findIndex(
          (obj) => obj.encounter.provider.firstName === firstName
        ) === index
      );
    });
    setSortedData(sortData());
    setUniqueProvidersList(uniqueObjects);
  }, [data]);
  return (
    <>
      {openDeleteClaimModal ? (
        <DeletePatientClaimModal
          show={openDeleteClaimModal}
          handleModal={handleDeleteClaimModal}
          claimId={deleteClaimId}
          setReloadClaims={setReloadClaims}
        />
      ) : null}
      <TableContainer
        sx={{
          borderRadius: "10px",
          border: "1px solid #D9E8EC",
          maxHeight: "60vh",
          maxWidth: "100%",
          overflowX: "auto",
          "&::-webkit-scrollbar": { height: "8px", width: "4px" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#D9E8EC",
            borderRadius: "4px",
          },
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <TableRow>
              {columnHeadersList?.map((item, idx) => {
                return (
                  <TableCell key={item?.key?.concat(idx)}>
                    <TableSortLabel
                      // active={sortBy === item?.key}
                      direction={sortBy === item?.key ? sortDirection : "asc"}
                      // onClick={() => handleSort(item?.key)}
                      sx={{
                        cursor: "default",
                      }}
                      hideSortIcon={true}
                    >
                      <span className="font-12 text--secondary">
                        {item?.value}
                      </span>
                      {/* Conditional rendering of custom-colored icon */}

                      {hierarchy ? (
                        <span style={{ color: "#EC609B" }}>
                          {" "}
                          {/* Change the color here */}
                          {sortDirection === "desc" && sortBy === item?.key ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((item, idx) => {
              const keys = Object.keys(item);
              let firstName = item?.encounter?.provider?.firstName;
              let lastName = item?.encounter?.provider?.lastName;
              let prefix = item?.encounter?.provider?.prefix;

              return (
                <>
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ color: "#336383", userSelect: "text" }}>
                      <div
                        style={{ userSelect: "text" }}
                        className="d-flex flex-row justify-content-between font-12 text-decoration-underline-secondary cursor--pointer"
                      >
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(!open)}
                          hidden={hierarchy !== true}
                        >
                          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                        <PnToolTip title={item?.claimNumber}>
                          <span
                            onClick={() => {
                              navigate(`/claim-history/${item?.claimId}`, {
                                state: { data: item },
                              });
                            }}
                          >
                            {/* {truncate(item?.claimNumber, 12)} */}
                            {item?.claimNumber}
                          </span>
                        </PnToolTip>
                      </div>
                    </TableCell>

                    <TableCell sx={{ color: "#336383" }}>
                      <span className="font-12">
                        {item?.claimResponse?.apexEdiClaimNumber ?? "N/A"}
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: "#336383" }}>
                      <span className="font-12">
                        {"".concat(prefix ?? "", " ", firstName, " ", lastName)}
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: "#336383" }}>
                      <span className="font-12">
                        {"".concat(
                          item?.encounter?.patient?.firstName,
                          " ",
                          item?.encounter?.patient?.lastName
                        )}
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: "#336383" }}>
                      <span className="font-12">
                        {calenderDateTimeHelper(
                          item?.encounter?.createdDate,
                          "MMM dd, yyyy"
                        )}
                      </span>
                    </TableCell>

                    <TableCell sx={{ color: "#336383" }}>
                      <span className="font-12">
                        {item?.encounter?.patient?.patientInsurance?.find(
                          (i) =>
                            i.patientInsuranceId == item?.patientInsuranceDataId
                        )?.payerName ?? "N/A"}
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: "#336383" }}>
                      <span className="font-12">${item?.totalAmount}.00</span>
                    </TableCell>

                    <TableCell sx={{ color: "#336383" }}>
                      <span className="font-12">
                        {item?.providerPaymentAmount  !== undefined && item?.providerPaymentAmount  !== null ? currencyFormatter("USD",item?.providerPaymentAmount):
                        
                        item?.claimResponse?.payerAmount !== undefined &&
                          item?.claimResponse?.payerAmount !== null
                          ? "$" + item?.claimResponse?.payerAmount + ".00"
                          : "N/A"}
                      </span>{" "}
                    </TableCell>

                    <TableCell sx={{ color: "#336383" }}>
                      <span className="font-12">
                        {
                        item?.providerPatientAmount  !== undefined && item?.providerPatientAmount  !== null ? currencyFormatter("USD",item?.providerPatientAmount):
                        item?.claimPayment?.patientResponsibility !== null &&
                          !isNaN(item?.claimPayment?.patientResponsibility)
                          ? currencyFormatter(
                            "USD",
                            item?.claimPayment?.patientResponsibility
                          )
                          : "N/A"}
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: "#336383" }}>
                      <span className="font-12">
                        {statusToString(item?.status)}
                      </span>
                    </TableCell>

                    <TableCell sx={{ color: "#336383" }}>
                      <div className="d-flex gap-2">
                        <div
                          onClick={() => {
                            if (
                              item?.status !== "CLAIM_SUBMITTED" &&
                              item?.status !== "CLAIM_APPROVED"
                            ) {
                              navigate("/edit-claim-data", {
                                state: { data: item },
                              });
                            }
                          }}
                          className={`${item?.status === "CLAIM_SUBMITTED" ||
                            item?.status === "CLAIM_APPROVED"
                            ? "cursor--disabled"
                            : "cursor--pointer"
                            }`}
                          hidden={
                            subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bec
                              ?.active === true
                              ? false
                              : true
                          }
                        >
                          <PnToolTip title={`Edit Claim Data`}>
                            <EditIconComponent
                              alt={"editIcon"}
                              id="claim-edit"
                              title={"Edit Claim Data"}
                              style={{
                                color: `${item?.status === "CLAIM_SUBMITTED" ||
                                  (item?.status === "CLAIM_APPROVED")
                                  ? "#bcd6da"
                                  : "#336383"
                                  }`,
                                width: "15px",
                                height: "15px",
                              }}
                            ></EditIconComponent>
                          </PnToolTip>
                        </div>
                        <div
                          onClick={() => {
                            if (
                              item?.status !== "CLAIM_SUBMITTED" &&
                              item?.status != "CLAIM_APPROVED"
                            ) {
                              setDeleteClaimId((p) => item?.claimId);
                              handleDeleteClaimModal();
                            }
                          }}
                          className={`${item?.status === "CLAIM_SUBMITTED" ||
                            item?.status === "CLAIM_APPROVED"
                            ? "cursor--disabled"
                            : "cursor--pointer"
                            }`}
                          hidden={
                            subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bdc
                              ?.active === true
                              ? false
                              : true
                          }
                        >
                          <PnToolTip
                            title={`${item?.status === "CLAIM_SUBMITTED" ||
                              item?.status === "CLAIM_APPROVED"
                              ? ""
                              : "Delete Claim"
                              }`}
                          >
                            {item?.status === "CLAIM_APPROVED" ? (
                              <img
                                src={TrashButtonIconDisabled}
                                alt={"deleteIconDisabled"}
                                id="encounter-delete-disabled"
                                style={{ width: "15px", height: "15px" }}
                              ></img>
                            ) : (
                              <img
                                src={TrashButtonIcon}
                                alt={"deleteIcon"}
                                id="encounter-delete"
                                style={{ width: "15px", height: "15px" }}
                              ></img>
                            )}
                          </PnToolTip>
                        </div>

                        {item?.status === "CLAIM_READY_FOR_SUBMISSION" ? (
                          <div>
                            {item?.status === "CLAIM_READY_FOR_SUBMISSION" ? (
                              subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]
                                ?.bsc?.active === true ? (
                                <PnToolTip title="Review Claim">
                                  <PreviewIcon
                                    onClick={() => {
                                      navigate("/review-claim", {
                                        state: { data: item },
                                      });
                                    }}
                                    fontSize="small"
                                    style={{ color: "#336383" }}
                                    className="cursor--pointer font-12 disabled"
                                  />
                                </PnToolTip>
                              ) : null
                            ) : null}
                          </div>
                        ) : null}
                        <div>
                          {item?.status === "CLAIM_DENIED" ? (
                            <PnToolTip title="Re-Submit Claim">
                              <ReplayIcon
                                onClick={() => {
                                  navigate("/re-submit-claim", {
                                    state: { data: item },
                                  });
                                }}
                                fontSize="small"
                                style={{ color: "#336383" }}
                                className="cursor--pointer font-12"
                              />
                            </PnToolTip>
                          ) : null}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow hidden={hierarchy !== true}>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={12}
                    >
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Table size="small" aria-label="claim services">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <span className="font-12 text--secondary">
                                    Service Id
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="font-12 text--secondary">
                                    Date (From)
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="font-12 text--secondary">
                                    Date (To)
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="font-12 text--secondary">
                                    Quantity
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="font-12 text--secondary">
                                    Charges
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="font-12 text--secondary">
                                    HCPCS Code
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="font-12 text--secondary">
                                    Description
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="font-12 text--secondary">
                                    Diagnoses
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="font-12 text--secondary">
                                    Modifiers
                                  </span>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item?.claimServices?.map((service) => (
                                <TableRow key={service?.claimServiceId}>
                                  <TableCell sx={{ color: "#336383" }}>
                                    <span className="font-12">
                                      {service?.claimServiceId}
                                    </span>
                                  </TableCell>
                                  <TableCell sx={{ color: "#336383" }}>
                                    <span className="font-12">
                                      {calenderDateTimeHelper(
                                        service?.dateOfServiceFrom,
                                        "MMM dd, yyyy"
                                      )}
                                    </span>
                                  </TableCell>
                                  <TableCell sx={{ color: "#336383" }}>
                                    <span className="font-12">
                                      {calenderDateTimeHelper(
                                        service?.dateOfServiceTo,
                                        "MMM dd, yyyy"
                                      )}
                                    </span>
                                  </TableCell>
                                  <TableCell sx={{ color: "#336383" }}>
                                    <span className="font-12">
                                      {service?.quantity}
                                    </span>
                                  </TableCell>
                                  <TableCell sx={{ color: "#336383" }}>
                                    <span className="font-12">
                                      ${service?.charges}.00
                                    </span>
                                  </TableCell>
                                  <TableCell sx={{ color: "#336383" }}>
                                    <span className="font-12">
                                      {service?.hcpcsProcedureCode}
                                    </span>
                                  </TableCell>
                                  <TableCell sx={{ color: "#336383" }}>
                                    <span className="font-12">
                                      {truncate(
                                        service?.hcpcsProcedureDescription,
                                        30
                                      )}
                                    </span>
                                  </TableCell>
                                  <TableCell sx={{ color: "#336383" }}>
                                    <span className="font-12">
                                      {service?.diagnoses?.map((diagnosis) => (
                                        <div key={diagnosis.diagnosisId}>
                                          {`${diagnosis.code} - ${truncate(
                                            diagnosis.description,
                                            10
                                          )}`}
                                        </div>
                                      ))}
                                    </span>
                                  </TableCell>
                                  <TableCell sx={{ color: "#336383" }}>
                                    <span className="font-12">
                                      {service?.modifiers}
                                    </span>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AllClaimsTable;
