import { Modal } from "react-bootstrap";
import classes from "./ReportColumnModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import CSVService from "../Services/CSVService.service";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import { Backdrop, CircularProgress } from "@mui/material";

const ReportsColumns = (props) => {
  // Initialize the state to store the checkbox states
  const [checkboxes, setCheckboxes] = useState(
    props.checkboxes?.data.reduce((acc, item) => {
      acc[item.key] = item.defaultCheck; // Set the initial state using defaultCheck
      return acc;
    }, {})
  );

  const handleChange = (e, key) => {
    const { checked } = e.target;

    // Update the checkbox state for the specific checkbox
    setCheckboxes((prevState) => ({
      ...prevState,
      [key]: checked, // Toggle the checkbox state
    }));
    // Update the parent component's columns state
    props?.setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      updatedColumns[props?.index].data = updatedColumns[props?.index].data.map(
        (item) => {
          if (item.key === key) {
            return { ...item, defaultCheck: checked }; // Update the checkbox state
          }
          return item;
        }
      );
      return updatedColumns;
    });

    console.log("Checkbox Key: ", key, "Checked: ", checked);
  };

  return (
    <>
      <span className="font-12 fw-sb text--terciary">{props?.heading}</span>
      <div
        className={`form-check ms-1 mt-2 ${classes["scroll__bar"]}`}
        style={{ maxHeight: "200px", minHeight: "150px", overflowY: "auto" }}
      >
        {props.checkboxes?.data.map((item) => {
          return (
            <div key={item.key} className="form-check cursor--pointer">
              <input
                className="form-check-input pn-checkbox shadow-none"
                type="checkbox"
                id={item.key}
                checked={checkboxes[item.key] || false} // Control the checkbox with state
                onChange={(e) => handleChange(e, item.key)} // Handle changes on click
              />
              <label className="form-check-label font-12" htmlFor={item.key}>
                {item.value}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

const ReportColumModal = (props) => {
  const { user } = useAuth();

  const [columns, setColumns] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [claimsData, setClaimsData] = useState([]);
  const [sortByDate,setSortByDate] = useState(false);

  const [isClaimsDataLoading, setIsClaimsDataLoading] = useState(false);

  const fetchAllClaims = async (fromDate, toDate) => {
    // console.log({ provider, patient, status });
    setClaimsData([]);
    setIsClaimsDataLoading(true);
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: `/claims/tenants/${user?.tenantId}/insurance-claims`,
      params: {
        ...(fromDate?.length > 0 && { fromDate: fromDate }),
        ...(toDate?.length > 0 && { toDate: toDate }),
      },
    });
    if (!httpResponse.error) {
      // setClaimsData(httpResponse.data);
      downloadCSV(httpResponse.data);
    } else {
    }
    setIsClaimsDataLoading(false);
  };

  const downloadCSV = (claimsData) => {
    if (claimsData?.length > 0) {
      // CSVService.downloadCSV(claims, 'claims_data.csv');
      let tableMetaData = columns.reduce((acc, item) => {
        const { data } = item;
        acc.push(...data);
        return acc;
      }, []);
      console.log("tableMetaData: ", tableMetaData);

      let filterClaimsData = [];
      for (let claimItem of claimsData) {
        // console.log("claimItem: ", claimItem);
        let claimObject = {};
        let services = claimItem.claimServices;
        for (let claimServiceItem of services) {
          // console.log("claimServiceItem: ",claimServiceItem);

          for (let element of tableMetaData) {
            if (element.defaultCheck === true) {
              let objElement = CSVService.getSelectedColumns(
                claimItem,
                element
              );
              // console.log("objElement: ", objElement);
              claimObject[objElement.key] = objElement.value;
            }
          }
          for (let element of tableMetaData) {
            // console.log("element: ",element);

            if (
              element.tableName === "claimServices" &&
              element.defaultCheck === true
            ) {
              let objElement = CSVService.getSelectedColumns(
                claimServiceItem,
                element
              );
              // console.log("objElement: ", objElement);
              claimObject[objElement.key] = objElement.value;
            }
          }
          // console.log("claimObject: ",claimObject);

          filterClaimsData.push(claimObject);
        }
      }
      CSVService.downloadCSV(filterClaimsData);
      console.log("filterClaimsData: ", filterClaimsData);
      //   props?.handleModal();
    }
  };

  const handleStartDate = (dateVal) => {
    if (dateVal !== undefined) {
      setStartDate(calenderDateTimeHelper(dateVal, "YYYY-MM-DD"));
    }
  };

  const handleEndDate = (dateVal) => {
    if (dateVal !== undefined) {
      setEndDate(calenderDateTimeHelper(dateVal, "YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    let columnsData = CSVService.claimDefaultReportMetaData();
    // Grouping the columns by tableName and formatting the data
    const groupedData = columnsData.reduce((acc, column) => {
      const { tableName, key, value, defaultCheck } = column;

      // Check if the tableName exists in the accumulator, if not, create it
      if (!acc[tableName]) {
        acc[tableName] = { tableName, data: [] };
      }

      // Push the key-value pair along with defaultCheck into the respective table group
      acc[tableName].data.push({ key, value, defaultCheck, tableName });

      return acc;
    }, {});

    // Convert the object to an array of grouped data
    const result = Object.values(groupedData);

    setColumns(result);
  }, []);

  console.log("columns: ", columns);
  // console.log("startDate: ", startDate);
  // console.log("endDate: ", endDate);

  return (
    <>
      <Modal
        show={props.show}
        contentClassName="modal-border modal-shadow h-100 bg--background"
        backdropClassName="modal-backdrop"
        dialogClassName={`${classes["modal__width"]}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb">
            Download CSV Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <label className="text--terciary font-12 align-self-center fw-sb">
                  Start Date:
                </label>
                <CalenderDatePicker
                  className={"col-3"}
                  f12={true}
                  dobHandler={handleStartDate}
                  date={startDate !== undefined ? startDate : ""}
                  key={`startDate`}
                />
              </div>

              <div className="col-6">
                <label className="text--terciary font-12 align-self-center fw-sb">
                  End Date:
                </label>
                <CalenderDatePicker
                  f12={true}
                  dobHandler={handleEndDate}
                  date={endDate !== undefined ? endDate : ""}
                  key={`EndDate`}
                />
              </div>

              <div className="col-12 mt-2">
                <span className="font-14 fw-sb text--terciary">
                  {"Report Columns"}
                </span>
              </div>
              {columns?.length > 0 &&
                columns?.map((item, index) => {
                  return (
                    <div className="col-6 mt-3 border--right">
                      <ReportsColumns
                        key={item?.tableName}
                        heading={item?.tableName}
                        checkboxes={item}
                        setColumns={setColumns}
                        index={index} // Pass the index as a prop
                      />
                    </div>
                  );
                })}

              <div className="col-12 mt-3 mb-3">
                <input
                  type="checkbox"
                  name="sortByDate"
                  id="sortByDate"
                  value="sortByDate"
                  className="me-1 cursor--pointer"
                  onChange={(e) => setSortByDate(e.target.checked)}
                />
                <label
                  htmlFor="sortByDate"
                  className="me-5 font-14 text--terciary"
                >
                  {"Enabel Sort by Date"}
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-12">
              <button
                className="blue_task_button btn"
                onClick={() => {
                  fetchAllClaims(startDate, endDate);
                }}
                disabled={isClaimsDataLoading}
              >
                {"Download CSV"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={isClaimsDataLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ReportColumModal;
