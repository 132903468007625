import { useCallback } from "react";
import { useState } from "react";
import { search } from "../hooks/sendHttpRequest";
import { useDispatch } from "react-redux";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";

export default function useCptCodeSearch() {
    const dispatch = useDispatch();
    const [cptSuggestions, setCptSuggestions] = useState([]);
    const [isCptLoading, setIsCptLoading] = useState(false);
    const [isCptError, setIsCptError] = useState(false);
    const [cptSearchInputValue, setCptSearchInputValue] = useState("");
    const [cptCodeName, setCptCodeName] = useState("");
    const [selectedCptCode, setSelectedCptCode] = useState(undefined);

    const searchCptCode = async (cptInput) => {
        setIsCptLoading(true);
        const httpResponse = await search({
            url: `/claims/medical-codes`,
            method: "GET",
            params: {
                search: cptInput,
            },
        });
        if (!httpResponse.error) {
            setIsCptLoading(false);
            setCptSuggestions(httpResponse.data);
            setIsCptError(false);
        } else {
            if (httpResponse?.status === 400) {
                setIsCptError(true);
                setIsCptLoading(false);
            }
        }
    };

    const handleCptCodeSearchInput = useCallback(
        (cptInput) => {
            if (cptInput.length > 2) {
                // setDiagnosisName(icdInput)
                searchCptCode(cptInput);
                setCptSearchInputValue(cptInput);
            }
            setCptCodeName(cptInput);
            setCptSuggestions([]);
            setIsCptLoading(false);
        },
        [
            searchCptCode,
            setCptSearchInputValue,
            setCptCodeName,
            setCptSuggestions,
            setIsCptLoading,
        ]
    );

    const handleRemoveCptCode = (removeId) => {
        dispatch(setDirtyBitInStore(true));
        setSelectedCptCode(undefined);
        setCptCodeName("");
    };

    const handleAddCptCode = (cptCode) => {
        dispatch(setDirtyBitInStore(true));
        setCptCodeName(`${cptCode?.code} - ${cptCode?.description}`);
        setSelectedCptCode(cptCode);
    };

    return {
        cptSuggestions, setCptSuggestions, isCptLoading, setIsCptLoading, isCptError, setIsCptError, cptSearchInputValue, setCptSearchInputValue, cptCodeName, setCptCodeName, selectedCptCode, setSelectedCptCode, searchCptCode, handleCptCodeSearchInput, handleAddCptCode, handleRemoveCptCode
    }
}