import { ChevronLeft } from "@mui/icons-material";
import { Skeleton, Typography } from "@mui/material";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setDiagnosis,
  setDisableSendRxButton,
  setPatientEncounterData,
  setPrescriptionNoteText,
  setUpdateEncounterData,
  updatePrescribedMedication,
} from "../../redux/actions/providerActions";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";
import IdmeVerificationModal from "../UI/Modals/ProviderModals/IdmeVerificationModal";
import classes from "./NotificationPage.module.css";

const NotificationPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const videoCallContext = useContext(VideoCallContext);
  const [notificationList, setNotificationList] = useState([]);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const [idmeVerificationModal, setIdmeVerificationModal] = useState(false);

  const [IDMELink, setIDEMLink] = useState("");

  const fecthNotifications = async (patient) => {
    setIsNotificationsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/notifications",
      method: "GET",
      params: props?.patient === true ? {
        userId: user?.userId,
        tenantId: user?.tenantId,
        status: "all",
        type: ['NON_ACTIONABLE_CANCEL_APPT_NOTIFICATION_FOR_PATIENT', 'NON_ACTIONABLE_NEW_APPT_NOTIFICATION_FOR_PATIENT', 'NON_ACTIONABLE_RESCHEDULE_APPT_NOTIFICATION_FOR_PATIENT', 'NON_ACTIONABLE_48_HOUR_PRIOR_NOTIFICATION_FOR_PATIENT', 'NEW_APPOINTMENT_FOR_PATIENT_INSTANT_SMS'].toString()
      } : {
        userId: user?.userId,
        tenantId: user?.tenantId,
        status: "all",
        type: ['APPT_ACTIONABLE', 'NPPES_ACTIONABLE', 'NON_ACTIONABLE', "CLM_ACTIONABLE"].toString()
      },
    });
    if (!httpResponse.error) {
      console.log(httpResponse.data);
      setNotificationList(httpResponse.data);
      readNonActionableNotofications(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsNotificationsLoading(false);
  };

  const handleIdMeVerificationModal = () => {
    setIdmeVerificationModal(!idmeVerificationModal)
  }

  const sendStartAppointmentRequest = async (notification) => {
    let appointmentId = notification?.id;
    videoCallContext.updateAppointmentData(appointmentId);
    videoCallContext.updateAppointmentObject(notification);
    const httpResponse = await sendHttpRequest({
      url: `/appointment/${appointmentId}/start`,
      method: "GET",
    });
    if (!httpResponse.error) {
      navigate(
        `/consult/${notification?.appointmentSlot?.provider?.userId}/${notification?.prescriptionReference?.prescriptionReferenceId}/${notification?.appointmentSlot?.patient?.userId}`
      );
      console.log("Start appoitnment", httpResponse.data);
    } else {
      // navigate(
      //   `/consult/${notification?.appointmentSlot?.provider?.userId}/${notification?.prescriptionReference?.prescriptionReferenceId}/${notification?.appointmentSlot?.patient?.userId}`
      // );
      console.log(httpResponse.error);
    }

  };
  const handleMessageReadStatus = async (threadId) => {
    const httpResponse = await sendHttpRequest({
      url: "/messages/mark-read",
      method: "PUT",
      params: {
        userId: user?.userId,
        threadId: threadId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
  };
  const fetchClaimData = async (claimId) => {
    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user?.tenantId}/insurance-claims/${claimId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      console.log(httpResponse.data);
      navigate(`/claim-history/${claimId}`, { state: { data: httpResponse.data } })
    } else {
      console.log(httpResponse.error);
    }
  };
  const handleActions = (notification) => {
    // handleMessageReadStatus(notification?.threadId)
    if (notification?.messageType === "APPT_ACTIONABLE") {
      console.log(notification)
      // handlePatientEncouterData(props?.notificationList[0]?.appointment);
      handlePatientEncouterData(notification?.appointment);
    } else if (notification?.messageType === "NPPES_ACTIONABLE") {
      handleIdMeVerificationModal();
      setIDEMLink(notification?.route);
      // navigate(notification?.route);
    }
    else if(notification?.messageType === "CLM_ACTIONABLE") {
      handleMessageReadStatus(notification?.threadId);
      fetchClaimData(notification?.claimId);
    }
  };
  const readNonActionableNotofications = (notification) => {
    notification?.length > 0 && (
      notification?.map((notificationItem, index) => {
        if (localStorage.getItem("role") === "patient") {
          handleMessageReadStatus(notificationItem?.threadId);
        } else if (localStorage.getItem("role") !== "patient") {
          if ( notificationItem?.messageType.includes("NON_ACTIONABLE")) {
            handleMessageReadStatus(notificationItem?.threadId);
          }
        }
        // if (notificationItem?.messageType === "NON_ACTIONABLE") {
        // } else if (notificationItem?.messageType === "NON_ACTIONABLE_CANCEL_APPT_NOTIFICATION_FOR_PATIENT") {
        // } else if (notificationItem?.messageType === "NON_ACTIONABLE_RESCHEDULE_APPT_NOTIFICATION_FOR_PATIENT") {
        // } else if (notificationItem?.messageType === "NON_ACTIONABLE_NEW_APPT_NOTIFICATION_FOR_PATIENT") {}
      })
    )
  }

  const handlePatientEncouterData = async (notification) => {
    // dispatch(setConsulationInProgress(true)) //move this to AgoraConfigurationPage
    dispatch(setPrescriptionNoteText(""));
    dispatch(setDisableSendRxButton(false));
    dispatch(updatePrescribedMedication([]));
    if (notification?.status !== "started") {
      await sendStartAppointmentRequest(notification);
    }
    dispatch(setPatientEncounterData(notification));
    dispatch(
      setUpdateEncounterData({
        Diagnosis: {
          Note: "",
          Primary: [],
          Secondary: [],
        },
      })
    );
    dispatch(
      setDiagnosis({
        Note: "",
        Primary: [],
        Secondary: [],
      })
    );
    if (notification?.status === "started") {
      navigate(
        `/consult/${notification?.appointmentSlot?.provider?.userId}/${notification?.prescriptionReference?.prescriptionReferenceId}/${notification?.appointmentSlot?.patient?.userId}`
      );
    }
  };

  useEffect(() => {
    fecthNotifications(props?.paitent);
    readNonActionableNotofications();
  }, []);

  return (
    <>
      {/* {props?.patient === true ? <PatientNavbar /> : <Navbar />} */}

      <IdmeVerificationModal
        link={IDMELink}
        show={idmeVerificationModal}
        handleModal={handleIdMeVerificationModal}
      />

      <div className="border--bottom ">
        <div className="text--terciary font-32 align-self-center ms-3 py-3 ">
          <div className="d-flex flex-row">
            <div
              className="bg--background br-10 me-3 border--default border text-center my-auto "
              style={{ height: "37px", width: "38px" }}
              onClick={() => navigate(-1)}
            >
              <ChevronLeft
                style={{ color: "#EC609B", cursor: "pointer" }}
                className="my-auto mb-2 my-auto"
              />
            </div>
            <div className="font-24 align-self-center">Notifications</div>
          </div>
        </div>
      </div>

    {console.log(notificationList)}
      <div className="row mt-4">
        <div className="col-2"></div>
        <div className="col-8 mt-4 p-0">
          {isNotificationsLoading ? (
            <Skeleton />
          ) : (
            notificationList?.length > 0 &&
            notificationList?.map((notificationItem, index) => {
              return (
                <div key={index} className="row">
                  <div className="col-md-1 text-center ">
                    <div className="row" style={{ minWidth: 110 }}>
                      <div>
                        <div className="text--secondary px-1 bg--d9e8ec rounded font-12 fw-sb">
                          <div style={{ marginBottom: -5 }}>
                            {
                              dateTimeHelper(
                                notificationItem?.deliveryTime,
                                "MMM D, YYYY"
                              )
                            }
                          </div>
                          <div>
                            {
                              dateTimeHelper(
                                notificationItem?.deliveryTime,
                                "LT"
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 ms-4">
                    <ul class={`${classes.timeline}`}>
                      <li class={`${classes["timeline-item"]}`}>
                        <div className="border--bottom">
                          <Typography
                            style={{
                              fontSize: 12,
                              color: "#336383",
                              fontFamily: "Monserrat",
                              marginBottom: 24
                            }}
                          >
                            <div>
                              {notificationItem?.body}
                        
                            </div>
                            <div className="">
                              {notificationItem?.messageType !== "NON_ACTIONABLE" && notificationItem?.status.toLowerCase() !== "expired" &&
                                (notificationItem?.status.toLowerCase() === "completed" ? (
                                  <div className="align-self-center">
                                    <div className="text--terciary font-12 fw-sb">Completed</div>
                                    <div className="text--secondary font-font fw-sb">
                                      {dateTimeHelper(notificationItem?.completionTime,
                                        "MMM D, YYYY LT")}
                                    </div>
                                  </div>
                                ) : (
                                  // <div className="text-end ">
                                  notificationItem?.buttonText ?
                                    <button
                                      className={`text--white align-self-center font-12 fw-sb mt-3 px-3 py-1 bg--blue border-0 ${classes["action__button"]}`}
                                      disabled={notificationItem?.messageType !== "CLM_ACTIONABLE" && notificationItem?.status === "READ"}
                                      onClick={() => {
                                        handleActions(notificationItem);
                                      }}
                                    >
                                      {notificationItem?.buttonText}
                                    </button>
                                    : ""

                                  // </div>
                                ))}
                            </div>
                          </Typography>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="col-md-4 p-0"> */}
                  {/* <div className="text--terciary font-14 fw-sb ">
                      {notificationItem?.subject}
                    </div> */}

                  {/* </div> */}
                  <div className="col-4 p-0 text-end align-self-center">

                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );
};

export default NotificationPage;
