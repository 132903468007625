import { Avatar, Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import sendHttpRequest, {
  search,
} from "../../components/hooks/sendHttpRequest";
import { addTimeHelper, dateTimeHelper } from "../../utils/dateTimeHelper";
import { Skeleton } from "@mui/material";
import placeholder from "../../assets/images/placeholder.png";
import { Link, useNavigate } from "react-router-dom";
import { strings } from "../../res/strings";
import { useAuth } from "../../components/hooks/useAuth";
import { PnToolTip } from "../../components/UI/Reusable/PnComponents";
import { truncate } from "../../utils/helpers/HelperFunctions";
import { useContext } from "react";
import { VideoCallContext } from "../../components/UI/AgoraHooks/VideoCallContext";
import { useInterval } from "../../components/hooks/useInterval";
import useModal from "../../components/hooks/useModal";
import MakePaymentModal from "../../components/payment/MakePaymentModal";
import PatientPortalVideoCallModal from "../patientvideoconsult/PatientPortalVideoCallModal";
import { downloadPatientAfterVisitSummaryURL } from "../../res/endpointurls";
import { ConfigMap } from "../../utils/helpers/ConfigMap";
import useMediaQuery from "../../components/hooks/useMediaQuery";
import classes from "./UpcomingAppoointmentItem.module.css";
import { RefreshDataComponent } from "../../components/UI/General/RefreshDataComponent";
import { ChevronLeft, ChevronRight, DashLg } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { ENCOUNTERS } from "../../utils/helpers/subscriptionsConstants";

const canPatientConnectToVideo = async (appId) => {
  // let appId = appointment?.id;
  const httpResponse = await sendHttpRequest({
    method: "GET",
    url: `/video/JoinRequestStatus/${appId}`,
  });
  if (!httpResponse.error) {
    // console.log(httpResponse);
    if (
      httpResponse?.data !== undefined &&
      httpResponse.data?.toLowerCase() === "end"
    ) {
      return false;
    } else if (
      httpResponse?.data !== undefined &&
      (httpResponse.data?.toLowerCase() === "new" ||
        httpResponse.data?.toLowerCase() === "leave")
    ) {
      return true;
    }
  }
};

const UpcomingAppointmentItem = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
      
  const isMobile = useMediaQuery("(max-width: 1345px)");
  const videoCallContext = useContext(VideoCallContext);
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState("");
  const [foodAllergy, setFoodAllergy] = useState("");
  const [drugAllergy, setDrugAllergy] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();
  const [allergies, setAllergies] = useState();
  const [isAllergyLoading, setIsAllergyLoading] = useState(false);
  const [canConnectVideo, setCanConnectVideo] = useState([]);

  const [arePastAppointmentsLoading, setArePastAppointmentsLoading] =
    useState(false);
  const [pastAppointments, setPastAppointments] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(props?.index);

  const [currentPage,setCurrentPage] = useState(0);

  function getVideoConnectStatus(appointmentId) {
    const status = canConnectVideo
      ?.filter((app) => app?.id === appointmentId)
      ?.at(0)?.canConnectVideo;
    return status;
  }

  const { open, handleOpen, handleModal } = useModal();
  const [reloadAppointments, setReloadAppointments] = useState(false);
  const [totalPages,setTotalPages] = useState(0);

  const {
    open: openVideoModal,
    handleOpen: handleOpenVideoModal,
    handleModal: handleVideoModal,
  } = useModal();

  const fetchAllergy = async () => {
    setIsAllergyLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/patient/${user.userId}/allergies`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setAllergies(httpResponse.data);
      allergyString(httpResponse.data, setFoodAllergy, "Food");
      allergyString(httpResponse.data, setDrugAllergy, "Drug");
      // console.log(httpResponse.data);
    }
    setIsAllergyLoading(false);
  };

  let string = "";

  const processArray = async (array) => {
    const processedItems = await Promise.all(
      array.map(async (appointment) => {
        // Perform async operation on each item
        const result = await canPatientConnectToVideo(appointment?.id);
        // Process the result as needed
        return { id: appointment?.id, canConnectVideo: result };
      })
    );

    return processedItems;
  };

  const fetchUpcomingAppointment = async (pageNumber) => {
   
    setIsLoading(true);
    
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${user?.tenant?.tenantId}/patients/${user?.userId}/appointments?upcoming=true`,
      method: "GET",
      params: {
        page:pageNumber,
        size:3,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse?.data);
      if (httpResponse.data?.content?.length > 0) {
        try {
          const canConnectResponses = await processArray(httpResponse?.data);
          // const canConnect = await canPatientConnectToVideo(httpResponse.data[0]?.id);
          setCanConnectVideo(canConnectResponses);

          props?.setAppointmentsLength(httpResponse?.data?.content?.length);
          // // console.log(canConnect);
          // setCanConnectVideo(canConnect);
        } catch (err) {
          console.log("PNPRTL_CANNOT_CONNECT_API_ERROR", err);
        }
        setTotalPages(httpResponse.data?.totalPages)
        setAppointments((p) => httpResponse.data?.content);
        videoCallContext.updateAppointmentData(httpResponse.data?.content[props?.index]);
        localStorage.setItem(
          "context",
          JSON.stringify(httpResponse.data?.content[props?.index])
        );
      }
    } else {
      setTotalPages(0);
      setAppointments([]);
      // console.log(httpResponse.error);
      setError(httpResponse.error);
    }
    setIsLoading(false);
  };

  const fetchPastAppointments = async () => {
    setArePastAppointmentsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/appointment/patient/past",
      method: "GET",
      params: {
        patientId: user?.userId,
        pageSize: 3,
      },
    });
    if (!httpResponse.error) {
      setPastAppointments((p) => httpResponse.data);
    }
    setArePastAppointmentsLoading(false);
  };

  const allergyString = (allergen, createString, allergenType) => {
    let str = "";
    allergen?.length > 0 &&
      allergen.forEach((allergy, index) => {
        if (allergy?.allergyType === allergenType) {
          // console.log(allergenType, allergen?.length - 1);
          if (allergen?.length - 1 !== index) {
            str = str + allergy?.drugProductCodedText + ", ";
          } else {
            str = str + allergy?.drugProductCodedText;
          }
          // createString((prev) => { console.log(allergy?.DrugProductCoded?.Text + allergen?.length - 1 !== index ? ", " : ""); return prev + allergy?.DrugProductCoded?.Text + allergen?.length - 1 !== index ? ", " : "" })
        }
      });
    createString(str);
  };

  const handleDownload = async () => {
    await fetch(
      ConfigMap("APP") +
        downloadPatientAfterVisitSummaryURL(
          appointments?.at(props?.index)?.prescriptionReference?.encounter
            ?.encounterId
        ),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          Authorization:
            localStorage.getItem("tkn") !== null
              ? localStorage.getItem("tkn")
              : "",
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // console.log(blob);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `after-summary.pdf`);
        link.target = "_blank";

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const downloadAppointmentReceipt = async (appointment) => {
    const httpResponse = await sendHttpRequest({
      url: `/appointments/${appointment?.id}/receipt`,
      method: "GET",
      responseType: "arraybuffer",
      params: {
        userId: user?.userId,
      },
    });
    if (!httpResponse.error) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `Receipt_${appointment?.id}`;
      const href = URL.createObjectURL(
        new Blob([httpResponse.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      link.href = href;
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
    }
  };

  async function handleJoinNow() {
    handleOpenVideoModal();
  }

  const handlePage = (pageNumber)=>{
    setCurrentPage(pageNumber);
    fetchUpcomingAppointment(pageNumber);
  }

  useEffect(() => {
    videoCallContext.updateAppointmentData(appointments?.at(props?.index));
    localStorage.setItem(
      "context",
      JSON.stringify(appointments?.at(props?.index))
    );
  }, [props?.index]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllergy();
      await fetchUpcomingAppointment(0);
    };

    fetchData();
  }, [reloadAppointments]);

  return (

    <>
      {/* Header */}
      <div className="row mb-3 justify-content-between align-items-baseline">
        <div className="col-6">
          <h2 className="font-18">Upcoming Appointments</h2>
        </div>
        <div className="col-6 d-flex justify-content-end gap-1 align-items-baseline text-end ">
          <div>
            <RefreshDataComponent
              isAsyncCallback={false}
              callback={() => setReloadAppointments(p => !p)}
              isComponentDataLoading={isLoading}
            />

            <Link
              to="/patient-portal/appointments"
              className="fw-bold text-decoration-none font-12 "
            >
              View all
            </Link>
          </div>

          <div className={`d-inline-block`}><DashLg style={{ transform: 'rotate(90deg)' }} size={22} color="#70707070" /></div>

          <div className="d-flex gap-1">
            <button type="button"  className="clickable btn--border btn bg-white br-10" onClick={()=>{handlePage(currentPage-1)}} disabled={currentPage===0}>
              <ChevronLeft className="text--primary fw-sb" />
            </button>
            <button disabled={appointments?.length === 0 ? true : totalPages >= currentPage} type="button"  className="clickable btn--border btn bg-white br-10" onClick={()=>{handlePage(currentPage+1)}}>
              <ChevronRight className="fw-sb text--primary " />
            </button>
          </div>
        </div>
      </div>
      {
        isLoading ? <Skeleton/> : appointments?.length === 0 ? <span className="font-12 text--terciary p-2">{"No Data Available"}</span> :
        <div className="container-fluid border--default  br-10 gx-2">
          {appointments.slice(0, 3).map((item, index) => {
            const provider =
              item?.prescriptionReference?.providerTenantPracticeLocation?.provider;
            const appointmentSlot = item?.appointmentSlot;
            const patientVisitType =
              item?.patientVisitType?.internalValue?.toLowerCase();
            const chiefComplaint = item?.chiefComplaint;
            const facility =
              item?.prescriptionReference?.providerTenantPracticeLocation?.facility;
            const prefix = provider?.name[0]?.prefix;
            const firstName = provider?.name[0]?.firstName;
            const lastName = provider?.name[0]?.lastName;
            const specialization = provider?.speciality?.[0]?.specialization || "";
            const startDateTime = appointmentSlot?.startDateTime;
            const endDateTime = appointmentSlot?.endDateTime;
            const facilityDetails =
              patientVisitType === "in-patient" &&
              `${facility?.businessName}, ${facility?.addressLine1}, ${facility?.city}, ${facility?.state}, ${facility?.zipCode}`;

            return (
              <>
              
              {/* Body */}
              <div
                className={`row pt-1 pb-1 ${
                  index === 2 || index === appointments?.slice(0, 3).length
                    ? ""
                    : "border--bottom"
                }`}
                key={index}
              >
                <>
                  {/* Provider Information */}
                  <div className="col-3">
                    <Box>
                      <Stack direction="row">
                        <Avatar src={placeholder} alt="provider picture"></Avatar>
                        <Stack sx={{ ml: 1 }}>
                          {isLoading ? (
                            <Skeleton animation="wave" width={200} />
                          ) : (
                            <span className="fw-sb font-14 lh-0 m-0 d-block">
                              {prefix} {firstName} {lastName}
                            </span>
                          )}
                          <span className="font-10 lh-0 d-block text--secondary m-0">
                            {specialization}
                          </span>
                        </Stack>
                      </Stack>
                    </Box>
                  </div>

                  {/* Appointment Date and Time */}
                  <div className="col-2 ">
                    <Stack direction="column" spacing={0}>
                      <label
                        className="text--secondary font-12 fw-sb m-0"
                        style={{ marginBottom: "-12px" }}
                      >
                        {strings.en.date}
                      </label>
                      <p
                        className="m-0 text--terciary font-12"
                        style={{ marginTop: "-8px" }}
                      >
                        {isLoading ? (
                          <Skeleton animation="wave" />
                        ) : startDateTime ? (
                          <>
                            {dateTimeHelper(startDateTime, "MMM D, YYYY")}
                            <br />
                            {dateTimeHelper(startDateTime, "LT")} -{" "}
                            {dateTimeHelper(endDateTime, "LT")}
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </Stack>
                  </div>

                  {/* Chief Complaint or Facility Details */}
                  <div className="col-2 ">
                    <Stack direction="column" spacing={0}>
                      <label
                        className="text--secondary font-12 fw-sb m-0"
                        style={{ marginBottom: "-12px" }}
                      >
                        {patientVisitType === "in-patient"
                          ? "Facility"
                          : chiefComplaint
                          ? strings.en.chiefcomplaint
                          : ""}
                      </label>
                      <p
                        className="m-0 text--terciary font-12"
                        style={{ marginTop: "-8px" }}
                      >
                        {isLoading ? (
                          <Skeleton animation="wave" />
                        ) : patientVisitType === "in-patient" ? (
                          truncate(facilityDetails,20)
                        ) : (
                          truncate(chiefComplaint,20)
                        )}
                      </p>
                    </Stack>
                  </div>

                  {/* Food and Drug Allergies */}
                  <div className="col-2 ">
                    <Stack direction="column" style={{ fontSize: 12 }} spacing={0}>
                      <label className="text--secondary fw-sb font-12 m-0">
                        {strings.en.allergies}
                      </label>
                      {isLoading ? (
                        <Skeleton animation="wave" />
                      ) : foodAllergy ? (
                        <PnToolTip title={foodAllergy} arrow placement="top">
                          <span>{truncate("Food: " + foodAllergy, 20)}</span>
                        </PnToolTip>
                      ) : (
                        strings.en.nofoodallergies
                      )}
                    </Stack>
                    <Stack direction="column" style={{ fontSize: 12 }} spacing={0}>
                      <label className="text--secondary fw-sb font-12 m-0"> </label>
                      {isLoading ? (
                        <Skeleton animation="wave" />
                      ) : drugAllergy ? (
                        <PnToolTip title={drugAllergy} arrow placement="top">
                          <span>{truncate("Drug: " + drugAllergy, 20)}</span>
                        </PnToolTip>
                      ) : (
                        strings.en.nodrugallergies
                      )}
                    </Stack>
                  </div>
                  <div className="col-sm-3 text-end align-self-center">
                        {item?.paymentProcessed === false ? (
                        <button
                            className="btn bg--blue text-white font-12 blue_task_button"
                            onClick={() => {
                                // handleOpen();
                                navigate("/make-payment", {
                                state: {
                                    appointment: item,
                                    amountValue: item?.patientBill?.amountBilled,
                                    amountDue: item?.patientBill?.outstandingBalance,
                                },
                                });
                            }}
                        >
                            Make Payment
                        </button>
                        ) : (
                        <>
                            <button
                                // disabled={!canConnectVideo}
                                className="btn bg--blue br-10 text-white d9e8ec--button"
                                onClick={() => {
                                downloadAppointmentReceipt(item);
                                }}
                                hidden={
                                !item?.receiptAvailable
                                }
                            >
                                {"Receipt"}
                            </button>
                            
                            {/* {console.log("appointment?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient'", appointment?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient')} */}
                            {item?.status === "complete" ? (
                                <button
                                // disabled={!getVideoConnectStatus(item?.id) || appointments?.at(props?.index)?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient' || appointments?.at(props?.index)?.appointmentPassed === true || appointments?.at(props?.index)?.status === 'complete'}
                                className="btn bg--blue br-10 text-white font-12  blue_task_button"
                                onClick={handleDownload}
                                >
                                Summary
                                </button>
                            ) : (
                                <button
                                disabled={
                                    !getVideoConnectStatus(
                                    item?.id
                                    ) ||
                                    appointments
                                    ?.at(props?.index)
                                    ?.patientVisitType?.internalValue?.toLowerCase() ===
                                    "in-patient" ||
                                    item
                                    ?.appointmentPassed === true ||
                                    item?.status ===
                                    "complete"
                                }
                                className="btn mt-1 bg--blue br-10 text-white font-12 blue_task_button"
                                onClick={handleJoinNow}
                                hidden={subscriptionPrivilegeGroups[ENCOUNTERS] === undefined}
                                >
                                {strings.en.join_now}
                                </button>
                            )}
                        </>
                        )}
                    </div>

                  <div className="row">
                    <div className="col-sm-12 text-end">
                      {item?.paymentProcessed === false ? (
                        <>
                          <span className="text--secondary fw-sb font-12">
                            {"Payment Due: "}
                          </span>
                          <span className="font-12 text--danger fw-sb">
                            ${item?.patientBill?.outstandingBalance}
                          </span>
                        </>
                      ) : null}
                    </div>
                    
                  </div>
                </>
              </div>
              </>
            );
          })}
        </div>
      }
    </>
  );
};

export default UpcomingAppointmentItem;
