import { Avatar, Skeleton } from "@mui/material";
import usePatientDemographics from "../hooks/usePatientDemographics";
import placeholder from "../../assets/images/placeholder.png";
import { calenderDateTimeHelper, getAgeByDateOfBirth } from "../../utils/dateTimeHelper";
import { useState } from "react";
import PatientDemographicsService from "../Services/PatientDemographics.service";
import { useEffect } from "react";

const PatientDemographics = (props) => {
    const { patientId } = props;
    const { patientInformation, isPatientInformationLoading, error } = usePatientDemographics(patientId);
    if (props?.setPatientDemographics) {
        props?.setPatientDemographics(patientInformation);
    }

    if (error) {
        if (props?.setIsPatientNotFound) {
            props?.setIsPatientNotFound(true);
        }
    }

    const [isSmoker, setIsSmoker] = useState(2);
    let patientName = error === false ? patientInformation?.name[0] : null;

    const setSmokerStatus = (socialHistory) => {
        if (socialHistory != undefined && socialHistory != null && socialHistory != "" && socialHistory?.length > 0) {
            const smokingQuestion = socialHistory.find((socialHistory) => (socialHistory?.question?.questionId === 1));
            const smokeValue = smokingQuestion?.answer?.length > 0 ? (smokingQuestion?.answer[0]) : null;
            if (smokeValue !== null) {
                //Value greater than 2 means the patient is a smoker
                if (smokeValue > 2) {
                    setIsSmoker(1);
                }
                else {
                    setIsSmoker(0);
                }
            }
            else {
                setIsSmoker(-1);
            }
        }
    };
    useEffect(() => {
        PatientDemographicsService.fecthSocialHistory(patientId).then((response) => { setSmokerStatus(response); });

    }, [patientId]);
    if (isPatientInformationLoading === true) {
        return <Skeleton />
    }





    return (
        <div className="d-flex py-3 px-4 border--bottom">
            <div>
                <Avatar
                    src={
                        patientInformation?.profilePicture !== undefined
                            ? patientInformation?.profilePicture
                            : placeholder
                    }
                ></Avatar>
            </div>
            <div className="px-2 mt-1">
                <>
                    <h3 className="font-16 m-0 p-0 fw-sb align-self-center" style={{ lineHeight: '16px' }}>
                        {`${patientName?.firstName || ''} ${patientName?.middleName?.length > 0 ? patientName?.middleName : ''} ${patientName?.lastName || ''}`}
                    </h3>
                    <div className="d-flex gap-2">
                        <div className="font-14 text-center fw-thin text--secondary">
                            {"PID: "} {patientInformation?.patientTenantIdentification}
                        </div>
                        <div className="border--right">&nbsp;</div>
                        <div className="font-14 text-center fw-thin text--secondary">
                            {patientInformation?.gender ?? "N/A"} &ndash;{" "}
                            {getAgeByDateOfBirth(patientInformation?.dateOfBirth) +
                                " years"}
                        </div>
                        <div className="border--right">&nbsp;</div>

                        <div className="font-14 text-center fw-thin text--secondary">
                            {"DOB: " +
                                calenderDateTimeHelper(patientInformation?.dateOfBirth, "MMM DD, YYYY")}
                        </div>
                        <div className="border--right">&nbsp;</div>
                        <div className="font-14 text-center fw-thin text--secondary">
                            Smoking Status:{" "}
                            {isSmoker === 1 ? "Smoker" : isSmoker === 0 ? "Non smoker" : "Unspecified"}
                        </div>

                    </div>
                    {/* <h4>{patientInformation?.HumanPatient?.PatientId}</h4> */}
                </>
            </div>
        </div>
    )
}

export default PatientDemographics