import { Form, Modal } from "react-bootstrap";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeDateTimeFormat, convertUTCtoTimzone, dateTimeHelper } from "../../../../utils/dateTimeHelper";
import classes from "./AppointmentRescheduleModal.module.css";
import TextArea from "../../../forms/TextArea";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import AppoinmentCalender from "../../General/AppointmentCalender";
import { useUnsavedWorkPrompt } from "../../../hooks/useUnsavedWorkPrompt";
import { formatPhoneNumber, unsavedWorkHandler } from "../../../../utils/helpers/HelperFunctions";
import { newAppointmentModalStrings } from "../../../../res/strings";
import { useAuth } from "../../../hooks/useAuth";
import { fetchPastAptURL, fetchAppointmentConsentFormsURL, aptServiceURL } from "../../../../res/endpointurls";
import useConsentFormSearch from "../../../consent-forms/useConsentFormSearch";
import ConsentFormSearch from "../../../consent-forms/ConsentFormSearch";
import Badge from "../../Reusable/Badge";
import ReferringPhysicianModal from "../ReferringPhysicianModal";


const PatientVisitType = ({ setPatientVisitType, patientVisitType }) => {
  const [patientVisit, setPatientVisit] = useState(patientVisitType ?? 'in-patient');

  const handlePntVisitType = (event) => {
    const id = event.target.id;
    setPatientVisit(id);
    setPatientVisitType(id);
  };

  return (
    <>
      <input
        type="radio"
        name="patientVisitType"
        id="virtual"
        value="virtual"
        className="me-1 cursor--pointer"
        checked={patientVisit === 'virtual'}
        onChange={(e) => handlePntVisitType(e)}
      // disabled = {true}
      />
      <label htmlFor="virtual" className="me-5 font-14 text--terciary">
        {"Virtual"}
      </label>
      <input
        type="radio"
        name="patientVisitType"
        id="in-patient"
        value="in-patient"
        className="me-1 cursor--pointer"
        checked={patientVisit === 'in-patient'}
        onChange={(e) => handlePntVisitType(e)}
      // disabled = {true}
      />
      <label htmlFor="in-patient" className="me-5 font-14 text--terciary">
        {"In-Person"}
      </label>

    </>
  );
};

const ProviderFacility = ({
  facilitiesData,
  isFacilityLoading,
  handlefacilityId,
  selectedFacility
}) => {
  const { user } = useAuth();
  const __str = newAppointmentModalStrings.en;

  return (
    <>
      <label className="fw-sb font-14 text--terciary">
        {__str.facilities_str}
      </label>
      {isFacilityLoading ? (
        <Skeleton />
      ) : (
        <select
          className={`form-select font-14 shadow-none ${classes["custom-select"]} border--default br-10`}
          aria-label="Select follow up appointment"
          style={{
            height: "40px",
          }}
          onChange={(e) => {
            handlefacilityId(e.target.value);
            // onDirty();
          }}
          value={selectedFacility ?? 0}
        >
          <option value="0" selected key={"facilityAll"}>
            {"All Facilities"}
          </option>
          {facilitiesData.map((facilityItem) => {
            return (
              <option
                value={facilityItem?.facilityId}
                id={facilityItem?.facilityId}
                key={facilityItem?.facilityId}
              >
                {facilityItem?.businessName}
              </option>
            );
          })}
        </select>
      )}
    </>
  );
};

const AppointmentRescheduleModal = (props) => {
  // const patientId = useSelector((state) => state.searchedPatientId.patientId);

  const { user } = useAuth();
  const patientId = props.appointmentItemData?.appointmentSlot?.patient?.userId;
  const dispatch = useDispatch();
  const __str = newAppointmentModalStrings.en;
  const [isSlotsLoading, setIsSlotsLoading] = useState(false);
  const [appointmentType, setAppointmentType] = useState(
    props.appointmentItemData?.appointmentType
  );
  const [providerID, setProviderID] = useState(
    props.appointmentItemData?.prescriptionReference?.providerTenantPracticeLocation?.provider?.userId
  );
  // const [providerID, setProviderID] = useState();
  const [chiefComplaint, setChiefComplaint] = useState(
    props?.appointmentItemData?.chiefComplaint
  );
  const [commentText, setCommentText] = useState(
    props?.appointmentItemData?.comments
  );
  const [freeSlots, setFreeSlots] = useState([]);
  const [slotId, setSlotId] = useState();

  const [slotStartTime, setSlotStartTime] = useState();
  const [unSelectFreeSlot, setUnSelectFreeSlot] = useState(false);
  const [isAppointmentScheduled, setIsAppointmentScheduled] = useState(false);
  const [isCustomAppointmentSelected, setIsCustomAppointmentSelected] =
    useState(false);
  const [customStartTime, setCustomStartTime] = useState(
    dateTimeHelper(
      props.appointmentItemData?.appointmentSlot?.startDateTime,
      "HH:mm"
    )
  );
  const [customDuration, setCustomDuration] = useState(15);
  const [timexone, setTimexone] = useState("");
  const [prescribersList, setPrescribersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const joined = [year, month, day].join("-");
  const [scheduleDate, setScheduleDate] = useState(
    convertUTCtoTimzone(
      props.appointmentItemData?.appointmentSlot?.startDateTime,
      user.timezone,
      "YYYY-MM-DD"
    )
  );
  const [slotDurationList, setSlotDurationList] = useState([]);
  const [updateIdInSeesion, setUpdateIdInSeesion] = useState(false);
  const [slotsTimezone, setSlotsTimezone] = useState("");
  const [slotsTimezoneOffset, setSlotsTimezoneOffset] = useState("");
  const [appointmentDuration, setAppointmentDuration] = useState(props?.appointmentItemData?.appointmentType?.durationMinutes);
  const [isDurationLoading, setIsDurationLoading] = useState(false);
  const [pastAppointmentData, setPastAppointmentData] = useState([]);
  const [isPastAptLoading, setIsPastAptLoading] = useState(false);
  const [selectedPastAptId, setSelectedPastAptId] = useState();

  //==========Referring Physician============//
  const [referringPhysiciansList, setReferringPhysiciansList] = useState([]);
  const [areReferringPhysiciansLoading, setAreReferringPhysiciansLoading] = useState(false);
  const [referringPhysician, setReferringPhysician] = useState(props?.appointmentItemData?.referringPhysician);
  const [showReferringPhysicianFields, setShowReferringPhysicianFields] = useState(false);
  const [regexErrors, setRegexErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    zipcode: false,
  });

  function handleReferringPhysicianData(event) {
    event.preventDefault();
    if (event.target.name === "referringPhysicianDropdown") {
      const referringPhysicianId = event.target.value;
      const referringPhysicianData = referringPhysiciansList?.find((physician) => physician?.referringPhysicianId == referringPhysicianId);
      // console.log(referringPhysicianData);
      setReferringPhysician(previous => (referringPhysicianData));
      // handlePhysicianPhone(unformatPhoneNumber(referringPhysicianData?.phone))
    }
    else {
      const name = event.target.name;
      const value = event.target.value;
      setReferringPhysician(previous => {
        const newData = { ...previous };
        newData[name] = value;
        return newData;
      })
    }

    // if (event.target.name === "firstName") {
    //   setReferringPhysician(previous => {
    //     const newData = { ...previous };
    //     newData["firstName"] = event.target.value;
    //     return newData;
    //   })
    // }

  }

  const handlePhysicianPhone = (phoneValue) => {
    // console.log("Handling Physcian Phone", phoneValue);
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length == 12
    ) {
      // console.log("Valed Part");
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      setReferringPhysician(previous => {
        const newData = { ...previous };
        newData["phone"] = mobileNumber;
        return newData;
      })
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
      setReferringPhysician(previous => {
        const newData = { ...previous };
        newData["phone"] = "";
        return newData;
      })
    } else if (phoneValue?.length === 2 || phoneValue === undefined) {
      // dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));

      setReferringPhysician(previous => {
        const newData = { ...previous };
        newData["phone"] = "";
        return newData;
      })
    }
  };

  async function loadReferringPhysicians(tenantId) {
    setAreReferringPhysiciansLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${tenantId}/referring-physicians`,
      method: "GET",
    });
    if (!httpResponse.error) {
      console.log("Referring Physicians-> ", httpResponse.data);
      setReferringPhysiciansList(httpResponse.data);
    }
    setAreReferringPhysiciansLoading(false);
  }


  //=====Consent Form Search=====//
  const { consentFormSuggestions, setConsentFormSuggestions, isConsentFormLoading, setIsConsentFormLoading, isConsentFormError, setIsConsentFormError, consentSearchInputValue, setConsentSearchInputValue, consentFormName, setConsentFormName, selectedConsentForm, setSelectedConsentForm, searchConsentForm, handleConsentFormSearchInput, handleAddConsentForm, handleRemoveConsentForm } = useConsentFormSearch();
  // const [consentFormList, setConsentFormList] = useState(() => {
  //   const forms = props.appointmentItemData.prescriptionReference.patient.patientConsentForms.map(form => {
  //     const { appointmentConsentForm: { id, name, url } } = form;
  //     return { id, name, url };
  //   })
  //   return forms
  // });

  const [consentFormList, setConsentFormList] = useState([]);

  // =========Patient visit type=================
  const [patientVisitType, setPatientVisitType] = useState(props?.appointmentItemData?.patientVisitType?.internalValue ?? "in-patient");
  // =========Facility===========================
  const [selectedFacility, setSelectedFacility] = useState(props?.appointmentItemData?.appointmentSlot?.providerTenantPracticeLocation?.facility?.facilityId ?? 0);
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [facilitiesData, setFacilitiesData] = useState([]);
  // console.log(consentFormList);

  //==========Appointment Service States=============================
  const [appointmentServices, setAppointmentServices] = useState([]);


  const addConsentFormToList = (addedConsentForm) => {
    handleAddConsentForm(addedConsentForm);
    setConsentFormList(prevList => {
      // Check if the addedConsentForm already exists in the prevList
      if (!prevList?.some(form => form.id === addedConsentForm.id)) {
        return [...prevList, addedConsentForm]; // Add the addedConsentForm if it doesn't exist
      }
      return prevList; // Return the unchanged prevList if the addedConsentForm already exists
    });
  }

  const removeConsentFormFromList = (formId) => {
    const filteredList = consentFormList?.filter((form) => form.id !== formId);
    setConsentFormList(filteredList);
  }

  // const slotDurationList = [
  //   { duration: 15, text: "15" },
  //   { duration: 30, text: "30" },
  //   { duration: 45, text: "45" },
  //   { duration: 60, text: "60" },
  //   { duration: 75, text: "75" },
  //   { duration: 90, text: "90" },
  //   { duration: 105, text: "105" },
  //   { duration: 120, text: "120" },
  // ];

  //=====Unsaved Work Code - BEGIN =====//
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();

  const gettext = (event) => {
    setChiefComplaint(event.trim());
    onDirty();
  };
  const getComment = (event) => {
    setCommentText(event.trim());
    onDirty();
  };

  const fecthFreeSlots1 = async (
    providerId,
    apptType,
    scheduleSelectedDate,
    // facilityId,
    durationMinutes
  ) => {
    setIsSlotsLoading(true);
    setFreeSlots([]);
    const httpResponse = await sendHttpRequest({
      url: "/availabilities/availableSlots",
      method: "GET",
      params: {
        providerId: providerId ?? providerID,
        fromDate:
          scheduleSelectedDate === undefined
            ? scheduleDate
            : scheduleSelectedDate,
        toDate:
          scheduleSelectedDate === undefined
            ? scheduleDate
            : scheduleSelectedDate,

        appointmentType: apptType,
        patientId: patientId,
        page: 0,
        duplicates: false,
        tenantId: user?.tenantId,
        facilityId: selectedFacility,
        duration: durationMinutes === undefined ? appointmentType?.durationMinutes : durationMinutes
      },
    });
    if (!httpResponse.error) {
      setFreeSlots(httpResponse.data?.providersAvailabilityList);
    } else {
      console.log(httpResponse.error);
    }
    setIsSlotsLoading(false);
  };

  const rescheduleAppointment = async () => {
    // setIsLoading(true);
    const date = new Date();
    let curentDate = dateTimeHelper(date, "YYYY-MM");
    setIsAppointmentScheduled(true);
    let parent = {}
    let duration = 0;
    let appoinmentStartTime = -1;
    // alert(appointmentType)
    if (appointmentType?.key !== "CUSTOM") {
      duration = props.appointmentItemData?.appointmentSlot?.duration;
      appoinmentStartTime = slotStartTime;
    } else {
      if (customStartTime !== undefined) {
        // duration = getTimeDifference(customStartTime, customEndTime);
        duration = customDuration;
        appoinmentStartTime = changeDateTimeFormat(
          scheduleDate + "T" + customStartTime + ":00",
          "YYYY-MM-DDTHH:mm"
        );
      }
    }
    // alert(duration)
    const patientConsentForms = consentFormList?.map((form) => {
      return {
        "appointmentConsentForm": {
          "id": form.id,
        }
      }
    });
    if (appointmentType.key === "follow_up") {
      parent = { id: selectedPastAptId }
    }
    const httpResponse = await sendHttpRequest({
      url: `/appointment/${props?.appointmentItemData?.id}/reschedule`,
      method: "POST",
      data: {
        appointmentId: props?.appointmentItemData?.id,
        prescriptionReference: {
          patient: {
            userId: props.appointmentItemData.appointmentSlot.patient.userId,
            patientConsentForms: patientConsentForms
          },
          provider: { userId: providerID },
        },
        chiefComplaint: chiefComplaint,
        comments: commentText,
        startTime: appoinmentStartTime,
        appointmentType: appointmentType,
        appointmentSlot: {
          provider: { userId: parseInt(providerID) },
          patient: {
            userId: props.appointmentItemData.appointmentSlot.patient.userId,
          },
          startDateTime: appoinmentStartTime,
          duration: duration,
          startDate: scheduleDate,
          providerTenantPracticeLocation: {
            tenant: {
              tenantId: user?.tenantId
            },
            facility: {
              facilityId: selectedFacility
            }
          }
        },
        parent: parent,
        patientVisitType: { internalValue: patientVisitType },
      },
    });
    if (!httpResponse.error) {
      // props.setIsSentAppointment(true);
      // console.log("appointment-> ", httpResponse.data);
      // dispatch(setPatientID(-1));
      props.setIsAppointmentRescheduled(true);

      // if (props?.handleCurrentMonth !== undefined) {
      //   props?.handleCurrentMonth();
      // }
      removeSelectedUser();
      const appRes = await props.fecthAppointments(curentDate);
      const appointmentsForSlotDate = appRes?.filter(item => {
        return item?.date == props?.convertDateFormat(props?.selectedCellDate);
      })?.at(0)?.appointments;
      appointmentsForSlotDate?.sort((a, b) => {
        const dateA = new Date(a.appointmentSlot.startDateTime);
        const dateB = new Date(b.appointmentSlot.startDateTime);

        // Compare dates
        return dateA - dateB;
      });
      if (appointmentsForSlotDate.length > 0) {
        props?.setCurrentCellAppointments(p => appointmentsForSlotDate);
      }
      props.handleModal();
    }
    else {
      setIsAppointmentScheduled(false);
      props.setOpenRescheduleAppointmentSnackbar(true);
      props.setRescheduleAptErrorMsg(httpResponse?.errorMessage?.message);
      // console.log(httpResponse.error);
      // props.handleModal();
    }
    setIsAppointmentScheduled(false);
    // setAppointmentType("new");
  };
  const handleAppointmentType = (event) => {
    let tenantServiceId = event.target.value;
    let aptObject = appointmentServices?.find((item) => { return item.tenantServiceId === Number(tenantServiceId) });
    let aptType = aptObject.key;

    if (aptType === "CUSTOM") {
      setIsCustomAppointmentSelected(true);
      setAppointmentType(aptObject);
      fetchAppointmentDuration(providerID, aptType);
    } else {
      setAppointmentDuration(appointmentType?.durationMinutes)
      setIsCustomAppointmentSelected(false);
      setAppointmentType(aptObject);
      setUnSelectFreeSlot(true);
      setSlotId("");
      // setSlotStartTime("");

      fecthFreeSlots1(providerID, aptType, undefined, aptObject?.durationMinutes);
    }
    if (aptType === "CUSTOM") {
      fetchAppointmentDuration(providerID, aptType);
    } else {
      setAppointmentDuration(aptObject?.durationMinutes)
    }
    if (aptType?.toLowerCase() === "follow_up") {
      fetchPastAppointments();
    }
  };

  const handleFreeSlot = (event) => {
    setUnSelectFreeSlot(false);
    let slotStartTime = event.target.value;
    setSlotStartTime(slotStartTime);
    onDirty();
  };


  const handleCustomStartTimeChange = (e) => {
    setCustomStartTime(e.target.value);
    // console.log("custom start time", e.target.value);
  };
  const handleCustomDuration = (e) => {
    setCustomDuration(e.target.value);
    onDirty();
  };
  const handleProviderId = (event) => {
    fecthFreeSlots1(event.target.value, appointmentType?.key);
    setProviderID(event.target.value);
    onDirty();
  };

  const removeSelectedUser = async () => {
    setFreeSlots([]);
    setProviderID(props?.selectedPrescriberId);
    const httpResponse = await sendHttpRequest({
      url: "/patient/deselect",
      method: "POST",
    });
    if (!httpResponse.error) {
      // setTimexone(httpResponse?.data?.timezone);
    } else {
      console.log(httpResponse.error);
    }
  };

  const fecthAllPrescribers = async (facilityId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/provider/providers",
      method: "GET",
      params: {
        appointmentType: appointmentType?.key,
        patientId: patientId,
        facilityId: facilityId ?? selectedFacility,
      },
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      let providers = httpResponse.data
      setPrescribersList(providers);
      let isProviderPresent = providers.find((providerItem) => { return providerItem.userId === Number(providerID) })
      if (isProviderPresent !== undefined) {
        fecthFreeSlots1(providerID, appointmentType?.key, scheduleDate, appointmentType?.durationMinutes);
      } else {
        setFreeSlots([])
      }
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
  };

  const getPatientTimezone = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/patient/timezone",
      method: "GET",
      params: {
        patientId: patientId
      }
    });
    if (!httpResponse.error) {
      setSlotsTimezoneOffset(httpResponse?.data?.timezoneOffset);
      setSlotsTimezone(httpResponse?.data?.timezone);
      // setTimexone(httpResponse?.data?.timezone);
      const time = convertUTCtoTimzone(
        props.appointmentItemData?.appointmentSlot
          ?.startDateTime,
        httpResponse?.data?.timezoneOffset,
        "HH:mm"
      )
      setCustomStartTime(p => time);
    } else {
      // console.log(httpResponse.error);
    }
  };
  const fetchAppointmentDuration = async (providerId, appointmentType) => {
    const httpResponse = await sendHttpRequest({
      url: "/appointment/duration",
      method: "GET",
      params: {
        prescriberId: 1935,
        appointmentType: appointmentType,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      let duration = httpResponse.data;
      if (duration.length > 0) {
        if (appointmentType === "CUSTOM") {
          let durationList = [];
          duration.forEach((durationItem) => {
            durationList.push({ duration: durationItem, text: durationItem });
          });
          setSlotDurationList(durationList);
        } else {
          setAppointmentDuration(duration[0]);
        }
      }
    } else {
      console.log(httpResponse.error);
    }
  };
  const fecthAppointmentConsentForms = async (appointmentId) => {
    const httpResponse = await sendHttpRequest({
      url: fetchAppointmentConsentFormsURL(appointmentId),
      method: "GET"
    });
    if (!httpResponse.error) {


      setConsentFormList(httpResponse.data);
      console.log("consent forms", httpResponse.data);



      // let duration = httpResponse.data;
      // if (duration.length > 0) {
      //   if (appointmentType === "CUSTOM") {
      //     let durationList = [];
      //     duration.forEach((durationItem) => {
      //       durationList.push({ duration: durationItem, text: durationItem });
      //     });
      //     setSlotDurationList(durationList);
      //   } else {
      //     setAppointmentDuration(duration[0]);
      //   }
      // }
    } else {
      console.log(httpResponse.error);
    }
  };
  const fetchPastAppointments = async () => {
    setIsPastAptLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPastAptURL(patientId, user.userId),
      method: "GET",
    })

    if (!httpResponse.error) {
      setPastAppointmentData(httpResponse.data);
      if (httpResponse.data?.length > 0) {
        handlePastAppointmentLink(undefined, httpResponse.data[0]);
      }
    }
    setIsPastAptLoading(false);
  }
  const fetchProviderFacilities = async () => {
    setIsFacilityLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tenants//facilities?forAppointment=true`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setFacilitiesData(httpResponse.data);
    }
    setIsFacilityLoading(false);
  };
  const fetchAppointmentService = async () => {
    const httpResponse = await sendHttpRequest({
      url: aptServiceURL(user.tenantId),
      method: "GET"
    })
    if (!httpResponse.error) {
      let data = httpResponse?.data
      setAppointmentServices(data)
      // setAppointmentType(data.find((item)=> {return item.key === "NEW"}))
    } else {
      setAppointmentServices([])
    }
  }



  const handlePastAppointmentLink = (e, aptObject) => {
    let aptId = 0;
    if (e !== undefined) {
      e.preventDefault();
      aptId = e.target.value;
    } else {
      aptId = aptObject?.id;
    }
    let result = pastAppointmentData?.find((item) => { return item.id === Number(aptId) });
    if (result === undefined && aptObject !== undefined) {
      result = aptObject;
    }
    if (result !== undefined) {
      let chiefComplaint = result?.prescriptionReference?.encounter?.chiefComplaint;
      let providerId = result?.prescriptionReference?.providerTenantPracticeLocation?.provider?.userId;

      setSelectedPastAptId(result?.id);
      setChiefComplaint(chiefComplaint);
      setProviderID(providerId);
    }
  }
  const handlefacilityId = (facilityId) => {
    setSelectedFacility(facilityId);
    if (patientId !== -1) {
      fecthAllPrescribers(facilityId);
    }
  };

  useEffect(() => {
    fecthAllPrescribers();
    fetchAppointmentService();
    if (appointmentType?.key?.toLowerCase() === "custom") {
      fetchAppointmentDuration(providerID, appointmentType?.key);
    }
  }, [patientId]);
  useEffect(() => {
    getPatientTimezone();
    // setPatientId();
    fecthAllPrescribers();
    fecthFreeSlots1(
      props.appointmentItemData?.prescriptionReference?.provider?.userId,
      props.appointmentItemData?.appointmentType?.key,
      props.appointmentItemData.appointmentSlot.startDate,
      props.appointmentItemData?.appointmentType?.durationMinutes,
    );
    setAppointmentType(props.appointmentItemData?.appointmentType);

    // if(updateIdInSeesion === true){
    if (facilitiesData?.length === 0) {
      fetchProviderFacilities();
    }
    fecthAllPrescribers();
    if (props.appointmentItemData?.appointmentType?.key?.toLowerCase() === "custom") {
      setIsCustomAppointmentSelected(true);
      setCustomDuration(props.appointmentItemData?.appointmentSlot?.duration);
    } else if (props.appointmentItemData?.appointmentType?.key?.toLowerCase() === "follow_up") {
      fetchPastAppointments();
      setSelectedPastAptId(props.appointmentItemData?.id);
    } else {
      setAppointmentDuration(props.appointmentItemData?.appointmentType?.durationMinutes);
    }

    fecthAppointmentConsentForms(props?.appointmentItemData?.id);


    // }
  }, [props?.appointmentItemData?.id]);

  useEffect(() => {
    let startTimeApt = convertUTCtoTimzone(
      props.appointmentItemData?.appointmentSlot
        ?.startDateTime,
      slotsTimezoneOffset,
      "HH:mm"
    )
    let dateApt = convertUTCtoTimzone(
      props.appointmentItemData?.appointmentSlot
        ?.startDateTime,
      slotsTimezoneOffset,
      "YYYY-MM-DD"
    )
    setSlotStartTime(dateApt + "T" + startTimeApt);
  }, [slotsTimezoneOffset])


  useEffect(() => {
    loadReferringPhysicians(user?.tenantId);
  }, [])

  return (
    <>
      {showReferringPhysicianFields ? <ReferringPhysicianModal
        show={showReferringPhysicianFields}
        handleModalClose={() => { setShowReferringPhysicianFields(false) }}
        referringPhysician={referringPhysician}
        regexErrors={regexErrors}
        setShowReferringPhysicianFields={setShowReferringPhysicianFields}
        tenantId={user?.tenantId}
        setReferringPhysician={setReferringPhysician}
        referringPhysiciansList={referringPhysiciansList}
        handleReferringPhysicianData={handleReferringPhysicianData}
        handlePhysicianPhone={handlePhysicianPhone}
      /> : null}
      <Modal
        show={props.show}
        onHide={() => {
          // setAppointmentType("new");
          // setFreeSlots([]);
          // setIsCustomAppointmentSelected(false);
          // handleNewAppointmentModal();
          // removeSelectedUser();
          props.handleModal();
        }}
        // size = "xl"
        centered
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes["custom-modal-style"]}`}
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes. Do you wish to continue?",
                isDirty,
                props.handleModal,
                onPristine,
                props.handleModal
              );
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            Reschedule Appointment
            <div className="mb-0" style={{ fontWeight: 400, fontSize: "12px" }}>
              {slotsTimezone === null ||
                slotsTimezone === "" ||
                slotsTimezone === undefined
                ? ""
                : "Patient Timezone: " + slotsTimezone}
              {/* // Date().slice(Date().indexOf("(") + 1, Date().lastIndexOf(")")) */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id="newAppointmentForm"
            onSubmit={(e) => {
              e.preventDefault();
              onPristine();
              rescheduleAppointment();
              // props.handleModal();
            }}
          >
            <div className="row">
              <div className="col px-4">
                {/* Patient */}
                <div className="form-group">
                  <label className="fw-sb font-14 text--terciary">
                    Patient
                  </label>
                  <input
                    type="text"
                    disabled={true}
                    className="form-control bg--disabled shadow-none border-default"
                    defaultValue={
                      props.appointmentItemData?.appointmentSlot?.patient?.name[0]
                        ?.firstName +
                      " " +
                      props.appointmentItemData?.appointmentSlot?.patient?.name[0]
                        ?.lastName
                    }
                    style={{
                      border: "1px solid #D9E8EC",

                      borderRadius: "10px",
                    }}
                  />
                </div>
                {/* Patient Visit Type */}
                <div className="form-group mt-4">
                  <PatientVisitType key={"patient-visit-type"} setPatientVisitType={setPatientVisitType} patientVisitType={patientVisitType} />
                </div>

                {/* Facility */}
                <div className="form-group mt-4">
                  {patientVisitType === "in-patient" ? (
                    <ProviderFacility
                      facilitiesData={facilitiesData}
                      isFacilityLoading={isFacilityLoading}
                      setSelectedFacility={setSelectedFacility}
                      handlefacilityId={handlefacilityId}
                      selectedFacility={selectedFacility}
                    />
                  ) : null}
                </div>

                {/* Provider */}
                <div className="form-group mt-4">
                  <label className="fw-sb font-14 text--terciary">
                    Provider
                  </label>
                  {isLoading ? (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={"70%"}
                      height={40}
                    />
                  ) : (
                    <select
                      className={`form-select font-14 shadow-none ${classes["custom-select"]}`}
                      aria-label="Provider select"
                      style={{
                        border: "1px solid #D9E8EC",
                        // height: "48px",
                        borderRadius: "10px",
                      }}
                      required
                      onChange={(e) => handleProviderId(e)}
                      onInvalid={(e) => {
                        e.target.setCustomValidity(
                          "Please select provider from the list"
                        );
                      }}
                      onInput={(e) => {
                        e.target.setCustomValidity("");
                      }}
                    >
                      <option value="" disabled selected hidden>
                        {__str.selectProvider}
                      </option>
                      {prescribersList !== undefined &&
                        prescribersList?.length > 0 ? (
                        prescribersList?.map((prescriberItem) => {
                          return (
                            <option
                              value={prescriberItem?.userId}
                              key={prescriberItem?.userId}
                              selected={
                                props.appointmentItemData?.prescriptionReference?.providerTenantPracticeLocation
                                  ?.provider?.userId ===
                                prescriberItem?.userId
                              }
                            >
                              {prescriberItem?.name[0]?.prefix}{" "}
                              {prescriberItem?.name[0]?.firstName}{" "}
                              {prescriberItem?.name[0]?.lastName}{" "}
                              {prescriberItem?.speciality[0]?.speciality !==
                                undefined
                                ? "(" +
                                prescriberItem?.speciality[0]?.speciality +
                                ")"
                                : ""
                              }
                            </option>
                          );
                        })
                      ) : !isLoading && prescribersList?.length === 0 ? (
                        <option key={0} value="" disabled>
                          {"No providers available"}
                        </option>
                      ) : (
                        <option key={0} value="" disabled>
                          {"Loading provider(s)"}
                        </option>
                      )}
                    </select>
                  )}
                </div>
                {/* Appointment Type */}
                <div className="form-group mt-4">
                  <label className="fw-sb font-14 text--terciary">
                    Appointment Type
                  </label>
                  <select
                    className={`form-select font-14 br-10 shadow-none ${classes["custom-select"]} border--default`}
                    aria-label="Appointment type select"

                    required
                    onChange={(e) => handleAppointmentType(e)}
                  >
                    {/* <option value="">Select Appointment</option> */}
                    {
                      appointmentServices.map((serviceItem) => {
                        return (
                          <option selected={serviceItem?.key === appointmentType?.key} value={serviceItem?.tenantServiceId} key={serviceItem?.tenantServiceId} id={serviceItem?.key}>{serviceItem?.title}</option>
                        )
                      })
                    }
                  </select>
                </div>
                {/* Past Appointment section */}
                {
                  appointmentType?.key?.toLowerCase() === "follow_up" ?
                    isPastAptLoading ? <Skeleton /> :
                      pastAppointmentData?.length > 0 ?
                        <div className="form-group mt-4">
                          <label className="fw-sb font-14 text--terciary">
                            {__str.passAppointments}
                          </label>
                          <select
                            className={`form-select font-14 shadow-none border--default ${classes["custom-select"]}`}
                            aria-label="Select follow up appointment"
                            style={{
                              border: "1px solid #D9E8EC",
                              height: "40px",
                              borderRadius: "10px",
                            }}
                            required
                            onChange={(e) => {
                              handlePastAppointmentLink(e);
                              onDirty();
                            }}
                          // disabled={true}
                          >
                            {/* <option value="" >
                          {__str.select}
                        </option> */}
                            {pastAppointmentData.map((aptItem) => {
                              let chiefComp = aptItem?.prescriptionReference?.encounter?.chiefComplaint ?? "";
                              return (
                                <option value={aptItem?.id} id={aptItem?.id} key={aptItem?.id}
                                  selected={props?.appointmentItemData?.id === aptItem?.id ? true : false}>
                                  {dateTimeHelper(aptItem?.appointmentSlot?.startDateTime, "MMM DD, YYYY LT").concat(" - ", chiefComp)}
                                </option>
                              )
                            })

                            }

                          </select>
                        </div>
                        : null
                    : null
                }
                {/* Chief Complaint */}
                <div className="form-group mt-4">
                  <label className="fw-sb font-14 text--terciary">
                    {"Reason for Visit"}
                  </label>
                  {/* <h2 className="font-18 text--terciary">Chief Complaint</h2> */}
                  <div>
                    <TextArea
                      className="form-control shadow-none border--default br-10"
                      gettext={gettext}
                      rows={appointmentType?.key?.toLowerCase() === "follow_up" ? 2 : 3}
                      defaultValue={
                        props?.appointmentItemData?.chiefComplaint
                      }
                      focus={true}
                    />
                  </div>
                </div>


                {typeof referringPhysician === "object" && referringPhysician !== null && referringPhysician !== undefined && Object.keys(referringPhysician)?.length > 2 && showReferringPhysicianFields === false ?
                  <div className="form-group mt-2">
                    <span className="font-12 fw-sb d-block">Referring Physician: <span className="fw-thin">Dr. {referringPhysician?.firstName} {referringPhysician?.lastName}, {referringPhysician?.phone}</span></span>
                  </div>
                  : <div className="form-group mt-2">
                    {/* <span className="text-decoration-underline fw-sb text--secondary font-14 d-flex align-items-center gap-1"><PersonAddAltIcon />{__str.addReferringPhysician}</span> */}
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        if (showReferringPhysicianFields === false) {
                          setShowReferringPhysicianFields(true);
                        }


                      }}
                      className="btn pn-button-icon-blue  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12"

                    >
                      <span className="pn-button-icon-blue pe-1">
                        <PersonAddAltIcon color="#336383" sx={{ fontSize: "20px" }} />
                      </span>
                      <span
                        className={`d-inline-block pn-link-button `}
                        style={{ verticalAlign: "sub" }}
                      >
                        Add Referring Physician
                      </span>
                    </button>

                  </div>}


                {/* Consent */}
                <div className="form-group mt-4">
                  <label className="fw-sb font-14 text--terciary">
                    Consent
                  </label>
                  {/* <textarea
                    type="text"
                    rows={appointmentType === "FOLLOW_UP" ? 2 : 3}
                    // placeholder={"Write here..."}
                    className="form-control br-10 border--default font-14 input shadow-none"
                    onChange={(e) => { e.preventDefault(); handleComments(e.target.value) }}
                  /> */}
                  <ConsentFormSearch
                    suggestions={consentFormSuggestions}
                    setSuggestions={setConsentFormSuggestions}
                    isLoading={isConsentFormLoading}
                    setIsLoading={setIsConsentFormLoading}
                    isError={isConsentFormError}
                    setIsError={setIsConsentFormError}
                    searchInputValue={consentSearchInputValue}
                    setSearchInputValue={setConsentSearchInputValue}
                    consentFormName={consentFormName}
                    selectedConsentForm={selectedConsentForm}
                    setSelectedConsentForm={setSelectedConsentForm}
                    searchConsentForm={searchConsentForm}
                    handleSearchInput={handleConsentFormSearchInput}
                    handleAddConsentForm={addConsentFormToList}
                  />
                  <div className="d-flex gap-2 mt-2 flex-wrap">
                    {consentFormList?.map((consentform, index) => (
                      <Badge
                        key={consentform.id}
                        data={`${consentform.name}`}
                        handleRemove={removeConsentFormFromList}
                        removeId={consentform.id}
                      />
                    ))}
                  </div>
                </div>
              </div>


              <div className="col px-4">
                <div className="form-group font-14">
                  <div style={{ borderBottom: "1px solid #d9e8ec" }}>
                    <label className="fw-sb font-14 text--terciary">
                      Select Date &#38; Time
                    </label>
                  </div>
                </div>

                <div>
                  <AppoinmentCalender
                    setScheduleDate={setScheduleDate}
                    setScheduleDateToSend={null}
                    providerID={providerID}
                    EnableDirtyCheck={true}
                    onDirty={onDirty}
                    appointmentType={appointmentType}
                    fecthFreeSlots1={fecthFreeSlots1}
                    appointmentDate={convertUTCtoTimzone(props.appointmentItemData.appointmentSlot.startDateTime, slotsTimezoneOffset !== undefined ? slotsTimezoneOffset : user.timezone,
                      "YYYY-MM-DD")}
                    slotsTimezoneOffset={slotsTimezoneOffset}
                  />
                </div>

                <div className="form-group mt-4 ">
                  {/* <label className="fw-sb font-14 text--terciary">
                      Provider Schedule
                    </label> */}

                  <div className="">
                    <div>
                      {isCustomAppointmentSelected === false ? (
                        <div>
                          {/* <div className="col-sm-6">
                              <label className="text--terciary b2 fw-sb">
                                Date
                              </label>
                              <CustomDatePicker
                                dobHandler={handleScheduleDate}
                                dateFormat="MM/dd/yyyy"
                                date={
                                  new Date(
                                    props.appointmentItemData.AvailableSlot.StartDate
                                  )
                                }
                              />
                            </div> */}
                          <div className="row">
                            <div className="col-sm-4">
                              <label className="text--terciary font-14 fw-sb">
                                Start Time
                              </label>
                              <div>
                                <Form.Group>
                                  <Form.Control
                                    as="select"
                                    className={`col-sm-12 form-select font-14 shadow-none ${classes["custom-select"]}`}
                                    aria-label="Slot select"
                                    required
                                    style={{
                                      border: "1px solid #D9E8EC",
                                      borderRadius: "10px",
                                    }}
                                    onChange={(e) => handleFreeSlot(e)}

                                  >


                                    {
                                      isSlotsLoading ? <Skeleton /> :

                                        freeSlots?.length > 0 ?
                                          (
                                            <>
                                              <option
                                                value={props.appointmentItemData?.appointmentSlot
                                                  ?.startDateTime}
                                                className="col-sm-12 font-14"
                                                disabled
                                                selected
                                                hidden
                                              >
                                                {
                                                  convertUTCtoTimzone(
                                                    props.appointmentItemData?.appointmentSlot
                                                      ?.startDateTime,
                                                    slotsTimezoneOffset,
                                                    "LT"
                                                  )
                                                }
                                              </option>

                                              {
                                                freeSlots?.map((slotObject, index) => {
                                                  // let sDate = getLocalDateFromUTC(
                                                  //   slotObject.StartTime,
                                                  //   "MMM DD, YYYY"
                                                  // );
                                                  let aptStartTime =
                                                    changeDateTimeFormat(
                                                      slotObject?.startDateTime,
                                                      "LT"
                                                    );
                                                  let aptEndTime = changeDateTimeFormat(
                                                    slotObject?.endTime,
                                                    "LT"
                                                  );
                                                  return (
                                                    <option
                                                      key={
                                                        slotObject?.startDateTime + index
                                                      }
                                                      className="col-sm-12 font-14 p-1 mt-1"
                                                      // data={slotItem?.id}
                                                      value={slotObject?.startDateTime}
                                                      style={{ textAlign: "justify" }}

                                                    >
                                                      {aptStartTime}
                                                    </option>
                                                  );
                                                })

                                              }
                                            </>
                                          )

                                          :
                                          (
                                            <option
                                              key={0}
                                              className="col-sm-12 font-14 p-1 mt-1"
                                              // data={slotItem?.id}
                                              value=""
                                              style={{ textAlign: "justify" }}
                                              disabled
                                            >
                                              {__str.noSlotFound}
                                            </option>
                                          )
                                      // : (
                                      //   <option
                                      //     key={0}
                                      //     className="col-sm-12 font-14 p-1 mt-1"
                                      //     // data={slotItem?.id}
                                      //     value=""
                                      //     style={{ textAlign: "justify" }}
                                      //     disabled
                                      //   >
                                      //     Loading Slots
                                      //   </option>
                                      // )
                                    }
                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div>
                                <label className="font-14 fw-sb text--terciary">
                                  Duration{" "}
                                </label>
                              </div>
                              <div
                                className="font-14  text--terciary align-self-center br-10"
                                style={{ border: "1px solid #d9e8ec" }}
                              >
                                <div className="center pt-1 ps-2 py-2">
                                  {isDurationLoading ? (
                                    <Skeleton />
                                  ) : appointmentDuration !== undefined ? (
                                    appointmentDuration + " minutes"
                                  ) : (
                                    <>&nbsp;</>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          {/* <div className="col-sm-4">
                              <label className="text--terciary b2 fw-sb">
                                Date
                              </label>
                              <CustomDatePicker
                                dobHandler={handleScheduleDate}
                                dateFormat="MM/dd/yyyy"
                              />
                            </div> */}
                          <div className="col-sm-4" style={{ width: "10vw" }}>
                            <label className="text--terciary font-14 fw-sb">
                              Start Time
                            </label>
                            <input
                              type="time"
                              name="time"
                              datsa-format="hh:mm"

                              required
                              // defaultValue={dateTimeHelper(props.appointmentItemData?.AvailableSlot?.StartDate,"hh:mm")}
                              // defaultValue={convertUTCtoTimzone(
                              //   props.appointmentItemData?.appointmentSlot
                              //     ?.startDateTime,
                              //   slotsTimezoneOffset,
                              //   "HH:mm"
                              // )}
                              value={customStartTime}
                              onInvalid={(e) => {
                                e.target.setCustomValidity(
                                  "Please enter a valid time"
                                );
                              }}
                              onInput={(e) => {
                                e.target.setCustomValidity("");
                              }}
                              className={`font-14 p-2 ${classes["start__time__input"]}`}
                              onChange={(e) => handleCustomStartTimeChange(e)}
                            />
                          </div>
                          <div className="col-sm-4">
                            <div style={{ width: "10vw" }}>
                              <label className="text--terciary font-14 fw-sb">
                                Duration
                              </label>
                              {isDurationLoading ? (
                                <Skeleton />
                              ) : (
                                <select
                                  className={`form-select ${classes["custom__duration"]} font-14 shadow-none ${classes["custom-select"]}`}
                                  aria-label="Appointment type select"
                                  required
                                  onChange={(e) => handleCustomDuration(e)}
                                >
                                  {slotDurationList.map(
                                    (durationItem, index) => {
                                      return (
                                        <option
                                          value={durationItem.duration}
                                          selected={
                                            props.appointmentItemData?.appointmentSlot
                                              ?.duration ===
                                            durationItem.duration
                                          }
                                        // selected={index === 0 && true}
                                        >
                                          {durationItem.duration + " minutes"}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer
          className="modal-footer"
        //   style={{ border: "none" }}
        >
          <button
            type="submit"
            form="newAppointmentForm"
            className="btn blue_task_button px-5 py-2"
            disabled={isAppointmentScheduled}
          >
            {isAppointmentScheduled ? "Rescheduling..." : "Reschedule"}
          </button>
        </Modal.Footer>
        {/* {console.log("patientId in appointment", patientId)} */}
        {/* {console.log("provider Id", providerID)} */}
        {console.log("appointment Type", appointmentType)}
        {/* {console.log("slotsList", freeSlots)} */}
        {/* {console.log("updateIdInSeesion",updateIdInSeesion)} */}
        {/* {console.log("scheduleDate",scheduleDate)} */}
        {/* {console.log(props.appointmentItemData?.AvailableSlot?.StartDateTime)}
        {console.log(dateTimeHelper(props.appointmentItemData?.AvailableSlot?.StartDateTime,"HH:mm:ss"))} */}
        {/* {console.log("isSlotsLoading: ",isSlotsLoading)} */}
        {console.log("appointmentItemData", props?.appointmentItemData)}
        {/* {console.log("scheduleDate: ",scheduleDate,slotsTimezoneOffset)} */}
        {/* {console.log("appointmentDuration: ", appointmentDuration)} */}
        {console.log("appointmentServices: ", appointmentServices)}


      </Modal>
    </>
  );
};

export default AppointmentRescheduleModal;
