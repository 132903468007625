import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import PageHeader from "../UI/General/PageHeader";
import sendHttpRequest from "../hooks/sendHttpRequest";
import DeniedClaimsTable from "../../claim-management-module/src/components/tables/DeniedClaimsTable";
import { PnSelect } from "../UI/PnSelect";
import { Skeleton } from "@mui/material";
import { useLocation } from "react-router-dom";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { payersListUrl } from "../../res/endpointurls";

const CLAIM_STATUS_OPTIONS = [
  {
    value: "CLAIM_READY_FOR_SUBMISSION",
    label: "Claim Ready for Submission",
    title: "Not Submitted Claims",
  },
  {
    value: "CLAIM_SUBMITTED",
    label: "Claim Submitted",
    title: "Pending Claims",
  },
  {
    value: "CLAIM_APPROVED",
    label: "Claim Approved",
    title: "Approved Claims",
  },
  {
    value: "CLAIM_SUBMISSION_ERROR",
    label: "Claim Submission Error",
    title: "Pending Claims",
  },
  { value: "CLAIM_DENIED", label: "Denied Claim", title: "Denied Claim" },
];

function DeniedClaimsPage(props) {
  const { user } = useAuth();
  const location = useLocation();

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [claims, setClaims] = useState([]);
  const [areClaimsLoading, setAreClaimsLoading] = useState(false);

  const [reloadClaims, setReloadClaims] = useState(false);

  const [renderingProvidersList, setRenderingProvidersList] = useState([]);
  const [areRenderingProvidersLoading, setAreRenderingProvidersLoading] =
    useState(false);

  const [patientsList, setPatientsList] = useState([]);
  const [arePatientsLoading, setArePatientsLoading] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [claimNumber, setClaimNumber] = useState(null);

  const [fromDate,setFromDate] = useState(null);
  const [toDate,setToDate] = useState(null);

  const [pageTitle, setPageTitle] = useState();
  const [payersList, setPayersList] = useState({ isLoading: false });

  const [selectedPayer,setSelectedPayer] = useState(null);


  const fetchReneringProviders = async () => {
    setAreRenderingProvidersLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/providers`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const renderingProviders = httpResponse.data;
      const renderingProviderOptions = renderingProviders?.map((provider) => ({
        value: provider?.providerId,
        label: `${provider?.prefix} ${provider?.firstName} ${provider?.lastName}`,
        provider: provider,
      }));
      setRenderingProvidersList(renderingProviderOptions);
    } else {
      setRenderingProvidersList([]);
      console.log(httpResponse.error);
    }
    setAreRenderingProvidersLoading(false);
  };

  const fetchClaimPatients = async () => {
    setArePatientsLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/patients`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const claimPatients = httpResponse.data;
      const patientDropdownOptions = claimPatients?.map((patient) => ({
        value: patient?.patientId,
        label: `${patient?.firstName} ${patient?.lastName}`,
        patient: patient,
      }));
      setPatientsList(patientDropdownOptions);
    } else {
      setPatientsList([]);
      console.log(httpResponse.error);
    }
    setArePatientsLoading(false);
  };

  function handleProviderId(selectedOption) {
    setSelectedProvider(selectedOption);
    // props?.setEncounterDto((dto) => ({ ...dto, "claimProviderDataId": event.target.value, "provider": { "providerId": event.target.value } }))
  }

  function handlePatientId(option) {
    setSelectedPatient(option);
  }

  function handleClaimNumber(event) {
    setClaimNumber(event.target.value);
  }

  function handleClaimStatus(option) {
    setSelectedStatus(option);
  }

  const fetchAllClaims = async (
    tenantId,
    claimNumber,
    provider,
    patient,
    status,fromDate,toDate,payer
  ) => {
    setAreClaimsLoading(true);
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: `/claims/tenants/${tenantId}/insurance-claims`,
      params: {
        ...(claimNumber !== "" && { claimNumber: claimNumber }),
        providerId: provider?.value,
        patientId: patient?.value,
        claimStatus: status?.value,
        ...(fromDate?.length > 0 && { fromDate: fromDate }),
        ...(toDate?.length > 0 && { toDate: toDate }),
        ...(payer && {payer:payer.value}),
      },
    });
    if (!httpResponse.error) {
      setClaims(httpResponse.data?.content);
      console.log(
        httpResponse.data?.filter((claim) => claim?.status === "CLAIM_DENIED")
      );
    } else {
      console.error(`Error in fetching claims for tenant ${tenantId}`);
    }
    setAreClaimsLoading(false);
  };
  const fetchPayersList = async () => {
    // setPayersList((prev) => ({ ...prev, isLoading: true }));
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: payersListUrl(),
    });
    if (!httpResponse.error) {
      const payerList = httpResponse.data?.map((payer) => ({
        value: payer?.payerCode,
        label: payer?.name,
        payer: payer,
      }));
      setPayersList(payerList);
    }
    // setPayersList((prev) => ({ ...prev, isLoading: false }));
  };

  const handleFilter = useCallback(async () => {
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      fromDate,
      toDate,selectedPayer
    );
  }, [
    user?.tenantId,
    claimNumber,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fromDate,
    toDate,selectedPayer,
    fetchAllClaims,
  ]);

  const handleClearFilter = useCallback(async () => {
    setClaimNumber("");
    setSelectedPatient(null);
    setSelectedProvider(null);
    setSelectedStatus(null);
    setFromDate(null);
    setToDate(null);
    setSelectedPayer(null);
    fetchAllClaims(user?.tenantId, "", null, null, null,null,null,selectedPayer);
  }, [
    user?.tenantId,
    claimNumber,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fromDate,
    toDate,selectedPayer,
    fetchAllClaims,
  ]);

  const handleFromDate = (dateValue) => {
    setFromDate(dateValue);
  };
  const handleToDate = (dateValue) => {
    setToDate(dateValue);
  };
  const handlePayer = (option) => {
    setSelectedPayer(option);
  };

  useEffect(() => {
    if(location?.state?.claimType === "all"){
      setPageTitle("Claims")
      fetchAllClaims(
        user?.tenantId,
        claimNumber,
        selectedProvider,
        selectedPatient,
        null,
        location?.state?.startDate,
        location?.state?.endDate,selectedPayer
      );  
      setFromDate(location?.state?.startDate || null);
      setToDate(location?.state?.endDate || null);
    }else{
      let claimStatus = CLAIM_STATUS_OPTIONS.find((item) => {
        return item?.value === location?.state?.claimType;
      });
      setPageTitle(claimStatus?.title);
      fetchAllClaims(
        user?.tenantId,
        claimNumber,
        selectedProvider,
        selectedPatient,
        claimStatus,
        fromDate,
        toDate,selectedPayer
      );
      setSelectedStatus(claimStatus);
    }

  }, [reloadClaims]);
  
  useEffect(() => {
    fetchPayersList();
    fetchReneringProviders();
    fetchClaimPatients();
  }, [])
  return (
    <>
      <PageHeader title={pageTitle} />
      <div className="container-fluid">
        <div className="row justify-content-center py-2 mb-1">
          <div className="col-sm-11">
            <div className="row">
            <div className="col-sm-2">
                <label className="text--secondary font-12 b2 fw-sb">
                  From
                </label>
                {
                    fromDate !== null ? 
                        <CalenderDatePicker
                        f12={true}
                        dobHandler={handleFromDate}
                        dateFormat="MMM d, yyyy"
                        date={fromDate !== null ? fromDate : undefined}
                        //   viewSchedule={true}
                        //   required
                        key={"FromDate"}
                        />:
                        <CalenderDatePicker
                        f12={true}
                        dobHandler={handleFromDate}
                        dateFormat="MMM d, yyyy"
                        date={""}
                        key={"FromDateNew"}
                        />
                }
              </div>
              <div className="col-sm-2">
                <label className="text--secondary font-12 b2 fw-sb">
                  To
                </label>
                {
                    toDate !== null ? 
                    <CalenderDatePicker
                      f12={true}
                      dobHandler={handleToDate}
                      dateFormat="MMM d, yyyy"
                      date={toDate !== null ? toDate : undefined}
                    //   viewSchedule={true}
                    //   required
                      key={"ToDateNew"}
                    />:
                    <CalenderDatePicker
                      f12={true}
                      dobHandler={handleToDate}
                      dateFormat="MMM d, yyyy"
                      date={""}
                    //   viewSchedule={true}
                    //   required
                      key={"ToDate"}
                    />
                }
              </div>
                            
              <div className="col-sm-2">
                <div className="form-group">
                  <label className=" text--secondary font-12 fw-sb">
                    Claim Number
                  </label>
                  <input
                    type="text"
                    placeholder="Claim Number"
                    style={{ minHeight: "38px" }}
                    onChange={handleClaimNumber}
                    value={claimNumber}
                    className="form-control simple-placeholder br-10 font-12 shadow-none border--default"
                  />
                </div>
              </div>

            </div>
            <div className="row">

              <div className="col-sm-3">
                <div className="form-group">
                  <label className=" text--secondary font-12 fw-sb">
                    Rendering Provider
                  </label>
                  {areRenderingProvidersLoading === true ? (
                    <Skeleton animation="wave" variant="text" width="100%" />
                  ) : (
                    <PnSelect
                      isClearable={true}
                      isSearchable
                      onChange={handleProviderId}
                      name="providers"
                      closeMenuOnSelect={true}
                      options={renderingProvidersList}
                      value={selectedProvider}
                    />
                  )}
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label className=" text--secondary font-12 fw-sb">
                    Patient
                  </label>
                  {arePatientsLoading === true ? (
                    <Skeleton animation="wave" variant="text" width="100%" />
                  ) : (
                    <PnSelect
                      isClearable={true}
                      isSearchable
                      onChange={handlePatientId}
                      name="patients"
                      closeMenuOnSelect={true}
                      options={patientsList}
                      value={selectedPatient}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-3">
                <label
                  htmlFor={"Payer List"}
                  className=" m-0 font-12 text--secondary fw-sb"
                >
                  {"Payer"}
                </label>
                <PnSelect
                  isClearable={true}
                  isSearchable
                  onChange={handlePayer}
                  name="payer"
                  closeMenuOnSelect={true}
                  options={payersList}
                  value={selectedPayer}
                />
              </div>

              <div className="col-sm-2 align-self-end">
                <button
                  onClick={handleFilter}
                  className="btn blue_task_button br-10 shadow-none"
                >
                  Filter Data
                </button>
              </div>
              <div className="col-sm-1 align-self-end">
                <span
                  onClick={handleClearFilter}
                  className={`pn-link-button d-inline-block  text-decoration-underline fw-sb font-12 text--secondary cursor--pointer ${
                    (claimNumber == "" || claimNumber == null) &&
                    selectedPayer == null &&
                    selectedProvider == null &&
                    selectedPatient == null &&
                    selectedStatus == null &&
                    fromDate == null &&
                    toDate == null 
                      ? "disabled-link cursor--disabled"
                      : ""
                  }`}
                >
                  Clear Filters
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-11">
            <DeniedClaimsTable
              columnHeadersList={[
                { key: "claim.claimNumber", value: "Claim Id" },
                {
                  key: "claim.claimResponse.apexEdiClaimNumber",
                  value: "Apex Claim Id",
                },
                {
                  key: "claim.encounter.provider.firstName",
                  value: "Provider",
                },
                { key: "claim.encounter.patient.firstName", value: "Patient" },
                { key: "claim.encounter.createdDate", value: "Encounter Date" },
                {
                  key: "claim.encounter.patient.patientInsur]ance[0].payerName",
                  value: "Payer",
                },
                { key: "claim.claimServices", value: "Claim Services" },
                { key: "totalAmount", value: "Amount" },
                {
                  key: "claim.claimResponse.payerAmount",
                  value: "Payer Amount",
                },
                {
                  key: "claim.claimPayment.patientResponsibility",
                  value: "Patient Responsibility",
                },
                { key: "status", value: "Status" },
                { key: " ", value: " " },
              ]}
              data={claims}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DeniedClaimsPage;
