import { useEffect, useState, useRef } from "react";
import {
  Avatar,
  Box,
  Divider,
  Stack,
  Switch,
  Typography,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import ChangePasswordModal from "../UI/Modals/ChangePasswordModal";
import moment from "moment-timezone";
import classes from "./myAccount.module.css";
import { styled } from "@mui/material/styles";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import TwoFAModal from "../UI/Modals/ProviderModals/TwoFAModal";
import DefaultAvatarIcon from "../../assets/icons/Icon-Default-Avatar.svg";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import PropTypes from "prop-types";
import options from "../../utils/data";

import { ChevronLeft } from "@mui/icons-material";
import { Typeahead } from "react-bootstrap-typeahead";
import { useUnsavedWorkPrompt } from "../hooks/useUnsavedWorkPrompt";
import { X } from "react-bootstrap-icons";
import { fieldValidation, navigateOnDirtyBit } from "../../utils/helpers/HelperFunctions";
import medicalAllowedCredentials from "../../utils/medicalAllowedCredentials";
import { useAuth } from "../hooks/useAuth";
import uuid from "react-uuid";
import { ACCOUNT_SETTINGS } from "../../utils/helpers/subscriptionsConstants";

//======Tabs Code=======//

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`prescribenow-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#336383",
      light: "#336383",
      dark: "#336383",
      contrastText: "#336383",
    },
    secondary: {
      main: "#EC609B",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 14,
  },
});

const getTabValue = function (tabTitle) {
  const tabsMap = new Map();
  tabsMap.set("General", 0);
  tabsMap.set("Medical Credentials", 1);
  return tabsMap.get(tabTitle);
};
//========Tabs Code End=======//

//Medical License Row Component
const LicenseRow = ({ license, handleRemoveMedicalLicense }) => {
  return (
    <div className="row ps-3 pb-2 ">
      <div className="col-sm-6 py-1 border-bottom border-bottom-color text-start">
        <div className="row justify-content-start">
          <div className="col-sm-10 text-start">
            <span className="font-12 text--secondary">
              {license?.taxonomyCode}
              {" - "}
              {license?.taxonomyDescription}
            </span>
          </div>
          {/* <div className="col-sm-2">
            <span
              className="cross-sign"
              disabled
              // onClick={() =>
              // 	handleRemoveMedicalLicense(
              // 		license?.StateLicense,
              // 		license?.State
              // 	)
              // }
            >
              <X
                color="#A2D1DE"
                // color="#336383"
                style={{ marginBottom: "2px" }}
                size={24}
              />
            </span>
          </div> */}
        </div>
      </div>
      <div className="col-sm-2 py-1 border-bottom border-bottom-color text-start">
        <span className=" font-12 text--secondary">
          {/* {license?.State} */}
          {
            options?.find((object) => {
              return object?.code === license?.state;
            })?.name
          }
        </span>
      </div>
      <div className="col-sm-2 py-1 border-bottom border-bottom-color text-start">
        <span className="fw-sb font-12 text--terciary">
          {license?.stateLicense}
        </span>
      </div>
      {/* <div className="col-sm-1 text-end py-1 border-bottom">
												</div> */}
    </div>
  );
};

const DeaRow = ({ license, handleRemoveDeaLicense }) => {
  return (
    <div className="row ps-3  pb-2 ">
      <div className="col-sm-3 py-1 border-bottom border-bottom-color text-start">
        <span className="fw-sb font-14 text--terciary">
          {license?.DeaNumber}
        </span>
      </div>
      <div className="col-sm-3 py-1 border-bottom border-bottom-color text-start">
        <div className="row justify-content-around">
          <div className="col-sm-10">
            <span className=" font-14 text--secondary">
              {
                options?.find((object) => {
                  return object?.code === license?.state;
                })?.name
              }
            </span>
          </div>
          <div className="col-sm-2">
            <span
              className="cross-sign"
              onClick={() =>
                handleRemoveDeaLicense(license?.DeaNumber, license?.State)
              }
            >
              <X color="#336383" style={{ marginBottom: "2px" }} size={24} />
            </span>
          </div>
        </div>
      </div>

      {/* <div className="col-sm-1 text-end py-1 border-bottom">
													</div> */}
    </div>
  );
};

const MyAccountPage = (props) => {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTZ, setIsLoadingTZ] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [profileInfo, setProfileInfo] = useState("");
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isAccountUpdate, setIsAccountUpdate] = useState(false);
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const [twoFactorState, setTwoFactorState] = useState(false);
  const [openTwoFAModal, setOpenTwoFAModal] = useState(false);
  const [timexone, setTimexone] = useState("");
  const [offset, setOffset] = useState("");
  const [timexoneArr, setTimexoneArr] = useState([]);
  const dispatch = useDispatch();
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const prescriberId = useRef(-1);
  const { user, updateUser } = useAuth();
  const [prescriberIdentification, setPrescriberIdentification] = useState("");

  const navigate = useNavigate();

  const TwoFactorSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: "#004FD4",
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 11 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
    },
    "& .MuiSwitch-thumb": {
      borderRadius: 11 / 2,
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      color: "#fff",
    },
  }));

  const changePassword = () => {
    setOpenChangePasswordModal(true);
  };

  const handleChangePasswordModal = () => {
    setOpenChangePasswordModal(!openChangePasswordModal);
  };

  const handleTwoFAModal = () => {
    if (!openTwoFAModal) {
      sendNumber();
    }
    console.log(openTwoFAModal);
    setOpenTwoFAModal(!openTwoFAModal);
  };

  const handlePasswordChangeToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsPasswordChanged(false);
  };

  const handleMedicalCredentialsToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsMedicalCredentialAdded(false);
  };

  const handleAccountUpdateToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAccountUpdate(false);
  };

  const fetchUserProfile = async () => {
    dispatch(setDirtyBitInStore(false));
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/currentuser",
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      getHumanReadableTimeZone();
      prescriberId.current = httpResponse.data.userId;
      setProfileInfo(httpResponse.data);
      setTwoFactorState(httpResponse.data.twoFactorAuth);
      setTimexone(httpResponse.data.timezone);
      setOffset(httpResponse.data.timezoneOffset);
      // saveTimezoneLocal(httpResponse.data.timezoneOffset);
      setPrescriberIdentification(httpResponse?.data?.identification);
      // console.log(httpResponse?.data?.Identification?.StateLicense);
      setMedicalLicenses(
        httpResponse?.data?.identification?.stateLicense || []
      );
      setDeaLicenses(httpResponse?.data?.identification?.DEANumber || []);
      setConsent(httpResponse?.data?.identification?.consent || false);
      consentRef.current = httpResponse?.data?.identification?.consent || false;
      incCount.current = httpResponse?.data?.identification?.IncCount;

      if (user?.timezone !== httpResponse.data.timezoneOffset) {
        let newUser = user;
        newUser.timezone = httpResponse.data.timezoneOffset;
        newUser.timezoneText = httpResponse.data.timezone;
        updateUser(newUser);
      }
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
  };

  const sendNumber = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/auth/twofactor",
      method: "PUT",
      data: {
        number:
          profileInfo?.communicationNumbers?.numbers.length > 0
            ? profileInfo?.communicationNumbers?.numbers[0]?.number
            : "",
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
    } else {
      // setError(httpResponse.errorMessage.error);
    }
  };

  const fetchTimeZone = async () => {
    setIsLoadingTZ(true);
    const httpResponse = await sendHttpRequest({
      url: "/timezone",
      method: "GET",
    });
    if (!httpResponse.error) {
      setTimeZoneList(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoadingTZ(false);
  };

  const saveTimezoneLocal = (timezoneOffset) => {
    localStorage.setItem("timezone", JSON.stringify(timezoneOffset));
  };

  const getHumanReadableTimeZone = () => {
    let tzArray = moment.tz.names().map((zone) => {
      return moment.tz(zone).format("zz Z");
    });

    setTimexoneArr([...new Set(tzArray)]);
  };

  const submitAccountChanges = async () => {
    setDisableButton(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/user/update",
      method: "PUT",
      data: {
        userId: profileInfo.userId,
        twoFactorAuth: twoFactorState,
        timezone: timexone,
        timezoneOffset: offset,
      },
    });
    if (!httpResponse.error) {
      dispatch(setDirtyBitInStore(false));
      setIsAccountUpdate(true);
      fetchTimeZone();
      fetchUserProfile();
    } else {
      console.log(httpResponse.error);
    }
    setDisableButton(false);
  };

  const timezoneHandler = (event) => {
    dispatch(setDirtyBitInStore(true));
    setOffset(
      event.target[event.target.selectedIndex].getAttribute("data-offset")
    );
    setTimexone(event.target.value);
  };

  function twoFactorSwitch(event) {
    dispatch(setDirtyBitInStore(true));
    if (event.target.checked) {
      if (!profileInfo?.twoFactorAuth) {
        handleTwoFAModal();
      } else {
        setTwoFactorState(event.target.checked);
      }
    } else {
      setTwoFactorState(event.target.checked);
    }
    // setProfileInfo({...profileInfo, TwoFactorAuth:checked});
  }
  const label = { inputProps: { "aria-label": "TwoFactorSwitch" } };

  //==============Medical Credentials tab============//
  const tabsList = ["General", "Medical Credentials"];
  const [value, setValue] = useState(0);
  const [state, setState] = useState([]);
  const [medicalLicense, setMedicalLicense] = useState("");
  const [expiration, setExpiration] = useState();
  const [npi, setNpi] = useState("");
  const [dea, setDea] = useState("");
  const [issueStateNpi, setIssueStateNpi] = useState([]);
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  const medicalLicenseRef = useRef();
  const stateRef = useRef();
  const dateRef = useRef();
  const npiRef = useRef();
  const stateNpiRef = useRef();
  const deaRef = useRef();
  const [medicalLicenses, setMedicalLicenses] = useState([]);
  const medicalLicenesRef = useRef([]);
  const [deaLicenses, setDeaLicenses] = useState([]);
  const [consent, setConsent] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const consentRef = useRef(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isMedicalCredentialAdded, setIsMedicalCredentialAdded] =
    useState(false);
  const stateDuplicationErrorRef = useRef("");
  const [stateDuplicationError, setStateDuplicationError] = useState("");
  const consentCheckBox = useRef();
  const deaDuplicationErrorRef = useRef("");
  const [deaDuplicationError, setDeaDuplicationError] = useState("");
  const [localChanges, setLocalChanges] = useState(false);
  const localChangesRef = useRef(false);
  const deaLicensesRef = useRef([]);
  const incCount = useRef();
  const addedRef = useRef(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const npiHandler = (event) => {
    setNpi(event.target.value);
    // dispatch(setDirtyBitInStore(true));
    enableSubmitButton();
  };

  const deaHandler = (event) => {
    setDea(event.target.value);
    if (deaDuplicationErrorRef.current !== "") {
      deaDuplicationErrorRef.current = "";
      setDeaDuplicationError("");
    }
    // dispatch(setDirtyBitInStore(true));
  };

  const issuingStateNpiHandler = (state) => {
    setIssueStateNpi(state);
    // dispatch(setDirtyBitInStore(true));
  };

  function unique(array, properties) {
    const keyValueArray = array.map((entry) => {
      const key = properties.map((k) => entry[k]).join("|");
      return [key, entry];
    });
    const map = new Map(keyValueArray);
    return Array.from(map.values());
  }

  const handleMedicalLicenseAdd = (license) => {
    const temp = [...medicalLicenses];
    temp.push(license);
    const uniqueArray = unique(temp, ["StateLicense", "State"]);

    setMedicalLicenses(uniqueArray);
    medicalLicenesRef.current = uniqueArray;
  };

  const handleDeaLicenseAdd = (dea) => {
    const temp = [...deaLicenses];
    temp.push(dea);
    const uniqueArray = unique(temp, ["DeaNumber", "State"]);
    setDeaLicenses(uniqueArray);
    deaLicensesRef.current = uniqueArray;
  };

  const handleRemoveMedicalLicense = (StateLicense, State) => {
    const temp = medicalLicenses.filter(
      (medicalLicense) =>
        medicalLicense?.StateLicense !== StateLicense ||
        medicalLicense?.State !== State
    );
    setMedicalLicenses(temp);
    medicalLicenesRef.current = temp;
    enableSubmitButton();
  };

  const handleRemoveDeaLicense = (DeaNumber, State) => {
    const temp = deaLicenses.filter(
      (deaLicense) =>
        deaLicense?.DeaNumber !== DeaNumber || deaLicense?.State !== State
    );
    setDeaLicenses(temp);
  };

  const medicalLicenseHandler = (event) => {
    setMedicalLicense(event.target.value);
    if (stateDuplicationErrorRef.current !== "") {
      stateDuplicationErrorRef.current = "";
      setStateDuplicationError("");
    }
    // dispatch(setDirtyBitInStore(true));
  };

  const consentHandler = (event) => {
    // dispatch(setDirtyBitInStore(true));
    consentRef.current = !consentRef.current;
    setLocalChanges(true);
    localChangesRef.current = true;
    setConsent(!consent);
    enableSubmitButton();
  };

  const disableCheckBox = () => {
    let disableStatus = false;
    if (consentRef.current === true && localChangesRef.current === false) {
      disableStatus = true;
    } else if (
      consentRef.current === true &&
      localChangesRef.current === true &&
      incCount.current < 3
    ) {
      disableStatus = false;
    } else if (
      consentRef.current === false &&
      localChangesRef.current === false &&
      incCount.current < 3
    ) {
      disableStatus = false;
    } else if (
      consentRef.current === false &&
      localChangesRef.current === true &&
      incCount.current < 3
    ) {
      disableStatus = false;
    } else if (incCount.current >= 3) {
      disableStatus = true;
    } else if (isMedicalCredentialAdded === true) {
      disableStatus = true;
    }
    return disableStatus;
  };

  const defaultCheckHandler = () => {
    if (consentRef.current === true && localChangesRef.current === false) {
      consentCheckBox.current.checked = true;
    } else if (
      consentRef.current === true &&
      localChangesRef.current === true &&
      incCount.current < 3
    ) {
      consentCheckBox.current.checked = false;
    } else if (
      consentRef.current === false &&
      localChangesRef.current === false &&
      incCount.current < 3
    ) {
      consentCheckBox.current.checked = false;
    } else if (
      consentRef.current === false &&
      localChangesRef.current === true &&
      incCount.current < 3
    ) {
      consentCheckBox.current.checked = false;
    }
  };

  const licenseFormSubmitHandler = (event) => {
    event.preventDefault();
    const licenseObject = {
      StateLicense: medicalLicense,
      State: state[0].code,
      ExpiryDate: expiration,
      Verified: false,
    };
    const stateLicenseExists = medicalLicenses.findIndex((object, index) => {
      return object?.State == licenseObject?.State;
    });

    if (stateLicenseExists !== -1) {
      stateDuplicationErrorRef.current =
        "Medical license of the same state already exists.";
      setStateDuplicationError(
        "Medical license of the same state already exists."
      );
    } else {
      handleMedicalLicenseAdd(licenseObject);
      setLocalChanges(true);
      localChangesRef.current = true;
      consentRef.current = false;
      setConsent((prev) => {
        return false;
      });
      defaultCheckHandler();
      enableSubmitButton();
    }
    clearFields();
    // dispatch(setDirtyBitInStore(false)); //TODO
  };

  const enableSubmitButton = (event) => {
    // console.log("FV(npiR)====>", fieldValidation(npiRef.current.value,"all"));
    // console.log("FV(mlR.current,arr)====>", fieldValidation(medicalLicenesRef.current,"array"));
    // console.log("FV(consentRef.current,un)===>", fieldValidation(consentRef.current,"un"));
    // console.log("consentRef.cureent===true====>", consentRef.current === true);
    // if(fieldValidation(npiRef.current.value,"all") && fieldValidation(medicalLicenesRef.current,"array") && fieldValidation(consentRef.current,"un") && consentRef.current === true){
    // 	setEnableSubmit(true);
    // }
    // else{
    // 	setEnableSubmit(false);
    // }

    if (!fieldValidation(npiRef.current.value, "all")) {
      // console.log("Step 0 Fale");
      setEnableSubmit(false);
    } else if (
      fieldValidation(npiRef.current.value, "all") &&
      localChangesRef.current === true
    ) {
      // console.log("Step 1");
      // console.log("consentRef.current === true",  consentRef.current === true);
      if (
        (fieldValidation(medicalLicenesRef.current, "array") ||
          fieldValidation(deaLicensesRef.current, "array")) &&
        consentRef.current === true
      ) {
        console.log("Step 2");
        setEnableSubmit(true);
      } else {
        console.log("Step 3");
        setEnableSubmit(false);
      }
    }
  };

  const deaLicenseFormSubmitHandler = (event) => {
    event.preventDefault();
    const deaLicenseObject = {
      DeaNumber: dea,
      State: issueStateNpi[0].code,
      ExpiryDate: "",
      Verified: false,
    };
    const deaNumberExists = deaLicenses?.findIndex((object, index) => {
      return object?.State == deaLicenseObject?.State;
    });
    if (deaNumberExists !== -1) {
      deaDuplicationErrorRef.current =
        "DEA Number of the same state already exists.";
      setDeaDuplicationError("DEA Number of the same state already exists.");
    } else {
      handleDeaLicenseAdd(deaLicenseObject);
      setLocalChanges(true);
      localChangesRef.current = true;
      consentRef.current = false;
      setConsent(false);
      defaultCheckHandler();
      enableSubmitButton();
      stateNpiRef.current.clear();
      deaRef.current.value = "";
      setIssueStateNpi([]);
      setDea("");
    }
  };

  const clearFields = () => {
    medicalLicenseRef.current.value = "";
    stateRef.current.clear();
    dateRef.current.clear();

    setMedicalLicense("");
    setState([]);
    setExpiration();
  };

  const expirationDateHandler = (date) => {
    setExpiration(date);
    onDirty();
    // dispatch(setDirtyBitInStore(true));
  };

  const issuingStateHandler = (state) => {
    setState(state);
    onDirty();
    // dispatch(setDirtyBitInStore(true));
  };

  //============Medical Credentials tab END==========///

  const createIdentification = () => {
    const identification = {
      PrescriberId: prescriberId?.current,
      Identification: {},
    };
    if (fieldValidation(npiRef.current.value, "all")) {
      identification["Identification"]["NPI"] = npiRef.current.value;
    }
    if (fieldValidation(medicalLicenses, "array")) {
      identification["Identification"]["StateLicense"] = [];
      medicalLicenses?.forEach((license) => {
        identification["Identification"]["StateLicense"]?.push(license);
      });
    }
    if (
      fieldValidation(consentRef.current, "un") &&
      consentRef.current === true
    ) {
      identification["Identification"]["Consent"] = consentRef.current;
    }
    if (fieldValidation(deaLicenses, "array")) {
      identification["Identification"]["DEANumber"] = [];
      deaLicenses?.forEach((license) => {
        identification["Identification"]["DEANumber"].push(license);
      });
    }
    // console.log(identification);
    return identification;
  };

  const addMedicalCredentialsRequest = async () => {
    setIsAdding(true);
    const data = createIdentification();
    // console.log(data);
    const httpResponse = await sendHttpRequest({
      url: "/prescriber/update",
      method: "PUT",
      data: {
        NonVeterinarian: data,
      },
    });
    if (!httpResponse.error) {
      setIsMedicalCredentialAdded((prevValue) => {
        return true;
      });
      addedRef.current = true;
      disableCheckBox();
      fetchUserProfile();
    } else {
      console.log(httpResponse.errorMessage);
    }
    setIsAdding(false);
  };

  // window.addEventListener("beforeunload", UnloadHandler(dirtyBitStatus));

  useEffect(() => {
    fetchTimeZone();
    fetchUserProfile();

    return () => {
      // window.removeEventListener("beforeunload", UnloadHandler(dirtyBitStatus));
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <>
        {openChangePasswordModal && (
          <ChangePasswordModal
            show={openChangePasswordModal}
            handleModal={handleChangePasswordModal}
            setIsPasswordChanged={setIsPasswordChanged}
          />
        )}
        {openTwoFAModal && (
          <TwoFAModal
            show={openTwoFAModal}
            key={uuid()}
            handleModal={handleTwoFAModal}
            headerTitle={"Two-Step Verification"}
            setTwoFactorState={setTwoFactorState}
            fetchUserProfile={fetchUserProfile}
            mobilePhone={
              profileInfo?.communicationNumbers?.numbers.length > 0
                ? profileInfo?.communicationNumbers?.numbers[0]
                : null
            }
          />
        )}
        <Backdrop
          sx={{
            color: "#EC609B",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* <Navbar /> */}
        <div
          className={`container-fluid bg-white  justify-content-center px-3 py-3 ${classes["page__title-border"]}`}
        >
          <div className="row">
            <div className="col-sm-4 text--terciary font-32 align-self-center ">
              <div className="d-flex flex-row">
                <div
                  className={`bg--background ${classes["chevron__left__div"]} br-10 me-3 border--default border text-center my-auto `}
                  onClick={() => {
                    navigateOnDirtyBit(
                      dirtyBitStatus,
                      navigate,
                      -1,
                      dispatch,
                      setDirtyBitInStore
                    );
                  }}
                >
                  <ChevronLeft
                    style={{ color: "#EC609B" }}
                    className="my-auto cursor-pointer mb-2 my-auto"
                  />
                </div>
                <h2 className="font-24 align-self-center m-0 text--terciary fw-sb">
                  My Account
                </h2>
              </div>
            </div>
            <div className="col-sm-8">
              <Tabs
                indicatorColor="secondary"
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
              >
                {user?.role?.toLowerCase() !== "practiceadmin" && (
                  <Tab
                    key={getTabValue("General")}
                    label={"General"}
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                      fontFamily: "Montserrat",
                    }}
                    hidden={(subscriptionPrivilegeGroups[ACCOUNT_SETTINGS] === undefined) || ( subscriptionPrivilegeGroups[ACCOUNT_SETTINGS]?.vmc !== undefined && subscriptionPrivilegeGroups[ACCOUNT_SETTINGS]?.vmc?.active === false ) ? true : false}
                  />
                )}

                {/* {medicalAllowedCredentials?.includes(user?.role) ? ( */}
                <Tab
                  key={getTabValue("Medical Credentials")}
                  label={"Medical Credentials"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "initial",
                    color: "#336383",
                    fontFamily: "Montserrat",
                  }}
                  hidden={(subscriptionPrivilegeGroups[ACCOUNT_SETTINGS] === undefined) || ( subscriptionPrivilegeGroups[ACCOUNT_SETTINGS]?.vmc !== undefined && subscriptionPrivilegeGroups[ACCOUNT_SETTINGS]?.vmc?.active === false ) ? true : false}

                />
                {/* ) : null} */}
              </Tabs>
            </div>
          </div>
        </div>

        <TabPanel value={value} index={getTabValue("General")}>
          {GeneralTabContent()}
        </TabPanel>

        {medicalAllowedCredentials?.includes(user?.role) ? (
          <TabPanel
            className="bg--background vh-100"
            value={value}
            index={getTabValue("Medical Credentials")}
          >
            <div className="container-fluid py-3">
              <div className="row justify-content-center pe-3">
                <div className="col-sm-8 mb-3 pe-4">
                  <div className="col-sm-6 " hidden={true}>
                    <label
                      htmlFor="npi-form"
                      className="form-label font-12 text--secondary"
                    >
                      Type 1 NPI{" "}
                      <span className="font-12 text--primary">&#42;</span>
                    </label>
                    <input
                      type="text"
                      ref={npiRef}
                      onChange={npiHandler}
                      id="npi-form"
                      className="form-control font-14 br-10 border--default shadow-none input"
                      required
                      defaultValue={prescriberIdentification?.npi}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-sm-8" hidden={true}>
                  <form
                    className="row"
                    id="medical-license"
                    onSubmit={licenseFormSubmitHandler}
                  >
                    <div className="col-sm-6">
                      <label
                        htmlFor="expiration"
                        className="form-label font-12 text--secondary"
                      >
                        Taxonomy{" "}
                        <span className="font-12 text--primary">&#42;</span>
                      </label>
                      <input
                        type="text"
                        // ref={medicalLicenseRef}
                        // onChange={medicalLicenseHandler}
                        id="Taxonomy"
                        className="form-control font-14 br-10 border--default shadow-none input"
                        // required
                        maxLength="32"
                      ></input>
                    </div>

                    <div className="col-sm-2">
                      <label
                        htmlFor="issueState"
                        className="form-label font-12 text--secondary"
                      >
                        Issuing State{" "}
                        <span className="font-12 text--primary">&#42;</span>
                      </label>
                      <Typeahead
                        id="issueState"
                        labelKey="name"
                        placeholder="Select"
                        onChange={issuingStateHandler}
                        ref={stateRef}
                        options={options}
                        className="rbt-menu font-10 my-select"
                        style={{
                          borderRadius: "10px",
                          fontSize: "10px !important",
                        }}
                        autoComplete="false"
                        inputProps={{ required: true, className: "font-14" }}
                      // required
                      />
                    </div>

                    <div className="col-sm-2">
                      <label
                        htmlFor="medicalLicense"
                        className="form-label font-12 text--secondary"
                      >
                        Medical License{" "}
                        <span className="font-12 text--primary">&#42;</span>
                      </label>
                      <input
                        type="text"
                        ref={medicalLicenseRef}
                        onChange={medicalLicenseHandler}
                        id="medicalLicense"
                        className="form-control font-14 br-10 border--default shadow-none input"
                        required
                        maxLength="32"
                      ></input>
                      {stateDuplicationError !== "" ? (
                        <span className="font-10 text-danger fw-thin">
                          {stateDuplicationErrorRef?.current}
                        </span>
                      ) : null}
                    </div>

                    <div className="col-sm-2 align-self-end">
                      <button
                        form="medical-license"
                        type="submit"
                        disabled
                        className="btn shadow-none text-decoration-underline text--disabled fw-sb font-12"
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-sm-8">
                  <div className={`${classes["license-list"]} mt-3`}>
                    {medicalLicenses?.map((license, index) => {
                      return (
                        <LicenseRow
                          key={index}
                          license={license}
                          handleRemoveMedicalLicense={
                            handleRemoveMedicalLicense
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid py-3" hidden={true}>
              <div className="row justify-content-center">
                <div className="col-sm-8">
                  <form
                    id="npi-form"
                    className="row"
                    onSubmit={deaLicenseFormSubmitHandler}
                  >
                    <div className="col-sm-3">
                      <label
                        htmlFor="dea"
                        className="form-label font-12 text--secondary"
                      >
                        DEA Number
                      </label>
                      <input
                        type="text"
                        ref={deaRef}
                        onChange={deaHandler}
                        id="dea"
                        className="form-control font-14 br-10 border--default shadow-none input"
                      ></input>
                      {deaDuplicationError !== "" ? (
                        <span className="font-10 fw-thin text-danger">
                          {deaDuplicationErrorRef?.current}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-sm-3">
                      <label
                        htmlFor="issueStateNpi"
                        className="form-label font-12 text--secondary"
                      >
                        Issuing State{" "}
                        <span className="font-12 text--primary"></span>
                      </label>
                      <Typeahead
                        id="issueStateNpi"
                        labelKey="name"
                        placeholder="Select"
                        onChange={issuingStateNpiHandler}
                        ref={stateNpiRef}
                        options={options}
                        className="rbt-menu my-select"
                        style={{ borderRadius: "10px" }}
                        autoComplete="false"
                        inputProps={{
                          required: deaRef?.value?.length > 0 ? true : false,
                          className: "font-14",
                        }}
                        disabled={
                          deaRef?.current?.value?.length > 0 ? false : true
                        }
                      />
                    </div>

                    <div className="col-sm-2 align-self-end">
                      <button
                        form="npi-form"
                        type="submit"
                        className="btn shadow-none text-decoration-underline  text--blue fw-sb font-14"
                        disabled={disableDeaAddBtn()}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-sm-8">
                  <div className={`${classes["license-list"]} mt-3`}>
                    {deaLicenses?.map((license, index) => {
                      return (
                        <DeaRow
                          key={index}
                          license={license}
                          handleRemoveDeaLicense={handleRemoveDeaLicense}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row justify-content-around py-3">
                <div className="col-sm-8">
                  <div className="horizontal-rule" />
                  <div className="row pt-3">
                    <div className="col-sm-7">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={consentHandler}
                          name="consent"
                          ref={consentCheckBox}
                          defaultChecked={disableCheckBox()}
                          id="consent"
                          disabled={
                            disableCheckBox() || isAdding
                              ? true
                              : isMedicalCredentialAdded
                                ? true
                                : false
                          }
                        />

                        <label
                          htmlFor="consent"
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "#323232",
                          }}
                          className="form-check-label"
                        >
                          By checking this box, I certify that the information I
                          submitted is accurate and that I am licensed to
                          prescribe medication in the jurisdictions where I
                          practice. I also consent to PrescribeNow's use of the
                          information that I provided for verification of my
                          identity, licensure, and prescriptive authority as
                          specified in the Provider User Agreement.
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4 text-center ">
                      <button
                        onClick={() => {
                          dispatch(setDirtyBitInStore(false));
                          addMedicalCredentialsRequest();
                          setEnableSubmit(false);
                        }}
                        className={`btn shadow-none bg--primary br-10 font-14 text-white ${classes["btn_submit"]
                          } ${!enableSubmit || incCount >= 3 ? "disabled" : ""}`}
                        disabled={true} //added from BE
                      >
                        {isAdding ? "Submitting" : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        ) : null}
        <CustomizedSnackbar
          message={"Changes saved successfully"}
          severity="success"
          open={isPasswordChanged}
          handleClose={handlePasswordChangeToastClose}
        />
        <CustomizedSnackbar
          message={"Changes saved successfully"}
          severity="success"
          open={isAccountUpdate}
          handleClose={handleAccountUpdateToastClose}
        />

        <CustomizedSnackbar
          message={"Medical Credentials added successfully"}
          severity="success"
          open={isMedicalCredentialAdded}
          handleClose={handleMedicalCredentialsToastClose}
        />
      </>
    </ThemeProvider>
  );

  function disableDeaAddBtn() {
    return deaRef?.current?.value?.length > 0 &&
      stateNpiRef?.current?.getInput()?.value !== ""
      ? false
      : true;
  }

  function GeneralTabContent() {
    return (
      <div className="container-fluid bg--background py-3 h-100">
        <div className="row justify-content-center">
          <div className="col-sm-6 col-12 ">
            <Stack
              direction="row"
              className="py-2 border--bottom"
              alignItems="center"
              spacing={2}
            >
              <Avatar
                alt={
                  profileInfo?.name?.length > 0
                    ? profileInfo?.name[0]?.lastName
                    : ""
                }
                src={
                  profileInfo?.profilePicture
                    ? profileInfo?.profilePicture
                    : DefaultAvatarIcon
                }
              />
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "center",
                    m: 1,
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#323232",
                  }}
                >
                  {profileInfo?.name?.length > 0
                    ? profileInfo?.name[0]?.prefix
                    : ""}{" "}
                  {profileInfo?.name?.length > 0
                    ? profileInfo?.name[0]?.firstName
                    : ""}{" "}
                  {profileInfo?.name?.length > 0
                    ? profileInfo?.name[0]?.lastName
                    : ""}
                </Box>
              </Typography>
            </Stack>

            {/* <Divider /> */}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-6 col-12 ">
            <Stack
              direction="row"
              className="py-2"
              alignItems="center"
              spacing={2}
            >
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "center",
                    m: 1,
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#323232",
                  }}
                >
                  Personal Information
                </Box>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              className="py-2 ps-2"
              justifyContent={"between"}
              alignItems="center"
              spacing={2}
            >
              <div className="col-sm-6">
                <label
                  htmlFor="FirstName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  First Name
                </label>
                <input
                  type="text"
                  disabled={true}
                  // value={prescriberInfo?.Name?.FirstName}
                  value={
                    profileInfo?.name?.length > 0
                      ? profileInfo?.name[0]?.firstName
                      : ""
                  }
                  className={`form-control font-12 m-0 border--default bg--disabled br-10 shadow-none input`}
                // onBlur={firstNameBlur}
                />
                {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="LastName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  disabled={true}
                  // value={prescriberInfo?.Name?.LastName}
                  value={
                    profileInfo?.name?.length > 0
                      ? profileInfo?.name[0]?.lastName
                      : ""
                  }
                  className={`form-control font-12 m-0 border--default bg--disabled br-10 shadow-none input`}
                // onBlur={firstNameBlur}
                />
                {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>
            </Stack>
            <Stack
              direction="row"
              className="py-2 ps-2"
              justifyContent={"between"}
              alignItems="center"
              spacing={2}
            >
              <div className="col-sm-6">
                <label
                  htmlFor="Email"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Email
                </label>
                <input
                  type="email"
                  disabled={true}
                  value={profileInfo?.username}
                  className={`form-control font-12 m-0 border--default bg--disabled br-10 shadow-none input`}
                />
              </div>

              <div className="col-sm-6">
                <label
                  htmlFor="Email"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Phone
                </label>
                <input
                  type="text"
                  disabled={true}
                  value={
                    profileInfo?.communicationNumbers?.numbers.at(0)?.number
                  }
                  className={`form-control font-12 m-0 border--default bg--disabled br-10 shadow-none input`}
                />
              </div>
            </Stack>
            <Stack
              direction="row"
              className="py-2 ps-2"
              justifyContent={"between"}
              alignItems="center"
              spacing={2}
            >
              <div className="col-md-6">
                <label
                  htmlFor="Role"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Role
                </label>
                <input
                  type="text"
                  disabled={true}
                  value={RolesTextConversion(profileInfo?.role) ?? ""}
                  className={`form-control font-12 m-0 border--default bg--disabled br-10 shadow-none input`}
                />
              </div>
              {medicalAllowedCredentials?.includes(user?.role) ? (
                <div className="col-sm-6">
                  <label
                    htmlFor="NPI"
                    className="form-label m-0 font-12 text--secondary fw-sb"
                  >
                    NPI
                  </label>
                  <input
                    type="text"
                    disabled={true}
                    value={
                      profileInfo?.identification !== undefined
                        ? profileInfo?.identification.npi
                        : ""
                    }
                    className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                    style={{ backgroundColor: "#F2F7F8" }}
                  />
                </div>
              ) : null}
            </Stack>
            {medicalAllowedCredentials?.includes(user?.role) ? (
              <Stack
                direction="row"
                className="py-2 ps-2"
                justifyContent={"between"}
                alignItems="center"
                spacing={2}
              >
                <div className="col-sm-6">
                  <label
                    htmlFor="eprescriptionStatus"
                    className="form-label m-0 font-12 text--secondary fw-sb"
                  >
                    E-Prescription Status
                  </label>

                  <input
                    type="text"
                    disabled={true}
                    value={
                      profileInfo?.identification !== undefined
                        ? profileInfo?.identification?.status === false
                          ? "Disabled"
                          : "Enabled"
                        : "Disabled"
                    }
                    className={`form-control font-12 bg--disabled m-0 border--default br-10 shadow-none input`}
                  />
                </div>
              </Stack>
            ) : null}
            {/* <div className="pt-3 ">
              <Divider />
            </div> */}

            <Stack
              direction="row"
              className="py-2 border--bottom border--top"
              justifyContent={"between"}
              alignItems="center"
              spacing={2}
            >
              <div className="col-sm-4">
                <button
                  className="btn text--blue fw-sb font-14 shadow-none"
                  onClick={handleChangePasswordModal}
                >
                  Change Password
                </button>
              </div>
            </Stack>

            <Stack
              direction="row"
              className="py-2"
              justifyContent={"between"}
              alignItems="center"
              spacing={2}
            >
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "center",
                    m: 1,
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#323232",
                  }}
                >
                  Two-Step Verification
                </Box>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              className="py-2 ps-2 border--bottom"
              justifyContent={"between"}
              alignItems="center"
              spacing={2}
            >
              <div
                className="col-sm-4"
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid #D9E8EC",
                  borderRadius: "10px",
                }}
              >
                <div className="row">
                  <div className="col-sm-8 justify-content-start align-self-center">
                    <Typography component="span">
                      <Box
                        sx={{
                          m: 1,
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          fontSize: "12px",
                          color: "#323232",
                        }}
                      >
                        {twoFactorState === true ? "Enabled" : "Disabled"}
                      </Box>
                    </Typography>
                  </div>
                  <div className="col-4 justify-content-end align-self-center">
                    <TwoFactorSwitch
                      {...label}
                      checked={twoFactorState}
                      onChange={twoFactorSwitch}
                    />
                    {/* <Switch style={{ borderRadius: '5px' }} checked={twoFactorState} onChange={twoFactorSwitch} /> */}
                  </div>
                </div>
              </div>
            </Stack>

            {/* <div className="py-4">
              <Divider />
            </div> */}

            <Stack
              direction="row"
              justifyContent={"between"}
              alignItems="center"
              spacing={2}
            >
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "center",
                    m: 1,
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#323232",
                  }}
                >
                  Timezone
                </Box>
              </Typography>
            </Stack>

            {/* {console.log(moment(new Date()).utcOffset(0, false).format())} */}
            {/* {console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)} */}

            <Stack
              direction="row"
              className="py-2 ps-2"
              alignItems="center"
              spacing={2}
            >
              <div className="col-sm-6">
                <select
                  onChange={timezoneHandler}
                  className="form-select font-14 fw-sb border--default br-10 shadow-none input"
                  aria-label="timezone"
                  id="timezone"
                  style={{ borderRadius: "10px" }}
                  placeholder="Select Timezone"
                >
                  <option className="fw-sb" disabled selected hidden value="">
                    Select Timezone
                  </option>
                  {isLoading ? (
                    <option className="fw-sb">Loading...</option>
                  ) : timeZoneList?.length > 0 ? (
                    timeZoneList?.map((item, index) => {
                      return (
                        <option
                          key={item + index}
                          value={item?.name}
                          data-offset={item?.offset}
                          selected={item?.name == profileInfo.timezone}
                          className="fw-sb"
                        >
                          {item?.name} &nbsp; {item?.offset}
                          {/* {moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z')} */}
                          {/* {Date().slice(Date().indexOf("(") + 1, Date().lastIndexOf(")"))} */}
                        </option>
                      );
                    })
                  ) : (
                    ""
                  )}
                </select>
              </div>
            </Stack>

            {/* <div className="pt-4">
              <Divider />
            </div> */}

            <Stack
              direction="row"

              className="py-2 border--top"
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Box>
                <div className="col-sm-12">
                  <button
                    className="btn blue_task_button b2 px-5 br-10 py-1"
                    disabled={disableButton}
                    onClick={submitAccountChanges}
                  >
                    Update
                  </button>
                </div>
              </Box>
            </Stack>
          </div>
        </div>
      </div>
    );
  }
};

export default MyAccountPage;
