
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { navigateOnDirtyBit } from '../../../utils/helpers/HelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { setDirtyBitInStore } from '../../../redux/actions/providerActions';


const PageHeader = ({ title, children, ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);

    return (
        <div className="container-fluid border--bottom bg--white " >
            <div className='d-flex justify-content-between'>
                <div className="d-flex p-1 py-3 bg-white">
                    <div className="align-self-center">
                        <div className="d-flex flex-row">
                            <div
                                style={{ width: "32px", height: "32px" }}
                                className={`bg--background  br-10 me-3 border--default border align-self-center text-center my-auto `}
                                onClick={() => {
                                    if (props?.revertEditMode !== undefined) {
                                        navigateOnDirtyBit(
                                            dirtyBitStatus,
                                            props?.revertEditMode(),
                                            -1,
                                            dispatch,
                                            setDirtyBitInStore
                                          );
                                    }
                                    else {

                                        
                                            navigateOnDirtyBit(
                                                dirtyBitStatus,
                                                navigate,
                                                -1,
                                                dispatch,
                                                setDirtyBitInStore
                                              );
                                        
                                    }

                                }}
                            >
                                <ChevronLeft
                                    style={{ color: "#EC609B", width: "20px", height: "20px" }}
                                    className="cursor-pointer my-auto mt-1"
                                />
                            </div>
                            <div className="font-22  fw-sb text--terciary align-self-center">
                                {title}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex py-3">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PageHeader