
import InputMask from "react-input-mask";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import moment from "moment-timezone";
import TeleconsultForm from "../../components/pages/TeleconsultForm";
import PrivacyNoticeForm from "../../components/pages/forms/PrivacyNoticeForm";
import AllFormsSubmitted from "../../components/pages/forms/AllFormsSubmitted";
import MasterCard from "../../assets/icons/Mastercard";
import Visa from "../../assets/icons/Visa";
import Discover from "../../assets/icons/Discover";
import Amex from "../../assets/icons/Amex";
import { useAuth } from "../../components/hooks/useAuth";
import { useMemo } from "react";

const currencyMap = new Map([['USD', '$']])
export function getCurrencySymbol(currency) {
  return currencyMap.get(currency);
}


export function truncate(str, n) {
  return str?.length > n ? str?.substr(0, n - 1) + `...` : str;
}

export function truncateWithStartIndexGiven(str, s) {
  return str?.length > s ? str?.substr(s, str.length - 1) : str;
}

export function jsonToMap(jsonObject) {
  let map = new Map();
  if (jsonObject !== undefined && jsonObject !== null) {
    map = new Map(Object.entries(jsonObject));
  }
  return map;
}

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");
  if (cleaned?.length > 10) {
    cleaned = cleaned.slice(0, 10)
  }
  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};


export const formatPostalCode = (zip) => {
  if (zip?.length === 5) {
    return zip;
  } else if (zip?.length > 0) {
    return zip?.substring(0, 5) + "-" + zip?.substring(5);
  }
};

export const sessionPostApiCall = async (key, value) => {
  let json = {};
  let val = value;
  let ky = key;
  json[ky] = JSON.stringify(val);
  const httpResponse = await sendHttpRequest({
    url: "/session",
    method: "POST",
    data: json,
  });
  if (!httpResponse.error) {
    return httpResponse.data;
  } else {
    return false;
  }
};

export const sessionGetApiCall = () => {
  const httpResponse = sendHttpRequest({
    url: "/session",
    method: "GET",
  });
  if (!httpResponse.error) {
    return httpResponse.data;
  } else {
    return false;
  }
};

export const unsavedWorkHandler = (
  type,
  message = `There are unsaved changes that will be lost if you close this ${type}.`,
  dirtyBit,
  onOkHandler,
  clearDirtyBitHandler,
  modalHandler = null
) => {
  if (type.toLowerCase() === "modal") {
    unsavedModalHandler(
      dirtyBit,
      modalHandler,
      message,
      onOkHandler,
      clearDirtyBitHandler
    );
  }
};

function unsavedModalHandler(
  dirtyBit,
  modalHandler,
  message,
  onOkHandler,
  clearDirtyBitHandler
) {
  if (!dirtyBit()) {
    modalHandler();
  } else if (dirtyBit()) {
    let userResponse = window.confirm(message);
    if (userResponse) {
      onOkHandler();
      clearDirtyBitHandler();
    } else {
      //do nothing...
    }
  }
}

export const unsavedPageHandler = ({
  message = "There are unsaved changes on this page. Do you wish to leave the page?",
  dirtyBit = null,
  clearDirtyBitHandler = null,
}) => {
  if (dirtyBit()) {
    let userResponse = window.confirm(message);
    if (userResponse) {
      clearDirtyBitHandler();
    }
  }
};

export function navigateOnDirtyBit(
  dirtyBit,
  navigate,
  path,
  dispatch,
  setDirtyBitInStore
) {
  if (dirtyBit === true) {
    let userConfirmation = window.confirm(
      "There are unsaved changes on this page. Do you want to leave?"
    );
    if (userConfirmation === true) {
      navigate(path);
      dispatch(setDirtyBitInStore(false));
    } else {
    }
  } else {
    navigate(path);
  }
}

export function fieldValidation(field, type) {
  if (type.toLowerCase() === "all") {
    return field !== undefined && field !== null && field !== "";
  } else if (type.toLowerCase() === "un") {
    return field !== undefined && field !== null;
  } else if (type.toLowerCase() === "array") {
    return (
      field !== null &&
      field !== undefined &&
      Array.isArray(field) &&
      field.length > 0
    );
  }
}

export function patientFormsMap() {
  let formMap = new Map();
  formMap.set("Telemedicine Consent", TeleconsultForm);
  formMap.set("Privacy Notice", PrivacyNoticeForm);
  formMap.set("All Forms Submitted", AllFormsSubmitted);
  // formMap.set("Patient Registration Form", PrivacyNoticeForm);
  // formMap.set("Medical History Form", PrivacyNoticeForm);
  // formMap.set("HIPAA Data Use Agreement", PrivacyNoticeForm);

  return formMap;
}

export const cardsMap = function (cardType) {
  const cards = new Map();
  cards.set("master-card", <MasterCard />);
  cards.set("visa", <Visa />);
  cards.set("discover", <Discover />);
  cards.set("american-express", <Amex />);

  return cards.get(cardType);
};

export function isOnlySpaces(str) {
  return typeof str === "string" ? str?.trim().length === 0 : null;
}

export function UnloadHandler(dirtyBitStatus) {
  return function (event) {
    if (dirtyBitStatus === true) {
      event.returnValue =
        "There are unsaved changes on this page. Do you wish to continue?";
    }
  };
}

export function videoUnloadHandler() {
  return function (event) {
    event.returnValue = "Video consultation will be ended and you have to rejoin. Do you wish to continue?";
  };
}

export const addObjectToArray = (arr, object) => {
  if (arr !== undefined && arr !== null && Array.isArray(arr)) {
    if (object !== undefined && object !== null) {
      arr.push(object);
    }
  }
};

export const createIfNotExists = (obj, prop, variant) => {
  if (obj !== undefined && obj !== null) {
    if (obj[prop] === undefined) {
      if (variant?.toLowerCase() === "array") {
        obj[prop] = [];
      } else if (variant?.toLowerCase() === "object") {
        obj[prop] = {};
      }
    }
  }
};


export const getErrorForField = (execeptionsArray, fieldName) => {
  let fieldErrors = [];
  if (Array.isArray(execeptionsArray) && execeptionsArray?.length > 0) {
    if (typeof fieldName === "string") {
      let errors = execeptionsArray?.filter((o) => o?.name === fieldName);
      fieldErrors = [...new Set(errors.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
    }
  }
  return fieldErrors;
}

export const isValidZipCode = (zipCode) => {
  return /^\d{5}(-\d{4})?$/.test(zipCode);
}

export const isValidInteger = (num) => {
  return /^\+?([1-9]\d*)$/.test(num);
}
export const pharmacySpecialty = (specialtyObject) => {
  let specialty = specialtyObject?.replace("~Specialty", "");
  let specialtyList = specialty?.split("~");
  // if (specialtyList?.length > 0) {
  //   specialtyList = specialtyList?.filter((sp) => (sp === 'MailOrder' || sp === 'Retail'));
  // }
  return specialtyList?.join(", ");

}

export const changeRxMap = new Map([
  ['G', 'Generic substitution'],
  ['T', 'Therapeutic interchange'],
  ['D', 'Drug use evaluation'],
  ['P', 'Prior authorization'],
  ['U', 'Prescriber authorization'],
  ['S', 'Script clarification'],
  ['OS', 'Out of stock']
]);


export const rxSubcodeMap = new Map([
  ['I', 'Taxonomy'],
  ['G', 'NPI'],
  ['A', 'State License'],
  ['B', 'DEA license'],
  ['C', 'DEA registration'],
  ['D', 'State Controlled Substance License'],
  ['E', 'State controlled substance registration'],
  ['F', 'NADEAN license'],
  ['H', 'Prescription benefit enrollment'],
  ['J', 'REMS enrollment'],
  ['L', 'Supervising prescriber validation'],
  ['M', 'Certificate to prescribe number']

]);

export const genderMap = new Map([
  ['m', 'Male'],
  ['f', 'Female'],
  ['u', 'Unknown'],
  ['male', 'Male'],
  ['female', 'Female'],
  ['unknown', 'Unknown'],
]);

export const prescriptionMap = new Map([
  ['cancelation denied', 'Cancelation Denied'],
  ['cancelation error', 'Cancelation Error'],
  ['error', 'Error'],
  ['canceled', 'Canceled']
]);


export const showTaxonomySubcodes = ['I'];


export const isPalindrome = (str) => {
  var re = /[\W_]/g;
  var lowRegStr = str.toLowerCase().replace(re, '');
  var reverseStr = lowRegStr.split('').reverse().join('');
  return reverseStr === lowRegStr;
}


// If all entires of object of insurance are empty, then return true otherwise false
export const removeIfAllEmpty = (insuranceObject) => {
  let isEmpty = true;
  if (insuranceObject !== undefined && insuranceObject !== null) {
    for (const [key, value] of Object.entries(insuranceObject)) {
      if (key !== "type") {
        if (fieldValidation(value, "all")) {
          isEmpty = false;
        }
      }
    }
  }
  return isEmpty;
};

export const removeIfAllEmptyFromApiData = (insuranceObject) => {
  let isEmpty = true;
  if (insuranceObject !== undefined && insuranceObject !== null) {
    for (const [key, value] of Object.entries(insuranceObject)) {
      if (key !== "type" && key !== "id") {
        if (value !== "" && value !== null) {
          isEmpty = false;
        }
      }
    }
  }
  return isEmpty;
};

export function isValidSSN(str) {
  let regex = new RegExp(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/);

  if (regex.test(str) === true) {
    return true;
  }
  else {
    return false;
  }
}

export default function MaskInput(props) {
  return (
    <InputMask
      mask={props.mask}
      value={props.value}
      onChange={props.onChange}
      maskChar="_"
      placeholder=''
      onInvalid={(e) => {
        e.target.setCustomValidity("Please fill out this field");
      }}
      onInput={(e) => {
        e.target.setCustomValidity("");
      }}
      {...props}
    ></InputMask>
  );
}
export const sleepSync = (ms) => {
  const end = new Date().getTime() + ms;
  while (new Date().getTime() < end) {
    /* do nothing */
  }
};

export const arePropertiesNullExceptExcludedOne = (obj, exclude) => {
  let copiedObject = structuredClone(obj);
  delete copiedObject[exclude];
  return Object.values(copiedObject).every(value => value === null);
}


export const unique = (array, properties) => {
  const keyValueArray = array.map((entry) => {
    const key = properties.map((k) => entry[k]).join("|");
    return [key, entry];
  });
  const map = new Map(keyValueArray);
  return Array.from(map.values());
}

export const validateNullUndefined = (val) => {
  return val !== undefined && val !== null;
}
export const validatePhoneNumber = (number) => {
  let phoneRegEx = /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/i;
  return phoneRegEx.test(number)
}

export const getUTCFromTimezone = (value = "utc") => {
  let user = JSON.parse(localStorage.getItem("user"));
  let tztxt = "";
  if (fieldValidation(user, "un")) {
    tztxt = user['timezoneText']
  }
  const timezone = timezoneLov()?.filter(t => tztxt === t.timezone);
  return timezone?.length === 1 ? timezone[0][value] : undefined;
}

export function convertToTimezone(utcDate, offset, ianaName) {
  const utcMoment = moment.utc(utcDate);
  const timezoneMoment = utcMoment.utcOffset(offset);
  const convertedDate = timezoneMoment.tz(ianaName).toDate();
  return convertedDate;
}

export const timezoneLov = () => {
  return [
    {
      "timezone": "Hawaii Standard Time",
      "offset": "-1000",
      "utc": "UTC-10",
      "abbvr": "HST",
      "iana_name": "Pacific/Honolulu"
    },
    {
      "timezone": "Alaska Standard Time",
      "offset": "-0900",
      "utc": "UTC-9",
      "abbvr": "HDT",
      "iana_name": "America/Anchorage"
    },
    {
      "timezone": "Hawaii-Aleutian Daylight Time",
      "offset": "-0900",
      "utc": "UTC-9",
      "abbvr": "HDT",
      "iana_name": "Pacific/Honolulu"
    },
    {
      "timezone": "Alaska Daylight Time",
      "offset": "-0800",
      "utc": "UTC-8",
      "abbvr": "AKDT",
      "iana_name": "America/Anchorage"
    },
    {
      "timezone": "Pacific Standard Time",
      "offset": "-0800",
      "utc": "UTC-8",
      "abbvr": "PST",
      "iana_name": "America/Los_Angeles"
    },
    {
      "timezone": "Mountain Standard Time",
      "offset": "-0700",
      "utc": "UTC-7",
      "abbvr": "MST",
      "iana_name": "America/Denver"
    },
    {
      "timezone": "Pacific Daylight Time",
      "offset": "-0700",
      "utc": "UTC-7",
      "abbvr": "PDT",
      "iana_name": "America/Los_Angeles"
    },
    {
      "timezone": "Central Standard Time",
      "offset": "-0600",
      "utc": "UTC-6",
      "abbvr": "CST",
      "iana_name": "America/Chicago"
    },
    {
      "timezone": "Mountain Daylight Time",
      "offset": "-0600",
      "utc": "UTC-6",
      "abbvr": "MDT",
      "iana_name": "America/Denver"
    },
    {
      "timezone": "Central Daylight Time",
      "offset": "-0500",
      "utc": "UTC-5",
      "abbvr": "CDT",
      "iana_name": "America/Chicago"
    },
    {
      "timezone": "Eastern Standard Time",
      "offset": "-0500",
      "utc": "UTC-5",
      "abbvr": "EST",
      "iana_name": "America/New_York"
    },
    {
      "timezone": "Atlantic Standard Time",
      "offset": "-0400",
      "utc": "UTC-4",
      "abbvr": "AST",
      "iana_name": "America/Halifax"
    },
    {
      "timezone": "Eastern Daylight Time",
      "offset": "-0400",
      "utc": "UTC-4",
      "abbvr": "EDT",
      "iana_name": "America/New_York"
    },
    {
      "timezone": "Newfoundland Standard Time",
      "offset": "-0330",
      "utc": "UTC-3:30",
      "abbvr": "NST",
      "iana_name": "America/St_Johns"
    },
    {
      "timezone": "Atlantic Daylight Time",
      "offset": "-0300",
      "utc": "UTC-3",
      "abbvr": "ADT",
      "iana_name": "America/Halifax"
    },
    {
      "timezone": "Pierre & Miquelon Standard Time",
      "offset": "-0300",
      "utc": "UTC-3",
      "abbvr": "PMST",
      "iana_name": "America/Miquelon"
    },
    {
      "timezone": "West Greenland Time",
      "offset": "-0300",
      "utc": "UTC-3",
      "abbvr": "WGT",
      "iana_name": "America/Godthab"
    },
    {
      "timezone": "Newfoundland Daylight Time",
      "offset": "-0230",
      "utc": "UTC-2:30",
      "abbvr": "NDT",
      "iana_name": "America/St_Johns"
    },
    {
      "timezone": "Pierre & Miquelon Daylight Time",
      "offset": "-0200",
      "utc": "UTC-2",
      "abbvr": "PMDT",
      "iana_name": "America/Miquelon"
    },
    {
      "timezone": "Western Greenland Summer Time",
      "offset": "-0200",
      "utc": "UTC-2",
      "abbvr": "WGST",
      "iana_name": "America/Godthab"
    },
    {
      "timezone": "East Greenland Time",
      "offset": "-0100",
      "utc": "UTC-1",
      "abbvr": "EGT",
      "iana_name": "America/Scoresbysund"
    },
    {
      "timezone": "East Greenland Summer Time",
      "offset": "-0000",
      "utc": "UTC-0",
      "abbvr": "EGST",
      "iana_name": "America/Scoresbysund"
    },
    {
      "timezone": "Greenwich Mean Time",
      "offset": "-0000",
      "utc": "UTC-0",
      "abbvr": "GMT",
      "iana_name": "Etc/GMT"
    }
  ]
}




export const rxFillIndicatorLov = () => {
  return [
    {
      "indicator": "All fill statuses",
      "value": "ALL_FILL_STATUSES"
    },
    {
      "indicator": "All fill statuses except transferred",
      "value": "ALL_FILL_STATUSES_EXCEPT_TRANSFERRED"
    },
    {
      "indicator": "Dispensed and partially dispensed",
      "value": "DISPENSED_AND_PARTIALLY_DISPENSED"
    },
    {
      "indicator": "Partially dispensed and not dispensed",
      "value": "PARTIALLY_DISPENSED_AND_NOT_DISPENSED"
    },
    {
      "indicator": "Not dispensed and transferred",
      "value": "NOT_DISPENSED_AND_TRANSFERRED"
    },
    {
      "indicator": "Partially dispensed",
      "value": "PARTIALLY_DISPENSED"
    },
    {
      "indicator": "Not dispensed",
      "value": "NOT_DISPENSED"
    },
    {
      "indicator": "Cancel all fill statuses",
      "value": "CANCEL_ALL_FILL_STATUSES"
    }
  ]
}

export function getProviderConcatenatedNameFromNameObject(nameObject) {
  if (!nameObject) {
    return '';
  }

  const firstName = typeof nameObject.firstName === 'string' ? nameObject.firstName : '';
  const lastName = typeof nameObject.lastName === 'string' ? nameObject.lastName : '';
  const prefix = typeof nameObject.prefix === 'string' ? nameObject.prefix : '';

  return `${prefix} ${firstName} ${lastName}`;
}

export function getPatientConcatenatedNameFromNameObject(nameObject) {
  if (!nameObject) {
    return '';
  }
  const firstName = typeof nameObject.firstName === 'string' ? nameObject.firstName : '';
  const lastName = typeof nameObject.lastName === 'string' ? nameObject.lastName : '';
  const middleName = typeof nameObject?.middleName === 'string' ? nameObject.middleName : '';
  let output = `${firstName} ${lastName}`;

  if (middleName !== '' || middleName !== null) {
    output = `${firstName} ${middleName} ${lastName}`
  }

  return output;
}

export const AllergySummaryComponent = ({ allergy }) => {
  return (
    <div className="font-12 m-0 p-0">
      {allergy?.drugProductCodedText} ({allergy?.allergyType}{fieldValidation(allergy?.severityCodedText, "all") ? ", " + allergy?.severityCodedText : ""}){fieldValidation(allergy?.reactionCodedText, "all") ? " - " + allergy?.reactionCodedText : ""}
    </div>
  )
}

export function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let csv = '';

  // Extracting header
  const header = Object.keys(array[0]);
  csv += header.join(',') + '\r\n';

  // Extracting values
  for (const item of array) {
    const row = Object.values(item).map(value => {
      if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
      } else {
        return value;
      }
    });
    csv += row.join(',') + '\r\n';
  }

  return csv;
}

export function generateCSVFile(csvData, fileName) {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

  // Create a temporary anchor element
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Set the CSV file name
    const csvFileName = fileName + '.csv';

    // Create a temporary URL and attach it to the anchor element
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', csvFileName);

    // Trigger the download by programmatically clicking the anchor element
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function getProviderNameWithQualification(nameObject, qualification) {
  if (!nameObject) {
    return '';
  }

  const firstName = typeof nameObject.firstName === 'string' ? nameObject.firstName : '';
  const lastName = typeof nameObject.lastName === 'string' ? nameObject.lastName : '';
  const prefix = typeof qualification === 'string' ? ", " + qualification : '';

  return `${firstName} ${lastName}${prefix} `;
}

export function validateEmail(email) {
  // Regular expression for basic email validation
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
}


export function appointmentNavigation(appointmentType, patientId, prescriptionReferenceId, providerId, state) {
  if (appointmentType === 'follow_up' || appointmentType === undefined) {
    return `/all-patients/${patientId}/soap-note`, { state: state };
  } else {
    return `/consult/${providerId}/${prescriptionReferenceId}/${patientId}`
  }
}

export function currencyFormatter(currency, amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency
  });

  return formatter.format(amount);
}

export function initCap(str) {
  if (!str) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}


export function unformatPhoneNumber(phoneNumber) {
  return phoneNumber?.replace(/[^\d]/g, '') ?? '';
}


export function updateWeekStartDay(newWeekStartDay) {
  // Retrieve user data from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.tenant) {
    // Update the weekStartDay value
    user.tenant.weekStartDay = newWeekStartDay;

    // Save the updated object back to localStorage
    localStorage.setItem("user", JSON.stringify(user));

    console.log("Updated weekStartDay to:", newWeekStartDay);
  } else {
    console.error("User or tenant data is not available in localStorage.");
  }
}
