export const BILLING_AND_CLAIMS = "Billing & Claims";
export const ENCOUNTERS = "Encounters";
export const TASKS = "Tasks";
export const ACCOUNT_SETTINGS = "Account Settings";
export const APPOINTMENTS = "Appointments";
export const PRACTICE_MANAGEMENT = "Practice Management";
export const MESSAGES = "Messages";
export const PATIENTS = "Patients";
export const CLAIM_ONLY = "ClaimsOnly";
export const EHR_ONLY = "EHROnly";
export const HOME = "Home";
export const FACILITIES = "Facilities";
export const SETTINGS = "Settings";
export const USER_ROLES = "User Roles";
export const PRACTICE_USERS = "Practice Users";
export const REPORTS = "Reports";
export const PRESCRIPTIONS = "Prescriptions";
export const LAB_ORDERS = "Lab Orders";
export const IMAGING_ORDERS = "Imaging Orders";
export const PROVIDER_NOTE = "Provider Note";
export const SOAP_NOTE = "SOAP Note";
export const ELIGIBILITY_CHECKS_CLAIMS_PATIENT_INVOICES = "Eligibility Checks/ Claims/ Patient Invoices";
export const PATIENTS2 = "Patients2";
export const ENCOUNTERS2 = "Encounters2";
export const CLAIMS = "Claims";
export const PRACTICE_INFORMATION = "Practice Information";
export const APPOINTMENT_SETTINGS = "Appointment Settings";
export const NOTIFICATION_SETTINGS = "Notification Settings";
export const PAYPAL_SETTINGS = "Paypal Settings";
export const SUBSCRIPTION_DETAILS = "Subscription Detials";
export const CLAIMS_SETTINGS = "Claims Settings";
export const FEE_SCHEDULE = "Fee Schedule";
export const PAYMENTS = "Payments";
export const PATIENT_PORTAL = "Patient Portal";
export const PATIENT_REGISTRATION = "Patient Registration";
