import React from "react";
import { Modal } from "react-bootstrap";

const DeleteUserConfirmationModal = ({
  deleteUser,
  deletePromptText,
  show,
  handleDeleteModalClose,
}) => {
  return (
    <>
      <Modal
        show={show}
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        size="sm"
        dialogClassName="modal-40vw"
        backdrop="static"
        centered
        onHide={() => {
          handleDeleteModalClose();
        }}
      >
         <Modal.Header className="modal-header" closeButton>
          <Modal.Title className="font-18 text--terciary">
            Delete User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row py-3 justify-content-center">
            <div className="col-sm-12">
              <h2 className="font-16 fw-sb text-center text--terciary">
                Delete Confirmation
              </h2>
              <p className="font-14 text-center">{deletePromptText}</p>
              <div className="row justify-content-center">
                <div className="col-sm-4">
                  <button
                    className={`btn px-4 br-10 bg--d9e8ec shadow-none fw-sb font-14`}
                    onClick={handleDeleteModalClose}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-sm-4">
                  <button
                    className={`btn bg--red px-4 br-10 text-white shadow-none fw-sb font-14`}
                    onClick={deleteUser}
                  >
                    {'Confirm'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteUserConfirmationModal;
