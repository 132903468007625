import { Dropdown } from "react-bootstrap";
import PageHeader from "../UI/General/PageHeader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classes from "./ReportingDashboard.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import ClaimTemplateTypeModal from "../UI/Modals/ClaimTemplateTypeModal";

const ReportsDownloadMenu = (props) => {
  return (
    <>
      <Dropdown
        drop="down"
        container={document.body}
        popperConfig={{ strategy: "fixed" }}
      >
        {" "}
        {/* Ensuring dropdown is rendered in the body */}
        <Dropdown.Toggle
          id="demo-customized-button"
          style={{
            border: "1px solid #d9e8ec",
            color: "#336383",
            background: "#D9E8EC",
            fontSize: "12px",
            fontWeight: "600",
            borderRadius: "10px",
            height: "3em",
            width: "12em",
            fontFamily: "Montserrat",
            textTransform: "capitalize",
            borderBottom: 0,
          }}
          className="shadow-none"
          endIcon={<KeyboardArrowDownIcon className="ms-2" />}
        >
          Download
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            background: "#D9E8EC",
            color: "#336383",
            borderRadius: "10px",
            border: "#D9E8EC",
            boxShadow: "none",
            minWidth: "11.07em",
            position: "relative", // Ensure the position context is correct
          }}
          className="font-12"
        >
          <Dropdown.Item
            style={{
              color: "#336383",
              fontWeight: "600",
            }}
            className="font-12"
          >
            Download as Excel
          </Dropdown.Item>
          <Dropdown.Item
            style={{ color: "#336383", fontWeight: "600" }}
            className="font-12"
          >
            Download as PDF
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const ReportsColumns = (props) => {

  const [checkboxesData,setCheckboxesData] = useState(props.checkboxes?.data);
  const [allCheck,setAllCheck] = useState();

  // Function to handle checkbox change
  function handleCheckboxChange(event, tableItem) {
    const { checked } = event.target; // Get checked status and key from the event
    const key = tableItem?.claimReportMetaData?.key; // Extract the key from the target
    console.log("key: ", key);
    console.log("checked: ", checked);
    console.log("props?.newTemplate: ",props?.newTemplate);
    
    // Find the item in the array by key and update isDefaultChecked value
    let claimReportMetaData = props?.newTemplate.map((item) =>
      item?.claimReportMetaData?.key === key
        ? { ...item, isDefaultChecked: checked }
        : item
    );

    // Logging updated data to check changes
    console.log(claimReportMetaData);
    props?.setNewTemplate(claimReportMetaData);
  }
  const handleTemplateObject = (e, columnsObject) => {
    // let checked = e.target.checked;
    // let key  = e.target.id;
    // let result =
    // let newObject = {columnsObject,isDefaultChecked:checked}
    // props?.setNewTemplate({...props?.newTemplate,newObject})
  };

  const handleCheckAll = (event) => {
    const { checked } = event.target; // Get checked status
    const tableName = event.target.id; // Get table name from the checkbox id
  
    console.log("tableName: ", tableName);
    console.log("checked: ", checked);
  
    // Map over the newTemplate and update isDefaultChecked based on table name, checked status, and isEditable flag
    const updatedTemplate = props?.newTemplate.map((item) =>
      item?.claimReportMetaData?.displayTableName === tableName && item?.isEditable
        ? { ...item, isDefaultChecked: checked }
        : item
    );
  
      let checkboxData = props.checkboxes?.data.map((item) =>
         item?.isEditable
          ? { ...item, isDefaultChecked: checked }
          : item
      ); 
      console.log("checkboxData: ",checkboxData);
      for (let item of checkboxData){
        if(item.isEditable){
          document.getElementById(item.claimReportMetaData.key).checked = checked;
        }
      }
      // setCheckboxesData(p=>checkboxData)
    
    // Log the updated template to verify changes
    console.log("Updated Template: ", updatedTemplate);
    
    // Update the state with the new template
    props?.setNewTemplate(updatedTemplate);
  };

  useEffect(()=>{
    setCheckboxesData(props.checkboxes?.data)
  },[props.checkboxes?.data])

  useEffect(()=>{
    let defaultChecked = true;
    for(let item of props.checkboxes?.data){
      if(item.isDefaultChecked === false){
        defaultChecked = false
        break;
      }
    }
    console.log("defaultChecked: ",defaultChecked);
    
    if(defaultChecked){
      setAllCheck(true);
    }
  },[props.checkboxes?.data])
    console.log("allCheck: ",allCheck,props?.heading);
    
  return (
    <>
      <div className="d-flex flex-row justify-content-between">
        <span className="font-12 fw-sb text--terciary">{props?.heading}</span>
        <input
          onClick={(e)=>handleCheckAll(e)}
          defaultChecked={allCheck}
          type="checkbox"
          className="form-check-input mt-1 shadow-none cursor--pointer"
          id={props?.heading}//display table name
        />
      </div>
      <div
        className={`form-check ms-1 mt-2 ${classes["scroll__bar"]}`}
        style={{ maxHeight: "200px", minHeight: "150px", overflowY: "auto" }}
      >
        {checkboxesData.map((item) => {
          return (
            <div
              key={item?.claimReportMetaData?.key}
              className="form-check"
              hidden={!item?.isDisplay}
            >
              <input
                className="form-check-input pn-checkbox shadow-none cursor--pointer"
                type="checkbox"
                id={item?.claimReportMetaData?.key}
                defaultChecked={item?.isDefaultChecked}
                disabled={!item?.isEditable}
                onClick={(e) => {
                  handleCheckboxChange(e, item);
                }}
              />
              <label
                className="form-check-label font-12"
                htmlFor={item?.claimReportMetaData?.key}
              >
                {item?.claimReportMetaData?.displayColumnName}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

const ReportingDashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [tablesMetaData, setTablesMetaData] = useState([]);
  const [isTablesMetaDataLoading, setIsTablesMetaDataLoading] = useState(false);
  const [newTemplate, setNewTemplate] = useState();
  const [claimServiceTemplate, setClaimServiceTemplate] = useState();

  const [isTemplateSaving, setIsTemplateSaving] = useState(false);
  const [templateSavingMsg, setTemplateSavingMsg] = useState("");
  const [templateSavingSeverity, setTemplateSavingSeverity] = useState("");
  const [openSavingSnackbar, setOpenSavingSnackbar] = useState(false);
  const [templateName, setTemplateName] = useState();
  const [claimTemplateType, setClaimTemplateType] = useState(1);

  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);
  const [tenantTemplates, setTenantTemplates] = useState([]);

  const [openTemplatetypeModal, setOpenTemplatetypeModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateTemplateObj, setUpdateTemplateObj] = useState();

  const selectRef = useRef(null); // Create a ref for the select element


  // Function to format JSON data as specified
  function formatData(data) {
    const groupedData = {};

    // Group by displayTableName
    data.forEach((item) => {
      const tableName = item.displayTableName;

      // Initialize the entry if it doesn't exist
      if (!groupedData[tableName]) {
        groupedData[tableName] = [];
      }

      // Push the item into the corresponding group
      groupedData[tableName].push({
        isDefaultChecked: item?.isDefaultChecked,
        isDisplay: item?.isDisplay,
        isEditable: item?.isEditable,
        sequence_number: item?.sequence_number,
        claimReportMetaData: { ...item },
      });
    });

    // Create the final formatted array
    const result = Object.keys(groupedData).map((tableName) => ({
      displayTableName: tableName,
      data: groupedData[tableName],
    }));

    return result;
  }
  function formatDataTenantTemplate(inputArray, templateId) {
    const formattedData = [];

    inputArray.forEach((item) => {
      const tableName = item.claimReportMetaData.displayTableName;

      // Update outer values based on the claimReportMetaData values
      item.claimReportMetaData.isDefaultChecked = item.isDefaultChecked;
      item.claimReportMetaData.isDisplay = item.isDisplay;
      item.claimReportMetaData.isEditable = item.isEditable;

      let tableEntry = formattedData.find(
        (entry) => entry.displayTableName === tableName
      );

      if (!tableEntry) {
        tableEntry = {
          displayTableName: tableName,
          data: [],
        };
        formattedData.push(tableEntry);
      }

      tableEntry.data.push(item);
    });
    console.log("formattedData: ", formattedData);

    return formattedData.reduce((acc, item) => {
      const displayTableName = item?.displayTableName?.toLowerCase();
      if (templateId !== 1 || displayTableName !== "claim services") {
        acc.push({
          displayTableName: item.displayTableName,
          data: item.data,
        });
      }
      return acc;
    }, []);
  }
  function sortBySequenceNumber(items){
    return items.sort((a,b)=>{return a?.claimReportMetaData?.sequence_number - b?.claimReportMetaData?.sequence_number});
  }
  const fetchTablesMetaData = async () => {
    setIsTablesMetaDataLoading(true);

    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user?.tenantId}/meta-data`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // setTablesMetaData(httpResponse.data);
      // Group the JSON data
      const result = formatData(httpResponse.data);
      let templateList = [];

      result.forEach((item) => {
        templateList.push(...sortBySequenceNumber(item.data));
      });
      setTablesMetaData(result);
      setNewTemplate(templateList);
      // Output the result
      console.log("result: ",result);
    }

    setIsTablesMetaDataLoading(false);
  };

  function updateData(data, templateName, claimTemplateType) {
    return data.map((item) => ({
      ...item, // Spread the existing object
      claimReportTemplate: {
        templateName: templateName,
        claimTemplateType: {
          claimTemplateTypeId: claimTemplateType,
        },
      },
    }));
  }
  const saveTemplate = async () => {
    setIsTemplateSaving(true);
    let formattedData = updateData(
      newTemplate,
      templateName,
      claimTemplateType
    );
    console.log("formattedData: ", formattedData);

    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user.tenantId}/report-tempate`,
      method: "POST",
      data: {
        templateName: templateName,
        claimTemplateType: {
          claimTemplateTypeId: claimTemplateType,
        },
        tenant: { tenantId: user?.tenantId },
        claimReportTemplateDetail: newTemplate,
      },
    });
    if (!httpResponse.error) {
      setNewTemplate([]);
      setTablesMetaData([]);
      setTemplateName(undefined);
      setTemplateSavingMsg("Template Saved Successfully");
      setTemplateSavingSeverity("success");
      setOpenSavingSnackbar(true);
      fetchTenantTemplates();
    } else {
      console.log(httpResponse);

      setTemplateSavingMsg(httpResponse.error);
      setTemplateSavingSeverity("error");
      setOpenSavingSnackbar(true);
    }
    setIsTemplateSaving(false);
  };
  const updateTemplate = async () => {
    setIsTemplateSaving(true);

    let claimReportTemplateId = updateTemplateObj?.claimReportTemplateId;

    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user.tenantId}/report-tempate/${claimReportTemplateId}`,
      method: "PUT",
      data: {
        ...updateTemplateObj,
        claimReportTemplateDetail: newTemplate,
        templateName: templateName,
      },
    });
    if (!httpResponse.error) {
      setNewTemplate([]);
      setTablesMetaData([]);
      setTemplateName(undefined);
      setUpdateTemplateObj(undefined);
      setTemplateSavingMsg("Template updated Successfully");
      setTemplateSavingSeverity("success");
      setOpenSavingSnackbar(true);
      fetchTenantTemplates();
    } else {
      console.log(httpResponse);

      setTemplateSavingMsg(httpResponse.error);
      setTemplateSavingSeverity("error");
      setOpenSavingSnackbar(true);
    }
    setIsTemplateSaving(false);
  };

  const fetchTenantTemplates = async () => {
    setIsTemplatesLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user.tenantId}/report-templates`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setIsUpdate(false);
      setTenantTemplates(httpResponse.data);
      setNewTemplate([]);
    }
    setIsTemplatesLoading(false);
  };

  const handleNewTemplate = () => {
    if (selectRef.current) {
      selectRef.current.value = "Select Template"; // Reset the select value
    }
    setIsUpdate(false);
    setTemplateName(p=>undefined);
    fetchTablesMetaData();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSavingSnackbar(false);
  };
  // console.log("New Template: ",newTemplate);

  const handleTemplateLoading = (e) => {
    setIsUpdate(true);
    let templateId = e.target.value;
    let template = tenantTemplates.find((item) => {
      return item?.claimReportTemplateId === Number(templateId);
    });

    if (template !== undefined) {
      setTemplateName(template.templateName);
      setUpdateTemplateObj(template);
      setClaimTemplateType(template?.claimTemplateType?.claimTemplateTypeId);
      const result = formatDataTenantTemplate(
        template?.claimReportTemplateDetail,
        Number(templateId)
      );
      let templateList = [];

      result.forEach((item) => {
        templateList.push(...sortBySequenceNumber(item.data));
      });
      setTablesMetaData(result);
      setNewTemplate(template?.claimReportTemplateDetail);
      if (Number(template?.claimTemplateType?.claimTemplateTypeId) === 2) {
        let serviceTemp = result.find((item) => {
          return item.displayTableName?.toLowerCase() === "claim services";
        });

        if (serviceTemp !== undefined) {
          setClaimServiceTemplate(
            serviceTemp.data.filter((item) => {
              return item.isDefaultChecked === true;
            })
          );
        }
      }
    }
  };

  const handleTmeplateTypeModal = () => {
    setOpenTemplatetypeModal(false);
  };

  console.log("tablesMetaData: ", tablesMetaData);
  console.log("newTemplate: ", newTemplate);
  console.log("claimServiceTemplate: ", claimServiceTemplate);

  useEffect(() => {
    fetchTenantTemplates();
  }, []);
  return (
    <>
      {openTemplatetypeModal ? (
        <ClaimTemplateTypeModal
          show={openTemplatetypeModal}
          handleModal={handleTmeplateTypeModal}
          setClaimTemplateType={setClaimTemplateType}
          handleNewTemplate={handleNewTemplate}
        />
      ) : null}
      <PageHeader title={"Report Builder"} />
      <div className="container">
        <div className="row border--bottom pb-3 mt-3 d-flex justify-content-between align-items-center">
          {/* Left section: Select Report Template */}
          <div className="col-5 d-flex flex-row justify-contnent-between">
            <div className="row">
              <div className="col-9">
                {isTemplatesLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <label className="font-12 text--tericary">
                      {"Select Report Template"}
                    </label>
                    <select
                      ref={selectRef}
                      className="form-select font-12 shadow-none input br-10 border--default"
                      onChange={(e) => {
                        handleTemplateLoading(e);
                      }}
                    >
                      <option>{"Select Template"}</option>
                      {tenantTemplates.map((item) => {
                        return (
                          <option
                            key={item?.claimReportTemplateId}
                            value={item?.claimReportTemplateId}
                            id={item?.claimReportTemplateId}
                          >
                            {item?.templateName}
                          </option>
                        );
                      })}
                    </select>
                  </>
                )}
              </div>
              <div className="col-3 ">
                <label>&nbsp;</label>
                <button
                  className="blue_task_button"
                  onClick={() => {
                    // handleNewTemplate();
                    
                    setOpenTemplatetypeModal(true);
                  }}
                >
                  {"New Template"}
                </button>
              </div>
            </div>
          </div>

          {/* Right section: Download and View Report buttons */}
          <div className="col-3 d-flex justify-content-end">
            {/* <ReportsDownloadMenu /> */}
            {/* <button className="d9e8ec--button">{"Save"}</button> */}
            <button
              className="blue_task_button ms-2"
              onClick={() => {
                navigate("/claims-reports", {
                  state: {
                    template: newTemplate,
                    templateType: claimTemplateType,
                    claimServiceTemplate: claimServiceTemplate,
                  },
                });
              }}
              disabled={
                // newTemplate === undefined ? true:
                // newTemplate?.length === 0 ? true:
                // updateTemplateObj === undefined
                !isUpdate
              }
            >
              {"View Report"}
            </button>
          </div>
        </div>
      </div>

      <div className="container mb-3">
        {isTablesMetaDataLoading ? (
          <Skeleton />
        ) : (
          <>
            <div className="row">
              <div className="col-12" hidden={tablesMetaData?.length === 0}>
                <span className="font-14 fw-sb text--terciary">
                  {"Report Columns"}
                </span>
              </div>
              {tablesMetaData?.length > 0
                ? tablesMetaData?.map((tableItem) => {
                    if (
                      tableItem?.data?.length > 0 &&
                      (claimTemplateType !== 1 ||
                        tableItem?.displayTableName?.toLowerCase() !==
                          "claim services")
                    ) {
                      return (
                        <div className="col-3 border--right mt-3">
                          <ReportsColumns
                            key={tableItem?.displayTableName}
                            checkboxes={tableItem}
                            heading={tableItem?.displayTableName}
                            setNewTemplate={setNewTemplate}
                            newTemplate={newTemplate}
                            tablesMetaData={tablesMetaData}
                          />
                        </div>
                      );
                    }
                  })
                : null}
            </div>
            <div
              className="row mt-3 mb-3 border--bottom border--top p-3 text-end"
              hidden={tablesMetaData?.length === 0}
            >
              <div className="col-9"></div>
              <div className="col-3 d-flex flex-row justify-content-between">
                <input
                  type="text"
                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  placeholder="Template Name"
                  onChange={(e) => {
                    setTemplateName(e.target.value);
                  }}
                  defaultValue={templateName !== undefined ? templateName : ""}
                />
                <button
                  className="blue_task_button"
                  onClick={() => {
                    if (isUpdate) {
                      updateTemplate();
                    } else {
                      saveTemplate();
                    }
                  }}
                  disabled={
                    isTemplateSaving ||
                    newTemplate === undefined ||
                    templateName === undefined
                  }
                >
                  {isUpdate ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isTemplateSaving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CustomizedSnackbars
        message={templateSavingMsg}
        severity={templateSavingSeverity}
        open={openSavingSnackbar}
        handleClose={handleClose}
      />
      {console.log("newTemplate: ",newTemplate)
      }
    </>
  );
};

export default ReportingDashboard;
